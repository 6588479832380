import axios from "axios";
const TRANS_URL = process.env.REACT_APP_TRANSACTION_API_URL;

export async function getDesignReviewTrackerData(ProjectCode) {
  let url = `${TRANS_URL}/designreviewtracker/getDesignReviewTrackerData/${encodeURIComponent(ProjectCode)}`;
  const response = await axios
    .get(url)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      console.log("error => ",error );
      return {data:[]};
    });
  return response.data;
}

//----------------Delete Design review tracker ----------------------------
export const deleteDesignReviewTracker = async (delBody) => {
  let url = `${TRANS_URL}/designreviewtracker/deleteDesignReviewTracker`;
  //console.log(url)
  const response = await axios
    .post(url, delBody)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      console.log("error => ",error );
      return {data: { message: "Unable to find request", status: false }};
    });
  return response.data;
};

//======================Add new DRT record ==========================
export const addDesignReviewTracker = async (DRT) => {
  let url = `${TRANS_URL}/designreviewtracker/addDesignReviewTracker`;
  const response = await axios
    .post(url, DRT)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      console.log("error => ",error );
      return {data: { message: "Unable to find request", status: false }};
    });
  return response.data;
};

//======================Update DRT record ==========================
export const updateDesignReviewTracker = async (drtObj,newFileFlg) => {
  let url = `${TRANS_URL}/designreviewtracker/updateDesignReviewTracker/${encodeURIComponent(newFileFlg)}`;
  ///console.log(url)
  const response = await axios
    .post(url, drtObj)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      console.log("error => ",error );
      return {data: { message: "Unable to find request", status: false }};
    });
  return response.data;
};

//download file from s3
export async function downloadAttachment(key, fileName) {
  let s3key = encodeURIComponent(key);
  let s3fileName = encodeURIComponent(fileName);
  let url = `${TRANS_URL}/attachment/downloadFile/key/${s3key}/fileName/${s3fileName}`;
  const response = await axios
    .get(url)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      console.log("error => ", error)
      return { data :{ message: "Unable to fetch File", status: false} };
    });
  let preSignedUrl = response.data;
  const link = document.createElement("a");
  //link.href = preSignedUrl;
  link.setAttribute("href", preSignedUrl);
  link.setAttribute("download", fileName);
  // link.setAttribute("target", "_blank");
  //document.body.appendChild(link);
  link.click();
  //URL.revokeObjectURL(link.href);
  //link.parentNode.removeChild(link);
  link.remove();
}


