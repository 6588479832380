import React, { useState, useEffect, useContext } from "react";
import { Container, Spinner } from "react-bootstrap";
import ActionComp from "../../layouts/ActionComp"
import BreadcrumbComp from "../../layouts/BreadcrumbComp";
import GridMaster from "../GridMaster";
import StatusRenderer from "../StatusRenderer.jsx";
import ActionRenderer from "../ActionRenderer";
import AddEditProjectMaster from "../ProjectMaster/AddEditProjectMaster";
import { deleteProject, getProjectData, updateProjectInactive, DeleteProjectTeam, DeleteProjectOrder, DeleteProjectService, getUserWiseAllProjects } from "../../../services/project-service";
import { useDispatch } from "react-redux";
import { menuActions } from "../../../store/menu-slice";
import { searchActions } from "../../../store/grid-slice";
import { exportToExcel } from "./ExcelCreation";
import { formatDateInDDMMYYYY, formatDateInYYYYMMDD, getCurrentDateTime } from "../../../functions/gbcs-functions";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import successImage from "../../../icons/success-tick-logo.svg";
import errorImage from "../../../icons/error-tick-logo.svg";
import { AuthContext } from "../../../contexts/AuthContext";
import { getRolesForUnmaskedData } from "../../../services/common-master-service";
import escapeExcelData from "../../utils/customValidation";
import { getProjTranChklst } from "../../../services/project-transaction-service";
import { addLogDetails } from "../../../services/logger-entry-service";
import { ALERT_PARAMS } from "../../../utils/constant.js";

const ProjectMaster = () => {
  const MySwal = withReactContent(Swal);
  const context = useContext(AuthContext);
  const userDetails = { email: context.userEmail, userName: context.userName, role: context.role, dataControl: context.dataControl, userId: context.userId }
  const [rowData, setRowData] = useState({});
  const [filteredResult, setFilteredResult] = useState([]);
  const [filteredResulttemp, setFilteredResulttemp] = useState([]);
  const [filterColLength, setFilterColLength] = useState(0);
  const [action, setAction] = useState("");
  const [reload, setReload] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const dispatch = useDispatch();
  const [excelData, setExcelData] = useState([]);
  const [resetFilter, setResetFilter] = useState(true);
  const [loading, setLoading] = useState(true);

  dispatch(menuActions.setMenu("Project Master"));
  const columnDefs = [
    {
      headerName: "Status",
      field: "DeleteIndication",
      flex: false,
      resizable: false,
      tooltipField: "DeleteIndication",
      cellRenderer: StatusRenderer
    },
    {
      headerName: "LOB",
      field: "LOB",
      tooltipField: "LOB",
      minWidth: 150,
    },
    {
      headerName: "Project Code",
      field: "ProjectCode",
      tooltipField: "ProjectCode",
      minWidth: 150,
    },
    {
      headerName: "Project Name",
      field: "ProjectName",
      tooltipField: "ProjectName",
      minWidth: 150,
    },
    {
      headerName: "Country",
      field: "Country",
      tooltipField: "Country",
      minWidth: 150,
    },
    {
      headerName: "Location",
      field: "Location",
      tooltipField: "Location",
      minWidth: 150
    },
    {
      headerName: "Start Date",
      field: "ProjStartDate",
      tooltipField: "ProjStartDate",
      minWidth: 150,
      filter: "agDateColumnFilter",
      valueFormatter: (params) => {
        return formatDateInDDMMYYYY(params.data.ProjStartDate);
      },
      filterParams: {
        comparator: (filterLocalDateAtMidnight, cellValue) => {
          const dateAsString = cellValue;
          if (dateAsString == null) {
            return 0;
          }
          // In the example application, dates are stored as dd/mm/yyyy
          // We create a Date object for comparison against the filter date
          const dateCell = new Date(formatDateInYYYYMMDD(cellValue) + " 00:00:00");

          if (dateCell < filterLocalDateAtMidnight) {
            return -1;
          } else if (dateCell > filterLocalDateAtMidnight) {
            return 1;
          }
          return 0;
        },
      },
    },
    {
      headerName: "End Date",
      field: "ProjEndDate",
      tooltipField: "ProjEndDate",
      minWidth: 150,
      filter: "agDateColumnFilter",
      valueFormatter: (params) => {
        return formatDateInDDMMYYYY(params.data.ProjEndDate);
      },
      filterParams: {
        comparator: (filterLocalDateAtMidnight, cellValue) => {
          const dateAsString = cellValue;
          if (dateAsString == null) {
            return 0;
          }
          // In the example application, dates are stored as dd/mm/yyyy
          // We create a Date object for comparison against the filter date
          const dateCell = new Date(formatDateInYYYYMMDD(cellValue) + " 00:00:00");

          if (dateCell < filterLocalDateAtMidnight) {
            return -1;
          } else if (dateCell > filterLocalDateAtMidnight) {
            return 1;
          }
          return 0;
        },
      },
    },
    {
      headerName: "Revised End Date",
      field: "RevisedProjEndDate",
      tooltipField: "RevisedProjEndDate",
      minWidth: 150,
      filter: "agDateColumnFilter",
      valueFormatter: (params) => {
        return formatDateInDDMMYYYY(params.data.RevisedProjEndDate);
      },
      filterParams: {
        comparator: (filterLocalDateAtMidnight, cellValue) => {
          const dateAsString = cellValue;
          if (dateAsString == null) {
            return 0;
          }
          // In the example application, dates are stored as dd/mm/yyyy
          // We create a Date object for comparison against the filter date
          const dateCell = new Date(formatDateInYYYYMMDD(cellValue) + " 00:00:00");

          if (dateCell < filterLocalDateAtMidnight) {
            return -1;
          } else if (dateCell > filterLocalDateAtMidnight) {
            return 1;
          }
          return 0;
        },
      },
    },
    {
      headerName: "Customer Name",
      field: "CustomerName",
      tooltipField: "CustomerName",
      minWidth: 150,
    },
    {
      headerName: "Sector",
      field: "Sector",
      tooltipField: "Sector",
      minWidth: 150,
    },
    {
      headerName: "Project Area",
      field: "ProjArea",
      tooltipField: "ProjArea",
      minWidth: 150,
      filter: "agNumberColumnFilter",
      valueGetter: params => {
        return parseFloat(params.data.ProjArea.replaceAll(",", ""));
      },
      valueFormatter: (params) => {
        return params.data.ProjArea;
      },
      comparator: (text, cellValue) => {
        if (parseFloat(cellValue) > parseFloat(text)) {
          return -1;
        } else if (parseFloat(cellValue) < parseFloat(text)) {
          return 1;
        }
        return 0;
      }
    },
    {
      headerName: "Order Value",
      field: "OriginalOrderValue",
      tooltipField: "OriginalOrderValue",
      minWidth: 150,
      filter: "agNumberColumnFilter",
      valueGetter: params => {
        return parseFloat(params.data.OriginalOrderValue.replaceAll(",", ""));
      },
      valueFormatter: (params) => {
        return params.data.OriginalOrderValue;
      },
      comparator: (text, cellValue) => {
        if (parseFloat(cellValue) > parseFloat(text)) {
          return -1;
        } else if (parseFloat(cellValue) < parseFloat(text)) {
          return 1;
        }
        return 0;
      }
    },
    {
      headerName: "Godrej Employee Name",
      field: "GodrejEmpName",
      tooltipField: "GodrejEmpName",
      minWidth: 150,
      resizable: false
    },
    {
      field: "",
      minWidth: 120,
      width: 120,
      cellRenderer: function (params) {
        return <ActionRenderer selectedData={params.data} handleViewEvent={handleView} handleEditEvent={handleEdit} handleDeleteEvent={handleDelete} />
      },
      sortable: false,
      filter: false,
      pinned: 'right',
      resizable:false
    },
  ];

  const handleDelete = async (params) => {
    let projresult;
    MySwal.fire({
      text: "Are you sure, do you want to delete project details?",
      confirmButtonText: "Delete",
      title: "Delete Project",
      ...ALERT_PARAMS
    }).then(async (result2) => {
      if (result2.isConfirmed) {
        const [FATChklstData, SATChklstData] = await Promise.all([getProjTranChklst("FAT", params.ProjectCode), getProjTranChklst("SAT", params.ProjectCode)]);
        let obj = { "ProjectCode": params.ProjectCode }
        let projectresult = await getProjectData(obj);
        let dsrData = projectresult.filter((item) => item.SK.startsWith("DesignReviewTracker#"))
        if (dsrData?.length > 0 || FATChklstData?.length > 0 || SATChklstData?.length > 0) {
          let projObj = {};
          let todayDate = getCurrentDateTime("-");
          projObj.PK = params.PK;
          projObj.SK = params.SK;
          projObj.ModifiedBy = userDetails.userId;
          projObj.ModifiedByName = userDetails.userName;
          projObj.ModifiedOn = todayDate;
          projObj.DeleteIndication = "Inactive";
          projresult = await updateProjectInactive(projObj);
          if (projresult.status) {
            await addLogDetails(params, "Delete", "SK#" + params.SK, "ProjectMasterLog", userDetails, "Entry");
            MySwal.fire({
              text: "Transactions are already linked to Project. Thus, selected project cannot be Permanently Deleted and is updated as Inactive.",
              imageUrl: successImage,
              customClass: "swal-success",
              didClose: () => {
                setReload(!reload);
              },
            });
          }
          else {
            MySwal.fire({
              text: "Unable to process request.",
              imageUrl: errorImage,
              customClass: "swal-success",
            });
          }
        }
        else {
          projresult = await deleteProject(params.ProjectCode);

          if (projresult.status) {
            await addLogDetails(params, "Delete", "SK#" + params.SK, "ProjectMasterLog", userDetails, "Entry");
            for (let dt of projectresult) {
              if (dt.SK.startsWith("ProjectTeam#")) {
                let projObj = { PK: dt.PK, SK: dt.SK };
                await DeleteProjectTeam(projObj);
                await addLogDetails(dt, "Delete", "PK#" + dt.PK + "#SK#" + dt.SK, "ProjectMasterLog", userDetails, "Entry");
              }
              else if (dt.SK.startsWith("ProjectOrder#")) {
                let projObj = { PK: dt.PK, SK: dt.SK };
                await DeleteProjectOrder(projObj);
              }
              else if (dt.SK.startsWith("ProjectService#")) {
                let projObj = { PK: dt.PK, SK: dt.SK };
                await DeleteProjectService(projObj);
                await addLogDetails(dt, "Delete", "PK#" + dt.PK + "#SK#" + dt.SK, "ProjectMasterLog", userDetails, "Entry");
              }
            }
            MySwal.fire({
              text: "The Project is Permanently Deleted.",
              imageUrl: successImage,
              customClass: "swal-success",
              didClose: () => {
                setReload(!reload);
              },
            });
          }
          else {
            MySwal.fire({
              text: "Unable to process request.",
              imageUrl: errorImage,
              customClass: "swal-success",
            });
          }
        }
      }
    });
  }

  const headerName = (field) => {
    let name;
    switch (field) {
      case "DeleteIndication":
        name = "Status";
        break;
      case "LOB":
        name = "LOB";
        break;
      case "ProjectCode":
        name = "Project Code";
        break;
      case "ProjectName":
        name = "Project Name";
        break;
      case "Country":
        name = "Country";
        break;
      case "Location":
        name = "Location";
        break;
      case "ProjStartDate":
        name = "Start Date";
        break;
      case "ProjEndDate":
        name = "End Date";
        break;
      case "RevisedProjEndDate":
        name = "Revised End Date";
        break;
      case "CustomerName":
        name = "Customer Name";
        break;
      case "Sector":
        name = "Sector";
        break;
      case "ProjArea":
        name = "Project Area";
        break;
      case "OriginalOrderValue":
        name = "Order Value";
        break;
      case "GodrejEmpName":
        name = "Godrej Employee Name";
        break;
      default:
        name = "No match found";
        break;
    }
    return name;
  };

  const fieldName = (header) => {
    let name;
    switch (header) {
      case "Status":
        name = "DeleteIndication";
        break;
      case "LOB":
        name = "LOB";
        break;
      case "Project Code":
        name = "ProjectCode";
        break;
      case "Project Name":
        name = "ProjectName";
        break;
      case "Country":
        name = "Country";
        break;
      case "Location":
        name = "Location";
        break;
      case "Start Date":
        name = "ProjStartDate";
        break;
      case "End Date":
        name = "ProjEndDate";
        break;
      case "Revised End Date":
        name = "RevisedProjEndDate";
        break;
      case "Customer Name":
        name = "CustomerName";
        break;
      case "Sector":
        name = "Sector";
        break;
      case "Project Area":
        name = "ProjArea";
        break;
      case "Order Value":
        name = "OriginalOrderValue";
        break;
      case "Godrej Employee Name":
        name = "GodrejEmpName";
        break;
      default:
        name = "No match found";
        break;
    }
    return name;
  };

  const fetchProjectMaster = async () => {
    setLoading(true);
    dispatch(searchActions.setSearch(""));
    let sortedProjects = await getUserWiseAllProjects(userDetails, true);
    setFilteredResult(sortedProjects);
    setExcelData(sortedProjects);
    setResetFilter(!resetFilter);
    setLoading(false);
  };

  useEffect(() => {
    fetchProjectMaster();
  }, [reload]);

  const handleView = async (row) => {
    setAction("View");
    setRowData(row);
    setShowForm(true);
  }

  const handleEdit = async (row) => {
    setAction("Edit");
    setRowData(row);
    setShowForm(true);
  }

  const handleAdd = () => {
    setAction("Add");
    setShowForm(true);
  };

  const handleGlobalExport = async () => {
    let filename = "Project Master Details";
    let tempArr = [];
    let result = JSON.parse(JSON.stringify(excelData));
    let stakelen = 0;
    let rolesData = await getRolesForUnmaskedData().catch((err) => { console.log(err) });
    let showMaskedDataInd = -1;
    if (rolesData.length > 0) {
      showMaskedDataInd = rolesData[0].RoleCode.trim().split(",").indexOf(userDetails.role);
    }

    let orderArr = [];
    let teamArr = [];
    let serviceArr = [];
    for (let element of result) {
      element.CreatedOn = formatDateInDDMMYYYY(element.CreatedOn);
      element.ModifiedOn = formatDateInDDMMYYYY(element.ModifiedOn);
      element.ProjStartDate = formatDateInDDMMYYYY(element.ProjStartDate);
      element.ProjEndDate = formatDateInDDMMYYYY(element.ProjEndDate);
      element.RevisedProjEndDate = formatDateInDDMMYYYY(element.RevisedProjEndDate);

      if (showMaskedDataInd !== -1) {
        await addLogDetails(element, "Download", "SK#" + element.SK, "ProjectMasterLog", userDetails, "Entry");
      }

      let ele = { "ProjectCode": element.ProjectCode }
      let projectresult = await getProjectData(ele)
      for (let k = 0; k < projectresult.length; k++) {
        projectresult[k].CreatedOn = formatDateInDDMMYYYY(projectresult[k].CreatedOn);
        projectresult[k].ModifiedOn = formatDateInDDMMYYYY(projectresult[k].ModifiedOn);


        if ((projectresult[k].SK).startsWith("ProjectOrder#SeqNo#")) {
          orderArr.push(projectresult[k]);
        }
        else if ((projectresult[k].SK).startsWith("ProjectTeam#SeqNo#")) {
          if (showMaskedDataInd === -1) {
            projectresult[k].MobileNo = escapeExcelData(projectresult[k].MobileNo, true);
            projectresult[k].EmailId = escapeExcelData(projectresult[k].EmailId, true);
          }
          else {
            await addLogDetails(projectresult[k], "Download", "PK#" + projectresult[k].PK + "#SK#" + projectresult[k].SK, "ProjectMasterLog", userDetails, "Entry");
          }
          teamArr.push(projectresult[k]);
        }
        else if ((projectresult[k].SK).startsWith("ProjectService#SeqNo#")) {
          if (showMaskedDataInd == -1) {
            projectresult[k].MobileNo = escapeExcelData(projectresult[k].MobileNo, true);
            projectresult[k].EmailId = escapeExcelData(projectresult[k].EmailId, true);
          }
          else {
            await addLogDetails(projectresult[k], "Download", "PK#" + projectresult[k].PK + "#SK#" + projectresult[k].SK, "ProjectMasterLog", userDetails, "Entry");
          }
          serviceArr.push(projectresult[k]);
        }
      }

      if (showMaskedDataInd == -1) {
        element.ClientContactNo1 = escapeExcelData(element.ClientContactNo1, true);
        element.ClientContactNo2 = escapeExcelData(element.ClientContactNo2, true);
        element.GodrejContactNo = escapeExcelData(element.GodrejContactNo, true);
        element.ClientEmail1 = escapeExcelData(element.ClientEmail1, true);
        element.ClientEmail2 = escapeExcelData(element.ClientEmail2, true);
        element.GodrejEmailId = escapeExcelData(element.GodrejEmailId, true);
      }

      if (element.StakeholderDetails.length !== 0) {
        let cnt = 0;
        element.StakeholderDetails.forEach(ele => {
          let mskedPMCOfficialContNo = ele.PMCOfficialContNo;
          let mskedPMCEmailId = ele.PMCEmailId;
          if (showMaskedDataInd == -1) {
            mskedPMCOfficialContNo = escapeExcelData(ele.PMCOfficialContNo, true);
            mskedPMCEmailId = escapeExcelData(ele.PMCEmailId, true);
          }
          if (cnt === 0) {
            element["StakeholderType"] = ele.StakeholderType;
            element["StakeCompanyDivision"] = ele.StakeCompanyDivision;
            element["PMCContactPerson"] = ele.PMCContactPerson;
            element["PMCOfficialContNo"] = mskedPMCOfficialContNo;
            element["PMCEmailId"] = mskedPMCEmailId;
          }
          else {
            element["StakeholderType-" + cnt] = ele.StakeholderType;
            element["StakeCompanyDivision-" + cnt] = ele.StakeCompanyDivision;
            element["PMCContactPerson-" + cnt] = ele.PMCContactPerson;
            element["PMCOfficialContNo-" + cnt] = mskedPMCOfficialContNo;
            element["PMCEmailId-" + cnt] = mskedPMCEmailId;
          }
          cnt++;
        })
        if (stakelen < cnt) {
          stakelen = cnt;
        }
      }
      tempArr.push(element);
    }

    // setExcelData(tempArr);
    let colName = ["Project Status", "LOB", "Project Type", "Project Code", "Project Name", "Location Type", "Location", "Country", "Site Address", "Sector", "Project Area", "Zone", "Project Start Date", "Project End Date", "Revised Project End Date", "Original Order Value", "Revised Order Value", "IST Applicable for Critical Area", "Godrej ENE Employee", "Email Id", "Contact No", "Customer", "Customer Contact Person - 1", "Contact No - 1", "Email id - 1", "Customer Contact Person - 2", "Customer Contact No - 2", "Customer Email Id - 2", "Stakeholder Type", "Stakeholder Company/Division", "Stakeholder Contact Person", "Stakeholder Contact No", "Stakeholder Email Id"];
    let Fieldname = ["DeleteIndication", "LOB", "ProjectType", "ProjectCode", "ProjectName", "LocationType", "Location", "Country", "SiteAddress", "Sector", "ProjArea", "Zone", "ProjStartDate", "ProjEndDate", "RevisedProjEndDate", "OriginalOrderValue", "RevisedOrderValue", "ISTApplicable", "GodrejEmpName", "GodrejEmailId", "GodrejContactNo", "CustomerName", "ClientContactPerson1", "ClientContactNo1", "ClientEmail1", "ClientContactPerson2", "ClientContactNo2", "ClientEmail2", "StakeholderType", "StakeCompanyDivision", "PMCContactPerson", "PMCOfficialContNo", "PMCEmailId"];
    if (stakelen > 0) {
      for (let i = 1; i < stakelen; i++) {
        colName.push("Stakeholder Type - " + i, "Stakeholder Company  / Division - " + i, "Stakeholder Contact Person - " + i, "Stakeholder Contact No - " + i, "Stakeholder Email Id - " + i);
        Fieldname.push("StakeholderType-" + i, "StakeCompanyDivision-" + i, "PMCContactPerson-" + i, "PMCOfficialContNo-" + i, "PMCEmailId-" + i);
      }
    }
    colName.push("Inserted By", "Inserted On", "Modified By", "Modified On");
    Fieldname.push("CreatedBy", "CreatedOn", "ModifiedByName", "ModifiedOn");
    let colNameOrder = ["Project Status", "Project Code", "Project", "Sr. No.", "Document Type", "Document Name", "Attachment", "Inserted By", "Inserted On", "Modified By", "Modified On"];
    let FieldnameOrder = ["DeleteIndication", "ProjectCode", "ProjectName", "SeqNo", "DocumentType", "DocumentName", "Attachment", "CreatedBy", "CreatedOn", "ModifiedBy", "ModifiedOn"];
    let colNameTeam = ["User Status", "Project Code", "Project", "Sr. No.", "Stakeholder Type", "User Name", "Designation", "Company Or Division", "Role", "Mobile No.", "Email Id", "Inserted By", "Inserted On", "Modified By", "Modified On"];
    let FieldnameTeam = ["DeleteIndication", "ProjectCode", "ProjectName", "SeqNo", "StakeholderType", "UserName", "Designation", "CompanyOrDivision", "Role", "MobileNo", "EmailId", "CreatedBy", "CreatedOn", "ModifiedBy", "ModifiedOn"];
    let colNameService = ["Project Equipment / Activity Status", "Project Code", "Project", "Sr. No.", "Service Name", "Equipment / Activity", "Equipment / Activity Tag", "Contractor Or Vendor", "Mobile No", "Email Id", "Inserted By", "Inserted On", "Modified By", "Modified On"];
    let FieldnameService = ["DeleteIndication", "ProjectCode", "ProjectName", "SeqNo", "ServiceName", "EquipmentName", "EquipmentTag", "Contractor", "MobileNo", "EmailId", "CreatedBy", "CreatedOn", "ModifiedBy", "ModifiedOn"];
    exportToExcel(filename, "Project Details", result, orderArr, teamArr, serviceArr, "A1:AN1", colName, Fieldname, colNameOrder, FieldnameOrder, colNameTeam, FieldnameTeam, colNameService, FieldnameService);
  };

  return (
    <>
      {loading && <div className="spinner-box"> <Spinner animation="border" className="spinner" /></div>}
      <BreadcrumbComp />
      <Container fluid className="main-container">
        <ActionComp label={"Add New Project"} exportEvent={() => handleGlobalExport()} handleAdd={handleAdd} />
        <GridMaster
          rowDetails={filteredResult}
          colDetails={columnDefs}
          fieldNames={fieldName}
          headerNames={headerName}
          getDataEvent={(getFilteredData) => setFilteredResulttemp(getFilteredData)}
          getFilterColLength={(getLength) => setFilterColLength(getLength)}
          handleView={handleView}
          handleEdit={handleEdit}
          setExcelData={setExcelData}
          openConfirmBox={handleDelete}
          resetind={resetFilter}
        />
        {showForm && (
          <AddEditProjectMaster
            show={showForm}
            action={action}
            rowdata={rowData}
            onHide={() => setShowForm(false)}
            setreload={setReload}
            reload={reload}
          />
        )}
      </Container>
    </>
  );
};

export default ProjectMaster;