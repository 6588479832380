import { createContext, Component } from "react";
import menuList from "../data/sessionmaster.json";

export const AuthContext = createContext();

class AuthContextProvider extends Component {
  constructor() {
    super();
    console.log("session", JSON.parse(sessionStorage.getItem("UserData")));

    const user = JSON.parse(sessionStorage.getItem("UserData"));
    console.log("sessionuser", user);
    let menuNames = [];



    if (user?.UserName) {
      let accessibleMenus = user?.accessibleMenu;
      accessibleMenus?.forEach((value) => {
        menuNames.push(value.SessionName);
        let parentmenu = menuList.filter((item) => item.SubMenu === value.SessionName)[0]?.ParentMenu;
        if (parentmenu !== undefined && menuNames.indexOf(parentmenu) === -1) {
          menuNames.push(parentmenu);
        }

      });
      this.state = {
        userName: user.UserName,
        userEmail: user.UserEmail,
        role: user.UserRoleCode,
        accessibleMenus: accessibleMenus,
        menuName: menuNames,
        userType: user.usertype,
        dataControl: user.dataControl,
        userId: user.userId,
        designation: user.designation,
        displayContractFinInfo: user.displayContractFinInfo,
        mobileNo: user.mobileNo,
        companyName: user.companyName,
        companyCode: user.companyCode
      };
    }
  }


  render() {
    return (
      <AuthContext.Provider value={{ ...this.state }}>
        {this.props.children}
      </AuthContext.Provider>
    );
  }
}

export default AuthContextProvider;
