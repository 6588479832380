import React, { useEffect, useState, useContext } from "react";
import { Button, Form, Modal, Row, Col, Spinner } from "react-bootstrap";
import {
    getAllCompanyDivisionData,
    getActiveSectorData,
    AddNewCompanyDivision,
    UpdateCompanyDivision
} from "../../services/companydivision-master-service";
import { getProjectsByCompany } from "../../services/project-service";
import { BiSelectMultiple } from "react-icons/bi";
import { isMobNumber } from "../../functions/validations";
import { RenderIf, getCurrentDateTime } from "../../functions/gbcs-functions";
import MultiValuePopUp from "../utils/MultiValuePopUp";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import seccessImage from "../../icons/success-tick-logo.svg";
import errorImage from "../../icons/error-tick-logo.svg";
import { AuthContext } from "../../contexts/AuthContext";
import { addLogDetails } from "../../services/logger-entry-service";
import { ALERT_PARAMS } from "../../utils/constant.js";

const AddEditCompanyDivisionMaster = (props) => {
    let action = props.action;
    const MySwal = withReactContent(Swal);
    const context = useContext(AuthContext);
    const userDetails = { email: context.userEmail, userName: context.userName, userId: context.userId }
    const [toggleClass, setToggleClass] = useState("activeToggleLabel")
    const [SectorCombo, setSectorCombo] = useState([]);
    const [showForm, setShowForm] = useState(false);
    const [ShowMobileNo, setShowMobileNo] = useState(!(action === "Edit" || action === "View"));
    const [loading, setLoading] = useState(false);
    const [companydivisionObj, setCompanyDivisionObj] = useState(
        action === "Edit" || action === "View"
            ? props.rowdata
            : {
                SK: "",
                PK: "",
                CompanyName: "",
                CompanyCode: "",
                DeleteIndication: "Active",
                CompanyLocationType: ["India"],
                CompanyCountry: "India",
                CompanyHOAddress: "",
                CompanyTelephoneNo: "",
                CompanyWebsiteAddress: "",
                CompanySectorCode: "",
                CompanySectorName: ""
            }

    );

    const [popUp, setPopUp] = useState({
        name: "",
        type: "",
        key: "",
        value: "",
    });

    const [countryDisplay, setCountryDisplay] = useState(
        ((action === "Edit" || action === "Add") && companydivisionObj.CompanyLocationType.indexOf("Overseas") >= 0) ? "" : "d-none"
    );

    const countryPopUp = "Country Master:" + companydivisionObj.CompanyLocationType;

    const [error, setError] = useState({
        CompanyLocationType: false,
        CompanyCountry: false,
        CompanyTelephoneNo: false,
        CompanyWebsiteAddress: false
    });

    const getSectorMasterData = async () => {
        setLoading(true);
        const result = await getActiveSectorData();
        setSectorCombo(result);
        setLoading(false);
    };

    useEffect(() => {
        getSectorMasterData();
        if (action === "Edit" || action === "View") {
            setCompanyDivisionObj((prevState) => ({
                ...prevState,
                CompanyLocationType: props.rowdata.CompanyLocationType.split(","),
            }));
            if (props.rowdata.DeleteIndication === "Active") {
                setToggleClass("activeToggleLabel")
            }
            else {
                setToggleClass("inactiveToggleLabel")
            }
        }
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (action == "View") {
            props.onHide();
        }
        else {
            let result;
            let validationResult = validateDetails()
            if (!validationResult) {
                setLoading(true);
                const result1 = await getAllCompanyDivisionData();
                if (result1.length > 0) {
                    let errMsg = "";
                    if (result1.filter((item) => (action === "Add" || (action === "Edit" && item.CompanyCode.toLowerCase() !== companydivisionObj.CompanyCode.toLowerCase())) && item.CompanyName.toLowerCase() === companydivisionObj.CompanyName.toLowerCase()).length > 0) {
                        errMsg = errMsg + "Company / Division already exists. ";
                    }
                    if (result1.length > 0 && result1.filter((item) => (action === "Add" || (action === "Edit" && item.CompanyCode.toLowerCase() !== companydivisionObj.CompanyCode.toLowerCase())) && item.CompanyTelephoneNo.toLowerCase() === companydivisionObj.CompanyTelephoneNo.toLowerCase()).length > 0) {
                        errMsg = errMsg + "Telephone No already exists. ";
                    }
                    if (result1.length > 0 && result1.filter((item) => (action === "Add" || (action === "Edit" && item.CompanyCode.toLowerCase() !== companydivisionObj.CompanyCode.toLowerCase())) && item.CompanyWebsiteAddress.toLowerCase() === companydivisionObj.CompanyWebsiteAddress.toLowerCase()).length > 0) {
                        errMsg = errMsg + "Website Address already exists. ";
                    }
                    if (action == "Edit" && companydivisionObj.DeleteIndication === "Inactive") {
                        const compResult = await getProjectsByCompany(companydivisionObj.CompanyCode);
                        if (compResult?.length > 0) {
                            let activeComp = compResult.filter((item) => item.DeleteIndication === "Active").length;
                            if (activeComp > 0) {
                                setLoading(false);
                                MySwal.fire({
                                    text: "Projects are already linked to " + companydivisionObj.CompanyName + " Company / Division. Thus, selected Company / Division cannot be Deleted.",
                                    imageUrl: errorImage,
                                    customClass: "swal-success"
                                });
                                return;
                            }
                        }
                    }
                    if (errMsg !== "") {
                        setLoading(false);
                        MySwal.fire({
                            text: errMsg,
                            imageUrl: errorImage,
                            customClass: "swal-success",
                        });
                        return;
                    }
                }

                let confirmBoxText = "";
                let confirmButtonText = "";
                if (action === "Add") {
                    confirmBoxText = "Are you sure, do you want to add new Company / Division?";
                    confirmButtonText = "Add";
                }
                else if (action == "Edit") {
                    confirmBoxText = "Are you sure, do you want to update Company / Division?";
                    confirmButtonText = "Update";
                }
                setLoading(false);
                MySwal.fire({
                    text: confirmBoxText,
                    confirmButtonText: confirmButtonText,
                    title: action + " Company / Division",
                    ...ALERT_PARAMS
                }).then(async (result2) => {
                    if (result2.isConfirmed) {
                        setLoading(true);
                        let todayDate = getCurrentDateTime("-");
                        if (action === "Add") {
                            let maxCompanyCode = "COM00001";
                            if (result1.length > 0) {
                                let latestCompanyCode = result1[result1.length - 1].CompanyCode;
                                let compNo = latestCompanyCode.substring(3, latestCompanyCode.length)
                                maxCompanyCode = "COM" + ((parseInt(compNo) + 1).toString().padStart(5, '0'));
                            }
                            companydivisionObj.CompanyLocationType = companydivisionObj.CompanyLocationType.toString();
                            companydivisionObj.CreatedBy = userDetails.userId;
                            companydivisionObj.CreatedByName = userDetails.userName;
                            companydivisionObj.CreatedOn = todayDate;
                            companydivisionObj.ModifiedBy = userDetails.userId;
                            companydivisionObj.ModifiedByName = userDetails.userName;
                            companydivisionObj.ModifiedOn = todayDate;
                            companydivisionObj.CompanyCode = maxCompanyCode;
                            companydivisionObj.PK = "CompanyDivisionMaster";
                            companydivisionObj.SK = "CompanyCode#" + maxCompanyCode;

                            result = await AddNewCompanyDivision(companydivisionObj);
                        } else if (action == "Edit") {
                            companydivisionObj.CompanyLocationType = companydivisionObj.CompanyLocationType.toString();
                            companydivisionObj.ModifiedBy = userDetails.userId;
                            companydivisionObj.ModifiedByName = userDetails.userName;
                            companydivisionObj.ModifiedOn = todayDate;
                            result = await UpdateCompanyDivision(companydivisionObj);
                        }
                        //debugger;
                        if (result.status) {
                            let compObj = {
                                TelephoneNo: companydivisionObj.CompanyTelephoneNo
                            }
                            let tempUnique = "SK#" + companydivisionObj.SK;
                            await addLogDetails(compObj, action, tempUnique, "CompanyDivisionMasterLog", userDetails, "Master")
                            setLoading(false);
                            MySwal.fire({
                                text: result.message,
                                imageUrl: seccessImage,
                                customClass: "swal-success",
                                didClose: () => {
                                    props.setReload(!props.reload);
                                    props.onHide();
                                },
                            });
                        }
                        else {
                            setLoading(false);
                            MySwal.fire({
                                text: "Unable to Process Request",
                                imageUrl: errorImage,
                                customClass: "swal-success",
                            });
                        }
                    }
                });
            }
            else {
                return;
            }
        }
    };

    const validateDetails = () => {
        let isError = false;
        const errorMsg = {
            CompanyLocationType: false,
            CompanyCountry: false,
            CompanyTelephoneNo: false,
            CompanyWebsiteAddress: false
        };
        if (companydivisionObj.CompanyLocationType.length == 0) {
            isError = true;
            errorMsg.CompanyLocationType = true;
        }
        if (companydivisionObj.CompanyCountry === "") {
            isError = true;
            errorMsg.CompanyCountry = true;
        }
        if (isMobNumber(companydivisionObj.CompanyTelephoneNo)) {
            isError = true;
            errorMsg.CompanyTelephoneNo = true;
        }
        setError(errorMsg)
        return isError;
    }

    const handleCancel = () => {
        props.onHide();
    };

    const showPopUp = (name, type, key, value) => {
        setPopUp({ name, type, key, value });
        setShowForm(true);
    };

    const setPopUpData = (data) => {
        let countryArr = [];
        if (companydivisionObj.CompanyLocationType.indexOf("India") !== -1) {
            countryArr.push("India");
        }

        for (let i of data.result) {
            countryArr.push(i.Country);
        }
        setCompanyDivisionObj((prevState) => ({
            ...prevState,
            CompanyCountry: countryArr.toString(),
        }));
    };

    const handleSwitchChange = (e) => {
        if (e.target.checked) {
            setToggleClass("activeToggleLabel")
        } else {
            setToggleClass("inactiveToggleLabel")
        }

        setCompanyDivisionObj((prevState) => ({
            ...prevState,
            DeleteIndication: e.target.checked ? "Active" : "Inactive",
        }));
    };

    const handleChange = (e) => {
        let { name, value } = e.target;

        if (name === "CompanySectorCode") {
            setCompanyDivisionObj((prevState) => ({
                ...prevState,
                CompanySectorName: e.target[e.target.selectedIndex].getAttribute("data-desc")
            }));
        }

        if (name === "CompanyTelephoneNo") {
            setShowMobileNo(false);
        }
        if (name === "CompanyLocationType") {
            let checkList = companydivisionObj.CompanyLocationType;
            let countryList = companydivisionObj.CompanyCountry;
            if (e.target.checked) {
                checkList.push(value);
                if (value === "India") {
                    countryList = "India" + (countryList !== "" ? "," + countryList : countryList);
                }
            } else {
                let index = checkList.indexOf(value);
                checkList.splice(index, 1);
                let selectedCountry = countryList.split(",");
                let countryIndex = selectedCountry.indexOf("India");
                if (value === "India") {
                    selectedCountry.splice(countryIndex, 1);
                    countryList = selectedCountry.toString();
                }
                if (value === "Overseas") {
                    if (countryIndex !== -1) {
                        countryList = "India";
                    } else {
                        countryList = "";
                    }
                }
            }
            setCompanyDivisionObj((prevState) => ({
                ...prevState,
                CompanyCountry: countryList,
            }));

            value = checkList;

            if (checkList.includes("Overseas")) {
                setCountryDisplay("");
            } else {
                setCountryDisplay("d-none");
            }
        }
        setCompanyDivisionObj((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleReset = () => {
        if (action === "Add") {
            setCompanyDivisionObj((prevState) => ({
                ...prevState,
                SK: "",
                PK: "",
                DeleteIndication: "Active",
                CompanyName: "",
                CompanyCode: "",
                CompanyLocationType: ["India"],
                CompanyCountry: "India",
                CompanyHOAddress: "",
                CompanyTelephoneNo: "",
                CompanyWebsiteAddress: "",
                CompanySectorCode: "",
                CompanySectorName: ""
            }));
            setCountryDisplay("d-none");
        }
        setError({
            CompanyLocationType: false,
            CompanyCountry: false,
            CompanyTelephoneNo: false,
            CompanyWebsiteAddress: false
        });
    };

    return (
        <>
            {loading && <div className="spinner-box"> <Spinner animation="border" className="spinner" /></div>}
            <Modal
                {...props}
                size="lg"
                backdrop="static" centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {action} Company / Division
                        <span className="req-input" >
                            {props.action !== "View" ? " (All fields are mandatory)" : ""}
                        </span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form
                        id="companydivisionmaster"
                        name="companydivisionmaster"
                        onSubmit={handleSubmit}
                        autoComplete="off"
                    >
                        <Row >
                            {action === "View" || action === "Edit" ?
                                <Col lg={4} md={4}>
                                    <Form.Group className="g-input">
                                        <Form.Control
                                            id="CompanyCode"
                                            type="text"
                                            name="CompanyCode"
                                            placeholder=" "
                                            value={companydivisionObj.CompanyCode}
                                            readOnly={action === "View" || action === "Edit"}
                                        />
                                        <Form.Label className="frm-label" for="CompanyCode">Company / Division Code</Form.Label>
                                    </Form.Group>
                                </Col>
                                : <></>
                            }
                            <Col lg={4} md={4}>
                                <Form.Group className="g-input">
                                    <Form.Control
                                        id="CompanyName"
                                        type="text"
                                        name="CompanyName"
                                        maxLength="100"
                                        placeholder=" "
                                        required
                                        value={companydivisionObj.CompanyName}
                                        onChange={handleChange}
                                        readOnly={action === "View"}
                                    />
                                    <Form.Label className="frm-label" for="CompanyName">Company / Division</Form.Label>
                                </Form.Group>
                            </Col>
                            {(action !== "View") &&
                                <Col md={4} xs={12}>
                                    <Form.Group>
                                        <Row>
                                            <Form.Label className="fieldLabel locationType">Location Type</Form.Label>
                                        </Row>
                                        <Row>
                                            <Col md={6} xs={6}>
                                                <Form.Check className="fieldLabel locationType"
                                                    type="checkbox"
                                                    name="CompanyLocationType"
                                                    checked={companydivisionObj.CompanyLocationType.includes("India")}
                                                    onChange={(e) => handleChange(e)}
                                                    label="India"
                                                    value="India"
                                                ></Form.Check>
                                            </Col>
                                            <Col md={6} xs={6}>
                                                <Form.Check className="fieldLabel locationType"
                                                    type="checkbox"
                                                    name="CompanyLocationType"
                                                    checked={companydivisionObj.CompanyLocationType.includes("Overseas")}
                                                    onChange={(e) => handleChange(e)}
                                                    label="Overseas"
                                                    value="Overseas"
                                                ></Form.Check>
                                            </Col>
                                        </Row>
                                        {error.CompanyLocationType && (
                                            <Row>
                                                <Form.Text className="text-danger">
                                                    Select Location Type.
                                                </Form.Text>
                                            </Row>
                                        )}
                                    </Form.Group>
                                </Col>
                            }
                            {(action === "View") &&
                                <Col md={4} xs={12}>
                                    <Form.Group>
                                        <Row>
                                            <Col md={6} xs={6}><Form.Label className="fieldLabel">Location Type</Form.Label></Col>
                                            <Col md={6} xs={6}><Form.Label className="fieldLabel semiBoldToggleButton">{companydivisionObj.CompanyLocationType.toString()}</Form.Label></Col>
                                        </Row>
                                    </Form.Group>
                                </Col>
                            }
                        </Row>
                        <Row className="row-fields">
                            <Col lg={4} md={4}>
                                <Form.Group className="g-input">
                                    <div className="d-flex align-items-center">
                                        <Form.Control
                                            id="CompanyCountry"
                                            type="text"
                                            name="CompanyCountry"
                                            placeholder=" "
                                            required
                                            value={companydivisionObj.CompanyCountry}
                                            onChange={handleChange}
                                            readOnly
                                        />
                                        <Form.Label className="frm-label" for="CompanyCountry">
                                            Country
                                        </Form.Label>
                                        <BiSelectMultiple
                                            className={countryDisplay}
                                            size={20}
                                            onClick={() => showPopUp(countryPopUp, "Multiple")}
                                            color={"#707070"}
                                            style={{ marginLeft: "-2rem" }}
                                            backdrop="static"
                                        />
                                    </div>
                                    {error.CompanyCountry && (
                                        <Form.Text className="text-danger">
                                            Select country.
                                        </Form.Text>
                                    )}

                                </Form.Group>
                            </Col>
                            <Col lg={4} md={4}>
                                <Form.Group className="g-input">
                                    <Form.Control
                                        id="CompanyHOAddress"
                                        type="text"
                                        name="CompanyHOAddress"
                                        maxLength="200"
                                        placeholder=" "
                                        required
                                        value={companydivisionObj.CompanyHOAddress}
                                        onChange={handleChange}
                                        readOnly={action === "View"}
                                    />
                                    <Form.Label className="frm-label" for="CompanyHOAddress">HO Address</Form.Label>
                                </Form.Group>
                            </Col>
                            <Col lg={4} md={4}>
                                <Form.Group className="g-input">
                                    <Form.Control
                                        id="CompanyTelephoneNo"
                                        type="text"
                                        name="CompanyTelephoneNo"
                                        maxLength="20"
                                        placeholder=" "
                                        required
                                        value={companydivisionObj.CompanyTelephoneNo}
                                        onChange={handleChange}
                                        onClick={() => setShowMobileNo(false)}
                                        onBlur={(e) => {
                                            let error = isMobNumber(e.target.value);
                                            setError((prevState) => ({
                                                ...prevState,
                                                CompanyTelephoneNo: error,
                                            }));
                                            companydivisionObj.CompanyTelephoneNo ?
                                                setShowMobileNo(false) : setShowMobileNo(true)
                                        }}
                                        readOnly={action === "View"}
                                    />
                                    <Form.Label className="frm-label" for="CompanyTelephoneNo">Telephone No.
                                        {ShowMobileNo ?
                                            <span className="telephone-no"> +91 8888888888</span>
                                            : null
                                        }
                                    </Form.Label>
                                    {error.CompanyTelephoneNo && (
                                        <Form.Text className="text-danger">
                                            Enter country code with telephone no.
                                        </Form.Text>
                                    )}
                                </Form.Group>

                            </Col>
                        </Row>
                        <Row>
                            <Col lg={4} md={4}>
                                <Form.Group className="g-input">
                                    <Form.Control
                                        id="CompanyWebsiteAddress"
                                        type="text"
                                        name="CompanyWebsiteAddress"
                                        maxLength="100"
                                        placeholder=" "
                                        required
                                        value={companydivisionObj.CompanyWebsiteAddress}
                                        onChange={handleChange}
                                        readOnly={action === "View"}
                                    />
                                    <Form.Label className="frm-label" for="CompanyWebsiteAddress">Website Address</Form.Label>
                                    {error.CompanyWebsiteAddress && (
                                        <Form.Text className="text-danger">
                                            Enter correct website address.
                                        </Form.Text>
                                    )}
                                </Form.Group>
                            </Col>
                            <Col lg={4} md={4} xs={12} >
                                <Form.Group className="g-select">
                                    <Form.Select
                                        id="CompanySectorCode"
                                        name="CompanySectorCode"
                                        type="text"
                                        placeholder=" "
                                        required
                                        value={companydivisionObj.CompanySectorCode}
                                        onChange={handleChange}
                                        disabled={action === "View"}
                                    >
                                        <option value="" >Select</option>
                                        {SectorCombo?.map((item) => {
                                            return (<option key={item.SectorCode} value={item.SectorCode} data-desc={item.SectorName}>{item.SectorName}</option>)
                                        })}
                                    </Form.Select>
                                    <Form.Label className="frm-label" for="CompanySectorCode">Sector</Form.Label>
                                </Form.Group>
                            </Col>
                            <Col md={4} xs={12}><Row>
                                <Col>
                                    <Form.Label className="fieldLabel">Status</Form.Label>
                                </Col>
                                <Col>
                                    <Form.Switch className={"semiBoldToggleButton " + toggleClass}
                                        label={companydivisionObj.DeleteIndication === "Active" ? "Active" : "Inactive"}
                                        name="DeleteIndication"
                                        checked={companydivisionObj.DeleteIndication === "Active"}
                                        onChange={(e) => {
                                            handleSwitchChange(e);
                                        }}
                                        disabled={action === "View"}
                                    />
                                </Col>
                            </Row>
                            </Col>
                        </Row>
                        <Row >
                            <Col md={9} xs={6}><Row>
                                <Col md={3} xs={12}>{action === "View" || action === "Edit" ? null :
                                    <Button className="semiBoldToggleButton cancelBtn"
                                        variant="outline-success"
                                        onClick={handleCancel}
                                    >
                                        Cancel
                                    </Button>
                                }
                                </Col>
                                <Col xs={12} md={6}>{action === "View" || action === "Edit" ? <></> :
                                    <Button className="resetBtn semiBoldToggleButton"
                                        variant="link"
                                        onClick={handleReset}
                                    >Reset Fields
                                    </Button>
                                }
                                </Col>
                            </Row>
                            </Col>
                            <Col md={3} xs={6}>
                                {action === "View" ?
                                    <Button variant="success" className="btn-add-color semiBoldToggleButton rightAlignedButton" type="submit">
                                        Done
                                    </Button>
                                    :
                                    <Button variant="success" className="btn-add-color semiBoldToggleButton rightAlignedButton" type="submit">
                                        {action === "Edit" ? "Update" : "Add"}
                                    </Button>
                                }
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
            </Modal>
            <RenderIf isTrue={showForm}>
                <MultiValuePopUp
                    show={showForm}
                    onHide={() => setShowForm(false)}
                    setSelected={setPopUpData}
                    masterName={popUp.name}
                    paramkey={popUp.key}
                    paramvalue={popUp.value}
                    locationData={companydivisionObj.CompanyCountry}
                />
            </RenderIf>
        </>
    );
}

export default AddEditCompanyDivisionMaster;