import React, { useEffect, useState, useContext, useImperativeHandle, forwardRef } from "react";
import { Form, Modal, Row, Col, Spinner } from "react-bootstrap";
import { getAllServiceEquipmentData, AddNewServiceEquipment, updateserviceequipment } from "../../services/equipmentactivity-master-service";
import { ServiceMasterData, deletS3Attachment, getPresignedURL, } from "../../services/common-master-service"
import { getCurrentDateTime } from "../../functions/gbcs-functions";
import Swal from "sweetalert2";
import "../utils/AddEditProjectMaster.css";
import withReactContent from "sweetalert2-react-content";
import successImage from "../../icons/success-tick-logo.svg";
import errorImage from "../../icons/error-tick-logo.svg";
import { AuthContext } from "../../contexts/AuthContext";
import { getProjectsByEquipment, updateProjectInactive } from "../../services/project-service";
import { sortByPropertyInDescending } from "../../utils/Utility";
import { downloadAttachment } from "../../services/designreviewtracker-service";
import Validate from "../utils/Validation";
import DragDropRender from "../utils/DragDropRender";
import axios from "axios";
import { ALERT_PARAMS } from "../../utils/constant.js";

const EquipmentDetails = forwardRef((props, ref) => {
    const MySwal = withReactContent(Swal);
    const context = useContext(AuthContext);
    const userDetails = { userId: context.userId, userName: context.userName }
    let action = props.action;
    const [ServicesList, setServicesList] = useState([]);
    const [toggleClass, setToggleClass] = useState("activeToggleLabel");
    const [loading, setLoading] = useState(true);
    let todayDate = getCurrentDateTime("-");
    const [addDataSaved, setAddDataSaved] = useState(false);
    const [fatApply, setFatApply] = useState(props.equipData.FATEquipApplicableChecklist?.length > 0 ? true : false);
    const [satApply, setSatApply] = useState(true);
    const [file, setFile] = useState([]);
    const oldFile = action === "Edit" || action === "View" ? props.equipData.AttachedDocument : "";
    const [newFileFlg, setnewFileFlg] = useState(false);

    const [serviceequipmentObj, setServiceEquipmentObj] = useState(
        action === "Edit" || action === "View"
            ? props.equipData
            : {
                SK: "",
                PK: "",
                ServiceType: "",
                ServiceCode: "",
                EquipmentCode: "",
                EquipmentName: "",
                DeleteIndication: "Active",
                FATEquipApplicableChecklist: [],
                SATEquipApplicableChecklist: [],
                MethodStatement: true,
                DetailedReport: true,
                FireIntegrationService: true,
                IntegrationSystemTest: true,
                CreatedOn: todayDate,
                CreatedBy: userDetails.userId,
                CreatedByName: userDetails.userName,
                ModifiedOn: todayDate,
                ModifiedBy: userDetails.userId,
                ModifiedByName: userDetails.userName,
                AttachedDocument: "",
            }
    );

    const [error, setError] = useState({
        ServiceCode: {},
        ServiceType: {},
        EquipmentName: {},
        FATEquipApplicableChecklist: {},
        SATEquipApplicableChecklist: {},
        ApplicableReport: {},
        AttachedDocument: {},
    });

    useEffect(() => {
        getServiceMasterData();
        if (action === "Edit" || action === "View") {
            if (props.equipData.DeleteIndication === "Active") {
                setToggleClass("activeToggleLabel")
            }
            else {
                setToggleClass("inactiveToggleLabel")
            }
            let checkList = [];
            let SATcheckList = [];
            if (props.equipData.FATEquipApplicableChecklist !== "")
                checkList = props.equipData.FATEquipApplicableChecklist.split(",");

            if (props.equipData.SATEquipApplicableChecklist !== "")
                SATcheckList = props.equipData.SATEquipApplicableChecklist.split(",");

            setServiceEquipmentObj((prevState) => ({
                ...prevState,
                FATEquipApplicableChecklist: checkList,
                SATEquipApplicableChecklist: SATcheckList,
            }));
        }
    }, []);

    const uploadFiles = async (signedURL) => {
        try {
            if (signedURL) {
                await axios.put(signedURL, file);
            }
            return "";
        } catch (error) {
            return " Error in file upload.";
        }
    };

    const handleDownloadDocument = async (fileName) => {
        console.log("download called");
        // console.log("old file", FilesArr.OldFile);
        if (oldFile !== "") {
            downloadAttachment(
                "EquipmentActivitySampleDoc/" +
                props.equipData.EquipmentCode +
                "/" +
                fileName,
                fileName
            ).catch((err) => {
                MySwal.fire({
                    text: "Error in Fetching file.",
                    imageUrl: errorImage,
                    customClass: "swal-success",
                });
            });
        }
    };

    const handleDeleteDocument = () => {
        if (action == "View") {
            return;
        }
        setServiceEquipmentObj((prevState) => ({
            ...prevState,
            AttachedDocument: "",
        }));

    };

    const handleChangeStatus = async (files) => {
        // debugger
        const maxFileSize = 31457280
        let isError = false;
        const newFile = files[0];
        console.log("newfile", newFile);
        let fileError = Validate(newFile, "docsFormats", maxFileSize);

        if (fileError === "") {
            setFile(newFile);
            setnewFileFlg(true);
            let fileName = files[0].name;
            console.log("filename", fileName);
            setServiceEquipmentObj((prevState) => ({
                ...prevState,
                AttachedDocument: fileName,
            }));
            // console.log(fileName);
            // console.log("inside handle change",serviceequipmentObj);
        } else {
            MySwal.fire({
                text: fileError,
                imageUrl: errorImage,
                customClass: "swal-success",
            });
            return;
        }
        return isError;
    };

    const getServiceMasterData = async () => {
        setLoading(true);
        const result = await ServiceMasterData();
        setServicesList(result);
        setLoading(false);
    };

    const handleReset = () => {
        setServiceEquipmentObj({
            SK: "",
            PK: "",
            ServiceType: "",
            ServiceCode: "",
            EquipmentCode: "",
            EquipmentName: "",
            DeleteIndication: "Active",
            FATEquipApplicableChecklist: [],
            SATEquipApplicableChecklist: [],
            MethodStatement: true,
            DetailedReport: true,
            FireIntegrationService: true,
            IntegrationSystemTest: true,
            CreatedOn: todayDate,
            CreatedBy: userDetails.userId,
            CreatedByName: userDetails.userName,
            ModifiedOn: todayDate,
            ModifiedBy: userDetails.userId,
            ModifiedByName: userDetails.userName,
            AttachedDocument: "",
        });
        setError({
            ServiceCode: {},
            ServiceType: {},
            EquipmentName: {},
            FATEquipApplicableChecklist: {},
            SATEquipApplicableChecklist: {},
            ApplicableReport: {},
            AttachedDocument: {},
        });
    }

    useImperativeHandle(ref, () => ({
        funcOnNext: async () => {
            await handleSubmit();
        },
        funcOnReset: () => {
            handleReset();
        },
    }));

    const validateDetails = () => {
        let isError = false;
        const errorMsg = {
            ServiceCode: {},
            FATEquipApplicableChecklist: {},
            SATEquipApplicableChecklist: {},
            ServiceType: {},
            EquipmentName: {},
            ApplicableReport: {},
            AttachedDocument: {},
        };
        if (serviceequipmentObj.ServiceCode === "") {
            isError = true;
            errorMsg.ServiceCode = {
                status: true,
                message: "Service Name is required.",
            };
        }
        if (serviceequipmentObj.ServiceType.trim() === "") {
            isError = true;
            errorMsg.ServiceType = {
                status: true,
                message: "Service Type is required.",
            };
        }
        if (serviceequipmentObj.EquipmentName.toString().trim() === "") {
            isError = true;
            errorMsg.EquipmentName = {
                status: true,
                message: "Equipment Name is required.",
            };
        }
        if (serviceequipmentObj.FATEquipApplicableChecklist.length === 0 && fatApply) {
            isError = true;
            errorMsg.FATEquipApplicableChecklist = {
                status: true,
                message: "FAT Checklist selection is required.",
            };
        }
        if (serviceequipmentObj.SATEquipApplicableChecklist.length === 0) {
            isError = true;
            errorMsg.SATEquipApplicableChecklist = {
                status: true,
                message: "SAT Checklist selection is required.",
            };
        }
        if (!serviceequipmentObj.MethodStatement && !serviceequipmentObj.DetailedReport && !serviceequipmentObj.FireIntegrationService && !serviceequipmentObj.IntegrationSystemTest) {
            isError = true;
            errorMsg.ApplicableReport = {
                status: true,
                message: "Atleast one selection is required.",
            };
        }
        if (!serviceequipmentObj.AttachedDocument) {
            isError = true;
            errorMsg.AttachedDocument = {
                status: true,
                message: "Sample Document for Detailed Report is required",
            };
        }
        setError(errorMsg);
        return isError;
    }

    const handleSubmit = async (e) => {
        if (!validateDetails()) {
            let result;
            setLoading(true);
            let tmpmasterData = await getAllServiceEquipmentData();
            let masterData = tmpmasterData.filter((item) => (item.ServiceType.toUpperCase() === serviceequipmentObj.ServiceType.toUpperCase()));
            if (tmpmasterData.length > 0 && tmpmasterData.filter((item) => ((action === "Add" && !addDataSaved) || ((action === "Edit" || addDataSaved) && item.EquipmentCode.toLowerCase() !== serviceequipmentObj.EquipmentCode.toLowerCase())) && item.EquipmentName.toLowerCase() === serviceequipmentObj.EquipmentName.toLowerCase()).length > 0) {
                setLoading(false);
                MySwal.fire({
                    text: "Equipment / Activity Name already exists.",
                    imageUrl: errorImage,
                    customClass: "swal-success",
                });
                return;
            }
            let confirmBoxText = "";
            let confirmButtonText = "";
            if (action === "Add") {
                confirmBoxText = "Are you sure, do you want to add new Equipment / Activity?";
                confirmButtonText = "Add";
            }
            else if (action === "Edit") {
                confirmBoxText = "Are you sure, do you want to update Equipment / Activity?";
                confirmButtonText = "Update";
            }
            setLoading(false);
            MySwal.fire({
                text: confirmBoxText,
                confirmButtonText: confirmButtonText,
                title: action + " Equipment / Activity",
                ...ALERT_PARAMS
            }).then(async (result2) => {
                if (result2.isConfirmed) {
                    setLoading(true);
                    let obj = Object.assign({}, serviceequipmentObj);
                    if (action === "Add" && !addDataSaved) {
                        let EquipmentCodeLabel = serviceequipmentObj.ServiceType.toLowerCase() === "equipment" ? "E" : "A";
                        masterData.sort(sortByPropertyInDescending("EquipmentCode"));
                        let maxEquiCode = EquipmentCodeLabel + "00001";
                        if (masterData.length > 0) {
                            let latestEquiCode = masterData[0].EquipmentCode;
                            let equipNo = latestEquiCode.substring(1, latestEquiCode.length)
                            maxEquiCode = EquipmentCodeLabel + ((parseInt(equipNo) + 1).toString().padStart(5, '0'));
                        }
                        serviceequipmentObj.EquipmentCode = maxEquiCode;
                        serviceequipmentObj.PK = "ServiceEquipmentMaster";
                        serviceequipmentObj.SK = "EquipmentCode#" + maxEquiCode;
                        obj.EquipmentCode = maxEquiCode;
                        obj.FATEquipApplicableChecklist = obj.FATEquipApplicableChecklist.toString();
                        obj.SATEquipApplicableChecklist = obj.SATEquipApplicableChecklist.toString();
                        obj.PK = "ServiceEquipmentMaster";
                        obj.SK = "EquipmentCode#" + maxEquiCode;
                        setServiceEquipmentObj((prevState) => ({
                            ...prevState,
                            PK: "ServiceEquipmentMaster",
                            SK: "EquipmentCode#" + maxEquiCode,
                            EquipmentCode: maxEquiCode
                        }));
                        result = AddNewServiceEquipment(obj);
                    } else if (action === "Edit" || addDataSaved) {
                        obj.ModifiedBy = userDetails.userId;
                        obj.ModifiedByName = userDetails.userName;
                        obj.ModifiedOn = todayDate;
                        obj.FATEquipApplicableChecklist = obj.FATEquipApplicableChecklist.toString();
                        obj.SATEquipApplicableChecklist = obj.SATEquipApplicableChecklist.toString();
                        result = updateserviceequipment(obj);
                    }

                    result
                        .then(async (resp) => {
                            if (resp.status) {
                                let signedURL = "";
                                if (newFileFlg) {
                                    let filename = obj.AttachedDocument;
                                    signedURL = await getPresignedURL(
                                        "EquipmentActivitySampleDoc/" +
                                        obj.EquipmentCode +
                                        "/" +
                                        filename
                                    );
                                    let fileUploadMsg = await uploadFiles(signedURL); //url generated
                                    setnewFileFlg(false)
                                }

                                if (oldFile && oldFile !== obj.AttachedDocument) {
                                    let fileObj = {
                                        fileName:
                                            "EquipmentActivitySampleDoc/" +
                                            props.equipData.EquipmentCode +
                                            "/" +
                                            oldFile,
                                    };
                                    let result3 = await deletS3Attachment(fileObj);
                                }
                                if (action === "Add" && !addDataSaved) {
                                    setAddDataSaved(true);
                                }
                                if (serviceequipmentObj.DeleteIndication === "Inactive") {
                                    let linkedProjects = await getProjectsByEquipment(serviceequipmentObj.EquipmentCode);
                                    let activeProjects = linkedProjects?.filter((item) => item.DeleteIndication === "Active");
                                    if (activeProjects?.length > 0) {
                                        for (let dt of activeProjects) {
                                            let projObj = {
                                                PK: "ProjectCode#" + dt.ProjectCode,
                                                SK: dt.SK,
                                                DeleteIndication: "Inactive",
                                                ModifiedBy: userDetails.userId,
                                                ModifiedByName: userDetails.userName,
                                                ModifiedOn: todayDate
                                            }
                                            let inactiveProjectEquip = await updateProjectInactive(projObj)
                                        }
                                    }
                                }
                                setLoading(false);
                                // props.setEquipData(serviceequipmentObj.EquipmentCode);
                                // props.equipmentNameData(serviceequipmentObj.EquipmentName);
                                props.setEquipData(serviceequipmentObj);
                                MySwal.fire({
                                    text: resp.message,
                                    imageUrl: successImage,
                                    customClass: "swal-success",
                                    didClose: () => {
                                        props.setreload(!props.reload);
                                    },
                                });
                            }
                            else {
                                setLoading(false);
                                MySwal.fire({
                                    text: resp.message,
                                    imageUrl: errorImage,
                                    customClass: "swal-success",
                                });
                            }
                        })
                        .catch((error) => {
                            setLoading(false);
                            MySwal.fire({
                                text: "Unable to Process Request",
                                imageUrl: errorImage,
                                customClass: "swal-success"
                            });
                        })
                }
            });
        }
    }

    const handleSwitchChange = (e) => {
        if (e.target.checked) {
            setToggleClass("activeToggleLabel")
        } else {
            setToggleClass("inactiveToggleLabel")
        }
        setServiceEquipmentObj((prevState) => ({
            ...prevState,
            DeleteIndication: e.target.checked ? "Active" : "Inactive",
        }));
    };

    const handleChange = (e) => {
        let { name, value } = e.target;
        if (name === "FATEquipApplicableChecklist" || name === "SATEquipApplicableChecklist") {
            let checkList = serviceequipmentObj[name];
            if (e.target.checked) {
                checkList.push(value);
            } else {
                let index = checkList.indexOf(value);
                checkList.splice(index, 1);
            }
            value = checkList;
        }
        if (name === "FireIntegrationService" || name === "IntegrationSystemTest" || name === "MethodStatement" || name === "DetailedReport") {
            if (e.target.checked) {
                value = true;
            } else {
                value = false;
            }
        }
        setServiceEquipmentObj((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    return (
        <>
            {loading && <div className="spinner-box"> <Spinner animation="border" className="spinner" /></div>}
            <Form
                id="equipmentdetails"
                name="equipmentdetails"
                onSubmit={handleSubmit}
                autoComplete="off"
            >
                <Modal.Body>
                    <Row>
                        <Col md={4} xs={12}>
                            <Form.Group className="g-select">
                                <Form.Select
                                    id="ServiceCode"
                                    name="ServiceCode"
                                    type="text"
                                    placeholder=" "
                                    value={serviceequipmentObj.ServiceCode}
                                    onChange={(e) => { handleChange(e) }}
                                    disabled={action === "Edit" || action === "View"}
                                    onBlur={() => serviceequipmentObj.ServiceCode !== "" &&
                                        setError((prevState) => ({
                                            ...prevState,
                                            ServiceCode: {},
                                        }))
                                    }
                                >
                                    <option value="" >Select</option>
                                    {ServicesList?.map((item) => {
                                        return (<option key={item.ServiceCode} value={item.ServiceCode}>{item.ServiceName}</option>)
                                    })
                                    }
                                </Form.Select>
                                <Form.Label className="frm-label" for="ServiceCode">Service Name</Form.Label>
                                {error.ServiceCode.status && (
                                    <Form.Text className="text-danger">
                                        {error.ServiceCode.message}
                                    </Form.Text>
                                )}
                            </Form.Group>
                        </Col>
                        {(action === "Edit" || action === "View") && (
                            <Col md={4} xs={12}>
                                <Row>
                                    <Col md={7} xs={6}><Form.Label className="fieldLabel">Service Type</Form.Label></Col>
                                    <Col md={5} xs={6}><Form.Label className="fieldLabel semiBoldToggleButton">{serviceequipmentObj.ServiceType}</Form.Label></Col>
                                </Row>
                            </Col>
                        )}
                        {action === "Add" && (
                            <Col md={4} xs={12}>
                                <Row>
                                    <Form.Label className="fieldLabel locationType">Service Type</Form.Label>
                                </Row>
                                <Row>
                                    <Col md={6} xs={6}>
                                        <Form.Check className="fieldLabel locationType"
                                            type="radio"
                                            name="ServiceType"
                                            checked={serviceequipmentObj.ServiceType === "Equipment"}
                                            onChange={handleChange}
                                            label="Equipment"
                                            value="Equipment"
                                            required
                                        ></Form.Check>
                                    </Col>
                                    <Col md={6} xs={6}>
                                        <Form.Check className="fieldLabel locationType"
                                            type="radio"
                                            name="ServiceType"
                                            checked={serviceequipmentObj.ServiceType === "Activity"}
                                            onChange={handleChange}
                                            label="Activity"
                                            value="Activity"
                                        ></Form.Check>
                                    </Col>
                                </Row>
                                {error.ServiceType.status && (
                                    <Form.Text className="text-danger">
                                        {error.ServiceType.message}
                                    </Form.Text>
                                )}
                            </Col>
                        )}
                        {(action === "Edit" || action === "View") && (
                            <Col md={4} xs={12}>
                                <Form.Group className="g-input">
                                    <Form.Control
                                        id="EquipmentCode"
                                        type="text"
                                        name="EquipmentCode"
                                        placeholder=" "
                                        required
                                        value={serviceequipmentObj.EquipmentCode}
                                        onChange={handleChange}
                                        readOnly
                                    />
                                    <Form.Label className="frm-label" for="EquipmentCode">Equipment / Activity Code</Form.Label>
                                </Form.Group>
                            </Col>
                        )}
                        <Col md={4} xs={12}>
                            <Form.Group className="g-input">
                                <Form.Control
                                    id="EquipmentName"
                                    type="text"
                                    name="EquipmentName"
                                    maxLength="150"
                                    placeholder=" "
                                    value={serviceequipmentObj.EquipmentName}
                                    onChange={handleChange}
                                    readOnly={action === "View"}
                                />
                                <Form.Label className="frm-label" for="EquipmentName">Equipment / Activity Name</Form.Label>
                                {error.EquipmentName.status && (
                                    <Form.Text className="text-danger">
                                        {error.EquipmentName.message}
                                    </Form.Text>
                                )}
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12} xs={12}>
                            <Form.Label className="SubHeaderProjectDetails">
                                FAT Applicable Checklist Category <span className="optional">(optional)</span>
                            </Form.Label>
                        </Col>
                        <Col md={12} xs={12}>
                            <Col>
                                <Form.Check
                                    type="checkbox"
                                    id="FATApplicable"
                                    name="FATApplicable"
                                    label="FAT Applicability"
                                    disabled={action === "View"}
                                    checked={fatApply}
                                    onChange={(e) => {
                                        setFatApply(e.target.checked);
                                        if (!e.target.checked) {
                                            setServiceEquipmentObj((prevState) => ({
                                                ...prevState,
                                                FATEquipApplicableChecklist: []
                                            }))
                                        }
                                    }}
                                />
                            </Col>
                        </Col>
                        <Col md={2} xs={6}>
                            <Form.Check
                                type="checkbox"
                                id="FATEquipApplicableChecklist"
                                name="FATEquipApplicableChecklist"
                                value="Functional"
                                checked={serviceequipmentObj.FATEquipApplicableChecklist.includes("Functional")}
                                disabled={action === "View" ? true : !fatApply}
                                onChange={(e) => {
                                    handleChange(e);
                                }}
                                label={`Functional`}
                            />
                        </Col>
                        <Col md={3} xs={6}>
                            <Form.Check
                                type="checkbox"
                                id="FATEquipApplicableChecklist"
                                name="FATEquipApplicableChecklist"
                                value="Pre-Functional"
                                checked={serviceequipmentObj.FATEquipApplicableChecklist.includes("Pre-Functional")}
                                disabled={action === "View" ? true : !fatApply}
                                onChange={(e) => {
                                    handleChange(e);
                                }}
                                label={`Pre-Functional`}
                            />
                        </Col>
                        {error.FATEquipApplicableChecklist.status && (
                            <Form.Text className="text-danger">
                                {error.FATEquipApplicableChecklist.message}
                            </Form.Text>
                        )}
                    </Row>
                    <Row>
                        <Col md={12} xs={12}>
                            <Form.Label className="SubHeaderProjectDetails">
                                SAT Applicable Checklist Category
                            </Form.Label>
                        </Col>
                        <Col md={12} xs={12}>
                            <Col>
                                <Form.Check
                                    type="checkbox"
                                    id="SATApplicable"
                                    name="SATApplicable"
                                    label="SAT Applicability"
                                    disabled={action === "View"}
                                    checked={satApply}
                                    onChange={(e) => {
                                        setSatApply(e.target.checked);
                                        if (!e.target.checked) {
                                            setServiceEquipmentObj((prevState) => ({
                                                ...prevState,
                                                SATEquipApplicableChecklist: []
                                            }))
                                        }
                                    }}
                                />
                            </Col>
                        </Col>
                        <Col md={2} xs={6}>
                            <Form.Check
                                type="checkbox"
                                id="SATEquipApplicableChecklist"
                                name="SATEquipApplicableChecklist"
                                value="Functional"
                                checked={serviceequipmentObj.SATEquipApplicableChecklist.includes("Functional")}
                                onChange={(e) => {
                                    handleChange(e);
                                }}
                                label={`Functional`}
                                disabled={action === "View" ? true : !satApply}
                            />
                        </Col>
                        <Col md={3} xs={6}>
                            <Form.Check
                                type="checkbox"
                                id="SATEquipApplicableChecklist"
                                name="SATEquipApplicableChecklist"
                                value="Pre-Functional"
                                checked={serviceequipmentObj.SATEquipApplicableChecklist.includes("Pre-Functional")}
                                onChange={(e) => {
                                    handleChange(e);
                                }}
                                label={`Pre-Functional`}
                                disabled={action === "View" ? true : !satApply}
                            />
                        </Col>
                        {error.SATEquipApplicableChecklist.status && (
                            <Form.Text className="text-danger">
                                {error.SATEquipApplicableChecklist.message}
                            </Form.Text>
                        )}
                    </Row>
                    <Row>
                        <Col md={12} xs={12}>
                            <Form.Label className="SubHeaderProjectDetails">
                                Applicable - Method Statement / Detailed Report / Fire Integration Service / Integration System Test (IST)
                            </Form.Label>
                        </Col>
                        <Col md={3} xs={12}>
                            <Form.Check
                                type="checkbox"
                                id="MethodStatement"
                                name="MethodStatement"
                                checked={serviceequipmentObj.MethodStatement}
                                onChange={(e) => {
                                    handleChange(e);
                                }}
                                label={`Method Statement`}
                                disabled={action === "View"}
                            />
                        </Col>
                        <Col md={3} xs={12}>
                            <Form.Check
                                type="checkbox"
                                id="DetailedReport"
                                name="DetailedReport"
                                disabled={action === "View"}
                                checked={serviceequipmentObj.DetailedReport}
                                onChange={(e) => {
                                    handleChange(e);
                                }}
                                label={`Detailed Report`}
                            />
                        </Col>
                        <Col md={3} xs={12}>
                            <Form.Check
                                type="checkbox"
                                id="FireIntegrationService"
                                name="FireIntegrationService"
                                checked={serviceequipmentObj.FireIntegrationService}
                                onChange={(e) => {
                                    handleChange(e);
                                }}
                                label={`Fire Integration Service`}
                                disabled={action === "View"}
                            />
                        </Col>
                        <Col md={3} xs={12}>
                            <Form.Check
                                type="checkbox"
                                id="IntegrationSystemTest"
                                name="IntegrationSystemTest"
                                checked={serviceequipmentObj.IntegrationSystemTest}
                                onChange={(e) => {
                                    handleChange(e);
                                }}
                                label={`Integration System Test (IST)`}
                                disabled={action === "View"}
                            />
                        </Col>
                        {error.ApplicableReport.status && (
                            <Form.Text className="text-danger">
                                {error.ApplicableReport.message}
                            </Form.Text>
                        )}
                    </Row>
                    <Row>
                        <Col md={4} xs={12}>
                            <Row>
                                <Col>
                                    <Form.Label className="fieldLabel">Status</Form.Label>
                                </Col>
                                <Col>
                                    <Form.Switch className={"semiBoldToggleButton " + toggleClass}
                                        label={serviceequipmentObj.DeleteIndication === "Active" ? "Active" : "Inactive"}
                                        name="DeleteIndication"
                                        checked={serviceequipmentObj.DeleteIndication === "Active"}
                                        onChange={(e) => {
                                            handleSwitchChange(e);
                                        }}
                                        disabled={action === "View"}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} className="pb-3">
                            <Form.Label className="SubHeaderProjectDetails">
                                Upload Sample for Detailed Report
                            </Form.Label>
                            <DragDropRender
                                supportedFilesLabel="Supports: All Word formats (e.g., doc, docs, docx), Max File Size – up to 30MB per file"
                                val={serviceequipmentObj.AttachedDocument}
                                handleChangeEvent={(params) => handleChangeStatus(params)}
                                handleDeleteDocEvent={(params) => handleDeleteDocument(params)}
                                handleDownloadDocEvent={(params) => handleDownloadDocument(params)}
                                action={action}
                            />
                        </Col>
                        {error.AttachedDocument.status && (
                            <Form.Text className="text-danger">
                                {error.AttachedDocument.message}
                            </Form.Text>
                        )}
                    </Row>
                </Modal.Body>
            </Form>
        </>
    )
});

export default EquipmentDetails;