import React, { useEffect, useState, useRef, useContext, useImperativeHandle, forwardRef } from "react";
import { Form, Modal, Row, Col } from "react-bootstrap";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Link } from "react-router-dom";
import { AuthContext } from "../../../contexts/AuthContext";
import { BsPlusLg } from "react-icons/bs";
import AddParameter from "./AddParameter";
import { UpdateChecklistHeaderData } from "../../../services/checklist-master-service";
import CheckboxComponent from "./CheckboxComponent";
import { ConstChecklistHeader, ConstChecklistFooter } from "./ParameterFile";
import TabButtons from "../../utils/TabButtons";
import successImage from "../../../icons/success-tick-logo.svg";
import errorImage from "../../../icons/error-tick-logo.svg";
import { getCurrentDateTime } from "../../../functions/gbcs-functions";
import { ALERT_PARAMS } from "../../../utils/constant.js";

const ChecklistFooter = forwardRef((props, ref) => {
  const MySwal = withReactContent(Swal);
  // const [headerObject, setHeaderObject] = useState([]);
  const [showParameterForm, setShowParameterForm] = useState(false);
  let action = props.action;
  let limitValue = props.limitValue;

  const context = useContext(AuthContext);
  const userDetails = {
    userName: context.userName,
    userId: context.userId,
  };
  let dbFieldName = props.tabName;
  const constFields = dbFieldName === "ChecklistHeader" ? ConstChecklistHeader : dbFieldName === "ChecklistFooter" ? ConstChecklistFooter : [];

  const [tmpheaderObject, setTmpheaderObject] = useState(
    ((action === "Edit" || action === "View") && props.rowdata.hasOwnProperty(dbFieldName))
      ? props.rowdata[dbFieldName]
      : constFields
  );

  const handleSubmit = async () => {
    // e.preventDefault();
    let res = false;
    try {
      if (props.primeKey === "" || props.secondKey === "") {
        res = false;
        MySwal.fire({
          text: "Checklist code is blank. Kindly maintain Checklist Details.",
          imageUrl: errorImage,
          customClass: "swal-success",
        });
        return;
      }

      let result;
      let todayDate = getCurrentDateTime("-");
      let headerObject = {
        PK: props.primeKey,
        SK: props.secondKey,
        ChecklistHeader: tmpheaderObject,
        ModifiedOn: todayDate,
        ModifiedBy: userDetails.userId,
        ModifiedByName: userDetails.userName,
      };
      console.log("headerObject", headerObject);
      result = await UpdateChecklistHeaderData(headerObject, dbFieldName);
      if (result?.status) {
        res = true;
        MySwal.fire({
          text: result.message,
          imageUrl: successImage,
          customClass: "swal-success",
        });
      } else {
        res = false;
        MySwal.fire({
          text: "Unable to Process Request",
          imageUrl: errorImage,
          customClass: "swal-success",
        });
      }
    } catch (err) {
      res = false;
      MySwal.fire({
        text: "Unable to Process Request",
        imageUrl: errorImage,
        customClass: "swal-success",
      });
    };
    return res;
  };

  useImperativeHandle(ref, () => ({
    funcOnNext: async () => {
      let res = false
      res = await handleSubmit();
      return res
      // return handleSubmit();
    }
  }));

  const setCheckBoxValue = async (data) => {
    const newArr = [...tmpheaderObject];
    let index = newArr.findIndex(
      (obj) =>
        obj.FieldName === data.FieldName && obj.FieldType === data.FieldType
    );
    newArr[index] = data;
    setTmpheaderObject(newArr);
  };

  const deleteUserAddedParameter = (data) => {
    MySwal.fire({
      text:
        "Are you sure, do you want to delete parameter " + data.FieldName + "?",
      confirmButtonText: "Delete",
      title: "Delete Parameter",
      ...ALERT_PARAMS
    }).then((result2) => {
      if (result2.isConfirmed) {
        const newArr = [...tmpheaderObject];
        let index = tmpheaderObject.findIndex(
          (obj) =>
            obj.FieldName === data.FieldName && obj.FieldType === data.FieldType
        );
        newArr.splice(index, 1);
        setTmpheaderObject(newArr);
      }
    });
  };

  const addNewRow = () => {
    if ((tmpheaderObject.length - constFields.length) < limitValue) {
      setShowParameterForm(true);
    } else {
      MySwal.fire({
        text: "You can add only " + limitValue + " parameters.",
        imageUrl: errorImage,
        customClass: "swal-success",
      });
    }
  };
  const setUserAddedParameter = async (data) => {
    setTmpheaderObject((current) => [...current, data]);
  };

  return (
    <>
      <div>
        <Form
          id="checklistheader"
          name="checklistheader"
          onSubmit={handleSubmit}
          autoComplete="off"
        >
          <div>
            <Row>
              <Form.Label className="sectionTitle">Default</Form.Label>
            </Row>
            <Row>
              {tmpheaderObject.map((selection, index) => {
                return selection.FieldType === "Default" ? (
                  <CheckboxComponent
                    action={props.action}
                    paramdata={selection}
                    PatameterType="Header"
                    setCheckBoxValue={setCheckBoxValue}
                  />
                ) : (
                  <></>
                );
              })}
            </Row>

            <Row>
              <Form.Label className="sectionTitle">Optional</Form.Label>
            </Row>
            <Row>
              {tmpheaderObject.map((selection, index) => {
                return (
                  //  <></>
                  selection.FieldType === "Optional" ? (
                    <CheckboxComponent
                      action={props.action}
                      paramdata={selection}
                      PatameterType="Header"
                      setCheckBoxValue={setCheckBoxValue}
                    />
                  ) : (
                    <></>
                  )
                );
              })}
            </Row>
            {(action !== "View" && props.checklistVersion.startsWith("TMP")) && (
              <Row>
                <div className="add-new-row">
                  <BsPlusLg size={20} className="plusIcon" />
                  &nbsp;
                  <Link onClick={() => addNewRow()} className="add-new-link ">
                    Add more
                  </Link>
                </div>
              </Row>
            )}
            <Row>
              {tmpheaderObject.map((selection, index) => {
                return selection.FieldType === "UserAdded" ? (
                  <CheckboxComponent
                    action={props.action}
                    paramdata={selection}
                    PatameterType="Header"
                    setCheckBoxValue={setCheckBoxValue}
                    deleteUserAddedParameter={deleteUserAddedParameter}
                  />
                ) : (
                  <></>
                );
              })}
            </Row>
            <br />
          </div>
          {/* <TabButtons
            currentTab={1}
            handleSelect={props.tabKey}
            action={action}
          /> */}
        </Form>
      </div>
      {showParameterForm && (
        <AddParameter
          show={showParameterForm}
          masterData={tmpheaderObject}
          onHide={() => setShowParameterForm(false)}
          setUserAddedParameter={setUserAddedParameter}
        />
      )}
    </>
  );
}
);

export default ChecklistFooter;
