import React, { useEffect, useState, useRef } from "react";
import { Modal, Row, Tabs, Tab, Container } from "react-bootstrap";
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import EquipmentDetails from "./EquipmentDetails";
import EquipmentParameters from "./EquipmentParameters";
import TabButtons from "../utils/TabButtons";
import { getEquipmentParameterLimitData } from "../../services/common-master-service";

const AddEditEquipmentActivityMaster = (props) => {
    let action = props.action;
    let tab0Ref = useRef();
    let tab1Ref = useRef();
    let tab2Ref = useRef();
    let tab3Ref = useRef();
    let tab4Ref = useRef();
    const [currentTab, setCurrentTab] = useState(0);
    const [code, setCode] = useState(action !== "Add" ? props.rowdata.EquipmentCode : "");
    const [name, setName] = useState(action !== "Add" ? props.rowdata.EquipmentName : "");
    const [PrerequisiteLimitVal, setPrerequisiteLimitVal] = useState(0);
    const [InstrumentsLimitVal, setInstrumentsLimitVal] = useState(0);
    const [TestingLimitVal, setTestingLimitVal] = useState(0);
    const [DocLimitVal, setDocLimitVal] = useState(0);
    const [equipData, setEquipData] = useState(action !== "Add" ? props.rowdata : {});

    const handleSelect = async (key, direction) => {
        let saveFlag = true;
        if (direction !== "save") {
            saveFlag = false;
        }
        if (saveFlag) {
            switch (currentTab) {
                case 0:
                    await tab0Ref.current.funcOnNext();
                    break;
                case 1:
                    await tab1Ref.current.funcOnNext();
                    break;
                case 2:
                    await tab2Ref.current.funcOnNext();
                    break;
                case 3:
                    await tab3Ref.current.funcOnNext();
                    break;
                case 4:
                    await tab4Ref.current.funcOnNext();
                    break;
                default:
                    break;
            }
        }

        if (direction == "prev") {
            if (currentTab !== 0) setCurrentTab(currentTab - 1)
        }
        else if (direction == "next") {
            if (currentTab !== 4) setCurrentTab(currentTab + 1)
        }
        else if (direction == "save") {
            setCurrentTab(currentTab)
        }
        else {
            setCurrentTab(Number(key))
        }
    }

    const closeOnDone = () => {
        props.onHide();
    }

    const handleReset = () => {
        tab0Ref.current.funcOnReset();
    }

    useEffect(() => {
        if (action !== "View") {
            fetchLimitData();
        }
    }, []);

    const fetchLimitData = async () => {
        let limitData = await getEquipmentParameterLimitData();
        if (limitData?.length > 0) {
            setPrerequisiteLimitVal(limitData[0].PrerequisiteLimitVal);
            setInstrumentsLimitVal(limitData[0].InstrumentsLimitVal);
            setTestingLimitVal(limitData[0].TestingLimitValue);
            setDocLimitVal(limitData[0].DocReviewLimitValue);
        }
    };

    return (
        <Modal
            {...props}
            size="xl"
            show={true}
            backdrop="static"
            id="equipmentModal"
            className="equip-container"
        >
            <Modal.Header closeButton>
                <Modal.Title className="modalTitle">
                    {props.action} Equipment / Activity
                </Modal.Title>
            </Modal.Header>
            <Container className="add-container">
                <Tabs activeKey={currentTab} onSelect={handleSelect} className="tabCssheader">
                    <Tab className="headerTabKey" eventKey={0} title={
                        <>
                            <span className="numberbg">1</span>
                            <span>Equipment Details</span>
                        </>
                    }>
                        <EquipmentDetails action={props.action}
                            ref={tab0Ref}
                            // rowdata={props.rowdata}
                            onHide={props.onHide}
                            // equipmentCodeData={setCode}
                            // equipmentNameData={setName}
                            setEquipData={setEquipData}
                            equipData={equipData}
                            tabKey={() => handleSelect(currentTab, "next")}
                            setreload={props.setReload}
                            reload={props.reload}
                        />
                    </Tab>
                    <Tab eventKey={1} className="nav-link" title={
                        <>
                            <span className="numberbg">2</span>
                            <span>Pre-Requisite</span>
                        </>
                    }>
                        <EquipmentParameters action={props.action}
                            ref={tab1Ref}
                            // codedata={code}
                            // namedata={name}
                            equipData={equipData}
                            tabKey={() => handleSelect(currentTab, "next")}
                            setreload={props.setreload}
                            reload={props.reload}
                            limitValue={PrerequisiteLimitVal}
                            paramtabname={"Prerequisite"}
                            headername={"Pre-Requisite"} />
                    </Tab>
                    <Tab eventKey={2} title={
                        <>
                            <span className="numberbg">3</span>
                            <span>Instruments</span>
                        </>
                    }>
                        <EquipmentParameters action={props.action}
                            ref={tab2Ref}
                            // codedata={code}
                            // namedata={name}
                            equipData={equipData}
                            tabKey={() => handleSelect(currentTab, "next")}
                            setreload={props.setreload}
                            reload={props.reload}
                            limitValue={InstrumentsLimitVal}
                            paramtabname={"Instruments"}
                            headername={"Instruments"}
                        />
                    </Tab>
                    <Tab eventKey={3} title={
                        <>
                            <span className="numberbg">4</span>
                            <span>Test Description</span>
                        </>
                    }>
                        <EquipmentParameters action={props.action}
                            ref={tab3Ref}
                            // codedata={code}
                            // namedata={name}
                            equipData={equipData}
                            tabKey={() => handleSelect(currentTab, "next")}
                            setreload={props.setreload}
                            reload={props.reload}
                            limitValue={TestingLimitVal}
                            paramtabname={"Testing"}
                            headername={"Test Description"} />
                    </Tab>
                    <Tab eventKey={4} title={
                        <>
                            <span className="numberbg">5</span>
                            <span>Document Review Tracker</span>
                        </>
                    }>
                        <EquipmentParameters action={props.action}
                            ref={tab4Ref}
                            // codedata={code}
                            // namedata={name}
                            equipData={equipData}
                            tabKey={() => handleSelect(currentTab, "next")}
                            setreload={props.setreload}
                            reload={props.reload}
                            limitValue={DocLimitVal}
                            paramtabname={"DocumentReview"}
                            headername={"Document Review Tracker"} />
                    </Tab>
                </Tabs>
                <br />
                <Row className="tabDirectionbtnRow">
                    <TabButtons
                        currentTab={currentTab}
                        lastTab={4}
                        handleSelect={handleSelect}
                        handleBack={closeOnDone}
                        handleReset={handleReset}
                        action={action}
                        showSave={currentTab === 0 || window.innerWidth > 768}
                    />
                </Row>
                <br />
            </Container>
        </Modal>
    );
}

export default AddEditEquipmentActivityMaster;
