import React, { useState, useContext, useEffect } from "react";
import { Row, Container, Col, Form, Button, Spinner } from "react-bootstrap";
import DragDropRender from "../utils/DragDropRender";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import successImage from "../../icons/success-tick-logo.svg";
import errorImage from "../../icons/error-tick-logo.svg";
import { AuthContext } from "../../contexts/AuthContext";
import { getCurrentDateTime } from "../../functions/gbcs-functions";
import { getOverAllDocumentGenerationData, addDocumentData } from "../../services/project-document-service";
import axios from "axios";
import { downloadAttachment } from "../../services/designreviewtracker-service"
import { deletS3Attachment, getPresignedURL } from "../../services/common-master-service"
import Validate from "../utils/Validation";
import ProjectDocumentParam from "./ProjectDocumentParam";

const AdditionalInformation = (props) => {

    const MySwal = withReactContent(Swal);
    const context = useContext(AuthContext);
    const userDetails = { userName: context.userName, userId: context.userId }

    const [file, setFile] = useState([]);
    const [FilesArr, setFilesArr] = useState({ OldFile: "", NewFile: "" });
    const [delFileFlg, setDelFileFlg] = useState(false);
    const [newFileFlg, setNewFileFlg] = useState(false);
    const [loading, setLoading] = useState(true);

    const [AdditionalInformationObj, setAdditionalInformationObj] = useState({
        ProjectCode: props.SelectedProject.ProjectCode,
        ProjectName: props.SelectedProject.ProjectName,
        ProjectBuildingPhoto: "",
        ProjectIntroduction: ""
    })

    const [error, setError] = useState({
        ProjectIntroduction: false,
        ProjectBuildingPhoto: false,
    });

    useEffect(() => {
        if (props.tabNo === 4) {
            getProjectDetails();
        }
    }, [props.tabNo]);

    const getProjectDetails = async () => {
        setLoading(true);
        let result = await getOverAllDocumentGenerationData(props.SelectedProject.ProjectCode);
        if (result?.length > 0) {
            setAdditionalInformationObj((prevState) => ({
                ...prevState,
                ProjectBuildingPhoto: result[0].ProjectBuildingPhoto,
                ProjectIntroduction: result[0].ProjectIntroduction,
                ProjectBuildingPhotoUploadedOn: result[0].ProjectBuildingPhotoUploadedOn,
                ProjectBuildingPhotoUploadedBy: result[0].ProjectBuildingPhotoUploadedBy,
                ProjectBuildingPhotoUploadedByName: result[0].ProjectBuildingPhotoUploadedByName
            }));
            FilesArr.OldFile = result[0].ProjectBuildingPhoto;
        }
        setLoading(false);
    }

    const handleUploadFile = async (files) => {
        setLoading(true);
        let isError = false;
        const newFile = files[0];
        let fileError = Validate(newFile, "imgFormats");

        if (fileError === "") {
            setFile(newFile);
            setNewFileFlg(true);
            let currentDate = getCurrentDateTime("-")
            let fileName = files[0].name;
            setAdditionalInformationObj((prevState) => ({
                ...prevState,
                ProjectBuildingPhoto: fileName,
                ProjectBuildingPhotoUploadedOn: currentDate,
                ProjectBuildingPhotoUploadedBy: userDetails.userId,
                ProjectBuildingPhotoUploadedByName: userDetails.userName,
            }));
            FilesArr.NewFile = fileName;
            setError((prevState) => ({
                ...prevState,
                ProjectBuildingPhoto: { status: false, message: "" }
            }));
            setLoading(false);

        } else {
            setLoading(false);
            MySwal.fire({
                text: fileError,
                imageUrl: errorImage,
                customClass: "swal-success",
            });
            return;
        }
        return isError;
    }

    const handleDownloadDocument = async (fileName) => {
        setLoading(true);
        if (FilesArr.OldFile) {
            downloadAttachment(
                "ProjectDocuments/" + props.SelectedProject.ProjectCode + "/AdditionalInformation/" + fileName,
                fileName
            ).catch((err) => {
                setLoading(false);
                MySwal.fire({
                    text: "Error in Fetching file.",
                    imageUrl: errorImage,
                    customClass: "swal-success",
                });
            });
            setLoading(false);
        }
    }

    const handleDeleteDocument = async (file) => {
        if (props.SelectedProject.DeleteIndication === "Active") {
            setLoading(true);
            FilesArr.NewFile = "";
            setAdditionalInformationObj((prevState) => ({
                ...prevState,
                ProjectBuildingPhoto: "",
                ProjectBuildingPhotoUploadedOn: "",
                ProjectBuildingPhotoUploadedBy: "",
                ProjectBuildingPhotoUploadedByName: ""
            }));
            setDelFileFlg(true);
            setLoading(false);
        }
    }

    const uploadFiles = async (signedURL, file) => {
        try {
            if (signedURL) {
                await axios.put(signedURL, file);
            }
            return "";
        } catch (error) {
            return " Error in file upload.";
        }
    }

    const handleSubmit = async () => {
        console.log("inside handle submit", FilesArr);
        if (!validateDetails()) {
            let confirmBoxText = "Are you sure, so you want to Save the details?";
            MySwal.fire({
                text: confirmBoxText,
                showDenyButton: true,
                cancelButtonColor: "#fff",
                confirmButtonText: "Yes",
                reverseButtons: true,
                customClass: "swal-confirmation",
                title: "Add Additional Information"
            }).then(async (result2) => {
                if (result2.isConfirmed) {
                    setLoading(true);
                    let result = await getOverAllDocumentGenerationData(props.SelectedProject.ProjectCode);
                    let addObj;
                    if (result?.length > 0) {
                        addObj = { ...result[0], ...AdditionalInformationObj }
                    }
                    else {
                        AdditionalInformationObj.PK = "ProjectDocuments#ProjectCode#" + props.SelectedProject.ProjectCode;
                        AdditionalInformationObj.SK = "ProjectCode#" + props.SelectedProject.ProjectCode;
                        addObj = AdditionalInformationObj;
                        //addObj = { ...addObj, ...AdditionalInformationObj }
                    }
                    let result1 = await addDocumentData(addObj);
                    if (result1?.status) {
                        let filepath = "ProjectDocuments/" + props.SelectedProject.ProjectCode + "/AdditionalInformation/" + FilesArr.NewFile;
                        if (FilesArr.NewFile !== "") {
                            let result = await getPresignedURL(filepath);
                            await uploadFiles(result, file);
                        }
                        if (delFileFlg && FilesArr.NewFile !== FilesArr.OldFile) {
                            let fileObj = { fileName: "ProjectDocuments/" + props.SelectedProject.ProjectCode + "/AdditionalInformation/" + FilesArr.OldFile };
                            await deletS3Attachment(fileObj);
                        }
                        setLoading(false);
                        MySwal.fire({
                            text: "Additional Information Saved Successfully.",
                            imageUrl: successImage,
                            customClass: "swal-success",
                        });
                    }
                    else {
                        setLoading(false);
                        MySwal.fire({
                            text: "Error while saving the details.",
                            imageUrl: errorImage,
                            customClass: "swal-success",
                        });
                    }
                }
            });
        }
    }

    const validateDetails = () => {
        let errorMsg = {
            ProjectIntroduction: false,
            ProjectBuildingPhoto: false
        };
        let isError = false;

        if (!AdditionalInformationObj.ProjectBuildingPhoto) {
            isError = true;
            errorMsg.ProjectBuildingPhoto = { status: isError, message: "Project building photo is required" };
        }
        if (!AdditionalInformationObj.ProjectIntroduction) {
            isError = true;
            errorMsg.ProjectIntroduction = { status: isError, message: "Document Introduction is required" };
        }
        setError(errorMsg);

        return isError;
    }

    const handleChange = (name, value) => {
        setAdditionalInformationObj((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    }

    return (
        <>
            {loading && <div className="spinner-box"> <Spinner animation="border" className="spinner" /></div>}
            <Container fluid className="main-blank-container">
                <Form
                    id="additionalInformation"
                    name="additionalInformation"
                    autoComplete="off"
                >
                    <Row className="docRevTopMargin">
                        <Col md={12} xs={12} className="pb-3">
                            <DragDropRender
                                val={AdditionalInformationObj.ProjectBuildingPhoto}
                                fieldName={"Project Building Photo"}
                                supportedFilesLabel={"File Format - (jpg, jpeg, png), Max File Size – up to 5MB per file."}
                                handleChangeEvent={(params) => handleUploadFile(params)}
                                handleDeleteDocEvent={(params) => handleDeleteDocument(params)}
                                handleDownloadDocEvent={(params) => handleDownloadDocument(params)}
                                action={""}
                            />
                            {error.ProjectBuildingPhoto.status && (
                                <Form.Text className="text-danger">
                                    {error.ProjectBuildingPhoto.message}
                                </Form.Text>
                            )}
                        </Col>
                        <Col md={12} xs={12}>
                            <Form.Group className="g-input mb-0">
                                <Form.Control
                                    id="ProjectIntroduction"
                                    as="textarea"
                                    rows={2}
                                    type="textarea"
                                    maxLength="2000"
                                    name="ProjectIntroduction"
                                    placeholder=" "
                                    value={AdditionalInformationObj.ProjectIntroduction}
                                    onChange={(e) => { handleChange(e.target.name, e.target.value) }}
                                    readOnly={props.SelectedProject.DeleteIndication !== "Active"}
                                />
                                <Form.Label className="frm-label input-label" for="ProjectIntroduction">Project Introduction</Form.Label>
                                {error.ProjectIntroduction.status && (
                                    <Form.Text className="text-danger">
                                        {error.ProjectIntroduction.message}
                                    </Form.Text>
                                )}
                            </Form.Group>
                        </Col>
                    </Row>
                    {props.SelectedProject.DeleteIndication === "Active" &&
                        <Button variant="outline-success" className="cancelBtn semiBoldToggleButton rightAlignedButton mb-4" onClick={() => handleSubmit()}>
                            Save
                        </Button>
                    }
                </Form>
                <br />
                <ProjectDocumentParam
                    SelectedProject={props.SelectedProject}
                    paramtabname={"AdditionalInformation"}
                    headername={"Additional Information"}
                    projectservicedata={props.projectservicedata}
                    limitValue={props.limitValue}
                    tabNo={props.tabNo}
                />
            </Container>
        </>
    )

};

export default AdditionalInformation;