import React, { useState, useContext, useEffect } from "react";
import { Button, Form, Modal, Row, Col, Spinner } from "react-bootstrap";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import errorImage from "../../icons/error-tick-logo.svg";
import {
  addEquipmentParameterDetails,
  getEquipmentParameterData
} from "../../services/equipmentactivity-master-service";
import successImage from "../../icons/success-tick-logo.svg";
import { AuthContext } from "../../contexts/AuthContext";
import { formatDateInYYYYMMDD, getCurrentDateTime, getTodaysDate } from "../../functions/gbcs-functions";
import DragDropRender from "../utils/DragDropRender";
import Validate from "../utils/Validation";
import axios from "axios";
import { downloadAttachment } from "../../services/designreviewtracker-service";

const AddEquipmentParameters = (props) => {
  const MySwal = withReactContent(Swal);
  const context = useContext(AuthContext);
  const userDetails = { userId: context.userId, userName: context.userName }
  let todayDate = getCurrentDateTime("-");
  let action = props.action;
  let tabName = props.paramTabName;
  let equipcode = props.equipcode
  const [paramObj, setParamObj] = useState(action === "Edit" || action === "View" ? props.rowdata : {});
  const [error, setError] = useState({});
  const [documentsArr, setDocumentsArr] = useState({});
  const [loading, setLoading] = useState(false);

  const screenName = props.screenname;

  useEffect(() => {
    ////debugger;
    let arrObj = {};
    for (let dt of props.tabflds) {
      if (dt.DataType === "Text") {
        arrObj[dt.FieldName] = "";
      }
      else if (dt.DataType === "CheckBox") {
        for (let dt1 of props.optionValues) {
          arrObj[dt1] = "";
        }
      }
      else if (dt.DataType === "Document") {
        arrObj[dt.FieldName] = "";
        documentsArr[dt.FieldName] = "";
      }
    }
    setError(arrObj);
    setDocumentsArr(documentsArr);
  }, []);

  const validateDetails = () => {
    //debugger;
    let isError = false;
    const errorMsg = {};
    for (let dt of props.tabflds) {
      if (dt.DataType === "Text" || dt.DataType === "Document" || dt.DataType === "Number" || dt.DataType === "Date") {
        errorMsg[dt.FieldName] = "";
      }
      else if (dt.DataType === "CheckBox") {
        for (let dt1 of props.optionValues) {
          errorMsg[dt1] = "";
        }
      }
    }
    for (let dt of props.tabflds) {
      if (dt.isMandatory) {
        if ((dt.DataType === "Text" || dt.DataType === "Number" || dt.DataType === "Date" || dt.DataType === "Document") && (!paramObj[dt.FieldName] || paramObj[dt.FieldName] === "")) {
          isError = true;
          errorMsg[dt.FieldName] = {
            status: true,
            message: dt.FieldName + " is required.",
          };
        }
        if (dt.DataType === "Checkbox") {
          let IsSelected = false;
          for (let dt1 of dt.optionValues) {
            if (paramObj[dt1]) {
              IsSelected = true;
            }
          }
          if (!IsSelected) {
            isError = true;
            errorMsg[dt.FieldName] = {
              status: true,
              message: dt.FieldName + " is required.",
            };
          }
        }
      }
      if (dt.DataType === "Checkbox" && dt.validation) {
        for (let dt1 of dt.optionValues) {
          console.log(dt1, paramObj[dt1], props.equipData[dt.validation[dt1]], props.equipData[dt.validation[dt1]]?.length)
          if (paramObj[dt1] && (!props.equipData[dt.validation[dt1]] || props.equipData[dt.validation[dt1]]?.length === 0)) {
            isError = true;
            errorMsg[dt.FieldName] = {
              status: true,
              message: dt1 + " is not selected for Equipment / Activity.",
            };
          }
        }
      }
      if (dt.DataType === "Date" && dt.validation) {
        if (dt.validation.hasOwnProperty("NotFutureToDate")) {
          let toDate = getTodaysDate();
          let toDateName = "today's date";
          if (dt.validation.NotFutureToDate !== "Current Date") {
            toDate = paramObj[dt.validation.NotFutureToDate];
            toDateName = dt.validation.NotFutureToDate;
          }
          console.log(dt.FieldName, paramObj[dt.FieldName], toDate)
          if (paramObj[dt.FieldName] && new Date(paramObj[dt.FieldName]) > new Date(toDate)) {
            isError = true;
            errorMsg[dt.FieldName] = {
              status: true,
              message: "Date should be less than " + toDateName
            };
          }
        }
        if (dt.validation.hasOwnProperty("FutureToDate")) {
          let toDate = formatDateInYYYYMMDD(todayDate, "-");
          let toDateName = "today's date";
          if (dt.validation.FutureToDate !== "Current Date") {
            toDate = paramObj[dt.validation.FutureToDate];
            toDateName = dt.validation.FutureToDate;
          }
          console.log(dt.FieldName, paramObj[dt.FieldName], toDate)
          if (paramObj[dt.FieldName] && new Date(paramObj[dt.FieldName]) < new Date(toDate)) {
            isError = true;
            errorMsg[dt.FieldName] = {
              status: true,
              message: "Date should be greater than " + toDateName
            };
          }
        }
      }
    }

    setError(errorMsg);
    return { isError, errorMsg };
  };

  const handleChange = (e) => {
    let { name, value, type } = e.target;
    if (type.toLowerCase() === "checkbox") {
      value = e.target.checked;
    }
    setParamObj((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    //debugger;
    e.preventDefault();
    let result;
    let messageAction = "";
    setLoading(true);
    let validationResult = validateDetails();
    if (!validationResult.isError) {
      let isDuplicate = false;
      let tmpObj = {};
      if (screenName === "ProjectDocuments") {
        tmpObj = { "equipcode": equipcode, "tabName": tabName, "projectcode": props.projdata.ProjectCode }
      }
      else {
        tmpObj = { "code": equipcode, "tabName": tabName }
      }
      const result1 = await getEquipmentParameterData(tmpObj, screenName);
      if (result1.length > 0) {
        isDuplicate = checkDuplicate(result1);
      }
      if (!isDuplicate) {
        if (action === "Add") {
          messageAction = "added";
          paramObj.CreatedOn = todayDate;
          paramObj.CreatedBy = userDetails.userId;
          paramObj.CreatedByName = userDetails.userName;
          paramObj.ModifiedOn = todayDate;
          paramObj.ModifiedBy = userDetails.userId;
          paramObj.ModifiedByName = userDetails.userName;
          if (screenName === "ProjectDocuments") {
            paramObj.ProjectCode = props.projdata.ProjectCode;
            paramObj.ServiceCode = props.equipData.ServiceCode;
            paramObj.ServiceName = props.equipData.ServiceName;
            paramObj.EquipmentName = props.equipData.EquipmentName;
            paramObj.EquipmentCode = props.equipData.EquipmentCode;
          }
          result = await addEquipmentParameterDetails(paramObj, tabName, equipcode, "new", screenName);
        }
        else if (action === "Edit") {
          messageAction = "updated";
          let obj = Object.assign({}, paramObj)
          obj.ModifiedOn = todayDate;
          obj.ModifiedBy = userDetails.userId;
          obj.ModifiedByName = userDetails.userName;
          result = await addEquipmentParameterDetails(obj, tabName, equipcode, "existing", screenName);
        }
        if (result?.status) {
          //debugger;
          if (tabName === "AdditionalInformation") {
            for (let property in result.signedURL) {
              //debugger;
              let fileUploadMsg = uploadFiles(result.signedURL[property], documentsArr[property]);
            }
          }
          setLoading(false);
          MySwal.fire({
            text: "Equipment / Activity " + props.headername + " has been " + messageAction + " successfully.",
            imageUrl: successImage,
            customClass: "swal-success",
            didClose: () => {
              props.fetchEquipmentParameterData();
              props.onHide();
            },
          });
        }
        else {
          setLoading(false);
          MySwal.fire({
            text: "Unable to Process Request",
            imageUrl: errorImage,
            customClass: "swal-success",
          });
        }
      }
      else {
        setLoading(false);
      }
    }
    else {
      setLoading(false);
    }
  };

  const uploadFiles = async (signedURL, file) => {
    try {
      if (signedURL) {
        await axios.put(signedURL, file);
      }
      return "";
    } catch (error) {
      return " Error in file upload.";
    }
  }

  const handleReset = () => {
    let errorMsg = {};
    for (let dt of props.tabflds) {
      if (dt.DataType === "Text" || dt.DataType === "Document" || dt.DataType === "Number" || dt.DataType === "Date") {
        errorMsg[dt.FieldName] = "";
      }
      else if (dt.DataType === "CheckBox") {
        for (let dt1 of props.optionValues) {
          errorMsg[dt1] = "";
        }
      }
    }
    setParamObj(action === "Edit" || action === "View" ? props.rowdata : {});
    setError(errorMsg);
    //debugger;
    setDocumentsArr({});
  }

  const checkDuplicate = (data) => {
    let isDuplicate = false;
    const errorMsg = {};
    for (let item of data) {
      for (let field of props.tabflds) {
        if (item.SK !== paramObj.SK && field.UniqueInd == true) {
          if (item[field.FieldName]?.toLowerCase() === paramObj[field.FieldName]?.trim().toLowerCase()) {
            MySwal.fire({
              text: field.FieldName + " already exists.",
              imageUrl: errorImage,
              customClass: "swal-success",
            });
            isDuplicate = true
            break;
          }
        }
      }
    }
    return isDuplicate;
  }

  const handleUploadDocument = async (files, field, fileSize) => {
    const newFile = files[0];
    let fileName = files[0].name;
    let fileError = Validate(newFile, "docsFormats", fileSize);
    if (fileError == "") {
      let currentDate = getCurrentDateTime("-");
      setParamObj((prevState) => ({
        ...prevState,
        [field]: fileName,
        [field + "UploadedBy"]: userDetails.userId,
        [field + "UploadedByName"]: userDetails.userName,
        [field + "UploadedDate"]: currentDate,
      }));
      setDocumentsArr((prevState) => ({
        ...prevState,
        [field]: newFile
      }))
    }
    else {
      MySwal.fire({
        text: fileError,
        imageUrl: errorImage,
        customClass: "swal-success",
      });
      return;
    }

  }

  const handleDeleteDocument = async (fieldNm) => {
    //debugger;
    setParamObj((prevState) => ({
      ...prevState,
      [fieldNm]: "",
      [fieldNm + "UploadedBy"]: "",
      [fieldNm + "UploadedByName"]: "",
      [fieldNm + "UploadedDate"]: "",
    }));

    setDocumentsArr((prevState) => ({
      ...prevState,
      [fieldNm]: "",
    }));
  };

  const handleDownloadDocument = async (fileName, field) => {
    console.log("download called");
    // console.log("old file",FilesArr.OldFile);
    if (documentsArr[field] !== "") {
      downloadAttachment(
        "EquipmentActivitySampleDoc/" +
        props.equipData.EquipmentCode +
        "/" +
        fileName,
        fileName
      ).catch((err) => {
        MySwal.fire({
          text: "Error in Fetching file.",
          imageUrl: errorImage,
          customClass: "swal-success",
        });
      });
    }
  };

  return (
    <>
      <Modal {...props} size="lg" backdrop="static" centered>
        <Modal.Header closeButton>
          <Modal.Title>{action} {props.headername}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {loading && <div className="spinner-box"> <Spinner animation="border" className="spinner" /></div>}
          <Form id={tabName} name={tabName} autoComplete="off">
            <Row>
              {props.tabflds?.map((dt, ind) => {
                return (<>
                  {(dt.DataType === "Text" || dt.DataType === "Number") &&
                    <Col xs={12}>
                      <Form.Group className="g-input">
                        <Form.Control
                          id={dt.FieldName}
                          type={dt.DataType.toLowerCase()}
                          name={dt.FieldName}
                          maxLength={dt.MaxLen}
                          placeholder=" "
                          required
                          value={paramObj[dt.FieldName] ? paramObj[dt.FieldName] : ""}
                          onChange={handleChange}
                        />
                        <Form.Label className="frm-label" htmlFor={dt.FieldName}>
                          {dt.FieldName}
                        </Form.Label>
                        {error[dt.FieldName]?.status && (
                          <Form.Text className="text-danger">
                            {error[dt.FieldName]?.message}
                          </Form.Text>
                        )}
                      </Form.Group>
                    </Col>
                  }
                  {dt.DataType === "Date" &&
                    <Col md={6} xs={12}>
                      <Form.Group className="g-input">
                        <Form.Control
                          id={dt.FieldName}
                          type="date"
                          name={dt.FieldName}
                          maxLength={dt.MaxLen}
                          placeholder=" "
                          required
                          value={paramObj[dt.FieldName] ? paramObj[dt.FieldName] : ""}
                          onChange={handleChange}
                        />
                        <Form.Label className="frm-label" htmlFor={dt.FieldName}>
                          {dt.FieldName}
                        </Form.Label>
                        {error[dt.FieldName]?.status && (
                          <Form.Text className="text-danger">
                            {error[dt.FieldName]?.message}
                          </Form.Text>
                        )}
                      </Form.Group>
                    </Col>
                  }
                  {dt.DataType === "Checkbox" && <>{
                    dt.optionValues.map((item) => {
                      return (
                        <Col md={3} xs={6}>
                          <Form.Check className="fieldLabel"
                            type="checkbox"
                            id={tabName + item}
                            name={item}
                            value={item}
                            checked={paramObj[item]}
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            label={item}
                          />
                        </Col>
                      )
                    })
                  }
                    {error[dt.FieldName]?.status && (
                      <Form.Text className="text-danger">
                        {error[dt.FieldName]?.message}
                      </Form.Text>
                    )}
                  </>
                  }
                  {
                    dt.DataType === "Document" &&

                    <Col md={12} xs={12} className="pb-2">
                      <Form.Label className="semiBoldToggleButton">
                        {dt.FieldName}
                      </Form.Label>
                      {dt.sampleDocDownloadAvailable && (
                        <DragDropRender
                          val={props.equipData.AttachedDocument} // file name
                          //fieldName={dt.FieldName}
                          supportedFilesLabel={dt.SupportedFiles}
                          handleDownloadDocEvent={(params) =>
                            handleDownloadDocument(params)
                          }
                          action={dt.Action ? dt.Action : ""}
                        />
                      )}
                      <DragDropRender
                        val={paramObj[dt.FieldName] ? paramObj[dt.FieldName] : ""}
                        //fieldName={dt.FieldName}
                        supportedFilesLabel={dt.SupportedFiles}
                        handleChangeEvent={(file) => handleUploadDocument(file, dt.FieldName, dt.FileSize)}
                        handleDeleteDocEvent={() => handleDeleteDocument(dt.FieldName)}
                        action={""}
                      />
                      {/* </Col> */}
                      {error[dt.FieldName]?.status && (
                        <Form.Text className="text-danger">
                          {error[dt.FieldName]?.message}
                        </Form.Text>
                      )}
                    </Col >
                  }
                </>)
              })}
            </Row>
            <br />
            <Row>
              <Col xs={6} md={6}>
                {action === "Add" &&
                  <Button className="semiBoldToggleButton cancelBtn" variant="outline-success" onClick={handleReset}>Reset</Button>
                }
              </Col>
              <Col xs={6} md={6}>
                <Button
                  variant="success"
                  className="btn-add-color semiBoldToggleButton rightAlignedButton"
                  onClick={handleSubmit}
                >
                  {action === "Add" ? "Add" : "Update"}
                </Button>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal >
    </>
  );
};

export default AddEquipmentParameters;
