export default function Validate(file, formats = "allFormats", reqfileSize = 5242880) {
  let error = "";
  let fileName = file.name;
  let fileExtn =
    fileName.indexOf(".") > 0
      ? fileName.split(".").pop().toLowerCase()
      : "undefined";
  let fileSize = file.size;
  let format = process.env.REACT_APP_SPECIAL_CHARACTERS;
  let allowedExtensions;
  if (formats === "docsFormats") {
    allowedExtensions = process.env.REACT_APP_FILE_DOCS_EXTENSIONS;
  }
  else if (formats === "imgFormats") {
    allowedExtensions = process.env.REACT_APP_FILE_PHOTO_EXTENSIONS;
  }
  else if (formats === "excelFormats") {
    allowedExtensions = process.env.REACT_APP_FILE_EXCEL_EXTENSIONS;
  }
  else {
    allowedExtensions = process.env.REACT_APP_FILE_EXTENSIONS;
  }
  console.log(format.split("").some((char) => fileName.includes(char)))
  if (format.split("").some((char) => fileName.includes(char))) {
    error = "Special Characters " + format + " are not allowed in file name.";
  }

  if (fileName.match(/\./g).length !== 1) {
    error = error + "File name contains more than one dot. ";
  }

  if (!allowedExtensions.includes(fileExtn)) {
    error = error + "Unsupported file format. ";
  }

  let mb = (reqfileSize / 1048576)

  if (fileSize > reqfileSize) {
    error = error + "File size is greater than " + mb + "MB.";
  }
  return error;
}

export const specialCharError = (e) => {
  let specialchar = process.env.REACT_APP_SPECIAL_CHARACTERS;
  let charsToCheck = "/\r\t/";
  let word = e.target.value.trim();
  let errorFlag = specialchar.split("").some((char) => word.includes(char));
  let errorFlag0 = charsToCheck
    .split("")
    .some((char) => word.charAt(0).includes(char));

  if (!!(errorFlag || errorFlag0)) {
    //let result1 = specialchar.replace(/.{1}/g, '$&, ');
    return { flag: true, msg: "Special Characters " + specialchar + " are not allowed." }
  }
  else {
    return { flag: false, msg: "" }
  }
};
