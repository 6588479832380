import axios from "axios";
const TRANS_URL = process.env.REACT_APP_TRANSACTION_API_URL;

export async function getIntegratedTabsData(projObj) {
  let url = `${TRANS_URL}/integratedsystemtesting/getintegratedtabsdata`;
  const response = await axios
    .post(url, projObj)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      console.log("error => ", error);
      return { data: [] };
    });
  return response.data;
}

//======================Add new IST record ==========================
export const addIntegratedSystemTestingDetails = async (istObj) => {
  let url = `${TRANS_URL}/integratedsystemtesting/addIntegratedSystemTestingDetails`;
  const response = await axios
    .post(url, istObj)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      console.log("error => ", error);
      return { data: { message: "Unable to process request", status: false } };
    });
  return response.data;
};

export async function deleteISTTabData(istobject) {
  let url = `${TRANS_URL}/integratedsystemtesting/deleteisttabdata`;
  const resp = await axios
    .post(url, istobject)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      console.log("error => ", error);
      return { data: { message: "Unable to process request", status: false } };
    });
  return resp.data;
}

export async function getScriptSummaryMaster() {
  let url = `${TRANS_URL}/integratedsystemtesting/getscriptsummarymaster`;
  const response = await axios
    .get(url)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      console.log("error => ", error);
      return { data: [] };
    });
  return response.data;
}

//==================== Get Ist Parameter limits ====================
export async function getIstParameterLimitData() {
  const response = await axios
    .get(TRANS_URL + "/integratedsystemtesting/getIstParameterLimitData")
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      console.log("error => ", error);
      return { data: [] };
    });
  return response.data;
}

//======================Add new IST record ==========================
export const addIstScriptDocuments = async (istObj) => {
  let url = `${TRANS_URL}/integratedsystemtesting/addistscriptdocuments`;
  const response = await axios
    .post(url, istObj)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      console.log("error => ", error);
      return { data: { message: "Unable to process request", status: false } };
    });
  return response.data;
};

export async function getIstScriptDocumentsData(projObj) {
  let url = `${TRANS_URL}/integratedsystemtesting/getistscriptdocumentsdata`;
  const response = await axios
    .post(url, projObj)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      console.log("error => ", error);
      return { data: [] };
    });
  return response.data;
}

export async function getPowerAnalyzerDocumentsData(projObj) {
  let url = `${TRANS_URL}/integratedsystemtesting/getpoweranalyzerdocumentsdata`;
  const response = await axios
    .post(url, projObj)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      return { data: { message: "Unable to process request", status: false } };
    });
  return response.data;
}

export const updatePowerAnalyzerDocuments = async (istObj) => {
  let url = `${TRANS_URL}/integratedsystemtesting/updatepoweranalyzerdocuments`;
  const response = await axios
    .post(url, istObj)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      console.log("error", error)
      return { data: { message: "Unable to process request", status: false } };
    });
  return response.data;
};

export async function getISTEquipDashboardData(ProjectCode) {
  let url = `${TRANS_URL}/integratedsystemtesting/getistequipdashboarddata`;
  let reqBody = { ProjectCode }
  const response = await axios
    .post(url, reqBody)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      console.log("error => ", error);
      return { data: [] };
    });
  return response.data;
}