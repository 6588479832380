import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;
const TRANS_URL = process.env.REACT_APP_TRANSACTION_API_URL;

export async function getAllServiceEquipmentData() {
  const response = await axios
    .get(API_URL + "/equipmentactivitymaster")
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      console.log("error => ", error);
      return { data: [] };
    });
  return response.data;
}

export async function getEquipmentParameterData(reqObj, screenName = "EquipMaster") {
  console.log("reqObj", reqObj)
  let hittingurl = API_URL + "/equipmentactivitymaster/getEquipmentParameterDetails";
  if (screenName === "ProjectDocuments") {
    hittingurl = TRANS_URL + "/projectdocument/getEquipmentParameterDetails";
  }
  const response = await axios
    .post(hittingurl, reqObj)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      console.log("error => ", error);
      return { data: [] };
    });
  return response.data;
}

//============================ Add New Service Equipment ====================================
export const AddNewServiceEquipment = async (EquipObj) => {
  let url = `${API_URL}/equipmentactivitymaster/addServiceEquipment`;
  const response = await axios
    .post(url, EquipObj)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      console.log("error => ", error);
      return { data: { message: "Unable to find request", status: false } };
    });
  return response.data;
};

//============================ Add New Equipment Parameter ====================================
export async function addEquipmentParameterDetails(paramObj, tabName, equipCode, flag, screenName = "EquipMaster") {
  let reqBody = { "paramObj": paramObj, "tabName": tabName, "equipCode": equipCode, "flag": flag }
  let hittingurl = API_URL + "/equipmentactivitymaster/addequipmentparameterdetails";

  if (screenName === "ProjectDocuments") {
    reqBody = { "paramObj": paramObj, "tabName": tabName, "equipCode": equipCode, "flag": flag }
    hittingurl = TRANS_URL + "/projectdocument/addequipmentparameterdetails";
  }
  const response = await axios
    .post(hittingurl, reqBody)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      console.log("error => ", error);
      return { data: { message: "Unable to find request", status: false } };
    });
  return response.data;
}

//============================ Update Service equipments ====================================
export const updateserviceequipment = async (EquipObj) => {
  let url = `${API_URL}/equipmentactivitymaster/updateserviceequipment`;
  const response = await axios
    .post(url, EquipObj)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      console.log("error => ", error);
      return { data: { message: "Unable to find request", status: false } };
    });
  return response.data;
};

//=============== get active equipments ============================
export async function getActiveEquipmentData(equipObj) {
  const response = await axios
    .post(API_URL + "/equipmentactivitymaster/getactiveequipments", equipObj)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      console.log("error => ", error);
      return { data: [] };
    });
  return response.data;
}

//=============== get active proj checklist equipments ============================
export async function getSelectedEquipDetails(equipObj) {
  const response = await axios
    .post(API_URL + "/equipmentactivitymaster/getselectedequipdetails", equipObj)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      console.log("error => ", error);
      return { data: [] };
    });
  return response.data;
}

//====================Delete Equipment Parameter ====================
export const deleteEquipmentDetails = async (paramData, screenName = "EquipMaster") => {
  console.log("paramData", paramData)
  let hittingurl = `${API_URL}/equipmentactivitymaster/deleteequipmentdetails`;
  if (screenName === "ProjectDocuments") {
    hittingurl = TRANS_URL + "/projectdocument/deleteprojdocparamdetails";
  }
  const response = await axios
    .post(hittingurl, paramData)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      console.log("error => ", error);
      return { data: { message: "Unable to find request", status: false } };
    });
  return response.data;
};
