import { Row, Col, Button, Form } from "react-bootstrap";
import { DragDropRender } from "../masters/ProjectMaster/DragDropRender"
import ProjectDocumentFileDetails from "./ProjectDocumentFileDetails";

const ProjectDocumentRowCollapsed = (props) => {

    let versionsArr = Array.from({ length: 5 }, (x, i) => i);
    let keyCol = props.documentRowData.DataKey ? props.documentRowData.DataKey.length : 0;
    let blankColArr = Array.from({ length: 2 - keyCol }, (x, i) => i);
    let toggleClass = "inactiveToggleLabelProjDoc";
    if (props.documentRowData.FreezeIndication === "Freeze") {
        toggleClass = "activeToggleLabelProjDoc";
    }

    return (
        <Row className="documents-header">
            {/* {
                props.documentRowData.ServiceCode &&
                <Col md={1} xs={12} className="documents-document-details project-doc-upload" >
                    <Col md={12} xs={6} className="service-equip-labeltext">Service</Col>
                    <Col md={12} xs={6}>{props.documentRowData.ServiceName}</Col>
                </Col>
            }
            {
                props.documentRowData.EquipmentCode &&
                <Col md={1} xs={12} className="documents-document-details project-doc-upload" >
                    <Col md={12} xs={6} className="service-equip-labeltext">Equipment</Col>
                    <Col md={12} xs={6}>{props.documentRowData.EquipmentName}</Col>
                </Col>
            }
            {
                props.documentRowData.SubDocumentFullName &&
                <Col md={1} xs={12} className="documents-document-details project-doc-upload" >
                    <Col md={12} xs={6} className="service-equip-labeltext">{props.documentRowData.SubDocumentFullName}</Col>
                </Col>
            } */}
            {
                props.documentRowData.DataKey?.map((dt) => {
                    return (
                        <Col md={1} xs={12} className="documents-document-details project-doc-upload" >
                            <Col md={12} xs={6} className="service-equip-labeltext">{dt.keyValue}</Col>
                        </Col>)
                })

            }
            <Col md={1} xs={4} className="documents-generate-btn">
                <Button className="semiBoldToggleButton"
                    variant="outline-success"
                    onClick={props.hendleGenerate}
                    disabled={props.documentRowData.disableFlag || props.documentRowData.FreezeIndication === "Freeze"}
                >
                    Generate
                </Button>
                <Form.Switch className={"semiBoldToggleButton " + toggleClass}
                    label={props.documentRowData.FreezeIndication === "Freeze" ? "Freeze" : "Unfreeze"}
                    name="FreezeIndication"
                    checked={props.documentRowData.FreezeIndication === "Freeze" ? true : false}
                    disabled={props.documentRowData.disableFlag}
                    onChange={(e) => {
                        props.handleSwitchChange(e, props.documentType, props.documentRowData, props.documentRowIndex);
                    }}
                />
            </Col>
            <Col md={1} xs={8} className="project-doc-generated" >
                {
                    props.documentRowData.DocumentName &&
                    <ProjectDocumentFileDetails
                        fileName={props.documentRowData.DocumentName}
                        documentCreatedBy={props.documentRowData.DocumentGeneratedByName}
                        documentDate={props.documentRowData.DocumentGenerationDate}
                        documentRowIndex={props.documentRowIndex}
                        handleDownloadDocEvent={(filename) => props.handleDownloadDocument(filename, props.documentType, props.documentRowData)}
                    />
                }
            </Col>
            {keyCol < 2 &&
                blankColArr?.map((index) => {
                    return (<Col md={1} className="project-doc-upload d-none d-md-block d-lg-block" >&nbsp;</Col>)
                })
            }
            {
                versionsArr?.map((index) => {
                    let docVerId = index + 1;
                    return (
                        <Col md={1} xs={12} className="project-doc-upload" >
                            {
                                props.documentRowData["UploadVersion" + docVerId + "DocumentName"] ?
                                    <ProjectDocumentFileDetails
                                        showDelete={!(props.documentRowData.disableFlag || props.documentRowData.FreezeIndication === "Freeze")}
                                        fileName={props.documentRowData["UploadVersion" + docVerId + "DocumentName"]}
                                        documentCreatedBy={props.documentRowData["UploadVersion" + docVerId + "UploadedByName"]}
                                        documentDate={props.documentRowData["UploadVersion" + docVerId + "UploadedDate"]}
                                        documentRowIndex={props.documentRowIndex}
                                        handleDeleteDocEvent={(filename) => props.handleDeleteUploadVersion(filename, props.documentRowData, props.documentType, props.documentRowIndex, docVerId)}
                                        handleDownloadDocEvent={(filename) => props.handleDownloadDocument(filename, props.documentType, props.documentRowData)}
                                    />
                                    :
                                    <DragDropRender
                                        isProjectDocuemnts={true}
                                        val={""}
                                        handleChangeEvent={(file) => props.handleUploadVersions(file, props.documentRowData, props.documentType, props.documentRowIndex, docVerId)}
                                        label={"Upload Version-" + docVerId}
                                        disabled={props.documentRowData.disableFlag || props.documentRowData.FreezeIndication === "Freeze"}
                                    />
                            }
                        </Col>
                    )
                })
            }
        </Row >
    );

};

export default ProjectDocumentRowCollapsed;