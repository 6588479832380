import React, { useEffect, useState, useRef, useMemo, useContext, useImperativeHandle, forwardRef } from "react";
import { Form, Modal, Row, Col, Container, Card } from "react-bootstrap";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "../../utils/AddEditProjectMaster.css";
import "../../masters/GridMaster.css";
import { AgGridReact } from "ag-grid-react";
import Pagination from "../../utils/Pagination";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import ActionRenderer from "../ActionRenderer";
import { AuthContext } from "../../../contexts/AuthContext";
import AddEditStatusDetails from "./AddEditStatusDetails";
import { BsPlusLg } from "react-icons/bs";
import { Link } from "react-router-dom";
import StatusRenderer from "../StatusRenderer";
import { UpdateStatusDetails } from "../../../services/checklist-master-service";
import {
  defaultEditableCol,
  getCurrentDateTime,
} from "../../../functions/gbcs-functions";
import successImage from "../../../icons/success-tick-logo.svg";
import errorImage from "../../../icons/error-tick-logo.svg";
import { ALERT_PARAMS } from "../../../utils/constant.js";

const StatusDetails = forwardRef((props, ref) => {
  let action = props.action;
  let limitValue = props.limitValue;
  const MySwal = withReactContent(Swal);
  const context = useContext(AuthContext);
  const userDetails = { userName: context.userName, userId: context.userId };
  let todayDate = getCurrentDateTime("-");
  const menuAccess = context.accessibleMenus.filter(
    (item) => item.SessionName === "Checklist Master"
  );

  const [actionButtons, setActionButtons] = useState(
    action === "View" || props.checklistVersion.startsWith("VER") ? {
      ViewAction: false,
      EditAction: false,
      DownloadAction: false,
      DeleteAction: false,
    } :
      {
        ViewAction: false,
        EditAction: menuAccess[0]?.Modify,
        DownloadAction: false,
        DeleteAction: menuAccess[0]?.Delete,
      });

  const [showAddStatusForm, setShowAddStatusForm] = useState(false);
  const [rowData, setRowData] = useState({});
  const [statusDetailAction, setStatusDetailAction] = useState("");
  // const [reload, setReload] = useState(false);
  const gridRef = useRef();
  const [gridApi, setGridApi] = useState(null);
  const pageSize = 3;
  const [currentPage, setCurrentPage] = useState(1);
  const [rowNo, setRowNo] = useState(0);

  const [tmpstatusDetails, setTmpstatusDetails] = useState([]);

  useEffect(() => {
    let colArr = [...columnDefs];
    let colIndex = colArr.findIndex(
      (obj) => obj.headerName === "Parameter Category"
    );
    colArr[colIndex].hide = !props.IsParameterCategory;
    setColumnDefs(colArr);
  }, [props.IsParameterCategory]);

  useEffect(() => {
    if (
      (action === "Edit" || action === "View") &&
      props.rowdata.hasOwnProperty("StatusDetails")
    ) {
      let tmpData = props.rowdata.StatusDetails;
      if (props.IsParameterCategory) {
        for (let dt of tmpData) {
          dt.displaycategory = dt.ParameterCategory.toString();
        }
      }
      setTmpstatusDetails(tmpData);
    }
  }, []);

  function onGridReady(params) {
    setGridApi(params.api);
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.resetRowHeights();
    gridRef.current = params.api; // <= assigned gridApi value on Grid ready
  }

  const defaultColDef = useMemo(() => {
    return defaultEditableCol();
  }, []);

  const actionRender = (params) => {
    return (
      <ActionRenderer
        selectedData={params}
        actionName={actionButtons}
        handleEditEvent={(params) => handleEdit(params.data)}
        handleDeleteEvent={(params) => handleDelete(params.data)}
      />
    );
  };

  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: "Sr No.",
      field: "SeqNo",
      tooltipField: "SeqNo",
      minWidth: 50,
      rowDrag: true,
      sortable: false,
      filter: false,
      resizable: true
    },
    {
      headerName: "Parameter Category",
      field: "displaycategory",
      minWidth: 250,
      tooltipField: "displaycategory",
      hide: true,
      resizable: true
    },
    {
      headerName: "Status",
      field: "Status",
      minWidth: 250,
      tooltipField: "Status",
      resizable: true
    },
    {
      headerName: "Result",
      field: "Result",
      minWidth: 250,
      tooltipField: "Result",
      cellRenderer: StatusRenderer,
      resizable: true
    },
    {
      headerName: "Remark",
      field: "Remark",
      tooltipField: "Remark",
      minWidth: 250,
      width: 400,
      resizable: true
    },
    {
      field: "",
      width: 150,
      cellRenderer: function (params) {
        return (
          <ActionRenderer
            selectedData={params}
            actionName={actionButtons}
            handleEditEvent={(params) => handleEdit(params.data)}
            handleDeleteEvent={(params) => handleDelete(params.data)}
          />
        );
      },
      sortable: false,
      filter: false,
      pinned: "right",
    },
  ]);

  const headerName = (field) => {
    let name;
    switch (field) {
      case "displaycategory":
        name = "Parameter Category";
        break;
      case "Status":
        name = "Status";
        break;
      case "Result":
        name = "Result";
        break;
      case "Remark":
        name = "Remark";
        break;
      default:
        name = "No match found";
        break;
    }
    return name;
  };

  const fieldName = (header) => {
    let name;
    switch (header) {
      case "Parameter Category":
        name = "displaycategory";
        break;
      case "status":
        name = "status";
        break;

      case "Result":
        name = "Result";
        break;
      case "Remark":
        name = "Remark";
        break;

      default:
        name = "No match found";
        break;
    }
    return name;
  };

  const filteredResult = useMemo(() => {
    let firstPageIndex = 0;
    if (currentPage > 1) {
      firstPageIndex = (currentPage - 1) * pageSize;
    }
    if (firstPageIndex >= tmpstatusDetails?.length) {
      firstPageIndex = 0;
      const totalPageCount = Math.ceil(tmpstatusDetails?.length / pageSize);
      if (totalPageCount > 0) {
        setCurrentPage(totalPageCount);
      } else {
        setCurrentPage(1);
      }
    }
    let lastPageIndex = parseInt(firstPageIndex) + parseInt(pageSize);
    return tmpstatusDetails
      ? tmpstatusDetails?.slice(firstPageIndex, lastPageIndex)
      : [];
  }, [currentPage, tmpstatusDetails, pageSize]);

  const handleEdit = async (row) => {
    setStatusDetailAction("Edit");
    setRowData(row);
    setShowAddStatusForm(true);
  };

  const handleDelete = async (data) => {
    MySwal.fire({
      text: "Are you sure, do you want to delete status " + data.Status + "?",
      confirmButtonText: "Delete",
      title: "Delete Status",
      ...ALERT_PARAMS
    }).then(async (result2) => {
      if (result2.isConfirmed) {
        let tmp = Object.assign([], tmpstatusDetails);
        let index = tmp.findIndex((item) => item.SeqNo === data.SeqNo);
        tmp.splice(index, 1);
        console.log("tmp", tmp);
        let obj = {};
        obj.StatusDetails = tmp;
        obj.PK = props.primeKey;
        obj.SK = props.secondKey;
        obj.ModifiedOn = todayDate;
        obj.ModifiedBy = userDetails.userId;
        obj.ModifiedByName = userDetails.userName;
        let result = UpdateStatusDetails(obj);
        result
          .then((resp) => {
            if (resp.status) {
              setTmpstatusDetails(tmp);
              MySwal.fire({
                text: "Status deleted successfully.",
                imageUrl: successImage,
                customClass: "swal-success",
              });
            }
          })
          .catch((error) => {
            MySwal.fire({
              text: "Unable to Process Request",
              imageUrl: errorImage,
              customClass: "swal-success",
            });
          });
      }
    });
  };

  function getRowData() {
    let rowData = [];
    gridRef.current.gridOptionsService.api.forEachNode((node) =>
      rowData.push(node.data)
    );
    return rowData;
  }


  const saveData = async () => {
    // e.preventDefault();
    let resp = { status: false, msg: "" };
    try {
      if (props.primeKey === "" || props.secondKey === "") {
        resp = { status: false, msg: "Checklist code is blank. Kindly maintain Checklist Details." };
        return resp;
      }

      let missingParamErr = "";
      for (let dt of tmpstatusDetails) {
        if (
          props.IsParameterCategory &&
          dt.hasOwnProperty("ParameterCategory") &&
          dt.ParameterCategory.length === 0
        ) {
          missingParamErr += dt.SeqNo + ", ";
        }
      }
      if (missingParamErr !== "") {
        resp = {
          status: false, msg: "Kindly fill Parameter Category for Sr No. " +
            missingParamErr.substring(0, missingParamErr.length - 2)
        };
        return resp;
      }

      let gridData = getRowData();
      let finalData = [];
      if (gridData?.length > 0) {
        let counter = 1;
        for (let dt of gridData) {
          let savedData = tmpstatusDetails.filter(
            (item) => item.SeqNo === dt.SeqNo
          );
          if (counter !== savedData[0]?.SeqNo) {
            savedData[0].SeqNo = counter;
          }
          finalData.push(savedData[0])
          counter++;
        }
        let obj = {};
        obj.StatusDetails = finalData;
        obj.PK = props.primeKey;
        obj.SK = props.secondKey;
        obj.ModifiedOn = todayDate;
        obj.ModifiedBy = userDetails.userId;
        obj.ModifiedByName = userDetails.userName;
        let result = await UpdateStatusDetails(obj);
        if (result?.status) {
          resp = { status: true, msg: result.message };
          setTmpstatusDetails(finalData);
        } else {
          resp = { status: false, msg: "Unable to Process Request." };
        }
      }
    } catch (err) {
      resp = { status: false, msg: "Unable to Process Request." };
    }
    return resp;
  };

  const handleSubmit = async () => {
    // e.preventDefault();

    let res = false;
    try {
      let result = await saveData();
      if (result.status) {
        res = true;
        MySwal.fire({
          text: result.msg,
          imageUrl: successImage,
          customClass: "swal-success",
        });
      }
      else {
        res = false;
        MySwal.fire({
          text: result.msg,
          imageUrl: errorImage,
          customClass: "swal-success",
        });
      }
    } catch (err) {
      res = false;
      MySwal.fire({
        text: "Unable to Process Request",
        imageUrl: errorImage,
        customClass: "swal-success",
      });
    }
    return res;
  };

  useImperativeHandle(ref, () => ({
    funcOnNext: async (direction) => {
      let res = false
      if (direction === "save") {
        if (tmpstatusDetails?.length === 0) {
          res = false;
          MySwal.fire({
            text: "Status details not maintained.",
            imageUrl: errorImage,
            customClass: "swal-success",
          });
        }
        else {
          res = await handleSubmit();
        }
      }
      else {
        res = await saveData();
      }
      return res
      // return handleSubmit();
    }
  }));

  const addNewRow = () => {
    if (props.primeKey === "" || props.secondKey === "") {
      MySwal.fire({
        text: "Checklist code is blank. Kindly maintain Checklist Details.",
        imageUrl: errorImage,
        customClass: "swal-success",
      });
      return;
    }
    if (tmpstatusDetails.length < limitValue) {
      setRowNo(tmpstatusDetails.length + 1);
      setStatusDetailAction("Add");
      setShowAddStatusForm(true);
    } else {
      MySwal.fire({
        text: "You can add only " + limitValue + " parameters.",
        imageUrl: errorImage,
        customClass: "swal-success",
      });
    }
  };

  const setAddNewParameter = (data) => {
    data.displaycategory = data.ParameterCategory.toString();
    if (statusDetailAction === "Add") {
      setTmpstatusDetails((current) => [...current, data]);
    } else {
      let colArr = [...tmpstatusDetails];
      let colIndex = colArr.findIndex((obj) => obj.SeqNo === data.SeqNo);
      colArr[colIndex] = data;
      setTmpstatusDetails(colArr);
    }
  };

  useEffect(() => {
    let colArr = [...columnDefs];
    let colIndex = colArr.findIndex((obj) => obj.field === "");
    colArr[colIndex].cellRenderer = actionRender;
    setColumnDefs(colArr);
  }, [tmpstatusDetails]);

  return (
    <Form
      id="statusdetails"
      name="statusdetails"
      onSubmit={handleSubmit}
      autoComplete="off"
    >
      <div>
        <br />
        {(action !== "View" && props.checklistVersion.startsWith("TMP")) &&
          <Row>
            <div className="addNewRow">
              <BsPlusLg size={20} className="plusIcon" />
              &nbsp;
              <Link onClick={() => addNewRow()} className="add-new-link">
                Add more
              </Link>
            </div>
          </Row>
        }
        <Container fluid className="grid-comp-containerCss">
          <div className="ag-theme-alpine agGridContainer d-none d-md-block d-lg-block">
            <AgGridReact
              ref={gridRef}
              rowData={tmpstatusDetails}
              columnDefs={columnDefs}
              // defaultColDef={defaultColDef}
              fieldNames={fieldName}
              headerNames={headerName}
              enableBrowserTooltips={true}
              onGridReady={onGridReady}
              rowDragManaged={true}
            />
          </div>
          <div className="market-wrapper d-md-none d-lg-none cardContainerResponsive">
            {filteredResult.map((selection, index) => (
              <Card className="cardContainer" key={index}>
                <Card.Body className="cardContainerResponsive">
                  <div
                    className={
                      action === "View" ? "hideDisplay" : "actionIcons"
                    }
                  >
                    <ActionRenderer
                      selectedData={selection}
                      actionName={actionButtons}
                      handleEditEvent={(selection) => handleEdit(selection)}
                      handleDeleteEvent={(selection) =>
                        handleDelete(selection)
                      }
                    />
                  </div>
                  {columnDefs.map((header, index) => (
                    <Row key={index} className="responsive-row">
                      <Col className="colHeader">{header.headerName}</Col>
                      {header.headerName === "Result" ? (
                        <Col className="colData">
                          <StatusRenderer
                            data={selection} colDef={{ field: header.field }}
                          />
                        </Col>
                      ) : (
                        <Col className="colData">
                          {selection[header.field]}
                        </Col>
                      )}
                    </Row>
                  ))}
                </Card.Body>
              </Card>
            ))}
            {tmpstatusDetails?.length > 0 && (
              <Pagination
                currentPage={currentPage}
                totalCount={tmpstatusDetails ? tmpstatusDetails.length : 0}
                data={tmpstatusDetails}
                pageSize={pageSize}
                onPageChange={(page) => setCurrentPage(page)}
              />
            )}
          </div>
        </Container>
        <br />
      </div>
      {/* <br />
        <TabButtons
          currentTab={1}
          handleSelect={props.tabKey}
          action={action}
        /> */}
      {showAddStatusForm && (
        <AddEditStatusDetails
          show={showAddStatusForm}
          masterData={tmpstatusDetails}
          onHide={() => setShowAddStatusForm(false)}
          setAddNewParameter={setAddNewParameter}
          // setReload={setReload}
          // reload={reload}
          rowdata={rowData}
          IsParameterCategory={props.IsParameterCategory}
          ParameterCaterydata={props.distinctCategories}
          action={statusDetailAction}
          rowNo={rowNo}
          primeKey={props.primeKey}
          secondKey={props.secondKey}
        />
      )}
    </Form>
  );
}
);

export default StatusDetails;
