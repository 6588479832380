import { LogLevel } from "@azure/msal-browser";

export const b2cPolicies = {
    names: {
        signUpSignIn: process.env.REACT_APP_B2C_SIGNIN_FLOW,
        // forgotPassword: 'B2C_1_reset_v3',
        // editProfile: 'B2C_1_edit_profile_v2',
    },
    authorities: {
        signUpSignIn: {
            authority: 'https://' + process.env.REACT_APP_B2C_AUTHORITY_DOMAIN + '/' + process.env.REACT_APP_B2C_TENANT + '/' + process.env.REACT_APP_B2C_SIGNIN_FLOW,
        },
        // forgotPassword: {
        //     authority: 'https://fabrikamb2c.b2clogin.com/fabrikamb2c.onmicrosoft.com/B2C_1_reset_v3',
        // },
        // editProfile: {
        //     authority: 'https://fabrikamb2c.b2clogin.com/fabrikamb2c.onmicrosoft.com/b2c_1_edit_profile_v2',
        // },
    },
    authorityDomain: process.env.REACT_APP_B2C_AUTHORITY_DOMAIN,
};

export const msalConfigExternal = {
    auth: {
        clientId: process.env.REACT_APP_B2C_CLIENTID, // This is the ONLY mandatory field that you need to supply.
        authority: b2cPolicies.authorities.signUpSignIn.authority, // Choose SUSI as your default authority.
        knownAuthorities: [b2cPolicies.authorityDomain], // Mark your B2C tenant's domain as trusted.
        redirectUri: '/', // You must register this URI on Azure Portal/App Registration. Defaults to window.location.origin
        postLogoutRedirectUri: '/', // Indicates the page to navigate after logout.
        navigateToLoginRequestUrl: false, // If "true", will navigate back to the original request location before processing the auth code response.
    },
    cache: {
        cacheLocation: 'sessionStorage', // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                    default:
                        return;
                }
            },
        },
    },
};

export const protectedResources = {
    apiTodoList: {
        endpoint: '/',
        scopes: {
            read: ['https://' + process.env.REACT_APP_B2C_TENANT + '/' + process.env.REACT_APP_B2C_CLIENTID + '/read']
        },
    },
};

export const extLoginRequest = {
    scopes: [...protectedResources.apiTodoList.scopes.read]
};