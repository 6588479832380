

import React, { forwardRef, useState, useEffect, useRef, useImperativeHandle } from "react";
import { Container, Spinner } from "react-bootstrap";
import { DashboardProjDataContainer } from "../../functions/gbcs-functions";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { getProjectServiceEqui } from "../../services/project-service";
import { ServiceMasterAllData } from "../../services/common-master-service"
import correctsign from "../../icons/correctsign.svg";
import wrongsign from "../../icons/wrongsign.svg";
import { exportToExcelProjectProgress } from "./ExcelCreation_Dashboard";

const ProjectProgressDetails = forwardRef((props, ref) => {

    const [loading, setLoading] = useState(false);
    const [gridApi, setGridApi] = useState(null);
    const calculatedFinalData = props.calculatedData.tab2Data;

    const gridRef = useRef();

    function onGridReady(params) {
        setGridApi(params.api);
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        params.api.resetRowHeights();
        gridRef.current = params.api; // <= assigned gridApi value on Grid ready
    }

    const defaultColPara = {
        flex: 1,
        // resizable: true,
        textAlign: "center",
        suppressMovable: true,
        // enableCellChangeFlash: true
        cellClass: 'cell-wrap-textDashboard',
        wrapHeaderText: true,
        autoHeaderHeight: true,
    }

    function displayStatus(flag) {
        // debugger
        return (
            <>{
                flag === "NA" ? <span className="dasboardNA">#</span> :
                    <img style={{
                        width: "16px", height: "17px"
                    }} src={flag === "Yes" ? correctsign : wrongsign}
                        alt="statusimg"
                    />
            }
            </>
        )
    }

    const columnDefs = [
        {
            headerName: "Service Name",
            field: "ServiceName",
            minWidth: 150,
            tooltipField: "ServiceName",
            cellRenderer: function (params) {
                const cellBlank = params.data.totalServiceRowSpan === params.data.ServiceRowSpan;
                if (cellBlank) {
                    return (
                        <div className="show-name">{params.value}</div>
                    )
                }
                return;
            },
            rowSpan: (params) => params.data.ServiceRowSpan !== undefined ? params.data.ServiceRowSpan : 1,
            cellClassRules: {
                "show-cell": (params) => { return params.data.ServiceRowSpan !== undefined; },
                "show-data": (params) => { return params.data.totalServiceRowSpan === params.data.ServiceRowSpan; }
            },
            pinned: 'left',
            headerClass: "grid-sor-columns"
        },
        {
            headerName: "Equipment / Activity Name",
            field: "EquipmentName",
            minWidth: 150,
            tooltipField: "EquipmentName",
            cellRenderer: function (params) {
                const cellBlank = params.data.totalEquipRowSpan === params.data.EquipRowSpan;
                if (cellBlank) {
                    return (
                        <div className="show-name">{params.value}</div>
                    )
                }
                return;
            },
            rowSpan: (params) => params.data.EquipRowSpan !== undefined ? params.data.EquipRowSpan : 1,
            cellClassRules: {
                "show-cell": (params) => { return params.data.EquipRowSpan !== undefined; },
                "show-data": (params) => { return params.data.totalEquipRowSpan === params.data.EquipRowSpan; }
            },
            cellDataType: false,
            pinned: 'left',
            headerClass: "grid-sor-columns"
        },
        {
            headerName: "Equipment / Activity Tag",
            field: "EquipmentTag",
            minWidth: 150,
            tooltipField: "EquipmentTag",
            pinned: 'left',
            headerClass: "grid-sor-columns"
        },
        {
            headerName: "Area / Location",
            field: "Area",
            minWidth: 150,
            tooltipField: "Area",
            headerClass: "grid-sor-columns"
        },
        {
            headerName: "Commissioning Plan",
            field: "Commissioning",
            minWidth: 150,
            tooltipField: "Commissioning",
            headerClass: "grid-sor-columns",
            cellRenderer: (params) => {
                return displayStatus(params.data.Commissioning);
            }
        },
        {
            headerName: "Tender Inclusion",
            field: "Tender",
            minWidth: 150,
            tooltipField: "Tender",
            headerClass: "grid-sor-columns",
            cellRenderer: (params) => {
                return displayStatus(params.data.Tender);
            }
        },
        {
            headerName: "Method Statement",
            field: "Method",
            minWidth: 150,
            tooltipField: "Method",
            headerClass: "grid-sor-columns",
            cellRenderer: (params) => {
                return displayStatus(params.data.Method);
            }
        },
        {
            headerName: "Detailed Report",
            field: "Detailed",
            minWidth: 150,
            tooltipField: "Detailed",
            headerClass: "grid-sor-columns",
            cellRenderer: (params) => {
                return displayStatus(params.data.Detailed);
            }
        },
        {
            headerName: "Summary Report/HOTO Closure Report",
            field: "SummaryHOTOReport",
            minWidth: 150,
            tooltipField: "SummaryHOTOReport",
            headerClass: "grid-sor-columns",
            cellRenderer: (params) => {
                return displayStatus(params.data.SummaryHOTOReport);
            }
        },
        {
            headerName: "Design / Document Review",
            field: "DesignDocumentReview",
            minWidth: 150,
            tooltipField: "DesignDocumentReview",
            headerClass: "grid-sor-columns",
            cellRenderer: (params) => {
                return displayStatus(params.data.DesignDocumentReview);
            }
        },
        {
            headerName: "Site Observation Report",
            field: "SOR",
            minWidth: 150,
            tooltipField: "SOR",
            headerClass: "grid-sor-columns",
            cellRenderer: (params) => {
                return displayStatus(params.data.SOR);
            }
        },
        {
            headerName: "Pre Functional",
            field: "PreFunctional",
            minWidth: 150,
            tooltipField: "PreFunctional",
            headerClass: "grid-sor-columns",
            cellRenderer: (params) => {
                return displayStatus(params.data.PreFunctional);
            }
        },
        {
            headerName: "Functional",
            field: "Functional",
            minWidth: 150,
            tooltipField: "Functional",
            headerClass: "grid-sor-columns",
            cellRenderer: (params) => {
                return displayStatus(params.data.Functional);
            }
        },
        {
            headerName: "IST",
            field: "IST",
            minWidth: 150,
            tooltipField: "IST",
            headerClass: "grid-sor-columns",
            cellRenderer: (params) => {
                return displayStatus(params.data.IST);
            }
        },
        {
            headerName: "Fire Integration Service",
            field: "FireIntegration",
            minWidth: 150,
            tooltipField: "FireIntegration",
            headerClass: "grid-sor-columns",
            cellRenderer: (params) => {
                return displayStatus(params.data.FireIntegration);
            }
        }
    ];

    useImperativeHandle(ref, () => ({
        funcOnExport: async () => {
            // debugger;
            await handleExportExcel();
        }
    }));

    const handleExportExcel = async () => {
        // debugger;
        console.log("columnDefs ===> ", columnDefs);
        console.log("calculatedFinalData ===> ", calculatedFinalData);
        if (calculatedFinalData?.length > 0) {
            let filename = "Project Progress Details.xlsx";
            let projectDataValue = "PROJECT PROGRESS DETAILS " + props.SelectedProject.CustomerName + ", " + props.SelectedProject.Location + " - " + props.SelectedProject.ProjectName;
            projectDataValue = projectDataValue.toString().toUpperCase();
            exportToExcelProjectProgress(filename, "data", calculatedFinalData, projectDataValue, columnDefs, "ProjectProgress");
        }
    }

    return (
        <>
            {loading && <div className="spinner-box"> <Spinner animation="border" className="spinner" /></div>}
            <Container fluid className="main-blank-container">
                <DashboardProjDataContainer
                    TabString={"Project Progress Details"}
                    SelectedProject={props.SelectedProject}
                    handleInfoClick={props.handleInfoClick}
                />
                <div className="grid-comp-containerDashboard">
                    <div className="ag-theme-alpine agGridContainer">
                        <AgGridReact
                            rowData={calculatedFinalData}
                            columnDefs={columnDefs}
                            suppressRowTransform={true}
                            defaultColDef={defaultColPara}
                            enableBrowserTooltips={true}
                            onGridReady={onGridReady}
                        />
                    </div>
                </div>
            </Container>
        </>
    );
});

export default ProjectProgressDetails;
