import React, { useContext } from "react";
import "./CSS/Header.css";
import { Navbar } from "react-bootstrap";
import { useSelector } from "react-redux";
import { MdLogout } from "react-icons/md";
import { AuthContext } from "../../contexts/AuthContext";
import { useMsal } from "@azure/msal-react";
import Toggler from "../../icons/Toggler.svg";
import AEM_Logo from "../../icons/GCEM_Logo.svg";
import Logout from "../../icons/logout.svg";
import withReactContent from "sweetalert2-react-content";
import { LOGOUTCONFIRM } from "../../utils/constant";
import { getUserInitials } from "../../utils/Utility";
import { ALERT_PARAMS } from "../../utils/constant.js";


import Swal from "sweetalert2";
const Header = (props) => {
  const context = useContext(AuthContext);
  const { instance } = useMsal();
  const MySwal = withReactContent(Swal);

  const menuName = useSelector((state) => state.menu.menuName);
  console.log("useecontext", context);
  const initials = getUserInitials(context.userName);

  function handleLogout() {
    MySwal.fire({
      text: LOGOUTCONFIRM,
      confirmButtonText: "Yes",
      title: "Logout",
      ...ALERT_PARAMS
    }).then(async (result2) => {
      if (result2.isConfirmed) {
        sessionStorage.removeItem("Token");
        sessionStorage.removeItem("UserData");
        sessionStorage.removeItem("SSOLogin");

        instance.logoutRedirect().catch((e) => {
          console.error(e);
        });

      }
    });
  }

  return (
    <>
      <div className="responsive-header">
        <div>
          <img
            style={{ zIndex: "999" }}
            src={Toggler}
            alt="Toggler"
            className="head-toggle"
            onClick={props.toggle}
          />
        </div>
        {/* <div className="responsive-logo">
          <img src={AEM_Logo} alt="AEM Logo" />
        </div> */}
        <div className="responsive-logo" >
          <span className="header-logo" >GCEM</span>
          <span className="header-system-name">Comprehensive T&C Tracker</span>
        </div>
      </div>
      {context?.userName && (
        <Navbar bsPrefix="header">
          <div className="header-menu-name">{menuName}</div>
          <div className="header-user-section">
            <div className="header-user-logo">
              <span>{initials}</span>
            </div>
            <div className="header-user-info">
              <span className="header-user-name">{context.userName}</span>
              <span className="header-user-email">{context.userEmail} </span>
            </div>
            {/* <MdLogout size={20} color="#6F6F6F" onClick={() => {
              handleLogout()
            }} /> */}
            <img src={Logout} alt="logout" onClick={() => {
              handleLogout()
            }} />
          </div>
        </Navbar>)}
    </>);
};

export default Header;
