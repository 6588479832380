
import axios from "axios";
import { getCurrentDateTime } from "../functions/gbcs-functions";
const API_URL = process.env.REACT_APP_API_URL;
const TRANS_URL = process.env.REACT_APP_TRANSACTION_API_URL;

export const addLogDetails = async (tempObj, tempAction, tempUnique, tempPk, userDetails, apiFlag) => {
  let currentDate = getCurrentDateTime("-");
  currentDate = currentDate.replace(/[^\w]/g, "");
  let selectKey = "Timestamp#" + currentDate + "#LoggedBy#" + userDetails.userId + "#" + tempUnique;

  let currentObj = {
    PK: tempPk,
    SK: selectKey,
    LoggedBy: userDetails.userId,
    LoggedByName: userDetails.userName,
    LoggedOn: getCurrentDateTime("-"),
    Action: tempAction,
    UniqueKey: tempUnique
  }

  let logObj = {
    ...tempObj,
    ...currentObj,
  };
  let url;
  if (apiFlag === "Master") {
    url = API_URL + "/loggerentry/addlogentry";
  }
  else if (apiFlag === "Entry") {
    url = TRANS_URL + "/loggerentry/addlogentry";
  }
  const response = await axios
    .post(url, logObj)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      console.log("error => ", error);
      return { data: { message: "Unable to find request", status: false } };
    });
  return response.data;
};

export async function getGDPRdata(paraObj, apiFlag) {
  //debugger;
  let url;
  if (apiFlag === "Master") {
    url = API_URL + "/loggerentry/getgdprdata";
  }
  else if (apiFlag === "Entry") {
    url = TRANS_URL + "/loggerentry/getgdprdata";
  }

  console.log("paraObj", paraObj)
  const response = await axios
    .post(url, paraObj)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      console.log("error => ", error);
      return { data: [] };
      // return {data: { message: "Unable to find request", status: false }};
    });
  return response.data;
}

//---------erase gdpr data-----------------------------------//
export async function eraseGDPRDetails(dataObj, apiFlag) {
  let url;
  if (apiFlag === "Master") {
    url = API_URL + "/loggerentry/erasegdprdetails";
  }
  else if (apiFlag === "Entry") {
    url = TRANS_URL + "/loggerentry/erasegdprdetails";
  }
  console.log("dataObj", url, dataObj)
  const response = await axios
    .post(url, dataObj)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      console.log("error => ", error);
      return { data: { message: "Unable to find request", status: false } };
    });
  return response.data;
}

