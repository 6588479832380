import React, { useEffect, useState, useContext } from "react";
import { resizableComponent } from "../../utils/ResizeComponent"
import ProjectDocumentRightPanel from "./ProjectDocumentRightPanel";
import ProjectDocumentLeftPanel from "./ProjectDocumentLeftPanel";
import { getProjDocTabsData } from "../../services/project-document-service";
import { addEquipmentParameterDetails, getEquipmentParameterData } from "../../services/equipmentactivity-master-service";
import { AuthContext } from "../../contexts/AuthContext";
import { getCurrentDateTime } from "../../functions/gbcs-functions";
import { Spinner } from "react-bootstrap";

const ProjectDocumentParam = (props) => {
    const [equipmentObj, setEquipmentObj] = useState({});
    const [enteredTabData, setEnteredTabData] = useState([]);
    let serviceEquipData = props.projectservicedata;
    const isWebScreen = window.innerWidth > 768 ? true : false;
    const [showLeftPanel, setShowLeftPanel] = useState(true);
    let todayDate = getCurrentDateTime("-");
    const context = useContext(AuthContext);
    const userDetails = { userId: context.userId, userName: context.userName }
    const [loading, setLoading] = useState(false);

    async function getEquipmentData(selectedEquip) {
        console.log("inside getEquipmentData", selectedEquip);
        let keyObj = { "PK": "ProjectDocuments#ProjectCode#" + props.SelectedProject.ProjectCode, "SK": props.paramtabname + "#EquipmentCode#" + selectedEquip.EquipmentCode + "#SeqNo#" }
        let equipTabData = await getProjDocTabsData(keyObj);
        if (equipTabData?.length === 0 && props.SelectedProject.DeleteIndication === "Active") {
            equipTabData = await getEquipmentParameterData({ "code": selectedEquip.EquipmentCode, "tabName": props.paramtabname }, "master")
            if (equipTabData?.length > 0) {
                let counter = 0;
                let array = [];
                for (let dt of equipTabData) {
                    dt.ProjectCode = props.SelectedProject.ProjectCode;
                    dt.ServiceCode = selectedEquip.ServiceCode;
                    dt.ServiceName = selectedEquip.ServiceName;
                    dt.EquipmentName = selectedEquip.EquipmentName;
                    dt.EquipmentCode = selectedEquip.EquipmentCode;
                    dt.CreatedOn = todayDate;
                    dt.CreatedBy = userDetails.userId;
                    dt.CreatedByName = userDetails.userName;
                    dt.ModifiedOn = todayDate;
                    dt.ModifiedBy = userDetails.userId;
                    dt.ModifiedByName = userDetails.userName;
                    let tmpRes = addEquipmentParameterDetails(dt, props.paramtabname, selectedEquip.EquipmentCode, "new", "ProjectDocuments");
                    array.push(tmpRes);

                    if (counter === 5) {
                        await Promise.all(array);
                        counter = 0;
                        array = [];
                    }
                }
                if (array.length > 0) {
                    await Promise.all(array);
                }
                equipTabData = await getProjDocTabsData(keyObj);
            }
        }
        return equipTabData;
    }

    async function callOnload() {
        setLoading(true);
        if (isWebScreen) {
            resizableComponent("leftPanel" + props.paramtabname, "rightPanel" + props.paramtabname);
        }
        if (serviceEquipData?.length > 0) {
            let equipData = await getEquipmentData(serviceEquipData[0]);
            setEnteredTabData(equipData);
            setEquipmentObj(serviceEquipData[0]);
        }
        setLoading(false);
    }

    useEffect(() => {
        console.log("inside useEffect", props.tabNo);
        if (props.tabNo === 1 && props.paramtabname === "Prerequisite"
            || props.tabNo === 2 && props.paramtabname === "Instruments"
            || props.tabNo === 3 && props.paramtabname === "Testing"
            || props.tabNo === 4 && props.paramtabname === "AdditionalInformation") {
            callOnload();
        }
    }, [props.tabNo]);

    return (
        <>
            {loading && <div className="spinner-box"> <Spinner animation="border" className="spinner" /></div>}

            <div id="projDocId" className="projDocParam">
                <div className={isWebScreen || showLeftPanel ? "leftPanel" : "head-logo"} id={"leftPanel" + props.paramtabname}>
                    <ProjectDocumentLeftPanel
                        projectdata={props.SelectedProject}
                        projectservicedata={serviceEquipData}
                        limitValue={props.limitValue}
                        paramtabname={props.paramtabname}
                        headername={props.headername}
                        equipobj={setEquipmentObj}
                        enteredTabData={setEnteredTabData}
                        panelrespshow={setShowLeftPanel}
                        webscreenflag={isWebScreen} />
                </div>
                <div className={isWebScreen || !showLeftPanel ? "rightPanel" : "head-logo"} id={"rightPanel" + props.paramtabname}>
                    <ProjectDocumentRightPanel
                        projectdata={props.SelectedProject}
                        requiredtabdata={enteredTabData}
                        equipobj={equipmentObj}
                        limitValue={props.limitValue}
                        paramtabname={props.paramtabname}
                        panelrespshow={setShowLeftPanel}
                        headername={props.headername} />
                </div>
            </div>
        </>
    );
};

export default ProjectDocumentParam;