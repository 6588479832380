import { Link } from "react-router-dom";
import Toggler from "../../icons/Toggler.svg";
import { Collapse, Dropdown, Nav, Navbar } from "react-bootstrap";
import React, { useState, useContext } from "react";
import dashboardBlack from "../../icons/dashboardBlack.svg";
import masterBlack from "../../icons/MasterBlack.svg";
import adminBlack from "../../icons/AdminBlack.svg";
import transBlack from "../../icons/transBlack.svg";
import godrejSidebarLogo from "../../icons/godrejSidebar.svg"
import Gdpr from "../../icons/GDPR.svg"
import { MdLogout } from "react-icons/md";
import { useMsal } from "@azure/msal-react";
import { useNavigate } from "react-router-dom";
import { ALERT_PARAMS } from "../../utils/constant.js";
import { BsChevronDown } from "react-icons/bs";
import { AuthContext } from "../../contexts/AuthContext";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { LOGOUTCONFIRM } from "../../utils/constant";
import { getUserInitials } from "../../utils/Utility";


const SideBar = ({ showSidebar, setShowSidebar }) => {
  const MySwal = withReactContent(Swal);
  const { instance } = useMsal();
  const navigate = useNavigate();
  const [masterMenu, setMenu] = useState(false);
  const [adminMenu, setAdminMenu] = useState(false);
  const [transMenu, setTransMenu] = useState(false);
  const context = useContext(AuthContext);
  const initials = getUserInitials(context.userName);

  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
    setMenu(false);
    setAdminMenu(false);
    setTransMenu(false);
    let sidebar1 = !showSidebar;
    //  dispatch(setSideBar(sidebar1));
  };

  function handleLogout() {
    MySwal.fire({
      text: LOGOUTCONFIRM,
      confirmButtonText: "Yes",
      title: "Logout",
      ...ALERT_PARAMS
    }).then(async (result2) => {
      if (result2.isConfirmed) {
        sessionStorage.removeItem("Token");
        sessionStorage.removeItem("UserData");
        sessionStorage.removeItem("SSOLogin");

        instance.logoutRedirect().catch((e) => {
          console.error(e);
        });

      }
    });
  }

  return (
    <>
      {context.menuName && (
        <div
          id="Sidebar"
          className={showSidebar ? "side-bar open" : "side-bar closed"}
        >
          <div className="sidebar-head">
            {/* <img src={AEM_Logo} alt="AEM Logo" className="head-logo ms-2" /> */}
            <div className="sidebar-logo" >
              <span className="head-logo" >GCEM</span>
              <span className="system-name">Comprehensive T&C Tracker</span>
            </div>
            <img
              src={Toggler}
              alt="Toggler"
              onClick={toggleSidebar}
              className="head-toggle" />
          </div>
          {showSidebar && (
            <div className="sidebar-head-2 d-md-none d-lg-none">
              <div className="user-section">
                <div className="user-logo">
                  <span>{initials}</span>
                </div>
                <div className="user-info">
                  <span className="user-name">{context.userName}</span>
                  <span className="user-email">{context.userEmail}</span>
                </div>
                <MdLogout size={20} color="#707070" onClick={() => {
                  // setShow(true);
                  setShowSidebar(false);
                  handleLogout()
                }}></MdLogout>
              </div>
            </div>
          )}
          <div className="sidebar-body" id="Sidebar-Body">
            <span className="menu">Menu</span>
            <Nav
              as="ul"
              bsPrefix="nav-list"
              defaultActiveKey={"Dashboard (Godrej)"}

            >
              {context?.menuName?.includes("Dashboard (Godrej)") && (
                <Nav.Item as="li" className="list1">
                  <Nav.Link
                    as={Link}
                    to="/"
                    eventKey={"Dashboard (Godrej)"}
                    bsPrefix="nav-list-item"
                    onClick={() => {
                      setShowSidebar(false);
                      setAdminMenu(false);
                      setMenu(false);
                      setTransMenu(false);
                    }}
                  // className={
                  //   location.pathname === "/dashboard" ? "active1" : "inactive"
                  // }
                  >
                    {/* <MdOutlineDashboard size={32} className="menu-icon" /> */}

                    <div style={{ position: "relative" }}>
                      <img
                        src={dashboardBlack}
                        alt="dashboard"
                        className="sidebar-icon"
                      />
                    </div>
                    <span className="menu-name">Dashboard (Godrej)</span>
                  </Nav.Link>
                </Nav.Item>
              )}

              {context?.menuName?.includes("Dashboard (Customer)") && (
                <Nav.Item as="li" className="list1">
                  <Nav.Link
                    as={Link}
                    to="/customerdashboard"
                    eventKey={"Dashboard (Customer)"}
                    bsPrefix="nav-list-item"
                    onClick={() => {
                      setShowSidebar(false);
                      setAdminMenu(false);
                      setMenu(false);
                      setTransMenu(false);
                    }}
                  >
                    <div style={{ position: "relative" }}>
                      <img
                        src={dashboardBlack}
                        alt="dashboard"
                        className="sidebar-icon"
                      />
                    </div>
                    <span className="menu-name">Dashboard (Customer)</span>
                  </Nav.Link>
                </Nav.Item>
              )}
              {context?.menuName?.includes("Admin") && (
                <Nav.Item
                  as="li"
                  className="list2"
                  onClick={() => {
                    setShowSidebar(true);
                    setAdminMenu(!adminMenu);
                  }}
                >
                  <Nav.Link eventKey={"Admin"} bsPrefix="nav-list-item">
                    {/* <RiOrganizationChart size={32} className="menu-icon" /> */}
                    <div style={{ position: "relative" }}>
                      <img src={adminBlack} alt="admin" className="sidebar-icon" />
                    </div>
                    <span className="menu-name">
                      Admin <BsChevronDown size={15} />
                    </span>
                  </Nav.Link>
                </Nav.Item>
              )}
              <Collapse in={adminMenu}>
                <div>
                  <ul className="nav-sub-menu">
                    {context?.menuName?.includes("User Master") && (
                      <Nav.Item as="li">
                        <Nav.Link
                          eventKey={"User Master"}
                          as={Link}
                          to="/usermaster"
                          bsPrefix="sub-menu-item"
                          onClick={() => {
                            setShowSidebar(false);
                            setAdminMenu(!adminMenu);
                            setMenu(false);
                            setTransMenu(false);
                          }}
                        >
                          User Master
                        </Nav.Link>
                      </Nav.Item>
                    )}
                    {context?.menuName?.includes("Role & Authorization Master") && (
                      <Nav.Item as="li">
                        <Nav.Link
                          eventKey={"Role & Authorization Master"}
                          as={Link}
                          to="/rolemaster"
                          bsPrefix="sub-menu-item"
                          onClick={() => {
                            setShowSidebar(false);
                            setAdminMenu(!adminMenu);
                            setMenu(false);
                            setTransMenu(false);
                          }}
                        >
                          Role & Authorization Master
                        </Nav.Link>
                      </Nav.Item>
                    )}
                  </ul>
                </div>
              </Collapse>
              {context?.menuName?.includes("Master") && (
                <Nav.Item
                  as="li"
                  className="list3"
                  onClick={() => {
                    setShowSidebar(true);
                    setMenu(!masterMenu);
                  }}
                >
                  <Nav.Link eventKey={"Master"} bsPrefix="nav-list-item">
                    {/* <RiOrganizationChart size={32} className="menu-icon" /> */}
                    <div style={{ position: "relative" }}>
                      <img src={masterBlack} alt="master" className="sidebar-icon" />
                    </div>
                    <span className="menu-name">
                      Master <BsChevronDown size={15} />
                    </span>
                  </Nav.Link>
                </Nav.Item>)}
              <Collapse in={masterMenu}>
                <div>
                  <ul className="nav-sub-menu">
                    {context?.menuName?.includes("Currency Master") && (
                      <Nav.Item as="li">
                        <Nav.Link
                          eventKey={"Currency Master"}
                          as={Link}
                          to="/currencymaster"
                          bsPrefix="sub-menu-item"
                          onClick={() => {
                            setShowSidebar(false);
                            setMenu(!masterMenu);
                            setAdminMenu(false);
                            setTransMenu(false);
                          }}
                        >
                          Currency Master
                        </Nav.Link>
                      </Nav.Item>

                    )}
                    {context?.menuName?.includes("Location Master") && (
                      <Nav.Item as="li">
                        <Nav.Link
                          eventKey={"Location Master"}
                          as={Link}
                          to="/locationmaster"
                          bsPrefix="sub-menu-item"
                          onClick={() => {
                            setShowSidebar(false);
                            setMenu(!masterMenu);
                            setAdminMenu(false);
                            setTransMenu(false);
                          }}
                        >
                          Location Master
                        </Nav.Link>
                      </Nav.Item>

                    )}
                    {context?.menuName?.includes("Equipment & Activity Master") && (
                      <Nav.Item as="li">
                        <Nav.Link
                          eventKey={"Equipment & Activity Master"}
                          as={Link}
                          to="/equipmentactivitymaster"
                          bsPrefix="sub-menu-item"
                          onClick={() => {
                            setShowSidebar(false);
                            setMenu(!masterMenu);
                            setAdminMenu(false);
                            setTransMenu(false);
                          }}
                        >
                          Equipment & Activity Master
                        </Nav.Link>
                      </Nav.Item>

                    )}
                    {context?.menuName?.includes("Company / Division Master") && (
                      <Nav.Item as="li">
                        <Nav.Link
                          eventKey={"Company / Division Master"}
                          as={Link}
                          to="/companydivisionmaster"
                          bsPrefix="sub-menu-item"
                          onClick={() => {
                            setShowSidebar(false);
                            setMenu(!masterMenu);
                            setAdminMenu(false);
                            setTransMenu(false);
                          }}
                        >
                          Company / Division Master
                        </Nav.Link>
                      </Nav.Item>

                    )}
                    {context?.menuName?.includes("Project Master") && (
                      <Nav.Item as="li">
                        <Nav.Link
                          eventKey={"Project Master"}
                          as={Link}
                          to="/projectmaster"
                          bsPrefix="sub-menu-item"
                          onClick={() => {
                            setShowSidebar(false);
                            setMenu(!masterMenu);
                            setAdminMenu(false);
                            setTransMenu(false);
                          }}
                        >
                          Project Master
                        </Nav.Link>
                      </Nav.Item>
                    )}
                    {context?.menuName?.includes("Checklist Master") && (
                      <Nav.Item as="li">
                        <Nav.Link
                          eventKey={"Checklist Master"}
                          as={Link}
                          to="/checklistmaster"
                          bsPrefix="sub-menu-item"
                          onClick={() => {
                            setShowSidebar(false);
                            setMenu(!masterMenu);
                            setAdminMenu(false);
                            setTransMenu(false);
                          }}
                        >
                          Checklist Master
                        </Nav.Link>
                      </Nav.Item>

                    )}
                  </ul>
                </div>
              </Collapse>
              {context?.menuName?.includes("Transaction") && (
                <Nav.Item
                  as="li"
                  className="list3"
                  onClick={() => {
                    setShowSidebar(true);
                    setTransMenu(!transMenu);
                  }}
                >
                  <Nav.Link eventKey={"Transaction"} bsPrefix="nav-list-item">
                    {/* <RiOrganizationChart size={32} className="menu-icon" /> */}
                    <div style={{ position: "relative" }}>
                      <img src={transBlack} alt="transaction" className="sidebar-icon" />
                    </div>
                    <span className="menu-name">
                      Transaction <BsChevronDown size={15} />
                    </span>
                  </Nav.Link>
                </Nav.Item>
              )}
              <Collapse in={transMenu}>
                <div>
                  <ul className="nav-sub-menu">
                    {context?.menuName?.includes("Design Review Tracker") && (
                      <Nav.Item as="li">
                        <Nav.Link
                          eventKey={"Design Review Tracker"}
                          as={Link}
                          to="/designreviewtracker"
                          bsPrefix="sub-menu-item"
                          onClick={() => {
                            setShowSidebar(false);
                            setTransMenu(!transMenu);
                            setAdminMenu(false);
                            setMenu(false);
                          }}
                        >
                          Design Review Tracker
                        </Nav.Link>
                      </Nav.Item>
                    )}
                    {context?.menuName?.includes("Document Review Tracker") && (
                      <Nav.Item as="li">
                        <Nav.Link
                          eventKey={"Document Review Tracker"}
                          as={Link}
                          to="/documentreviewtracker"
                          bsPrefix="sub-menu-item"
                          onClick={() => {
                            setShowSidebar(false);
                            setTransMenu(!transMenu);
                            setAdminMenu(false);
                            setMenu(false);
                          }}
                        >
                          Document Review Tracker
                        </Nav.Link>
                      </Nav.Item>
                    )}
                    {context?.menuName?.includes("Factory Acceptance Test") && (
                      <Nav.Item as="li">
                        <Nav.Link
                          eventKey={"Factory Acceptance Test"}
                          as={Link}
                          to="/fatchecklist"
                          bsPrefix="sub-menu-item"
                          onClick={() => {
                            setShowSidebar(false);
                            setTransMenu(!transMenu);
                            setAdminMenu(false);
                            setMenu(false);
                          }}
                        >
                          Factory Acceptance Test
                        </Nav.Link>
                      </Nav.Item>
                    )}
                    {context?.menuName?.includes("Site Acceptance Test") && (
                      <Nav.Item as="li">
                        <Nav.Link
                          eventKey={"Site Acceptance Test"}
                          as={Link}
                          to="/satchecklist"
                          bsPrefix="sub-menu-item"
                          onClick={() => {
                            setShowSidebar(false);
                            setTransMenu(!transMenu);
                            setAdminMenu(false);
                            setMenu(false);
                          }}
                        >
                          Site Acceptance Test
                        </Nav.Link>
                      </Nav.Item>
                    )}
                    {context?.menuName?.includes("Site Observation Report") && (
                      <Nav.Item as="li">
                        <Nav.Link
                          eventKey={"Site Observation Report"}
                          as={Link}
                          to="/siteobservationreport"
                          bsPrefix="sub-menu-item"
                          onClick={() => {
                            setShowSidebar(false);
                            setTransMenu(!transMenu);
                            setAdminMenu(false);
                            setMenu(false);
                          }}
                        >
                          Site Observation Report
                        </Nav.Link>
                      </Nav.Item>
                    )}
                    {context?.menuName?.includes("Fire Integration Verification") && (
                      <Nav.Item as="li">
                        <Nav.Link
                          eventKey={"Fire Integration Verification"}
                          as={Link}
                          to="/fireintegration"
                          bsPrefix="sub-menu-item"
                          onClick={() => {
                            setShowSidebar(false);
                            setTransMenu(!transMenu);
                            setAdminMenu(false);
                            setMenu(false);
                          }}
                        >
                          Fire Integration Verification
                        </Nav.Link>
                      </Nav.Item>
                    )}
                    {context?.menuName?.includes("Project Documents") && (
                      <Nav.Item as="li">
                        <Nav.Link
                          eventKey={"Project Documents"}
                          as={Link}
                          to="/projectdocuments"
                          bsPrefix="sub-menu-item"
                          onClick={() => {
                            setShowSidebar(false);
                            setTransMenu(!transMenu);
                            setAdminMenu(false);
                            setMenu(false);
                          }}
                        >
                          Project Documents
                        </Nav.Link>
                      </Nav.Item>
                    )}
                    {context?.menuName?.includes("Integrated System Testing") && (
                      <Nav.Item as="li">
                        <Nav.Link
                          eventKey={"Integrated System Testing"}
                          as={Link}
                          to="/integratedsystesting"
                          bsPrefix="sub-menu-item"
                          onClick={() => {
                            setShowSidebar(false);
                            setTransMenu(!transMenu);
                            setAdminMenu(false);
                            setMenu(false);
                          }}
                        >
                          Integrated System Testing
                        </Nav.Link>
                      </Nav.Item>
                    )}
                  </ul>
                </div>
              </Collapse>
              {context?.menuName?.includes("GDPR") && (
                <Nav.Item
                  as="li"
                  className="list4"
                  onClick={() => {
                    setShowSidebar(false);
                    setAdminMenu(false);
                    setMenu(false);
                    setTransMenu(false);
                  }}
                >
                  <Nav.Link
                    as={Link}
                    to="/gdpr"
                    eventKey={"GDPR"}
                    bsPrefix="nav-list-item">
                    <div style={{ position: "relative" }}>
                      <img src={Gdpr} alt="transaction" className="sidebar-icon" />
                    </div>
                    <span className="menu-name">
                      GDPR
                    </span>
                  </Nav.Link>
                </Nav.Item>
              )}
            </Nav>
            <div className="sidebar-godrej-logo-div">
              <img src={godrejSidebarLogo} alt="Godrej Logo" className="sidebar-godrej-logo" />
            </div>
          </div>

        </div>
      )}
    </>
  );
};

export default SideBar;
