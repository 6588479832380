import React, { useEffect, useState, useContext } from "react";
import { Button, Form, Modal, Row, Col, Spinner } from "react-bootstrap";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import successImage from "../../icons/success-tick-logo.svg";
import errorImage from "../../icons/error-tick-logo.svg";
import { AuthContext } from "../../contexts/AuthContext";
import { getStakeholderData } from "../../services/common-master-service";
import { BiSelectMultiple } from "react-icons/bi";
import { RenderIf } from "../../functions/gbcs-functions";
import SingleValuePopUp from "../utils/SingleValuePopUp";
import { getCurrentDateTime } from "../../functions/gbcs-functions";
import { sortByPropertyInDescending } from "../../utils/Utility";
import { isEmail, isMobNumber } from "../../functions/validations";
import { addIntegratedSystemTestingDetails, getIntegratedTabsData } from "../../services/integrated-system-testing-service";


const IntegratedAddEditVendor = (props) => {
    const MySwal = withReactContent(Swal);
    const context = useContext(AuthContext);
    const userDetails = { userId: context.userId, userName: context.userName }
    const [loading, setLoading] = useState(false);
    const [stakeholderResult, setStakeholderResult] = useState();
    const [showForm, setShowForm] = useState(false);
    let action = props.action;

    const [integratedVendorObj, setIntegratedVendorObj] = useState(
        action === "Edit" ? props.rowdata : {
            StakeholderType: "",
            UserName: "",
            CompanyOrDivision: "",
            Designation: "",
            EmailId: "",
            MobileNo: "",
            ApplicableforCriNonCriReport: ["Non-Critical"]
        });

    const [error, setError] = useState({
        StakeholderType: false,
        UserName: false,
        CompanyOrDivision: false,
        Designation: false,
        EmailId: false,
        MobileNo: false,
        ApplicableforCriNonCriReport: false
    });

    useEffect(() => {
        fetchStakeholderMaster();
        if (action === "Edit") {
            setIntegratedVendorObj((prev) => ({ ...prev, ApplicableforCriNonCriReport: props.rowdata.ApplicableforCriNonCriReport.split(",") }));
        }
    }, []);

    const [popUp, setPopUp] = useState({
        name: "",
        type: "",
        key: "",
        value: "",
        additionalParams: ""
    });

    const showPopUp = (name, type, key, value, additionalParams) => {
        setPopUp({ name, type, key, value, additionalParams });
        setShowForm(true);
    };

    const setPopUpData = async (data) => {
        //debugger;
        setIntegratedVendorObj((prevState) => ({
            ...prevState,
            UserName: data.result.UserName,
            CompanyOrDivision: data.result.CompanyName,
            Designation: data.result.Designation,
            EmailId: data.result.EmailId,
            MobileNo: data.result.MobileNo
        }));
    }

    const fetchStakeholderMaster = async () => {
        setLoading(true);
        const result = await getStakeholderData();
        let tmp = [];
        result.forEach(element => {
            tmp.push({ "Name": element.StakeholderName, "Code": element.SK })
        });
        setStakeholderResult(tmp);
        setLoading(false);
    };

    const handleChange = (e) => {
        //debugger;
        let { name, value } = e.target;
        if (name === "ApplicableforCriNonCriReport") {
            let checkList = integratedVendorObj.ApplicableforCriNonCriReport;
            if (e.target.checked) {
                checkList.push(value);
            }
            else {
                let index = checkList.indexOf(value);
                checkList.splice(index, 1);
            }
            value = checkList;
        }
        setIntegratedVendorObj((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    }

    const handleReset = () => {
        setIntegratedVendorObj({
            StakeholderType: "",
            UserName: "",
            CompanyOrDivision: "",
            Designation: "",
            EmailId: "",
            MobileNo: "",
            ApplicableforCriNonCriReport: ["Non-Critical"]
        });
        setError({
            StakeholderType: false,
            UserName: false,
            CompanyOrDivision: false,
            Designation: false,
            EmailId: false,
            MobileNo: false,
            ApplicableforCriNonCriReport: false
        });
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        if (!validateDetails()) {
            let vendorData = await getIntegratedTabsData({ "ProjectCode": props.SelectedProject.ProjectCode, "flag": "Stakeholder" });
            if (vendorData?.length > 0 && vendorData.filter((item) => (action === "Add" || (action === "Edit" && item.SK !== integratedVendorObj.SK)) && item.UserName.toLowerCase() === integratedVendorObj.UserName.toLowerCase()).length > 0) {
                setLoading(false);
                MySwal.fire({
                    text: "Contact Name is already exists.",
                    imageUrl: errorImage,
                    customClass: "swal-success",
                });
                return;
            }
            if (vendorData?.length > 0 && vendorData.filter((item) => (action === "Add" || (action === "Edit" && item.SK !== integratedVendorObj.SK)) && item.EmailId.toLowerCase() === integratedVendorObj.EmailId.toLowerCase()).length > 0) {
                setLoading(false);
                MySwal.fire({
                    text: "Email Id is already exists.",
                    imageUrl: errorImage,
                    customClass: "swal-success",
                });
                return;
            }
            if (vendorData?.length > 0 && vendorData.filter((item) => (action === "Add" || (action === "Edit" && item.SK !== integratedVendorObj.SK)) && item.MobileNo.toLowerCase() === integratedVendorObj.MobileNo.toLowerCase()).length > 0) {
                setLoading(false);
                MySwal.fire({
                    text: "Mobile No. is already exists.",
                    imageUrl: errorImage,
                    customClass: "swal-success",
                });
                return;
            }
            MySwal.fire({
                text: "Are you sure, do you want to " + action + " Stakeholder Contact?",
                showDenyButton: true,
                cancelButtonColor: "#fff",
                confirmButtonText: "Yes",
                reverseButtons: true,
                customClass: "swal-confirmation",
                title: action + " Stakeholder Contact"
            }).then(async (result2) => {
                if (result2.isConfirmed) {
                    setLoading(true);
                    if (action === "Add") {
                        let maxSeqNo = 0;
                        if (vendorData?.length > 0) {
                            let sortedArr = vendorData.sort(sortByPropertyInDescending("SeqNo"));
                            maxSeqNo = sortedArr[0].SeqNo
                        }

                        let NewMaxSeqNo = parseInt(maxSeqNo) + 1;

                        integratedVendorObj.CreatedOn = getCurrentDateTime("-");
                        integratedVendorObj.CreatedBy = userDetails.userId;
                        integratedVendorObj.CreatedByName = userDetails.userName;
                        integratedVendorObj.SeqNo = NewMaxSeqNo;
                        integratedVendorObj.ProjectCode = props.SelectedProject.ProjectCode;
                        integratedVendorObj.ProjectName = props.SelectedProject.ProjectName;
                        integratedVendorObj.RecordEntry = "Manual";
                        integratedVendorObj.SK = "Stakeholder#SeqNo#" + NewMaxSeqNo;
                        integratedVendorObj.PK = "IntegratedSystemTesting#ProjectCode#" + props.SelectedProject.ProjectCode;
                    }
                    integratedVendorObj.ApplicableforCriNonCriReport = integratedVendorObj.ApplicableforCriNonCriReport.toString();
                    integratedVendorObj.ModifiedOn = getCurrentDateTime("-");
                    integratedVendorObj.ModifiedBy = userDetails.userId;
                    integratedVendorObj.ModifiedByName = userDetails.userName;
                    let result = await addIntegratedSystemTestingDetails(integratedVendorObj);

                    if (result?.status) {
                        setLoading(false);
                        MySwal.fire({
                            text: "Stakeholder Contact has been " + (action === "Edit" ? "updated" : "added") + " successfully.",
                            imageUrl: successImage,
                            customClass: "swal-success",
                            didClose: () => {
                                props.getProjectVendorDetails();
                                props.onHide();
                            },
                        });
                    }
                    else {
                        setLoading(false);
                        integratedVendorObj.ApplicableforCriNonCriReport = integratedVendorObj.ApplicableforCriNonCriReport.split(",");
                        MySwal.fire({
                            text: "Unable to Process Request",
                            imageUrl: errorImage,
                            customClass: "swal-success",
                        });
                    }
                }
            });
        }
        setLoading(false);
    };

    const validateDetails = () => {
        let errorMsg = {
            StakeholderType: false,
            UserName: false,
            CompanyOrDivision: false,
            Designation: false,
            EmailId: false,
            MobileNo: false,
            ApplicableforCriNonCriReport: false
        };
        let isError = false;
        if (integratedVendorObj.StakeholderType.trim() === "") {
            isError = true;
            errorMsg.StakeholderType = { status: isError, message: "Stakeholder Type is required." };
        }
        if (integratedVendorObj.UserName.trim() === "") {
            isError = true;
            errorMsg.UserName = { status: isError, message: "Contact Name is required." };
        }
        if (integratedVendorObj.CompanyOrDivision.trim() === "") {
            isError = true;
            errorMsg.CompanyOrDivision = { status: isError, message: "Company / Division is required." };
        }
        if (integratedVendorObj.Designation.trim() === "") {
            isError = true;
            errorMsg.Designation = { status: isError, message: "Designation is required." };
        }
        let errorResult = isEmail(integratedVendorObj.EmailId.trim());
        if (errorResult.status) {
            isError = true;
            errorMsg.EmailId = { status: isError, message: "Enter valid Email Id." };
        }
        let mobileNoError = isMobNumber(integratedVendorObj.MobileNo.trim());
        if (mobileNoError) {
            isError = true;
            errorMsg.MobileNo = { status: isError, message: "Enter valid Mobile No." };
        }
        if (integratedVendorObj.ApplicableforCriNonCriReport.length <= 0) {
            isError = true;
            errorMsg.ApplicableforCriNonCriReport = { status: isError, message: "Applicable for Critical/ Non Critical Report is required." };
        }
        setError(errorMsg);
        return isError;
    }

    return (
        <>
            {loading && <div className="spinner-box"> <Spinner animation="border" className="spinner" /></div>}
            <Modal
                {...props}
                size="lg"
                backdrop="static" centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {action} Stakeholder Contact
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form
                        id="integratedtestingvendor"
                        name="integratedtestingvendor"
                        // onSubmit={handleSubmit}
                        autoComplete="off"
                    >
                        <Row >
                            <Col md={4} xs={12}>
                                <Form.Group className="g-select">
                                    <Form.Select
                                        id="StakeholderType"
                                        name="StakeholderType"
                                        type="text"
                                        placeholder=" "
                                        value={integratedVendorObj.StakeholderType}
                                        onChange={handleChange}
                                    >
                                        <option value="" >Select</option>
                                        {stakeholderResult && stakeholderResult.map((stakeholderResult) => (
                                            <option key={stakeholderResult.Code} value={stakeholderResult.Name}>{stakeholderResult.Name}</option>
                                        ))
                                        }
                                    </Form.Select>
                                    <Form.Label className="frm-label" for="StakeholderType">Stakeholder Type</Form.Label>
                                    {error.StakeholderType.status && (
                                        <Form.Text className="text-danger">
                                            {error.StakeholderType.message}
                                        </Form.Text>
                                    )}
                                </Form.Group>
                            </Col>
                            <Col md={4} xs={12}>
                                <Form.Group className="g-input">
                                    <div className="d-flex align-items-center">
                                        <Form.Control
                                            id="UserName"
                                            type="text"
                                            name="UserName"
                                            placeholder=" "
                                            maxLength="100"
                                            value={integratedVendorObj.UserName}
                                            onChange={handleChange}
                                        />
                                        <Form.Label className="frm-label" for="UserName">Contact Name</Form.Label>
                                        <BiSelectMultiple
                                            size={20}
                                            onClick={() => {
                                                if (integratedVendorObj.StakeholderType === "Godrej ENE Employee") {
                                                    showPopUp(
                                                        "Godrej Employee Details",
                                                        "Single",
                                                        "UserName",
                                                        integratedVendorObj.UserName
                                                    );
                                                }
                                                else {
                                                    showPopUp(
                                                        "User Details without ENE",
                                                        "Single",
                                                        "UserName",
                                                        integratedVendorObj.UserName
                                                    )
                                                }
                                            }
                                            }
                                            color={"#707070"}
                                            style={{ marginLeft: "-2rem" }}
                                            backdrop="static"
                                        />
                                    </div>
                                    {error.UserName.status && (
                                        <Form.Text className="text-danger">
                                            {error.UserName.message}
                                        </Form.Text>
                                    )}
                                </Form.Group>
                            </Col>
                            <Col md={4} xs={12}>
                                <Form.Group className="g-input">
                                    <Form.Control
                                        id="CompanyOrDivision"
                                        type="text"
                                        name="CompanyOrDivision"
                                        maxLength="100"
                                        placeholder=" "
                                        value={integratedVendorObj.CompanyOrDivision}
                                        onChange={handleChange}
                                    />
                                    <Form.Label className="frm-label" for="CompanyOrDivision">Company / Division</Form.Label>
                                    {error.CompanyOrDivision.status && (
                                        <Form.Text className="text-danger">
                                            {error.CompanyOrDivision.message}
                                        </Form.Text>
                                    )}
                                </Form.Group>
                            </Col>
                            <Col md={4} xs={12}>
                                <Form.Group className="g-input">
                                    <Form.Control
                                        id="Designation"
                                        type="text"
                                        name="Designation"
                                        maxLength="20"
                                        placeholder=" "
                                        value={integratedVendorObj.Designation}
                                        onChange={handleChange}
                                    />
                                    <Form.Label className="frm-label" for="Designation">Designation</Form.Label>
                                    {error.Designation.status && (
                                        <Form.Text className="text-danger">
                                            {error.Designation.message}
                                        </Form.Text>
                                    )}
                                </Form.Group>
                            </Col>
                            <Col md={4} xs={12}>
                                <Form.Group className="g-input">
                                    <Form.Control
                                        id="EmailId"
                                        type="text"
                                        name="EmailId"
                                        maxLength="100"
                                        placeholder=" "
                                        value={integratedVendorObj.EmailId}
                                        onChange={handleChange}
                                    />
                                    <Form.Label className="frm-label" for="EmailId">Email Id</Form.Label>
                                    {error.EmailId.status && (
                                        <Form.Text className="text-danger">
                                            {error.EmailId.message}
                                        </Form.Text>
                                    )}
                                </Form.Group>
                            </Col>
                            <Col md={4} xs={12}>
                                <Form.Group className="g-input">
                                    <Form.Control
                                        id="MobileNo"
                                        type="text"
                                        name="MobileNo"
                                        maxLength="20"
                                        placeholder=" "
                                        value={integratedVendorObj.MobileNo}
                                        onChange={handleChange}
                                    />
                                    <Form.Label className="frm-label" for="MobileNo">Mobile No.</Form.Label>
                                    {error.MobileNo.status && (
                                        <Form.Text className="text-danger">
                                            {error.MobileNo.message}
                                        </Form.Text>
                                    )}
                                </Form.Group>
                            </Col>
                            <Col md={6} xs={12}>
                                <Form.Group>
                                    <Row>
                                        <Form.Label className="fieldLabel locationType">Applicable for Critcal / Non-Critical Report</Form.Label>
                                    </Row>
                                    <Row>
                                        <Col md={4} xs={6}>
                                            <Form.Check className="fieldLabel locationType"
                                                type="checkbox"
                                                name="ApplicableforCriNonCriReport"
                                                checked={integratedVendorObj.ApplicableforCriNonCriReport.includes("Critical")}
                                                onChange={(e) => handleChange(e)}
                                                label="Critical"
                                                value="Critical"
                                                disabled={props.SelectedProject.ISTApplicable === "No"}
                                            ></Form.Check>
                                        </Col>
                                        <Col md={4} xs={6}>
                                            <Form.Check className="fieldLabel locationType"
                                                type="checkbox"
                                                name="ApplicableforCriNonCriReport"
                                                checked={integratedVendorObj.ApplicableforCriNonCriReport.includes("Non-Critical")}
                                                onChange={(e) => handleChange(e)}
                                                label="Non-Critical"
                                                value="Non-Critical"
                                            ></Form.Check>
                                        </Col>
                                    </Row>
                                    {error.ApplicableforCriNonCriReport.status && (
                                        <Form.Text className="text-danger">
                                            {error.ApplicableforCriNonCriReport.message}
                                        </Form.Text>
                                    )}
                                </Form.Group>
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col xs={6}>{action === "Add" &&
                                <Button variant="outline-success"
                                    className="semiBoldToggleButton cancelBtn"
                                    onClick={(e) => handleReset(e)}>
                                    Reset
                                </Button>
                            }
                            </Col>
                            <Col xs={6}>
                                <Button
                                    variant="success"
                                    className="btn-add-color semiBoldToggleButton rightAlignedButton"
                                    onClick={handleSubmit}>
                                    {action === "Edit" ? "Update" : "Add"}
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                    <RenderIf isTrue={popUp.type === "Single" && showForm}>
                        <SingleValuePopUp
                            show={showForm}
                            onHide={() => setShowForm(false)}
                            setSelected={setPopUpData}
                            mastername={popUp.name}
                            paramkey={popUp.key}
                            paramvalue={popUp.value}
                            additionalParams={popUp.additionalParams}
                            backdrop="static"
                        />
                    </RenderIf>
                </Modal.Body>
            </Modal>

        </>
    );

}

export default IntegratedAddEditVendor;