export function getUserInitials(userName) {
  let nameArr = userName.split(" ");
  let lastName = "";
  if (nameArr.length > 1) {
    lastName = nameArr[nameArr.length - 1].trim().substring(0, 1);
  }
  let initial = nameArr[0].trim().substring(0, 1).toUpperCase() + lastName.toUpperCase();
  return initial
}

export function sortByPropertyInAscending(property) {
  return function (a, b) {
    if (a[property] > b[property]) return 1;
    else if (a[property] < b[property]) return -1;
    return 0;
  };
}

export function sortByPropertyInDescending(property) {
  return function (a, b) {
    if (a[property] < b[property]) return 1;
    else if (a[property] > b[property]) return -1;
    return 0;
  };
}

export function getFinancialYears() {
  let startDate = process.env.REACT_APP_START_DATE
  let currentYear = new Date().getFullYear();
  let startYear = new Date(startDate).getFullYear();
  let fyStartYear = new Date(startDate).getMonth() < 3 ? startYear - 1 : startYear;
  let currentfyEndYear = new Date().getMonth() < 3 ? currentYear : currentYear + 1;
  let financialYears = [];
  for (let year = fyStartYear; year < currentfyEndYear; year++) {
    financialYears.push(`${year}-${year + 1}`);
  }
  return financialYears;
}

export function getFinancialYearForDate(date) {
  let dtYear = new Date(date).getFullYear();
  let fyYear = new Date(date).getMonth() < 3 ? dtYear - 1 + "-" + dtYear : dtYear + "-" + (dtYear + 1);
  return fyYear;
}
