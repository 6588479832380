import React, { useState, useRef, useEffect } from "react";
import BreadcrumbComp from "../layouts/BreadcrumbComp";
import { Tabs, Tab, Button, Container, Col, Form, Row, Spinner } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { menuActions } from "../../store/menu-slice";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import TabButtons from "../utils/TabButtons";
import IntegratedVendorContact from "./IntegratedVendorContact";
import errorImage from "../../icons/error-tick-logo.svg";
import IntegratedEquipment from "./IntegratedEquipment";
import IntegratedScript from "./IntegratedScript";
import IntegratedISTObservations from "./IntegratedISTObservations";
import IntegratedPowerAnalyzer from "./IntegratedPowerAnalyzer";
import { BiSelectMultiple } from "react-icons/bi";
import { RenderIf } from "../../functions/gbcs-functions";
import SingleValuePopUp from "../utils/SingleValuePopUp";
import { getIstParameterLimitData } from "../../services/integrated-system-testing-service";

const IntegratedSystemTesting = () => {

    const dispatch = useDispatch();
    dispatch(menuActions.setMenu("Integrated System Testing"));
    const MySwal = withReactContent(Swal);
    const [loading, setLoading] = useState(false);
    const [currentTab, setCurrentTab] = useState(0);
    const [isSubmitClicked, setIsSubmitClicked] = useState(false);
    const [SelectedProject, setSelectedProject] = useState();
    const [showPopUpForm, setshowPopUpForm] = useState(false);
    const [limitValue, setLimitValue] = useState({});


    let tab0Ref = useRef();
    let tab1Ref = useRef();
    let tab2Ref = useRef();
    let tab3Ref = useRef();
    let tab4Ref = useRef();

    const [popUp, setPopUp] = useState({
        name: "",
        type: "",
        key: "",
        value: "",
    });

    const showPopUp = (name, type, key, value, additionalParams) => {
        setPopUp({ name, type, key, value, additionalParams });
        setshowPopUpForm(true);
    };

    const setPopUpData = async (data) => {
        setSelectedProject(data.result);
        setIsSubmitClicked(false);
    }

    const handleSubmit = async () => {
        await fetchLimitData();
        setIsSubmitClicked(true);
        setCurrentTab(0);
    }

    const fetchLimitData = async () => {
        setLoading(true);
        let limitData = await getIstParameterLimitData();
        if (limitData?.length > 0) {
            setLimitValue(limitData[0]);
        }
        setLoading(false);
    };

    const handleSelect = async (key, direction) => {
        if (!isSubmitClicked) {
            MySwal.fire({
                text: "Please Submit Project details first.",
                imageUrl: errorImage,
                customClass: "swal-success",
            });
            return;
        }
        let saveFlag = true;
        if (direction !== "save") {
            saveFlag = false;
        }
        if (saveFlag) {
            switch (currentTab) {
                case 0:
                    await tab0Ref.current.funcOnNext();
                    break;
                case 1:
                    await tab1Ref.current.funcOnNext();
                    break;
                case 2:
                    await tab2Ref.current.funcOnNext();
                    break;
                case 3:
                    await tab3Ref.current.funcOnNext();
                    break;
                case 4:
                    await tab4Ref.current.funcOnNext();
                    break;
                default:
                    break;
            }
        }

        if (direction == "prev") {
            if (currentTab !== 0) setCurrentTab(currentTab - 1)
        }
        else if (direction == "next") {
            if (currentTab !== 4) setCurrentTab(currentTab + 1)
        }
        else if (direction == "save") {
            setCurrentTab(currentTab)
        }
        else {
            setCurrentTab(Number(key))
        }
    }

    return (
        <>
            {loading && <div className="spinner-box"> <Spinner animation="border" className="spinner" /></div>}
            <Container fluid className="main-blank-container">
                <BreadcrumbComp />
                <div className="action-comp-container bottom-border">
                    <Row className="docRevTopMargin">
                        <Col md={3} xs={12}>
                            <Form.Group className="g-input docRevInputMargin">
                                <Form.Control className="inputHeight"
                                    id="ProjectName"
                                    type="text"
                                    name="ProjectName"
                                    placeholder=" "
                                    required
                                    value={SelectedProject?.ProjectName}
                                    readOnly
                                />
                                <Form.Label className="frm-label input-label" for="ProjectName">Project</Form.Label>
                                <BiSelectMultiple className="modalPopUpIconActionComp"
                                    size={18}
                                    onClick={() => showPopUp("Project Master",
                                        "Single",
                                        "ProjectName",
                                        SelectedProject?.ProjectName,
                                        {})}
                                    color={"#707070"}
                                    style={{ marginLeft: "-2rem" }}
                                    backdrop="static"
                                />
                            </Form.Group>
                        </Col>
                        <Col md={3} xs={12} className="project-doc-buttons">
                            <Button
                                className="semiBoldToggleButton filterSubmit right-margin-button"
                                variant="success"
                                onClick={handleSubmit}
                                disabled={SelectedProject?.ProjectCode ? false : true}
                            >
                                Submit
                            </Button>
                        </Col>
                    </Row>
                </div>
                <Container className="add-container integratedContainer">
                    <div>
                        <Tabs
                            activeKey={currentTab}
                            onSelect={(e) => { handleSelect(e, "") }}
                            className="tabCssheader"
                        >
                            <Tab
                                className="headerTabKey"
                                eventKey={0}
                                title={
                                    <>
                                        <span className="numberbg">1</span>
                                        <span>Stakeholder Contact</span>
                                    </>
                                }
                            >
                                {
                                    isSubmitClicked ?
                                        <IntegratedVendorContact
                                            ref={tab0Ref}
                                            SelectedProject={SelectedProject}
                                            tabNo={currentTab}
                                            limitValue={limitValue}
                                        />
                                        :
                                        <div className="noSelection">Please select Project to view the data</div>
                                }
                            </Tab>
                            <Tab
                                className="headerTabKey"
                                eventKey={1}
                                title={
                                    <>
                                        <span className="numberbg">2</span>
                                        <span>Equipment</span>
                                    </>
                                }
                            >
                                {
                                    isSubmitClicked ?
                                        <IntegratedEquipment
                                            ref={tab1Ref}
                                            selectedProject={SelectedProject}
                                            tabNo={currentTab}
                                            limitValue={limitValue}
                                        />
                                        :
                                        <div className="noSelection">Please select Project to view the data</div>
                                }
                            </Tab>
                            <Tab
                                className="headerTabKey"
                                eventKey={2}
                                title={
                                    <>
                                        <span className="numberbg">3</span>
                                        <span>IST Script</span>
                                    </>
                                }
                            >
                                {
                                    isSubmitClicked ?
                                        <IntegratedScript
                                            ref={tab2Ref}
                                            selectedProject={SelectedProject}
                                            tabNo={currentTab}
                                            limitValue={limitValue}
                                        />
                                        :
                                        <div className="noSelection">Please select Project to view the data</div>
                                }
                            </Tab>
                            <Tab
                                className="headerTabKey"
                                eventKey={3}
                                title={
                                    <>
                                        <span className="numberbg">4</span>
                                        <span>IST Observations</span>
                                    </>
                                }
                            >
                                {
                                    isSubmitClicked ?
                                        <IntegratedISTObservations
                                            ref={tab1Ref}
                                            SelectedProject={SelectedProject}
                                            tabNo={currentTab}
                                        />
                                        :
                                        <div className="noSelection">Please select Project to view the data</div>
                                }
                            </Tab>
                            <Tab
                                className="headerTabKey"
                                eventKey={4}
                                title={
                                    <>
                                        <span className="numberbg">5</span>
                                        <span>Power Analyzer & IST Screenshot</span>
                                    </>
                                }
                            >
                                {
                                    isSubmitClicked ?
                                        <IntegratedPowerAnalyzer
                                            ref={tab4Ref}
                                            selectedProject={SelectedProject}
                                            tabNo={currentTab}
                                            limitValue={limitValue}
                                        />
                                        :
                                        <div className="noSelection">Please select Project to view the data</div>
                                }
                            </Tab>
                        </Tabs>
                        <br />
                        {isSubmitClicked &&
                            <Row className="tabButtonPosition">
                                <TabButtons
                                    currentTab={currentTab}
                                    lastTab={4}
                                    handleSelect={handleSelect}
                                    showSave={(currentTab === 2 || currentTab === 4) && SelectedProject.DeleteIndication === "Active"}
                                />
                            </Row>
                        }
                    </div>
                </Container>
                <RenderIf isTrue={showPopUpForm}>
                    <SingleValuePopUp
                        show={showPopUpForm}
                        onHide={() => setshowPopUpForm(false)}
                        setSelected={setPopUpData}
                        mastername={popUp.name}
                        paramkey={popUp.key}
                        paramvalue={popUp.value}
                        additionalParams={popUp.additionalParams}
                        backdrop="static"
                    />
                </RenderIf>
            </Container>
        </>
    );
}

export default IntegratedSystemTesting;