import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL; 

export async function getAllCompanyDivisionData(){
  const response = await axios
    .get(API_URL + "/companydivisionmaster")
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      console.log("error => ",error );
      return {data:[]};
    });
  return response.data;
}


export async function getActiveCompanyWithoutENE(){  
  const response = await axios
    .get(API_URL + "/companydivisionmaster/getActiveCompanyWithoutENE")
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      console.log("error => ",error );
      return {data:[]};
    });
  return response.data;
}

export async function getActiveCompanyDivisionData(){
  const response = await axios
    .get(API_URL + "/companydivisionmaster/getActivecompanydivision")
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      console.log("error => ",error );
      return {data:[]};
    });
  return response.data;
}

//============================ permanent delete ====================================
export async function DeleteCompany(comp_code) {
  let url = `${API_URL}/companydivisionmaster/deletecompany/${encodeURIComponent(comp_code)}`;
  const resp = await axios
    .post(url)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      console.log("error => ",error );
      return {data: { message: error.message, status: false }};
    });
  return resp.data;
}

//============================Get Sector data ====================================
export async function getActiveSectorData(){
  const response = await axios
    .get(API_URL + "/companydivisionmaster/activesectordata")
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      console.log("error => ",error );
      return {data:[]};
    });

    return response.data;
}

//============================ Add Company ====================================
export const AddNewCompanyDivision = async (CompObj) => {
  let url = `${API_URL}/companydivisionmaster/addcompanydivision`;
  const response = await axios
    .post(url, CompObj)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      console.log("error => ",error );
      return {data: { message: "Unable to find request", status: false }};
    });
  return response.data;
};

//============================ Update Company ====================================

export const UpdateCompanyDivision = async (compObj) => {
  let url = `${API_URL}/companydivisionmaster/updatecompanydivision`;
  const response = await axios
    .post(url, compObj)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      console.log("error => ",error );
      return {data: { message: "Unable to find request", status: false }};
    });
  return response.data;
};


