import { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";

const AutoLogOut = () => {
  console.log("inside AutoLogOut");
  const { instance } = useMsal();

  const signoutTime = process.env.REACT_APP_SESSION_TIMEOUT;
  console.log("signoutTime", signoutTime)
  let logoutTimeout;

  const logout = () => {
    handleLogout();
  };

  const setTimeouts = () => {
    logoutTimeout = setTimeout(logout, signoutTime);
  };

  const clearTimeouts = () => {
    if (logoutTimeout) clearTimeout(logoutTimeout);
  };

  useEffect(() => {
    const events = [
      "load",
      "mousemove",
      "mousedown",
      "click",
      "scroll",
      "keypress",
    ];

    const resetTimeout = () => {
      clearTimeouts();
      setTimeouts();
    };

    for (let i in events) {
      window.addEventListener(events[i], resetTimeout);
    }

    setTimeouts();
    return () => {
      for (let i in events) {
        window.removeEventListener(events[i], resetTimeout);
        clearTimeouts();
      }
    };
  }, []);

  function handleLogout() {
    sessionStorage.removeItem("Token");
    sessionStorage.removeItem("UserData");
    sessionStorage.removeItem("SSOLogin");

    instance.logoutRedirect().catch((e) => {
      console.error(e);
    });
  }
  return null;
};

export default AutoLogOut;
