import React from "react";
import Functional_Default from "../../icons/Functional_Default.svg"
import Functional_Error_Default from "../../icons/Functional_Error_Default.svg"
import Functional_Error_Hover from "../../icons/Functional_Error_Hover.svg"
import Functional_Hover from "../../icons/Functional_Hover.svg"
import Functional_Inactive_Default from "../../icons/Functional_Inactive_Default.svg"
import Functional_Inprocess_Default from "../../icons/Functional_Inprocess_Default.svg"
import Functional_Inprocess_Hover from "../../icons/Functional_Inprocess_Hover.svg"
import Prefunctional_Default from "../../icons/Prefunctional_Default.svg"
import Prefunctional_Error_Default from "../../icons/Prefunctional_Error_Default.svg"
import Prefunctional_Error_Hover from "../../icons/Prefunctional_Error_Hover.svg"
import Prefunctional_Hover from "../../icons/Prefunctional_Hover.svg"
import Prefunctional_Inactive_Default from "../../icons/Prefunctional_Inactive_Default.svg"
import Prefunctional_Inprocess_Default from "../../icons/Prefunctional_Inprocess_Default.svg"
import Prefunctional_Inprocess_Hover from "../../icons/Prefunctional_Inprocess_Hover.svg"
import "./ProjectChecklist.css";


function ChecklistCategoryStatusRenderer(props) {
    let statusPreimage = Prefunctional_Inactive_Default;
    let statusPreimageHover;
    let statusFunimageHover;
    let statusFunimage = Functional_Inactive_Default;
    let chkitem = props.selectedData.AppliedChecklist;
    let checklistPreInd = "";
    let checklistFunInd = "";

    if (chkitem.length > 0) {
        if (chkitem.indexOf("Pre-Functional") !== -1) {
            checklistPreInd = "Pre-Functional"
        }
        if (chkitem.indexOf("Functional") !== -1) {
            checklistFunInd = "Functional"
        }
    }
    if (checklistPreInd !== "Pre-Functional" || props.selectedData.DeleteIndication === "Inactive") {
        statusPreimage = Prefunctional_Inactive_Default;
        checklistPreInd = "";
    }
    else if (props.selectedData.ChecklistPrefunctionalStatus === "Started" && checklistPreInd === "Pre-Functional") {
        statusPreimage = Prefunctional_Inprocess_Default;
        statusPreimageHover = Prefunctional_Inprocess_Hover;
    }
    else if (props.selectedData.ChecklistPrefunctionalStatus === "Not started" && checklistPreInd === "Pre-Functional") {
        statusPreimage = Prefunctional_Error_Default;
        statusPreimageHover = Prefunctional_Error_Hover;
    }
    else if (props.selectedData.ChecklistPrefunctionalStatus === "Submitted" && props.selectedData.DeleteIndication === "Active") {
        statusPreimage = Prefunctional_Default;
        statusPreimageHover = Prefunctional_Hover;
    }

    if (checklistFunInd !== "Functional" || props.selectedData.DeleteIndication === "Inactive") {
        statusFunimage = Functional_Inactive_Default;
        checklistFunInd = "";
    }
    else if (props.selectedData.ChecklistFunctionalStatus === "Started" && checklistFunInd === "Functional") {
        statusFunimage = Functional_Inprocess_Default;
        statusFunimageHover = Functional_Inprocess_Hover;
    }
    else if (props.selectedData.ChecklistFunctionalStatus === "Not started" && checklistFunInd === "Functional") {
        statusFunimage = Functional_Error_Default;
        statusFunimageHover = Functional_Error_Hover;
    }
    else if (props.selectedData.ChecklistFunctionalStatus === "Submitted" && props.selectedData.DeleteIndication === "Active") {
        statusFunimage = Functional_Default;
        statusFunimageHover = Functional_Hover;
    }
    return (
        <>
            {
                checklistPreInd === "" ?
                    <img
                        className="checklistIndicator"
                        src={statusPreimage}
                        alt="statusPreimage"
                    />
                    :
                    <img
                        className="checklistIndicator"
                        onMouseOver={e => (e.currentTarget.src = statusPreimageHover)}
                        onMouseOut={e => (e.currentTarget.src = statusPreimage)}
                        src={statusPreimage}
                        alt="statusPreimage"
                        onClick={() => props.handlePrefunctionalEvent(props.selectedData)}
                    />
            } &nbsp;
            {
                checklistFunInd === "" ?
                    <img
                        className="checklistIndicator"
                        src={statusFunimage}
                        alt="statusFunimage"
                    />
                    :
                    <img
                        className="checklistIndicator"
                        onMouseOver={e => (e.currentTarget.src = statusFunimageHover)}
                        onMouseOut={e => (e.currentTarget.src = statusFunimage)}
                        src={statusFunimage}
                        alt="statusFunimage"
                        onClick={() => props.handleFunctionalEvent(props.selectedData)}
                    />

            }
        </>
    );
}

export { ChecklistCategoryStatusRenderer };