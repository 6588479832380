import React, { useState, useContext } from "react";
import { Button, Form, Modal, Row, Col, Spinner } from "react-bootstrap";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import errorImage from "../../icons/error-tick-logo.svg";
import successImage from "../../icons/success-tick-logo.svg";
import { AuthContext } from "../../contexts/AuthContext";
import { BiSelectMultiple } from "react-icons/bi";
import { getCurrentDateTime, RenderIf } from "../../functions/gbcs-functions";
import SingleValuePopUp from "../utils/SingleValuePopUp";
import { addIntegratedSystemTestingDetails, getIntegratedTabsData } from "../../services/integrated-system-testing-service";
import { sortByPropertyInDescending } from "../../utils/Utility";

const IntegratedAddEditEquipment = (props) => {
    const MySwal = withReactContent(Swal);
    const context = useContext(AuthContext);
    const userDetails = { userId: context.userId, userName: context.userName }
    const [error, setError] = useState({});
    const [showForm, setShowForm] = useState(false);
    const [loading, setLoading] = useState(false);
    const selectedProject = props.selectedProject;
    let action = props.action;

    const [integratedEquipmentObj, setIntegratedEquipmentObj] = useState(
        action === "Edit" ? props.rowdata : {
            ServiceName: "",
            ServiceCode: "",
            EquipmentCode: "",
            EquipmentName: "",
            EquipmentTag: "",
            Contractor: "",
            RecordEntry: "Manual",
            SystemReadyForIST: "Yes",
            ApplicableforCriNonCriReport: "Non-Critical",
            Remarks: ""
        });

    const validateDetails = () => {
        let isError = false;
        const errorMsg = {
            ServiceName: {},
            EquipmentName: {},
            EquipmentTag: {},
            Contractor: {}
        };
        if (integratedEquipmentObj.ServiceName === "") {
            isError = true;
            errorMsg.ServiceName = {
                status: true,
                message: "Service Name is required.",
            };
        }
        if (integratedEquipmentObj.EquipmentName.trim() === "") {
            isError = true;
            errorMsg.EquipmentName = {
                status: true,
                message: "Equipment / Activity Name is required.",
            };
        }
        if (integratedEquipmentObj.EquipmentTag.trim() === "") {
            isError = true;
            errorMsg.EquipmentTag = {
                status: true,
                message: "Equipment tag is required.",
            };
        }
        if (integratedEquipmentObj.Contractor.trim() === "") {
            isError = true;
            errorMsg.Contractor = {
                status: true,
                message: "Contractor / Vendor is required.",
            };
        }

        setError(errorMsg);
        return isError;
    };

    const handleChange = (e) => {
        let { name, value, type } = e.target;
        // if (type.toLowerCase() === "radio") {
        //     value = e.target.checked;
        // }
        // console.log(value)
        setIntegratedEquipmentObj((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const [popUp, setPopUp] = useState({
        name: "",
        type: "",
        key: "",
        value: "",
        additionalParams: ""
    });

    const showPopUp = (name, type, key, value, additionalParams) => {
        setPopUp({ name, type, key, value, additionalParams });
        setShowForm(true);
    };

    const setPopUpData = async (data) => {
        if (data.name === "Service Master") {
            setIntegratedEquipmentObj((prevState) => ({
                ...prevState,
                ServiceName: data.result.ServiceName,
                ServiceCode: data.result.ServiceCode,
                EquipmentName: "",
                EquipmentCode: ""
            }));
        }
        else if (data.name === "Equipment Master") {
            setIntegratedEquipmentObj((prevState) => ({
                ...prevState,
                ServiceName: data.result.ServiceName,
                ServiceCode: data.result.ServiceCode,
                EquipmentName: data.result.EquipmentName,
                EquipmentCode: data.result.EquipmentCode
            }));
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        let validationResult = validateDetails();
        if (!validationResult) {
            setLoading(true);
            let equipData = await getIntegratedTabsData({ "ProjectCode": selectedProject.ProjectCode, "flag": "Equipment" });
            if (equipData?.length > 0 && equipData.filter((item) => (action === "Add" || (action === "Edit" && item.SK !== integratedEquipmentObj.SK)) && item.EquipmentTag.toLowerCase() === integratedEquipmentObj.EquipmentTag.toLowerCase()).length > 0) {
                setLoading(false);
                MySwal.fire({
                    text: "Equipment / Activity Tag is already exists.",
                    imageUrl: errorImage,
                    customClass: "swal-success",
                });
                return;
            }
            MySwal.fire({
                text: "Are you sure, do you want to " + action + " new Equipment / Activity?",
                showDenyButton: true,
                cancelButtonColor: "#fff",
                confirmButtonText: "Yes",
                reverseButtons: true,
                customClass: "swal-confirmation",
                title: action + " Equipment / Activity"
            }).then(async (result2) => {
                if (result2.isConfirmed) {
                    setLoading(true);
                    if (action === "Add") {
                        let maxSeqNo = 0;
                        if (equipData?.length > 0) {
                            let sortedArr = equipData.sort(sortByPropertyInDescending("SeqNo"));
                            maxSeqNo = sortedArr[0].SeqNo
                        }

                        let NewMaxSeqNo = parseInt(maxSeqNo) + 1;
                        integratedEquipmentObj.CreatedOn = getCurrentDateTime("-");
                        integratedEquipmentObj.CreatedBy = userDetails.userId;
                        integratedEquipmentObj.CreatedByName = userDetails.userName;
                        integratedEquipmentObj.ProjectCode = selectedProject.ProjectCode;
                        integratedEquipmentObj.ProjectName = selectedProject.ProjectName;
                        integratedEquipmentObj.SeqNo = NewMaxSeqNo;
                        integratedEquipmentObj.PK = "IntegratedSystemTesting#ProjectCode#" + selectedProject.ProjectCode;
                        integratedEquipmentObj.SK = "Equipment#SeqNo#" + NewMaxSeqNo;
                    }
                    integratedEquipmentObj.ModifiedOn = getCurrentDateTime("-");
                    integratedEquipmentObj.ModifiedBy = userDetails.userId;
                    integratedEquipmentObj.ModifiedByName = userDetails.userName;
                    let result = await addIntegratedSystemTestingDetails(integratedEquipmentObj);
                    if (result?.status) {
                        setLoading(false);
                        MySwal.fire({
                            text: "Equipment / Activity has been " + (action === "Edit" ? "updated" : "added") + " successfully.",
                            imageUrl: successImage,
                            customClass: "swal-success",
                            didClose: () => {
                                props.fetchProjectServiceEqui();
                                props.onHide();
                            },
                        });
                    }
                    else {
                        setLoading(false);
                        MySwal.fire({
                            text: "Unable to Process Request",
                            imageUrl: errorImage,
                            customClass: "swal-success",
                        });
                    }
                }
            });
            setLoading(false);
        }
    };

    const handleReset = () => {
        setIntegratedEquipmentObj({
            ServiceCode: "",
            ServiceName: "",
            EquipmentCode: "",
            EquipmentName: "",
            EquipmentTag: "",
            Contractor: "",
            RecordEntry: "Manual",
            SystemReadyForIST: "Yes",
            ApplicableforCriNonCriReport: "Non-Critical"
        });
        setError({
            ServiceName: {},
            EquipmentName: {},
            EquipmentTag: {},
            Contractor: {}
        });
    }

    return (
        <>
            {loading && <div className="spinner-box"> <Spinner animation="border" className="spinner" /></div>}
            <Modal {...props} size="lg" backdrop="static" centered>
                <Modal.Header closeButton>
                    <Modal.Title>{action} Equipment / Activity</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form id="integratedEquipment" name="integratedEquipment" autoComplete="off">
                        <Row>
                            <Col lg={4} xs={12}>
                                <Form.Group className="g-input">
                                    <Form.Control
                                        id="ServiceName"
                                        type="Text"
                                        name="ServiceName"
                                        maxLength="200"
                                        placeholder=" "
                                        value={integratedEquipmentObj.ServiceName}
                                        onChange={handleChange}
                                        readOnly
                                    />
                                    <Form.Label className="frm-label" htmlFor="ServiceName">
                                        Service Name
                                    </Form.Label>
                                    <BiSelectMultiple
                                        className="modalPopUpIconProjDetails"
                                        size={20}
                                        onClick={() => {
                                            showPopUp(
                                                "Service Master",
                                                "Single",
                                                "ServiceName",
                                                integratedEquipmentObj.ServiceName,
                                                {}
                                            );
                                        }}
                                    />
                                    {error.ServiceName?.status && (
                                        <Form.Text className="text-danger">
                                            {error.ServiceName?.message}
                                        </Form.Text>
                                    )}
                                </Form.Group>
                            </Col>
                            <Col lg={4} xs={12}>
                                <Form.Group className="g-input">
                                    <Form.Control
                                        id="EquipmentName"
                                        type="Text"
                                        name="EquipmentName"
                                        maxLength="200"
                                        placeholder=" "
                                        value={integratedEquipmentObj.EquipmentName}
                                        onChange={handleChange}
                                        readOnly
                                    />
                                    <Form.Label className="frm-label" htmlFor="EquipmentName">
                                        Equipemtn / Activity Name
                                    </Form.Label>
                                    <BiSelectMultiple
                                        className="modalPopUpIconProjDetails"
                                        size={20}
                                        onClick={() => {
                                            showPopUp(
                                                "Equipment Master",
                                                "Single",
                                                "EquipmentName",
                                                integratedEquipmentObj.EquipmentName,
                                                [{ ServiceCode: integratedEquipmentObj.ServiceCode }, { ISTFlag: true }]
                                            );
                                        }}
                                    />
                                    {error.EquipmentName?.status && (
                                        <Form.Text className="text-danger">
                                            {error.EquipmentName?.message}
                                        </Form.Text>
                                    )}
                                </Form.Group>
                            </Col>
                            <Col lg={4} xs={12}>
                                <Form.Group className="g-input">
                                    <Form.Control
                                        id="EquipmentTag"
                                        type="Text"
                                        name="EquipmentTag"
                                        maxLength="200"
                                        placeholder=" "
                                        value={integratedEquipmentObj.EquipmentTag}
                                        onChange={handleChange}
                                    />
                                    <Form.Label className="frm-label" htmlFor="EquipmentTag">
                                        Equipment / Activity Tag
                                    </Form.Label>
                                    {error.EquipmentTag?.status && (
                                        <Form.Text className="text-danger">
                                            {error.EquipmentTag?.message}
                                        </Form.Text>
                                    )}
                                </Form.Group>
                            </Col>
                            <Col lg={4} xs={12}>
                                <Form.Group className="g-input">
                                    <Form.Control
                                        id="Contractor"
                                        type="Text"
                                        name="Contractor"
                                        maxLength="200"
                                        placeholder=" "
                                        value={integratedEquipmentObj.Contractor}
                                        onChange={handleChange}
                                    />
                                    <Form.Label className="frm-label" htmlFor="Contractor">
                                        Contractor / Vendor
                                    </Form.Label>
                                    {error.Contractor?.status && (
                                        <Form.Text className="text-danger">
                                            {error.Contractor?.message}
                                        </Form.Text>
                                    )}
                                </Form.Group>
                            </Col>
                            <Col lg={4} xs={12}>
                                <Form.Group className="g-select">
                                    <Form.Select
                                        id="SystemReadyForIST"
                                        name="SystemReadyForIST"
                                        type="text"
                                        placeholder=" "
                                        value={integratedEquipmentObj.SystemReadyForIST}
                                        onChange={(e) => { handleChange(e) }}
                                    >
                                        <option key="Yes" value="Yes">Yes</option>
                                        <option key="No" value="No">No</option>
                                        <option key="NA" value="NA">NA</option>
                                    </Form.Select>
                                    <Form.Label className="frm-label" htmlFor="SystemReadyForIST">System Ready For IST</Form.Label>
                                </Form.Group>
                            </Col>
                            <Col lg={4} xs={12}>
                                <Form.Group className="g-input">
                                    <Form.Control
                                        id="Remarks"
                                        type="Text"
                                        name="Remarks"
                                        maxLength="500"
                                        placeholder=" "
                                        value={integratedEquipmentObj.Remarks}
                                        onChange={handleChange}
                                    />
                                    <Form.Label className="frm-label" htmlFor="Remarks">
                                        Remarks
                                    </Form.Label>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6} xs={12}>
                                <Row>
                                    <Form.Label className="fieldLabel locationType">Applicable for Critcal / Non-Critical Report</Form.Label>
                                </Row>
                                <Row>
                                    <Col md={6} xs={6}>
                                        <Form.Check className="fieldLabel locationType"
                                            type="radio"
                                            name="ApplicableforCriNonCriReport"
                                            disabled={props.istapplicable === "No"}
                                            checked={integratedEquipmentObj.ApplicableforCriNonCriReport === "Critical"}
                                            onChange={handleChange}
                                            label="Critical"
                                            value="Critical"
                                        ></Form.Check>
                                    </Col>
                                    <Col md={6} xs={6}>
                                        <Form.Check className="fieldLabel locationType"
                                            type="radio"
                                            name="ApplicableforCriNonCriReport"
                                            checked={integratedEquipmentObj.ApplicableforCriNonCriReport === "Non-Critical"}
                                            onChange={handleChange}
                                            label="Non-Critical"
                                            value="Non-Critical"
                                        ></Form.Check>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <br />
                        <Row>
                            <Col xs={6} lg={6}>{action === "Add" &&
                                <Button variant="outline-success"
                                    className="semiBoldToggleButton cancelBtn"
                                    onClick={(e) => handleReset(e)}>
                                    Reset
                                </Button>
                            }
                            </Col>
                            <Col xs={6} lg={6}>
                                <Button
                                    variant="success"
                                    className="btn-add-color semiBoldToggleButton rightAlignedButton"
                                    onClick={handleSubmit}>
                                    {action === "Edit" ? "Update" : "Add"}
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                    <RenderIf isTrue={popUp.type === "Single" && showForm}>
                        <SingleValuePopUp
                            show={showForm}
                            onHide={() => setShowForm(false)}
                            setSelected={setPopUpData}
                            mastername={popUp.name}
                            paramkey={popUp.key}
                            paramvalue={popUp.value}
                            additionalParams={popUp.additionalParams}
                            backdrop="static"
                        />
                    </RenderIf>
                </Modal.Body >
            </Modal >
        </>
    );
};

export default IntegratedAddEditEquipment;
