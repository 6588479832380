import React, { useState } from "react";
import Chart from "react-apexcharts";

const ChartComponent = (props) => {
  const [series] = useState(props.data.series);
  const [series2] = useState(props.data.series2);
  let [chartOptions] = useState(props.data.chartOptions);

  const barstate = {
    series: [{
      data: series,
    }],
    options: {
      chart: {
        type: 'bar',
        toolbar: false,
        background: "#fff"
      },
      colors: ["#66BB6A"],
      plotOptions: {
        bar: {
          horizontal: true,
          dataLabels: {
            position: 'top',
          },
          barHeight: "60%",
          // offsetX: -20,
        }
      },
      dataLabels: {
        offsetX: 30,
        style: {
          colors: ['#000'],
          fontSize: '11px',
          fontWeight: 'normal',
          fontFamily: 'G&B-BodyCopy, sans-serif'
        },
        horizontal: true,
      },
      title: {
        text: props.chartLabel,
        align: 'left',
        offsetX: 10,
        offsetY: 10,
        // margin: 10,
        style: {
          color: '#333333',
          fontSize: '14px',
          fontWeight: 'normal',
          fontFamily: 'G&B-Headline, sans-serif'
        },
      },
      xaxis: {
        position: 'top',
        categories: chartOptions?.categories !== undefined ? chartOptions.categories : [],
      },
      tooltip: {
        custom: function ({ dataPointIndex, w }) {
          return (
            '<div>' +
            '<span style="color: black"> Equipment / Activity ' +
            w.config.xaxis.categories[dataPointIndex] + " of " +
            w.config.series[0].data[dataPointIndex] + " %" + '</span></div>'
          )
        },
        marker: {
          show: true,
        },
        fillSeriesColor: false,
      }
    },
  };

  const state = {
    series: series,
    series2: series2,
    options: {
      chart: {
        width: '100%',
        // height: '100%',
        type: 'pie',
        background: '#fff'
      },
      plotOptions: {
        pie: {
          customScale: 1,
          offsetY: 20
        }
      },
      dataLabels: {
        enabled: false
      },
      title: {
        text: props.chartLabel,
        align: 'left',
        offsetX: 10,
        offsetY: 10,
        // margin: 10,
        style: {
          color: '#333333',
          fontSize: '14px',
          fontWeight: 'normal',
          fontFamily: 'G&B-Headline, sans-serif'
        },
      },
      labels: chartOptions.labels,
      customLabels: chartOptions.customLabels,
      custom2Labels: chartOptions.custom2Labels,
      legend: {
        offsetY: 30,
        position: "right",
        width: 180
        // horizontalAlign: 'left',
        // formatter: customLegendFormatter
      },
      colors: chartOptions.colors,
      // responsive: [{
      //   breakpoint: 480,
      //   options: {
      //     chart: {
      //       width: 200
      //     },
      //     legend: {
      //       position: 'bottom',
      //       horizontalAlign: 'center',
      //     }
      //   }
      // }],
      tooltip: {
        custom: function ({ series, seriesIndex, w }) {
          if (chartOptions.customLabels) {
            let str = "";
            let cnt = 1;
            for (let dt of w.config.customLabels) {
              str = str + dt + "<br/>" + series2[seriesIndex][dt] + "%";
              if (cnt < w.config.customLabels.length) {
                str = str + "<br/><br/>";
              }
            }
            return (
              '<div>' +
              '<span>' + str +
              "</span>" +
              "</div>"
            );
          }
          else {
            return (
              '<div>' +
              "<span>" +
              w.config.labels[seriesIndex] + "<br/>" +
              " " +
              series[seriesIndex] + "%" +
              "</span>" +
              "</div>"
            );
          }
        },
        fillSeriesColor: false,
        marker: {
          show: true,
        },
      }
    }
  };

  return (
    <div id="chart">
      <Chart
        type={props.data.chartType}
        options={props.data.chartType === "bar" ? barstate.options : state.options}
        series={props.data.chartType === "bar" ? barstate.series : state.series}
        height={props.data.chartType === "bar" ? (100 + (series.length * 40)) : 300}
        className="dashboardPieRespDiv"
      />
    </div>
  );
};

export default ChartComponent;
