import React, { forwardRef, useState, useEffect, useRef, useImperativeHandle } from "react";
import { Container } from "react-bootstrap";
import { formatDateInDDMMYYYY, getTodaysDate } from "../../functions/gbcs-functions";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { DashboardProjDataContainer } from "../../functions/gbcs-functions";
import { BsDashLg, BsPlusLg } from "react-icons/bs";
import { exportToExcelProjectProgress } from "./ExcelCreation_Dashboard";

const DashboardWeightage = forwardRef((props, ref) => {

    const [gridApi, setGridApi] = useState(null);
    // const [filteredResult, setFilteredResult] = useState([]);
    const filteredResult = props.calculatedData?.finalData
    // const calculatedFinalData = props.calculatedData;
    const gridRef = useRef();
    const [hideColumn, setHideColumn] = useState(true);

    // const customComparator = (valueA, valueB) => {
    //     return valueA?.toLowerCase().localeCompare(valueB?.toLowerCase());
    // };

    const defaultColPara = {
        flex: 1,
        sortable: false,
        resizable: false,
        filter: false,
        textAlign: "center",
        suppressMovable: true,
        // enableCellChangeFlash: true,
        cellClass: 'cell-wrap-textDashboard',
        wrapHeaderText: true,
        autoHeaderHeight: true,
        // headerClass: "agHeaderCellDashboard"
    }

    function onGridReady(params) {
        setGridApi(params.api);
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        params.api.resetRowHeights();
        gridRef.current = params.api; // <= assigned gridApi value on Grid ready
    }


    const showColumns = () => {
        setHideColumn(!hideColumn);
    }

    const customHeaderComponent = (props) => {
        return (
            <>{props.displayName}
                &nbsp;
                {hideColumn ?
                    <BsPlusLg size={18} className="plusIcon" onClick={showColumns} />
                    :
                    <BsDashLg size={18} className="plusIcon" onClick={showColumns} />
                }
            </>
        );

    }

    const columnDefs = [
        {
            headerName: "SR NO.",
            field: "ServcieSeqNo",
            width: 45,
            headerTooltip: "SR NO.",
            headerClass: "grid-sor-columns",
            cellRenderer: function (params) {
                const cellBlank = params.data.totalServiceRowSpan === params.data.ServiceRowSpan;
                if (cellBlank) {
                    return (
                        <div className="show-name">{params.value}</div>
                    )
                }
                return;
            },
            rowSpan: (params) => params.data.ServiceRowSpan !== undefined ? params.data.ServiceRowSpan : 1,
            cellClassRules: {
                "show-cell": (params) => { return params.data.ServiceRowSpan !== undefined; }
            },
            pinned: 'left',
        },
        {
            headerName: "SERVICE",
            field: "ServiceName",
            headerTooltip: "SERVICE",
            headerClass: "grid-sor-columns",
            width: 100,
            cellRenderer: function (params) {
                const cellBlank = params.data.totalServiceRowSpan === params.data.ServiceRowSpan;
                if (cellBlank) {
                    return (
                        <div className="show-name">{params.value}</div>
                    )
                }
                return;
            },
            rowSpan: (params) => params.data.ServiceRowSpan !== undefined ? params.data.ServiceRowSpan : 1,
            cellClassRules: {
                "show-cell": (params) => { return params.data.ServiceRowSpan !== undefined; },
                "show-data": (params) => { return params.data.totalServiceRowSpan === params.data.ServiceRowSpan; }
            },
            pinned: 'left',
            wrapText: true,
            cellStyle: { wordBreak: 'normal' },
            headerComponentFramework: customHeaderComponent
        },
        {
            headerName: "FIXED SERVICE %",
            field: "FixedService",
            headerClass: "grid-sor-columns",
            width: 90,
            headerTooltip: "FIXED SERVICE %",
            cellRenderer: function (params) {
                const cellBlank = params.data.totalServiceRowSpan === params.data.ServiceRowSpan;
                if (cellBlank) {
                    return (
                        <div className="show-name">{params.value}</div>
                    )
                }
                return;
            },
            rowSpan: (params) => params.data.ServiceRowSpan !== undefined ? params.data.ServiceRowSpan : 1,
            cellClassRules: {
                "show-cell": (params) => { return params.data.ServiceRowSpan !== undefined; }
            },
            pinned: 'left',
            hide: hideColumn
        },
        {
            headerName: "APPLICABLE SERVICE",
            field: "ApplicableService",
            width: 100,
            headerTooltip: "APPLICABLE SERVICE",
            headerClass: "grid-sor-columns",
            cellRenderer: function (params) {
                const cellBlank = params.data.totalServiceRowSpan === params.data.ServiceRowSpan;
                if (cellBlank) {
                    return (
                        <div className="show-name">{params.value}</div>
                    )
                }
                return;
            },
            rowSpan: (params) => params.data.ServiceRowSpan !== undefined ? params.data.ServiceRowSpan : 1,
            cellClassRules: {
                "show-cell": (params) => { return params.data.ServiceRowSpan !== undefined; }
            },
            pinned: 'left',
            hide: hideColumn
        },
        {
            headerName: "CALCULATED SERVICE %",
            field: "CalculateService",
            width: 100,
            headerTooltip: "CALCULATED SERVICE %",
            headerClass: "grid-sor-columns",
            cellRenderer: function (params) {
                const cellBlank = params.data.totalServiceRowSpan === params.data.ServiceRowSpan;
                if (cellBlank) {
                    return (
                        <div className="show-name">{params.value}</div>
                    )
                }
                return;
            },
            rowSpan: (params) => params.data.ServiceRowSpan !== undefined ? params.data.ServiceRowSpan : 1,
            cellClassRules: {
                "show-cell": (params) => { return params.data.ServiceRowSpan !== undefined; }
            },
            pinned: 'left',
            hide: hideColumn
        },
        {
            headerName: "SERVICE %",
            field: "ServicePer",
            maxWidth: 80,
            headerTooltip: "SERVICE %",
            headerClass: "grid-sor-columns",
            cellRenderer: function (params) {
                const cellBlank = params.data.totalServiceRowSpan === params.data.ServiceRowSpan;
                if (cellBlank) {
                    return (
                        <div className="show-name">{params.value}</div>
                    )
                }
                return;
            },
            rowSpan: (params) => params.data.ServiceRowSpan !== undefined ? params.data.ServiceRowSpan : 1,
            cellClassRules: {
                "show-cell": (params) => { return params.data.ServiceRowSpan !== undefined; }
            },
            pinned: 'left',
            hide: hideColumn
        },
        {
            headerName: "EQUIPMENT / ACTIVITY",
            field: "EquipmentName",
            width: 100,
            headerTooltip: "EQUIPMENT / ACTIVITY",
            headerClass: "grid-sor-columns",
            cellRenderer: function (params) {
                const cellBlank = params.data.totalEquipRowSpan === params.data.EquipRowSpan;
                if (cellBlank) {
                    return (
                        <div className="show-name">{params.value}</div>
                    )
                }
                return;
            },
            rowSpan: (params) => params.data.EquipRowSpan !== undefined ? params.data.EquipRowSpan : 1,
            cellClassRules: {
                "show-cell": (params) => { return params.data.EquipRowSpan !== undefined; },
                "show-data": (params) => { return params.data.totalEquipRowSpan === params.data.EquipRowSpan; }
            },
            pinned: 'left',
            wrapText: true,
            cellStyle: { wordBreak: 'normal' }
        },
        {
            headerName: "PHASES",
            field: "Milestone",
            width: 170,
            headerTooltip: "PHASES",
            headerClass: "grid-sor-columns",
            pinned: 'left',
            wrapText: true,
            cellStyle: { wordBreak: 'normal' },
        },
        {
            headerName: "FIXED PHASES %",
            field: "FixedMilestone",
            minWidth: 150,
            headerTooltip: "FIXED PHASES %",
            headerClass: "grid-sor-columns"
        },
        {
            headerName: "APPLICABLE PHASES (EQUIPMENT MASTER)",
            field: "ApplicableMilestone",
            minWidth: 180,
            headerTooltip: "APPLICABLE PHASES (EQUIPMENT MASTER)",
            headerClass: "grid-sor-columns"
        },
        {
            headerName: "CALCULATED PHASES %",
            field: "CalculatedMilestone",
            minWidth: 120,
            headerTooltip: "CALCULATED PHASES %",
            headerClass: "grid-sor-columns"
        },
        {
            headerName: "PHASES %",
            field: "MilestonePerc",
            minWidth: 100,
            headerTooltip: "PHASES %",
            headerClass: "grid-sor-columns"
        },
        {
            headerName: "EQUIPMENT / ACTIVITY QTY {TAGS}",
            field: "EquipmentQty",
            minWidth: 150,
            headerTooltip: "EQUIPMENT / ACTIVITY QTY {TAGS}",
            headerClass: "grid-sor-columns",
            cellRenderer: function (params) {
                const cellBlank = params.data.totalEquipRowSpan === params.data.EquipRowSpan;
                if (cellBlank) {
                    return (
                        <div className="show-name">{params.value}</div>
                    )
                }
                return;
            },
            rowSpan: (params) => params.data.EquipRowSpan !== undefined ? params.data.EquipRowSpan : 1,
            cellClassRules: {
                "show-cell": (params) => { return params.data.EquipRowSpan !== undefined; }
            }
        },
        {
            headerName: "PHASES COUNT",
            field: "MilestoneCount",
            minWidth: 150,
            headerTooltip: "PHASES COUNT",
            headerClass: "grid-sor-columns"
        },
        {
            headerName: "PHASES COUNT COMPLETED",
            field: "MilestoneCountCompleted",
            minWidth: 150,
            headerTooltip: "PHASES COUNT COMPLETED",
            headerClass: "grid-sor-columns"
        },
        {
            headerName: "EQUIPMENT / ACTIVITY WEIGHTAGE",
            field: "EquipmentWeightage",
            minWidth: 150,
            headerTooltip: "EQUIPMENT / ACTIVITY WEIGHTAGE",
            headerClass: "grid-sor-columns",
            cellRenderer: function (params) {
                const cellBlank = params.data.totalEquipRowSpan === params.data.EquipRowSpan;
                if (cellBlank) {
                    return (
                        <div className="show-name">{params.value}</div>
                    )
                }
                return;
            },
            rowSpan: (params) => params.data.EquipRowSpan !== undefined ? params.data.EquipRowSpan : 1,
            cellClassRules: {
                "show-cell": (params) => { return params.data.EquipRowSpan !== undefined; }
            }
        },
        {
            headerName: "PHASES WEIGHTAGE (DISTRIBUTION)",
            field: "MilestoneWeightageDist",
            minWidth: 170,
            headerTooltip: "PHASES WEIGHTAGE (DISTRIBUTION)",
            headerClass: "grid-sor-columns"
        },
        {
            headerName: "EQUIPMENT / ACTIVITY PHASES WEIGHTAGE",
            field: "EquipmentMilestoneWeightage",
            minWidth: 170,
            headerTooltip: "EQUIPMENT / ACTIVITY PHASES WEIGHTAGE",
            headerClass: "grid-sor-columns"
        },
        {
            headerName: "PHASES WEIGHTAGE",
            field: "MilestoneWeightage",
            minWidth: 120,
            headerTooltip: "PHASES WEIGHTAGE",
            headerClass: "grid-sor-columns"
        },
        {
            headerName: "PHASES WEIGHTAGE COMPLETION",
            field: "MilestoneWeightageCompletion",
            minWidth: 170,
            headerTooltip: "PHASES WEIGHTAGE COMPLETION",
            headerClass: "grid-sor-columns"
        },
        {
            headerName: "EQUIPMENT / ACTIVITY COMPLETION %",
            field: "EquipmentCompletion",
            minWidth: 170,
            headerTooltip: "EQUIPMENT / ACTIVITY COMPLETION %",
            headerClass: "grid-sor-columns",
            cellRenderer: function (params) {
                const cellBlank = params.data.totalEquipRowSpan === params.data.EquipRowSpan;
                if (cellBlank) {
                    return (
                        <div className="show-name">{params.value}</div>
                    )
                }
                return;
            },
            rowSpan: (params) => params.data.EquipRowSpan !== undefined ? params.data.EquipRowSpan : 1,
            cellClassRules: {
                "show-cell": (params) => { return params.data.EquipRowSpan !== undefined; }
            }
        },
        {
            headerName: "SERVICE COMPLETION",
            field: "ServiceCompletion",
            minWidth: 120,
            headerTooltip: "SERVICE COMPLETION",
            headerClass: "grid-sor-columns",
            cellRenderer: function (params) {
                const cellBlank = params.data.totalServiceRowSpan === params.data.ServiceRowSpan;
                if (cellBlank) {
                    return (
                        <div className="show-name">{params.value}</div>
                    )
                }
                return;
            },
            rowSpan: (params) => params.data.ServiceRowSpan !== undefined ? params.data.ServiceRowSpan : 1,
            cellClassRules: {
                "show-cell": (params) => { return params.data.ServiceRowSpan !== undefined; }
            },
        },
        {
            headerName: "SERVICE WEIGHTAGE COMPLETION % (OVERALL PROJECT)",
            field: "ServiceWeightageCompletion",
            minWidth: 250,
            headerTooltip: "SERVICE WEIGHTAGE COMPLETION % (OVERALL PROJECT)",
            headerClass: "grid-sor-columns",
            cellRenderer: function (params) {
                const cellBlank = params.data.totalServiceRowSpan === params.data.ServiceRowSpan;
                if (cellBlank) {
                    return (
                        <div className="show-name">{params.value}</div>
                    )
                }
                return;
            },
            rowSpan: (params) => params.data.ServiceRowSpan !== undefined ? params.data.ServiceRowSpan : 1,
            cellClassRules: {
                "show-cell": (params) => { return params.data.ServiceRowSpan !== undefined; }
            },
        },
        {
            headerName: "PHASES COMPLETION %",
            field: "MilestoneCompletion",
            minWidth: 120,
            headerTooltip: "PHASES COMPLETION %",
            headerClass: "grid-sor-columns"
        },
    ];


    useImperativeHandle(ref, () => ({
        funcOnExport: async () => {
            // debugger;
            await handleExportExcel();
        }
    }));

    const handleExportExcel = async () => {
        // debugger;
        console.log("columnDefs ===> ", columnDefs);
        console.log("calculatedFinalData ===> ", filteredResult);
        if (filteredResult?.length > 0) {
            let filename = "Weightage.xlsx";
            let projectDataValue = "WEIGHTAGE " + props.SelectedProject.CustomerName + ", " + props.SelectedProject.Location + " - " + props.SelectedProject.ProjectName;
            projectDataValue = projectDataValue.toString().toUpperCase();
            exportToExcelProjectProgress(filename, "data", filteredResult, projectDataValue, columnDefs, "Weightage");
        }
    }

    // useEffect(() => {
    //     let dashTab4Result = calculatedFinalData?.finalData;
    //     setFilteredResult(dashTab4Result);
    // }, [props.tabNo]);

    return (
        <Container fluid className="main-blank-container">
            <DashboardProjDataContainer
                TabString={"WEIGHTAGE"}
                SelectedProject={props.SelectedProject}
                handleInfoClick={props.handleInfoClick}
            />
            <div id="myGrid" className="ag-theme-alpine agGridContainerDashboard">
                <AgGridReact
                    columnDefs={columnDefs}
                    defaultColDef={defaultColPara}
                    rowData={filteredResult}
                    suppressRowTransform={true}
                    enableBrowserTooltips={true}
                    onGridReady={onGridReady}
                />
            </div>
        </Container>
    )
});

export default DashboardWeightage;
