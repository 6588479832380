import React from "react";
import { Form, Row, Col } from "react-bootstrap";
import Dropzone from "react-dropzone";
import Delete_green from "../../icons/Delete_green.svg";
import Delete from "../../icons/Delete.svg";

function DragDropRender(props) {
  let action = props.action;
  let fieldNameFlag = props.hasOwnProperty("fieldName") ? true : false;
  let disableFlag = props.hasOwnProperty("disabled") ? props.disabled : false;
  let fieldName = props.fieldName ? props.fieldName : "Upload"
  let formats = "Supports: PDF, doc, docx, xls, xlsx, xlsb, jpg, jpeg, png, all PPT formats, all AutoCAD drawing formats Max File Size - upto 5MB";

  let supportedFormats = props.supportedFilesLabel ? props.supportedFilesLabel : formats

  return (
    <Form.Group className="dropzone-fileupload">
      {action !== "View" && !props.val ? (
        <div className="dropZoneContainer">
          <Dropzone
            onDrop={(acceptedFiles) => props.handleChangeEvent(acceptedFiles)}
            multiple={false}
            maxFiles={1}
            disabled={disableFlag}
          >
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps()} className="dropZoneMainDiv">
                <input {...getInputProps()} />
                <div className="dropTextContainer">
                  <Row className="DropBox-row">
                    <span className="semiBoldToggleButton">
                      Upload file here or &nbsp;
                      <span className="dropSelectText">Select a file</span>
                    </span>
                    <span className="dropSelectfiles">
                      {supportedFormats}
                    </span>
                  </Row>
                </div>
              </div>
            )}
          </Dropzone>
        </div>
      ) : (
        <Row>
          {
            fieldNameFlag &&
            <Col lg={2} md={2} xs={2} className="leftAlignedRowData">
              <Form.Label className="fieldLabel">{fieldName}</Form.Label>
            </Col>

          }
          <Col lg={10} md={10} xs={10} className="leftAlignedRowData">
            <Form.Label className="fileupload-name fieldLabel" onClick={() => props.handleDownloadDocEvent(props.val)}>
              {props.val}
              &nbsp;&nbsp;&nbsp;
            </Form.Label>
            <img
              className="action-icon"
              src={Delete}
              alt="delete"
              onClick={() => props.handleDeleteDocEvent(props.val)}
              hidden={action === "View"}
            />
          </Col>
        </Row>
      )}
    </Form.Group>
  );
}

export default DragDropRender;
