export const LOGIN_GODREJ_EMPLOYEE = "Godrej Employee"; // for Login Screen
export const LOGIN_DEALER = "User"; // for Login Screen
export const LOGOUTCONFIRM = "Are you sure you want to LogOut?";

export const ALERT_PARAMS = {
    showDenyButton: true,
    cancelButtonColor: "#fff",
    confirmButtonColor: "#810055",
    reverseButtons: true,
    customClass: "swal-confirmation"
}