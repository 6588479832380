import React, { useEffect, useState } from "react";
import "./StatusRenderer.css";
import correctsign from "../../icons/correctsign.svg";
import wrongsign from "../../icons/wrongsign.svg";
import redwrongsign from "../../icons/redwrongsign.svg";
import inprocesssign from "../../icons/inprocesssign.svg";
import stopsign from "../../icons/stopsign.svg";

export default (props) => {
  let statusInd = "";
  let colorClass = "";
  let statusimage = "";

  if (props.data.hasOwnProperty("DeleteIndication")) {
    statusInd = props.data.DeleteIndication;
    if (props.data.DeleteIndication === "Active") {
      colorClass = "activeStatus";
      statusimage = correctsign;
    }
    else {
      colorClass = "inactiveStatus";
      statusimage = wrongsign;
    }
  }

  if (props.data.hasOwnProperty("DRTStatus")) {
    statusInd = props.data.DRTStatus;
    if (props.data.hasOwnProperty("FreezeInd") && props.data.FreezeInd === "No") {
      colorClass = "activeStatus";
      statusimage = inprocesssign;
    }
    else {
      colorClass = "inactiveStatus";
      statusimage = wrongsign;
    }
  }

  if (props.data.hasOwnProperty("Result")) {
    statusInd = props.data.Result;
    if (props.data.Result === "Accepted") {
      colorClass = "activeStatus";
      statusimage = correctsign;
    }
    else if (props.data.Result === "Rejected") {
      colorClass = "rejectedStatus";
      statusimage = redwrongsign;
    }
  }

  if (props.colDef.field === "DocumentSubmissionStatus" || props.colDef.field === "ReSubmissionStatus" || props.colDef.field === "ApprovalStatus") {
    //debugger;
    statusInd = props.data[props.colDef.field];
    if (statusInd === "Completed") {
      colorClass = "activeStatus";
      statusimage = correctsign;
    }
    else {
      colorClass = "inactiveStatus";
      statusimage = wrongsign;
    }
  }

  if (props.data.hasOwnProperty("ISTStatus")) {
    statusInd = props.data.ISTStatus;
    if (props.data.ISTStatus === "Open") {
      colorClass = "activeStatus";
      statusimage = correctsign;
    }
    else if (props.data.ISTStatus === "Closed") {
      colorClass = "inactiveStatus";
      statusimage = wrongsign;
    }
    else {
      colorClass = "inactiveStatus";
      statusimage = stopsign;
    }
  }

  //let text_clr =  props.data.DeleteIndication === "Active" ? "#3CB23D" : "#575B64";
  // let shadow_clr = props.data.DeleteIndication === "Active" ? "#E9FAE9" : "#EBEBEC";
  // let statusimage = props.data.DeleteIndication === "Active" ? correctsign : wrongsign;
  // let text_clr = StatusArr.includes(StatusInd) ? "#3CB23D" :HoldStatusArr.includes(StatusInd) ? "#0636A5" :DropStatusArr.includes(StatusInd) ? "#E06454" : "#575B64";
  // let shadow_clr = StatusArr.includes(StatusInd) ? "#E9FAE9" :HoldStatusArr.includes(StatusInd) ? "#E6EDFD" :DropStatusArr.includes(StatusInd) ? "#FAE8E6" : "#EBEBEC";
  // let statusimage = StatusArr.includes(StatusInd) ? correctsign :HoldStatusArr.includes(StatusInd) ? holdsign :DropStatusArr.includes(StatusInd) ? droppedsign : wrongsign;

  return (
    <>
      {statusInd !== "" &&
        <div
          className={colorClass + " StatusIndicator"}>
          <img style={{
            width: "16px", height: "17px"
          }} src={statusimage}
            alt="statusimg"
          />&nbsp;
          <span>{statusInd}</span>
          {/* {props.data.DRTStatus ? props.data.DRTStatus : props.data.DeleteIndication} */}
          &nbsp;
        </div>
      }
    </>
  );
};