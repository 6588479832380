import axios from "axios";
const TRANS_URL = process.env.REACT_APP_TRANSACTION_API_URL;


export const generateDocument = async (projdetails, dataObj) => {
  let reqBody = { "projectData": projdetails, "dataObj": dataObj };
  let url = `${TRANS_URL}/projectdocument/getGenerateSingleProjectDocument`;
  const response = await axios
    .post(url, reqBody)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      console.log("error => ", error);
      return { data: { message: "Unable to find request", status: false } };
    });
  return response.data;
};

//get overall document generation deatils 
export async function getOverAllDocumentGenerationData(projectCode) {
  let projObj = { ProjectCode: projectCode }
  let url = `${TRANS_URL}/projectdocument/getoveralldocumentgenerationdata`;
  const response = await axios
    .post(url, projObj)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      console.log("error => ", error);
      return { data: [] };
    });
  return response.data;
}

//get All documents data for project
export async function getAllDocumentsDataForProject(projCode) {
  let paramObj = { ProjectCode: projCode }
  let url = `${TRANS_URL}/projectdocument/getalldocumentsdataforproject`;
  const response = await axios
    .post(url, paramObj)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      console.log("error => ", error);
      return { data: [] };
    });
  return response.data;
}

//---------update dcoument row wise record -----------------------------------//
export async function updateDocumentRowDetails(dataObj) {
  let url = `${TRANS_URL}/projectdocument/updatedocumentrowdetails`;
  const response = await axios
    .post(url, dataObj)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      console.log("error => ", error);
      return { data: { message: "Unable to find request", status: false } };
    });
  return response.data;
}

export async function getRowWiseDocumentData(dataObj) {
  let url = `${TRANS_URL}/projectdocument/getrowwisedocumentdata`;
  const response = await axios
    .post(url, dataObj)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      console.log("error => ", error);
      return { data: [] };
    });
  return response.data;
}

export async function addDocumentData(addObj) {
  let url = `${TRANS_URL}/projectdocument/adddocumentdata`;
  const response = await axios
    .post(url, addObj)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      console.log("error => ", error);
      return { data: { message: "Unable to find request", status: false } };
    });
  return response.data;
}

//--------------Get Project docs tab data -----------------///
export async function getProjDocTabsData(keyObj) {
  //debugger;
  let url = `${TRANS_URL}/projectdocument/getprojdoctabsdata`;
  const response = await axios
    .post(url, keyObj)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      console.log("error => ", error);
      return { data: [] };
    });
  return response.data;
}

//--------------Get Project docs tab Service wise data -----------------///
export async function getProjDocTabServiceWiseData(projectCode, serviceCode, filterTab) {
  let keyObj = {
    projectCode: projectCode,
    serviceCode: serviceCode,
    filterTab: filterTab
  }
  let url = `${TRANS_URL}/projectdocument/getProjDocTabServiceWiseData`;
  const response = await axios
    .post(url, keyObj)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      console.log("error => ", error);
      return { data: [] };
    });
  return response.data;
}