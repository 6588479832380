import React, { useEffect, useState, useContext } from "react";
import { Button, Form, Modal, Row, Col, Spinner } from "react-bootstrap";
import menuList from "../../data/sessionmaster.json";
import "./AddEditRoleMaster.css"
import 'react-toastify/dist/ReactToastify.css';
import { getAllRoles, AddNewRole } from "../../services/role-service";
import { getCurrentDateTime } from "../../functions/gbcs-functions";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import successImage from "../../icons/success-tick-logo.svg";
import errorImage from "../../icons/error-tick-logo.svg";
import { AuthContext } from "../../contexts/AuthContext";
import { getUsersForRole } from "../../services/user-service";
import { addLogDetails } from "../../services/logger-entry-service";
import { ALERT_PARAMS } from "../../utils/constant.js";

const AddEditRoleMaster = (props) => {
    const MySwal = withReactContent(Swal);
    const context = useContext(AuthContext);
    const userDetails = { email: context.userEmail, userName: context.userName, userId: context.userId }

    let action = props.action;
    const menuResult = menuList;
    const [toggleClass, setToggleClass] = useState("activeToggleLabel");
    const [loading, setLoading] = useState(false);

    const [roleObj, setRoleObj] = useState(
        action === "Edit" || action === "View"
            ? props.rowdata
            : {
                RoleName: "",
                RoleCode: "",
                DeleteIndication: "Active",
                DataControl: "Project-wise Data"
            }
    );
    useEffect(() => {
        if (action === "View" || action === "Edit") {
            if (props.rowdata.DeleteIndication === "Active") {
                setToggleClass("activeToggleLabel")
            }
            else {
                setToggleClass("inactiveToggleLabel")
            }
        }
    }, []);
    const [menuJson, setmenuJson] = useState(
        action === "View" || action === "Edit" ? props.rowdata.AuthorizedSessions :
            []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (action == "View") {
            props.onHide();
        }
        else {
            setLoading(true);
            const result1 = await getAllRoles();
            console.log("result1", result1)
            if (result1.length > 0 && result1.filter((item) => (action === "Add" || (action === "Edit" && item.RoleCode.toLowerCase() !== roleObj.RoleCode.toLowerCase())) && item.RoleCode.toLowerCase() === roleObj.RoleCode.toLowerCase()).length > 0) {
                setLoading(false);
                MySwal.fire({
                    text: "Role Code already exists.",
                    imageUrl: errorImage,
                    customClass: "swal-success",
                });
                return;
            }
            if (result1.length > 0 && result1.filter((item) => (action === "Add" || (action === "Edit" && item.RoleCode.toLowerCase() !== roleObj.RoleCode.toLowerCase())) && item.RoleName.toLowerCase() === roleObj.RoleName.toLowerCase()).length > 0) {
                setLoading(false);
                MySwal.fire({
                    text: "Role Name already exists.",
                    imageUrl: errorImage,
                    customClass: "swal-success",
                });
                return;
            }
            if (action == "Edit" && roleObj.DeleteIndication === "Inactive") {
                const userResult = await getUsersForRole(roleObj.RoleCode);
                if (userResult?.length > 0) {
                    console.log("userResult", userResult)
                    let activeUser = userResult.filter((item) => item.DeleteIndication === "Active").length;
                    if (activeUser > 0) {
                        console.log("activeUser", activeUser)
                        setLoading(false);
                        MySwal.fire({
                            text: "Users are already linked to " + roleObj.RoleName + " Role. Thus, selected Role cannot be Deleted.",
                            imageUrl: errorImage,
                            customClass: "swal-success"
                        });
                        return;
                    }
                }
            }
            setLoading(false);
            let confirmBoxText = "";
            let confirmButtonText = "";
            if (action === "Add") {
                confirmBoxText = "Are you sure, do you want to add new role?";
                confirmButtonText = "Add";
            }
            else if (action == "Edit") {
                confirmBoxText = "Are you sure, do you want to update role?";
                confirmButtonText = "Update";
            }

            MySwal.fire({
                text: confirmBoxText,
                confirmButtonText: confirmButtonText,
                title: action + " Role",
                ...ALERT_PARAMS
            }).then(async (result2) => {
                if (result2.isConfirmed) {
                    setLoading(true);
                    if (action === "Add") {
                        let todayDate = getCurrentDateTime("-");
                        roleObj.CreatedBy = userDetails.userId;
                        roleObj.CreatedByName = userDetails.userName;
                        roleObj.CreatedOn = todayDate;
                        roleObj.ModifiedBy = userDetails.userId;
                        roleObj.ModifiedByName = userDetails.userName;
                        roleObj.ModifiedOn = todayDate;
                        roleObj.AuthorizedSessions = menuJson;
                        roleObj.PK = "RoleMaster";
                        roleObj.SK = "RoleCode#" + roleObj.RoleCode;

                    } else if (action == "Edit") {
                        let todayDate = getCurrentDateTime("-");
                        roleObj.AuthorizedSessions = menuJson;
                        roleObj.ModifiedBy = userDetails.userId;
                        roleObj.ModifiedByName = userDetails.userName;
                        roleObj.ModifiedOn = todayDate;
                    }
                    let result = await AddNewRole(roleObj);
                    if (result?.status) {
                        let successMsg = result.message;
                        if (action == "Edit") {
                            successMsg = "Role is updated successfully";
                        }
                        //For logger details 
                        let tempUnique = "SK#" + roleObj.SK;
                        await addLogDetails(roleObj, action, tempUnique, "RoleMasterLog", userDetails, "Master")
                        setLoading(false);
                        MySwal.fire({
                            text: successMsg,
                            imageUrl: successImage,
                            customClass: "swal-success",
                            didClose: () => {
                                props.setreload(!props.reload);
                                props.onHide();
                            },
                        });
                    }
                    else {
                        setLoading(false);
                        MySwal.fire({
                            text: "Unable to Process Request",
                            imageUrl: errorImage,
                            customClass: "swal-success"
                        });
                    }
                }
            });
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        console.log(name, value)
        setRoleObj((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleChangeSelectAll = (e, menu, menuType, parentMenu, i) => {
        let flag = e.target.checked;
        let tempMenuJson = [];
        tempMenuJson = Object.assign([], menuJson)
        let resultMaster = { SessionName: menu, Display: flag, Add: flag, Modify: flag, Delete: flag, Export: flag };
        let result = Object.assign({}, resultMaster);

        const Index = tempMenuJson.findIndex((element) => element.SessionName === menu);
        if (Index !== -1) {
            tempMenuJson.splice(Index, 1);
        }
        if (flag) {
            tempMenuJson.push(result);
        }
        let submenus = {};
        submenus = menuResult.filter(e => e.ParentMenu === menu && e.SubMenu !== "");
        submenus.forEach(element => {
            const SubIndex = tempMenuJson.findIndex((data) => data.SessionName === element.SubMenu);
            let subMenuExistcheck = Object.assign({}, result);
            if (SubIndex !== -1) {
                tempMenuJson.splice(SubIndex, 1);
            }
            subMenuExistcheck["SessionName"] = element.SubMenu;
            if (flag) {
                tempMenuJson.push(subMenuExistcheck);
            }
        });
        console.log("tempMenuJson", tempMenuJson)
        setmenuJson(tempMenuJson);
    }

    const handleChangeCheckbox = (e, menu, menuType, parentMenu, i) => {
        let id = e.currentTarget.id;
        let tempMenuJson = [];
        tempMenuJson = Object.assign([], menuJson)
        let resultMaster = { SessionName: menu, Display: false, Add: false, Modify: false, Delete: false, Export: false };
        let result = Object.assign({}, resultMaster);

        const Index = tempMenuJson.findIndex((element) => element.SessionName === menu);
        if (Index !== -1) {
            result = tempMenuJson.find(element => element.SessionName === menu);
            tempMenuJson.splice(Index, 1);
        }
        result[id] = e.target.checked;
        let menuRecord = menuResult.filter((item) => item[menuType] === menu);
        if (menuRecord[0]?.HandleOnDisplay) {
            result.Display = e.target.checked;
            result.Add = e.target.checked;
            result.Modify = e.target.checked;
            result.Delete = e.target.checked;
            result.Export = e.target.checked;
        }

        if (result.Display || result.Add || result.Modify || result.Delete || result.Export) {
            result.Display = true;
            tempMenuJson.push(result);
        }
        let submenus = {};
        if (menuType === "ParentMenu") {
            submenus = menuResult.filter(e => e.ParentMenu === menu && e.SubMenu !== "");
            submenus.forEach(element => {
                const SubIndex = tempMenuJson.findIndex((data) => data.SessionName === element.SubMenu);
                let subMenuExistcheck = Object.assign({}, resultMaster);
                if (SubIndex !== -1) {
                    subMenuExistcheck = tempMenuJson.find(record => record.SessionName === element.SubMenu);
                    tempMenuJson.splice(SubIndex, 1);
                }
                subMenuExistcheck["SessionName"] = element.SubMenu;
                if (element.HandleOnDisplay) {
                    if (id === "Display") {
                        subMenuExistcheck.Display = e.target.checked;
                        subMenuExistcheck.Add = e.target.checked;
                        subMenuExistcheck.Modify = e.target.checked;
                        subMenuExistcheck.Delete = e.target.checked;
                        subMenuExistcheck.Export = e.target.checked;
                    }
                }
                else {
                    subMenuExistcheck[id] = e.target.checked;
                }
                if (subMenuExistcheck.Display || subMenuExistcheck.Add || subMenuExistcheck.Modify || subMenuExistcheck.Delete || subMenuExistcheck.Export) {
                    subMenuExistcheck.Display = true;
                    tempMenuJson.push(subMenuExistcheck);
                }
            });
        }

        if (menuType === "SubMenu" && !e.target.checked) {
            let parentMenuRecord = Object.assign({}, resultMaster);
            parentMenuRecord.SessionName = menu;
            const ParIndex = tempMenuJson.findIndex((data) => data.SessionName === parentMenu);
            if (ParIndex !== -1) {
                parentMenuRecord = tempMenuJson.find(item => item.SessionName === parentMenu);
                tempMenuJson.splice(ParIndex, 1);
            }
            parentMenuRecord[id] = e.target.checked;
            if (parentMenuRecord.Display || parentMenuRecord.Add || parentMenuRecord.Modify || parentMenuRecord.Delete || parentMenuRecord.Export) {
                tempMenuJson.push(parentMenuRecord);
            }
        }

        if (menuType === "SubMenu") {
            submenus = menuResult.filter(e => e.ParentMenu === parentMenu && e.SubMenu !== "");
            let count = 0;
            submenus.forEach(element => {
                const ind = tempMenuJson.findIndex((data) => data.SessionName === element.SubMenu);
                if (ind !== -1 && tempMenuJson.filter(e => e.SessionName === element.SubMenu)[0][id]) {
                    count++;
                }
            });
            if (count === submenus.length) {
                let parentMenuRecord = Object.assign({}, resultMaster);
                parentMenuRecord.SessionName = parentMenu;
                const ParIndex = tempMenuJson.findIndex((data) => data.SessionName === parentMenu);
                if (ParIndex !== -1) {
                    parentMenuRecord = tempMenuJson.find(item => item.SessionName === parentMenu);
                    tempMenuJson.splice(ParIndex, 1);
                }
                parentMenuRecord[id] = e.target.checked;
                if (parentMenuRecord.Display || parentMenuRecord.Add || parentMenuRecord.Modify || parentMenuRecord.Delete || parentMenuRecord.Export) {
                    parentMenuRecord.Display = e.target.checked;
                    tempMenuJson.push(parentMenuRecord);
                }
            }
        }
        setmenuJson(tempMenuJson);
        console.log("tempMenuJson at the end - ", tempMenuJson)
    }

    const handleCancel = () => {
        props.onHide();
    }

    const handleReset = () => {
        if (action === "Add") {
            setRoleObj((prevState) => ({
                ...prevState,
                RoleCode: "",
                RoleName: "",
                DeleteIndication: "Active",
                DataControl: "Project-wise Data"
            }));
            setmenuJson([]);
            setToggleClass("activeToggleLabel")
        }
    }

    const handleSwitchChange = (e) => {
        if (e.target.checked) {
            setToggleClass("activeToggleLabel")
        } else {
            setToggleClass("inactiveToggleLabel")
        }

        setRoleObj((prevState) => ({
            ...prevState,
            DeleteIndication: e.target.checked ? "Active" : "Inactive",
        }));
    };

    return (
        <>
            {loading && <div className="spinner-box"> <Spinner animation="border" className="spinner" /></div>}
            <Modal centered
                {...props}
                size="lg"
                backdrop="static"
            >
                <Modal.Header closeButton>
                    <Modal.Title className="modalTitle">
                        {action} Role
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form
                        id="rolemaster"
                        name="rolemaster"
                        onSubmit={handleSubmit}
                        autoComplete="off"
                    >
                        <Row >
                            <Col md={4} sm={12}>
                                <Form.Group className="g-input">
                                    <Form.Control
                                        id="RoleCode"
                                        type="text"
                                        name="RoleCode"
                                        maxLength="50"
                                        placeholder=" "
                                        required
                                        value={roleObj.RoleCode}
                                        onChange={handleChange}
                                        readOnly={action === "View" || action === "Edit" ? true : false}
                                    />
                                    <Form.Label className="frm-label" for="RoleCode">Role Code</Form.Label>
                                </Form.Group>
                            </Col>
                            <Col md={4} sm={12}>
                                <Form.Group className="g-input">
                                    <Form.Control
                                        id="RoleName"
                                        type="text"
                                        name="RoleName"
                                        maxLength="100"
                                        placeholder=" "
                                        required
                                        value={roleObj.RoleName}
                                        onChange={handleChange}
                                        readOnly={action === "View" ? true : false} />
                                    <Form.Label className="frm-label" for="RoleName">Role Name</Form.Label>
                                </Form.Group>
                            </Col>
                            <Col md={4} sm={12}>
                                <Row>
                                    {/* <Form.Group className="form-switch-input"> */}
                                    <Col>
                                        <Form.Label className="fieldLabel">Status</Form.Label>
                                        {/* <Col> <div class="g-input"></div>  <label style={{ fontSize: "14px", padding: "10px", color: "#6F6F70", fontWeight: "bold" }} >Status </label></Col> */}
                                    </Col>
                                    <Col>
                                        <Form.Switch className={"semiBoldToggleButton " + toggleClass}
                                            label={roleObj.DeleteIndication === "Active" ? "Active" : "Inactive"}
                                            name="DeleteIndication"
                                            // style={{ padding: "10px",color: "#428F30" }}
                                            checked={roleObj.DeleteIndication === "Active" ? true : false}
                                            onChange={(e) => {
                                                handleSwitchChange(e);
                                            }}
                                            disabled={action === "View" ? true : false}
                                        />
                                    </Col>
                                    {/* </Form.Group> */}
                                </Row>
                            </Col>
                            {action === "View" ? (
                                <Col md={12} xs={12}>
                                    <Row>
                                        <Col md={2} xs={6}><Form.Label className="fieldLabel">Data Control</Form.Label></Col>
                                        <Col md={5} xs={6}><Form.Label className="fieldLabel semiBoldToggleButton">{roleObj.DataControl}</Form.Label></Col>
                                    </Row>
                                </Col>
                            ) : (
                                <Col md={12} xs={12}>
                                    <Row>
                                        <Col md={2} xs={12}>
                                            <Form.Label className="fieldLabel locationType">Data Control</Form.Label>
                                        </Col>
                                        <Col md={3} xs={6}>
                                            <Form.Check className="fieldLabel locationType"
                                                type="radio"
                                                name="DataControl"
                                                checked={roleObj.DataControl === "Project-wise Data" ? true : false}
                                                onChange={handleChange}
                                                label="Project-wise Data"
                                                value="Project-wise Data"
                                            ></Form.Check>
                                        </Col>
                                        <Col md={3} xs={6}>
                                            <Form.Check className="fieldLabel locationType"
                                                type="radio"
                                                name="DataControl"
                                                checked={roleObj.DataControl === "All Projects Data" ? true : false}
                                                onChange={handleChange}
                                                label="All Projects Data"
                                                value="All Projects Data"
                                            ></Form.Check>
                                        </Col>
                                    </Row>
                                </Col>
                            )}
                        </Row>
                        <Row>
                            <span className="sectionTitle" >Role Authorization <span className="italicFont">( Optional )</span></span>
                        </Row>
                        <div className="tabledataformat d-none d-md-block d-lg-block">
                            <Row className="text-size" >
                                <Col xs={4} className="menuheadCol" >Session/Sub-session</Col>
                                <Col>Display</Col>
                                <Col >Add</Col>
                                <Col>Modify</Col>
                                <Col >Delete</Col>
                                <Col >Export</Col>
                            </Row>
                            {
                                menuResult?.map((data, index) => {
                                    return (
                                        <Row className={data.SubMenu === '' ? "parentMenuRow" : "subMenuRow"} >
                                            <Col xs={4} className="menuheadCol">
                                                {data.SubMenu === '' ? (<span>{data.ParentMenu} <span className="italicFont">(Select All)</span> </span>) : data.SubMenu}
                                            </Col>
                                            <Col ><Form.Check
                                                type="checkbox"
                                                id="Display"
                                                name={data.ParentMenu + index}
                                                checked={
                                                    (menuJson.filter(e => e.SessionName === (data.SubMenu === '' ? data.ParentMenu : data.SubMenu)).length <= 0) ? false : menuJson.filter(e => e.SessionName === (data.SubMenu === '' ? data.ParentMenu : data.SubMenu))[0].Display
                                                }
                                                onChange={(e) => {
                                                    handleChangeCheckbox(e, data.SubMenu === '' ? data.ParentMenu : data.SubMenu, data.SubMenu === '' ? "ParentMenu" : "SubMenu", data.ParentMenu, index);
                                                }}
                                                disabled={action === "View" ? true : false}
                                            />
                                            </Col>
                                            <Col >
                                                <Form.Check
                                                    type="checkbox"
                                                    id="Add"
                                                    name={data.ParentMenu + index}
                                                    checked={
                                                        (menuJson.filter(e => e.SessionName === (data.SubMenu === '' ? data.ParentMenu : data.SubMenu)).length <= 0) ? false : menuJson.filter(e => e.SessionName === (data.SubMenu === '' ? data.ParentMenu : data.SubMenu))[0].Add
                                                    }
                                                    onChange={(e) => {
                                                        handleChangeCheckbox(e, data.SubMenu === '' ? data.ParentMenu : data.SubMenu, data.SubMenu === '' ? "ParentMenu" : "SubMenu", data.ParentMenu, index);
                                                    }}
                                                    disabled={action === "View" || data.HandleOnDisplay ? true : false}
                                                />
                                            </Col>
                                            <Col > <Form.Check
                                                type="checkbox"
                                                id="Modify"
                                                name={data.ParentMenu + index}
                                                checked={
                                                    (menuJson.filter(e => e.SessionName === (data.SubMenu === '' ? data.ParentMenu : data.SubMenu)).length <= 0) ? false : menuJson.filter(e => e.SessionName === (data.SubMenu === '' ? data.ParentMenu : data.SubMenu))[0].Modify
                                                }
                                                onChange={(e) => {
                                                    handleChangeCheckbox(e, data.SubMenu === '' ? data.ParentMenu : data.SubMenu, data.SubMenu === '' ? "ParentMenu" : "SubMenu", data.ParentMenu, index);
                                                }}
                                                disabled={action === "View" || data.HandleOnDisplay ? true : false}
                                            />
                                            </Col>
                                            <Col > <Form.Check
                                                type="checkbox"
                                                id="Delete"
                                                name={data.ParentMenu + index}
                                                checked={
                                                    (menuJson.filter(e => e.SessionName === (data.SubMenu === '' ? data.ParentMenu : data.SubMenu)).length <= 0) ? false : menuJson.filter(e => e.SessionName === (data.SubMenu === '' ? data.ParentMenu : data.SubMenu))[0].Delete
                                                }
                                                onChange={(e) => {
                                                    handleChangeCheckbox(e, data.SubMenu === '' ? data.ParentMenu : data.SubMenu, data.SubMenu === '' ? "ParentMenu" : "SubMenu", data.ParentMenu, index);
                                                }}
                                                disabled={action === "View" || data.HandleOnDisplay ? true : false}
                                            />
                                            </Col>
                                            <Col > <Form.Check
                                                type="checkbox"
                                                id="Export"
                                                name={data.ParentMenu + index}
                                                checked={
                                                    (menuJson.filter(e => e.SessionName === (data.SubMenu === '' ? data.ParentMenu : data.SubMenu)).length <= 0) ? false : menuJson.filter(e => e.SessionName === (data.SubMenu === '' ? data.ParentMenu : data.SubMenu))[0].Export
                                                }
                                                onChange={(e) => {
                                                    handleChangeCheckbox(e, data.SubMenu === '' ? data.ParentMenu : data.SubMenu, data.SubMenu === '' ? "ParentMenu" : "SubMenu", data.ParentMenu, index);
                                                }}
                                                disabled={action === "View" || data.HandleOnDisplay ? true : false}
                                            />
                                            </Col>
                                        </Row>

                                    )
                                })

                            }
                        </div>
                        <div className="tabledataformat d-md-none d-lg-none">
                            {
                                menuResult?.map((data, index) => {
                                    return (
                                        <>
                                            {data.SubMenu === '' ?
                                                <>
                                                    <Row className="parentMenuRowMob">
                                                        <Col lg={2} md={2} xs={7} className="menuheadCol">
                                                            {data.ParentMenu}
                                                        </Col>
                                                        <Col lg={7} md={7} xs={4} className="mob-selectall">
                                                            Select All
                                                        </Col>
                                                        <Col lg={2} md={2} xs={1} className="mob-selectall-check">
                                                            <Form.Check
                                                                type="checkbox"
                                                                id="SelectAll"
                                                                name={data.ParentMenu + index}
                                                                checked={(menuJson.filter(e => e.SessionName === (data.SubMenu === '' ? data.ParentMenu : data.SubMenu)).length <= 0) ? false : menuJson.filter(e => e.SessionName === (data.SubMenu === '' ? data.ParentMenu : data.SubMenu))[0].Display}
                                                                onChange={(e) => {
                                                                    handleChangeSelectAll(e, data.SubMenu === '' ? data.ParentMenu : data.SubMenu, data.SubMenu === '' ? "ParentMenu" : "SubMenu", data.ParentMenu, index);
                                                                }}
                                                                disabled={action === "View" ? true : false}
                                                            />
                                                        </Col>

                                                    </Row>
                                                    <Row className="parentCheckBoxRowMob d-none">
                                                        <Col xs={1}>
                                                            <Form.Check
                                                                type="checkbox"
                                                                id="Display"
                                                                name={data.ParentMenu + index}
                                                                checked={
                                                                    (menuJson.filter(e => e.SessionName === (data.SubMenu === '' ? data.ParentMenu : data.SubMenu)).length <= 0) ? false : menuJson.filter(e => e.SessionName === (data.SubMenu === '' ? data.ParentMenu : data.SubMenu))[0].Display
                                                                }
                                                                onChange={(e) => {
                                                                    handleChangeCheckbox(e, data.SubMenu === '' ? data.ParentMenu : data.SubMenu, data.SubMenu === '' ? "ParentMenu" : "SubMenu", data.ParentMenu, index);
                                                                }}
                                                                disabled={action === "View" ? true : false}
                                                            />
                                                        </Col>
                                                        <Col xs={3}> Display
                                                        </Col>
                                                        <Col xs={1}>
                                                            <Form.Check
                                                                type="checkbox"
                                                                id="Add"
                                                                name={data.ParentMenu + index}
                                                                checked={
                                                                    (menuJson.filter(e => e.SessionName === (data.SubMenu === '' ? data.ParentMenu : data.SubMenu)).length <= 0) ? false : menuJson.filter(e => e.SessionName === (data.SubMenu === '' ? data.ParentMenu : data.SubMenu))[0].Add
                                                                }
                                                                onChange={(e) => {
                                                                    handleChangeCheckbox(e, data.SubMenu === '' ? data.ParentMenu : data.SubMenu, data.SubMenu === '' ? "ParentMenu" : "SubMenu", data.ParentMenu, index);
                                                                }}
                                                                disabled={action === "View" || data.HandleOnDisplay ? true : false}
                                                            />
                                                        </Col>
                                                        <Col xs={3}>Add
                                                        </Col>
                                                        <Col xs={1}>
                                                            <Form.Check
                                                                type="checkbox"
                                                                id="Modify"
                                                                name={data.ParentMenu + index}
                                                                checked={
                                                                    (menuJson.filter(e => e.SessionName === (data.SubMenu === '' ? data.ParentMenu : data.SubMenu)).length <= 0) ? false : menuJson.filter(e => e.SessionName === (data.SubMenu === '' ? data.ParentMenu : data.SubMenu))[0].Modify
                                                                }
                                                                onChange={(e) => {
                                                                    handleChangeCheckbox(e, data.SubMenu === '' ? data.ParentMenu : data.SubMenu, data.SubMenu === '' ? "ParentMenu" : "SubMenu", data.ParentMenu, index);
                                                                }}
                                                                disabled={action === "View" || data.HandleOnDisplay ? true : false}
                                                            />
                                                        </Col>
                                                        <Col xs={3}>Modify
                                                        </Col>
                                                        <Col xs={1}>
                                                            <Form.Check
                                                                type="checkbox"
                                                                id="Delete"
                                                                name={data.ParentMenu + index}
                                                                checked={
                                                                    (menuJson.filter(e => e.SessionName === (data.SubMenu === '' ? data.ParentMenu : data.SubMenu)).length <= 0) ? false : menuJson.filter(e => e.SessionName === (data.SubMenu === '' ? data.ParentMenu : data.SubMenu))[0].Delete
                                                                }
                                                                onChange={(e) => {
                                                                    handleChangeCheckbox(e, data.SubMenu === '' ? data.ParentMenu : data.SubMenu, data.SubMenu === '' ? "ParentMenu" : "SubMenu", data.ParentMenu, index);
                                                                }}
                                                                disabled={action === "View" || data.HandleOnDisplay ? true : false}
                                                            />
                                                        </Col>
                                                        <Col xs={3}>Delete
                                                        </Col>
                                                        <Col xs={1}>
                                                            <Form.Check
                                                                type="checkbox"
                                                                id="Export"
                                                                name={data.ParentMenu + index}
                                                                checked={
                                                                    (menuJson.filter(e => e.SessionName === (data.SubMenu === '' ? data.ParentMenu : data.SubMenu)).length <= 0) ? false : menuJson.filter(e => e.SessionName === (data.SubMenu === '' ? data.ParentMenu : data.SubMenu))[0].Export
                                                                }
                                                                onChange={(e) => {
                                                                    handleChangeCheckbox(e, data.SubMenu === '' ? data.ParentMenu : data.SubMenu, data.SubMenu === '' ? "ParentMenu" : "SubMenu", data.ParentMenu, index);
                                                                }}
                                                                disabled={action === "View" || data.HandleOnDisplay ? true : false}
                                                            />
                                                        </Col>
                                                        <Col xs={3}>Export
                                                        </Col>
                                                    </Row>
                                                </>
                                                :
                                                <div>
                                                    <Row className={data.SubMenu === '' ? "parentMenuRowMob" : "subMenuRowMob"}>
                                                        <Col className="menuheadCol">
                                                            {data.SubMenu}
                                                        </Col>
                                                    </Row>
                                                    <Row className="ckeckbox-row-mob">
                                                        <Col xs={1}>
                                                            <Form.Check
                                                                type="checkbox"
                                                                id="Display"
                                                                name={data.ParentMenu + index}
                                                                checked={
                                                                    (menuJson.filter(e => e.SessionName === (data.SubMenu === '' ? data.ParentMenu : data.SubMenu)).length <= 0) ? false : menuJson.filter(e => e.SessionName === (data.SubMenu === '' ? data.ParentMenu : data.SubMenu))[0].Display
                                                                }
                                                                onChange={(e) => {
                                                                    handleChangeCheckbox(e, data.SubMenu === '' ? data.ParentMenu : data.SubMenu, data.SubMenu === '' ? "ParentMenu" : "SubMenu", data.ParentMenu, index);
                                                                }}
                                                                disabled={action === "View" ? true : false}
                                                            />
                                                        </Col>
                                                        <Col xs={3}> Display
                                                        </Col>
                                                        <Col xs={1}>
                                                            <Form.Check
                                                                type="checkbox"
                                                                id="Add"
                                                                name={data.ParentMenu + index}
                                                                checked={
                                                                    (menuJson.filter(e => e.SessionName === (data.SubMenu === '' ? data.ParentMenu : data.SubMenu)).length <= 0) ? false : menuJson.filter(e => e.SessionName === (data.SubMenu === '' ? data.ParentMenu : data.SubMenu))[0].Add
                                                                }
                                                                onChange={(e) => {
                                                                    handleChangeCheckbox(e, data.SubMenu === '' ? data.ParentMenu : data.SubMenu, data.SubMenu === '' ? "ParentMenu" : "SubMenu", data.ParentMenu, index);
                                                                }}
                                                                disabled={action === "View" || data.HandleOnDisplay ? true : false}
                                                            />
                                                        </Col>
                                                        <Col xs={3}>Add
                                                        </Col>
                                                        <Col xs={1}>
                                                            <Form.Check
                                                                type="checkbox"
                                                                id="Modify"
                                                                name={data.ParentMenu + index}
                                                                checked={
                                                                    (menuJson.filter(e => e.SessionName === (data.SubMenu === '' ? data.ParentMenu : data.SubMenu)).length <= 0) ? false : menuJson.filter(e => e.SessionName === (data.SubMenu === '' ? data.ParentMenu : data.SubMenu))[0].Modify
                                                                }
                                                                onChange={(e) => {
                                                                    handleChangeCheckbox(e, data.SubMenu === '' ? data.ParentMenu : data.SubMenu, data.SubMenu === '' ? "ParentMenu" : "SubMenu", data.ParentMenu, index);
                                                                }}
                                                                disabled={action === "View" || data.HandleOnDisplay ? true : false}
                                                            />
                                                        </Col>
                                                        <Col xs={3}>Modify
                                                        </Col>
                                                        <Col xs={1}>
                                                            <Form.Check
                                                                type="checkbox"
                                                                id="Delete"
                                                                name={data.ParentMenu + index}
                                                                checked={
                                                                    (menuJson.filter(e => e.SessionName === (data.SubMenu === '' ? data.ParentMenu : data.SubMenu)).length <= 0) ? false : menuJson.filter(e => e.SessionName === (data.SubMenu === '' ? data.ParentMenu : data.SubMenu))[0].Delete
                                                                }
                                                                onChange={(e) => {
                                                                    handleChangeCheckbox(e, data.SubMenu === '' ? data.ParentMenu : data.SubMenu, data.SubMenu === '' ? "ParentMenu" : "SubMenu", data.ParentMenu, index);
                                                                }}
                                                                disabled={action === "View" || data.HandleOnDisplay ? true : false}
                                                            />
                                                        </Col>
                                                        <Col xs={3}>Delete
                                                        </Col>
                                                        <Col xs={1}>
                                                            <Form.Check
                                                                type="checkbox"
                                                                id="Export"
                                                                name={data.ParentMenu + index}
                                                                checked={
                                                                    (menuJson.filter(e => e.SessionName === (data.SubMenu === '' ? data.ParentMenu : data.SubMenu)).length <= 0) ? false : menuJson.filter(e => e.SessionName === (data.SubMenu === '' ? data.ParentMenu : data.SubMenu))[0].Export
                                                                }
                                                                onChange={(e) => {
                                                                    handleChangeCheckbox(e, data.SubMenu === '' ? data.ParentMenu : data.SubMenu, data.SubMenu === '' ? "ParentMenu" : "SubMenu", data.ParentMenu, index);
                                                                }}
                                                                disabled={action === "View" || data.HandleOnDisplay ? true : false}
                                                            />
                                                        </Col>
                                                        <Col xs={3}>Export
                                                        </Col>
                                                    </Row>
                                                </div>
                                            }
                                        </>
                                    )
                                })}
                        </div>
                        <br />
                        <div className="tabledataformat">
                            {action !== "View" ?
                                <Row className="remark">
                                    <Col md={12}>
                                        <span ><b>Remark</b></span>
                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                        <span>List of all sessions with checkbox for selection </span>
                                    </Col>
                                </Row>
                                : <></>
                            }
                            <br />
                            <Row >
                                <Col md={9} xs={6}><Row>
                                    <Col md={3} xs={12}>{action === "View" || action === "Edit" ? null :
                                        <Button className="semiBoldToggleButton cancelBtn"
                                            variant="outline-success"
                                            //onClick={props.onHide}
                                            onClick={handleCancel}
                                        >
                                            Cancel
                                        </Button>
                                    }
                                    </Col>
                                    <Col xs={12} md={6}>{action === "View" || action === "Edit" ? <></> :
                                        <Button className="resetBtn semiBoldToggleButton"
                                            variant="link"
                                            onClick={handleReset}
                                        >Reset Fields
                                        </Button>
                                    }
                                    </Col>
                                </Row>
                                </Col>
                                <Col md={3} xs={6}>
                                    {action === "View" ?
                                        <Button variant="success" className="btn-add-color semiBoldToggleButton rightAlignedButton" type="submit">
                                            Done
                                        </Button>
                                        :
                                        <Button variant="success" className="btn-add-color semiBoldToggleButton rightAlignedButton" type="submit">
                                            {action === "Edit" ? "Update" : "Add"}
                                        </Button>
                                    }
                                </Col>
                            </Row>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal >
        </>
    );
}

export default AddEditRoleMaster;
