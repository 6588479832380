export function validDecimals(e) {
  if (e.indexOf(".") >= 0) {
    let n = e.split(".");
    if (n[1].length > 2) {
      return { status: true, message: "Enter only 2 digits after decimal" };
    } else return { status: false, message: "" };
  }
  return { status: false, message: "" };
}

export function validINRCurrency(e) {
  if (e != null) {
    let str = e.toString().split(".");
    if (str[0].length >= 4) {
      str[0] = str[0].replace(/\B(?=(?:(\d\d)+(\d)(?!\d))+(?!\d))/g, ",");
    }
    if (str[1] && str[1].length >= 5) {
      str[1] = str[1].replace(/\B(?=(?:(\d\d)+(\d)(?!\d))+(?!\d))/g, ",");
    }
    return str.join(".");
  } else {
    return "";
  }

  //return { value: z, status: false, message: "" };
}

export function getFormatedAmount(amount, currency) {
  if (currency == "USD") {
    return validUSDCurrency(amount);
  }
  else {
    return validINRCurrency(amount);
  }

}

export function validUSDCurrency(e) {
  if (e != null) {
    let str = e.toString().split(".");
    if (str[0].length >= 4) {
      str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, "$1,");
    }
    if (str[1] && str[1].length >= 5) {
      str[1] = str[1].replace(/(\d{3})/g, "$1 ");
    }
    console.log(str.join("."));
    return str.join(".");
  } else {
    return "";
  }
}

export function validDecimalDigits(e) {
  if (e.indexOf(".") >= 0) {
    let n = e.split(".");
    if (n[1].length > 4) {
      return { status: true, message: "Enter only 4 digits after decimal" };
    } else return { status: false, message: "" };
  }
  return { status: false, message: "" };
}

export function checkNull(e) {
  if (!e) {
    return { status: true, message: "" };
  } else return { status: false, message: "" };
}

export function isAlphanumeric(e) {
  let regExp = /^[A-Za-z0-9]*$/;
  if (!e.match(regExp))
    return { status: true, message: "Special Character not allowed" };
  else return { status: false, message: "" };
}
export function isAlpha(e) {
  let regExp = /^[A-Za-z]*$/;
  if (!e.match(regExp))
    return {
      status: true,
      message: "Numbers and Special Character not allowed",
    };
  else return { status: false, message: "" };
}

export function isAlphanumericWithSpace(e) {
  let regExp = /^[A-Za-z0-9\s]*$/;
  if (!e.match(regExp))
    return { status: true, message: "Special Character not allowed" };
  else return { status: false, message: "" };
}
export function isAlphanumericWithSlash(e) {
  let regExp = /^[0-9\sa-zA-Z/°!@#$%^&*()_./-]+$/;
  if (!e.match(regExp))
    return { status: true, message: "Special Character not allowed" };
  else return { status: false, message: "" };
}
export function isAlphanumericWithSpacehyphenUs(e) {
  let regExp = /^[A-Za-z0-9\s\-\_]*$/;
  if (!e.match(regExp))
    return { status: true, message: "Special Character not allowed" };
  else return { status: false, message: "" };
}

export function isAlphanumericWithAtRate(e) {
  let regExp = /^[A-Za-z0-9@.]*$/;
  if (!e.match(regExp))
    return { status: true, message: "Special Character not allowed" };
  else return { status: false, message: "" };
}

export function isAlphanumericWithDot(e) {
  let regExp = /^[A-Za-z0-9.]*$/;
  if (!e.match(regExp))
    return { status: true, message: "Special Character not allowed" };
  else return { status: false, message: "" };
}

export function isAlphanumericWeb(e) {
  let regExp = /^([\w,:,@,.,//,\s/]*)$/;
  if (!e.match(regExp))
    return {
      status: true,
      message: "Alphanumeric with only (@ . : /) special characters allowed",
    };
  else return { status: false, message: "" };
}

export function isAlphanumericWithSpaceDash(e) {
  let regExp = /^[A-Za-z0-9\s-.()/]*$/;
  if (!e.match(regExp))
    return { status: true, message: "Special Character not allowed" };
  else return { status: false, message: "" };
}
export function isAlphanumericWithSpecialChars(e) {
  let regExp = /^[A-Za-z0-9\s- .()/|,!#_;@$%*&:\[\]]*$/;
  if (!e.match(regExp))
    return { status: true, message: "Special Characters not allowed" };
  else return { status: false, message: "" };
}

export function isNumber(e) {
  let regExp = /^[0-9\s+]*$/;
  if (!e.match(regExp))
    return { status: true, message: "Enter only Positive digits" };
  else return { status: false, message: "" };
}

export function isFutureDate(e) {
  if (new Date(e) > new Date()) {
    return { status: true, message: "Future date is not allowed" };
  }
  else return { status: false, message: "" };
}

export function isNumbernrange(e) {
  let regExp = /^[0-9\s+]*$/;
  if (!e.match(regExp))
    return { status: true, message: "Enter only Positive digits" };
  else if (e < 1 || e > 366)
    return { status: true, message: "Enter within range i.e., 1-366" };
  else return { status: false, message: "" };
}

export function number24(e) {
  let regExp = /^((?:[0-9]|1[0-9]|2[0-3])(?:\.\d{1,2})?|24(?:\.00?)?)$/;
  if (!e.match(regExp))
    return { status: true, message: "Enter Value between 0 - 24" };
  else return { status: false, message: "" };
}

export function isMobNumber(e) {
  // let regExp = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;
  let regExp = /^[\+][0-9]+[\s][0-9]+$/
  if (!e.match(regExp))
    return true;
  else
    return false;
}

export function isNumberWithDecimal(e) {
  // let regExp = /^\d+(\.\d)?\d*$/;
  let regExp = /^[0-9.]*$/;
  if (!e.match(regExp)) return { status: true, message: "Enter only digits" };
  else return { status: false, message: "" };
}
export function isNumberWithDecimalPM(e) {
  // let regExp = /^\d+(\.\d)?\d*$/;
  let regExp = /^[0-9.,]*$/;
  if (!e.match(regExp)) return { status: true, message: "Enter only digits" };
  else return { status: false, message: "" };
}

export function isEmail(e) {
  let regExp = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;

  if (!e.match(regExp))
    return { status: true, message: "Enter valid Email Id" };
  else return { status: false, message: "" };
}


export function isAlphanumericWithDotHyphenUnderscore(e) {
  let regExp = /^[A-Za-z0-9._-]*$/;
  if (!e.match(regExp))
    return { status: true, message: "Special Character not allowed" };
  else
    return { status: false, message: "" };
}

export function dateDiffInMins(date1, date2) {
  let Dt1 = new Date(date1);
  let Dt2 = new Date(date2);
  const difference = Dt2.getTime() - Dt1.getTime();
  const minutes = Math.ceil(difference / 1000 / 60);
  return minutes;
};