import React, { useState, useRef, useContext, useEffect } from "react";
import { Tabs, Tab, Button, Container, Col, Form, Row, Spinner } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { menuActions } from "../../store/menu-slice";
import BreadcrumbComp from "../layouts/BreadcrumbComp";
import ProjectDocumentGeneration from "./ProjectDocumentGeneration";
import AdditionalInformation from "./ProjectDocumentAdditionalInformation";
import { BiSelectMultiple } from "react-icons/bi";
import SingleValuePopUp from "../utils/SingleValuePopUp";
import { formatDateInDDMMYYYY, getTimeAMPMFormat, UserNameInitials, RenderIf } from "../../functions/gbcs-functions";
import { getProjectActiveServiceEqui } from "../../services/project-service.js";
import { getSelectedEquipDetails } from "../../services/equipmentactivity-master-service.js";
import { ServiceMasterData, getEquipmentParameterLimitData } from "../../services/common-master-service.js";
import { getOverAllDocumentGenerationData } from "../../services/project-document-service";
import TabButtons from "../utils/TabButtons";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import errorImage from "../../icons/error-tick-logo.svg";
import ProjectDocumentParam from "./ProjectDocumentParam";

const ProjectDocuments = () => {
    const dispatch = useDispatch();
    dispatch(menuActions.setMenu("Project Documents"));
    const MySwal = withReactContent(Swal);
    const [loading, setLoading] = useState(false);
    const [currentTab, setCurrentTab] = useState(0);
    const [SelectedProject, setSelectedProject] = useState();
    const [isSubmitClicked, setIsSubmitClicked] = useState(false);
    const [OverAllGenerated, setOverAllGenerated] = useState([]);
    const [showPopUpForm, setshowPopUpForm] = useState(false);
    const [projectServiceDetails, setProjectServiceDetails] = useState([]);
    const [updateGenerateAllInfo, setUpdateGenerateAllInfo] = useState(true);

    const [PrerequisiteLimitVal, setPrerequisiteLimitVal] = useState(0);
    const [InstrumentsLimitVal, setInstrumentsLimitVal] = useState(0);
    const [TestingLimitVal, setTestingLimitVal] = useState(0);
    const [AddInfoLimitVal, setAddInfoLimitVal] = useState(0);

    let tab0Ref = useRef();

    const [popUp, setPopUp] = useState({
        name: "",
        type: "",
        key: "",
        value: "",
    });

    const showPopUp = (name, type, key, value, additionalParams) => {
        setPopUp({ name, type, key, value, additionalParams });
        setshowPopUpForm(true);
    };

    const setPopUpData = async (data) => {
        setSelectedProject(data.result);
        setIsSubmitClicked(false);
    }

    useEffect(() => {
        fetchLimitData();
    }, []);

    const handleSubmit = async () => {
        setLoading(true);
        const [result, srvcresult] = await Promise.all([
            getOverAllDocumentGenerationData(SelectedProject.ProjectCode),
            getProjectActiveServiceEqui(SelectedProject.ProjectCode)
        ]);

        if (result.length > 0) {
            setOverAllGenerated(result[0]);
        }
        let finalData = [];

        if (srvcresult.length > 0) {
            let arr = [];
            for (let element of srvcresult) {
                if (arr.indexOf(element.EquipmentCode) === -1) {
                    arr.push(element.EquipmentCode)
                }
            }
            const [serviceResult, equipMasterResult] = await Promise.all([
                ServiceMasterData(),
                getSelectedEquipDetails(arr)
            ]);
            for (let element of srvcresult) {
                let temp = equipMasterResult.find(x => x.EquipmentCode === element.EquipmentCode);
                let obj = {
                    ServiceType: temp?.ServiceType,
                    ServiceCode: element.ServiceCode,
                    ServiceName: element.ServiceName,
                    EquipmentCode: element.EquipmentCode,
                    EquipmentName: temp ? temp.EquipmentName : element.EquipmentName,
                    EquipmentTag: element.EquipmentTag,
                    MethodStatement: temp ? temp.MethodStatement : element.MethodStatement,
                    DetailedReport: temp ? temp.DetailedReport : element.DetailedReport,
                    IntegrationSystemTest: temp ? temp.IntegrationSystemTest : element.IntegrationSystemTest,
                    AttachedDocument: temp ? temp.AttachedDocument : ""
                }
                let serviceFound = serviceResult.find(x => x.ServiceCode === element.ServiceCode);
                obj.ServiceName = serviceFound.ServiceName;
                obj.TenderInclusion = serviceFound.TenderInclusion;
                if (temp?.FATEquipApplicableChecklist !== "") {
                    obj.FatApplicable = "Yes";
                    obj.FATEquipApplicableChecklist = temp.FATEquipApplicableChecklist;
                }
                if (temp?.SATEquipApplicableChecklist !== "") {
                    obj.SatApplicable = "Yes";
                    obj.SATEquipApplicableChecklist = temp.SATEquipApplicableChecklist;
                }
                finalData.push(obj)
            }
        }
        setProjectServiceDetails(finalData);
        setIsSubmitClicked(true);
        setLoading(false);
    }

    const handleGenerateAll = async () => {
        await tab0Ref.current.funcOnGenerateAll();
    }

    const loadGenerateAllInfo = async () => {
        if (SelectedProject) {
            let result = await getOverAllDocumentGenerationData(SelectedProject.ProjectCode);
            console.log("result", result)
            if (result.length > 0) {
                setOverAllGenerated(result[0]);
            }
        }
    }

    useEffect(() => {
        loadGenerateAllInfo();
    }, [updateGenerateAllInfo]);

    const fetchLimitData = async () => {
        let limitData = await getEquipmentParameterLimitData();
        //debugger;
        if (limitData?.length > 0) {
            setPrerequisiteLimitVal(limitData[0].PrerequisiteLimitVal);
            setInstrumentsLimitVal(limitData[0].InstrumentsLimitVal);
            setTestingLimitVal(limitData[0].TestingLimitValue);
            setAddInfoLimitVal(limitData[0].AdditionalInfoLimitValue);
        }
    };

    const handleSelect = async (key, direction) => {
        if (!isSubmitClicked) {
            MySwal.fire({
                text: "Please Submit Project details first.",
                imageUrl: errorImage,
                customClass: "swal-success",
            });
            return;
        }

        if (direction == "prev") {
            if (currentTab !== 0) setCurrentTab(currentTab - 1)
        }
        else if (direction == "next") {
            if (currentTab !== 4) setCurrentTab(currentTab + 1)
        }
        else if (direction == "save") {
            setCurrentTab(currentTab)
        }
        else {
            setCurrentTab(Number(key))
        }
    }

    return (
        <>
            {loading && <div className="spinner-box"> <Spinner animation="border" className="spinner" /></div>}
            <Container fluid className="main-blank-container">
                <BreadcrumbComp />
                <div className="action-comp-container">
                    <Row className="docRevTopMargin">
                        <Col md={3} xs={12}>
                            <Form.Group className="g-input docRevInputMargin">
                                <Form.Control className="inputHeight"
                                    id="ProjectName"
                                    type="text"
                                    name="ProjectName"
                                    placeholder=" "
                                    required
                                    value={SelectedProject?.ProjectName}
                                    readOnly
                                />
                                <Form.Label className="frm-label input-label" for="ProjectName">Project</Form.Label>
                                <BiSelectMultiple className="modalPopUpIconActionComp"
                                    size={18}
                                    onClick={() => showPopUp("Project Master",
                                        "Single",
                                        "ProjectName",
                                        SelectedProject?.ProjectName,
                                        {})}
                                    color={"#707070"}
                                    style={{ marginLeft: "-2rem" }}
                                    backdrop="static"
                                />
                            </Form.Group>
                        </Col>
                        <Col md={3} xs={12} className="project-doc-buttons">
                            <Button
                                className="btn-add-color semiBoldToggleButton filterSubmit right-margin-button"
                                variant="success"
                                onClick={handleSubmit}
                                disabled={SelectedProject?.ProjectCode ? false : true}
                            >
                                Submit
                            </Button>
                            <Button
                                className="semiBoldToggleButton filterSubmit"
                                variant="outline-success"
                                onClick={handleGenerateAll}
                                disabled={!isSubmitClicked || SelectedProject?.DeleteIndication !== "Active"}
                            >
                                Generate All
                            </Button>
                        </Col>
                        {
                            OverAllGenerated.GenerateAll === "Yes" &&
                            <Col md={4} xs={12} className="userdetails-header">

                                <UserNameInitials value={OverAllGenerated.GenerateAllByName} />
                                &nbsp;
                                <span>{OverAllGenerated.GenerateAllByName}</span>
                                &nbsp;
                                <span className="document-date-time">{formatDateInDDMMYYYY(OverAllGenerated.GenerateAllOn.split(" ")[0])} | {getTimeAMPMFormat(OverAllGenerated.GenerateAllOn)}</span>
                            </Col>
                        }
                    </Row>
                </div>
                <Container className="add-container projectDoc">
                    <div>
                        <Tabs
                            activeKey={currentTab}
                            onSelect={(e) => { handleSelect(e, "") }}
                            className="tabCssheader"
                        >
                            <Tab
                                className="headerTabKey"
                                eventKey={0}
                                title={
                                    <>
                                        <span className="numberbg">1</span>
                                        <span>Project Documents</span>
                                    </>
                                }
                            >
                                {
                                    isSubmitClicked ?
                                        <ProjectDocumentGeneration
                                            ref={tab0Ref}
                                            SelectedProject={SelectedProject}
                                            projectservicedata={projectServiceDetails}
                                            tabKey={() => handleSelect(currentTab, "next")}
                                            updateInfo={setUpdateGenerateAllInfo}
                                            updateInfoVal={updateGenerateAllInfo}
                                        />
                                        :
                                        <div className="noSelection">Please select Project to view the data</div>
                                }
                            </Tab>
                            <Tab
                                className="headerTabKey"
                                eventKey={1}
                                title={
                                    <>
                                        <span className="numberbg">2</span>
                                        <span>Pre-Requisites</span>
                                    </>
                                }
                            >
                                {
                                    isSubmitClicked && (
                                        <ProjectDocumentParam
                                            SelectedProject={SelectedProject}
                                            projectservicedata={projectServiceDetails}
                                            paramtabname={"Prerequisite"}
                                            headername={"Pre-Requisite"}
                                            limitValue={PrerequisiteLimitVal}
                                            tabNo={currentTab}
                                        />
                                    )
                                }
                            </Tab>
                            <Tab
                                className="headerTabKey"
                                eventKey={2}
                                title={
                                    <>
                                        <span className="numberbg">3</span>
                                        <span>Instruments</span>
                                    </>
                                }
                            >
                                {
                                    isSubmitClicked && (
                                        <ProjectDocumentParam
                                            SelectedProject={SelectedProject}
                                            projectservicedata={projectServiceDetails}
                                            paramtabname={"Instruments"}
                                            headername={"Instruments"}
                                            limitValue={InstrumentsLimitVal}
                                            tabNo={currentTab}
                                        />
                                    )
                                }
                            </Tab>
                            <Tab
                                className="headerTabKey"
                                eventKey={3}
                                title={
                                    <>
                                        <span className="numberbg">4</span>
                                        <span>Test Description</span>
                                    </>
                                }
                            >
                                {
                                    isSubmitClicked && (
                                        <ProjectDocumentParam
                                            SelectedProject={SelectedProject}
                                            projectservicedata={projectServiceDetails}
                                            paramtabname={"Testing"}
                                            headername={"Test Description"}
                                            limitValue={TestingLimitVal}
                                            tabNo={currentTab}
                                        />
                                    )
                                }
                            </Tab>
                            <Tab
                                className="headerTabKey"
                                eventKey={4}
                                title={
                                    <>
                                        <span className="numberbg">5</span>
                                        <span>Additional Information</span>
                                    </>
                                }
                            >
                                {
                                    isSubmitClicked && (
                                        <AdditionalInformation
                                            SelectedProject={SelectedProject}
                                            projectservicedata={projectServiceDetails}
                                            limitValue={AddInfoLimitVal}
                                            tabNo={currentTab}
                                        />
                                    )
                                }
                            </Tab>
                        </Tabs>
                        <br />
                        {isSubmitClicked &&
                            <Row className="tabButtonPosition">
                                <TabButtons
                                    currentTab={currentTab}
                                    lastTab={4}
                                    handleSelect={handleSelect}
                                    showSave={false}
                                />
                            </Row>
                        }
                    </div>
                </Container>
                <RenderIf isTrue={showPopUpForm}>
                    <SingleValuePopUp
                        show={showPopUpForm}
                        onHide={() => setshowPopUpForm(false)}
                        setSelected={setPopUpData}
                        mastername={popUp.name}
                        paramkey={popUp.key}
                        paramvalue={popUp.value}
                        additionalParams={popUp.additionalParams}
                        backdrop="static"
                    />
                </RenderIf>
            </Container>
        </>
    );
}

export default ProjectDocuments; 