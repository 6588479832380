import React, { useEffect, useState, forwardRef, useRef, useImperativeHandle } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { AgGridReact } from "ag-grid-react";
import ChartComponent from "./ChartComponent.jsx";
import LimitCheckCellRenderer from "./LimitCellRenderer.jsx";
import { DashboardProjDataContainer } from "../../functions/gbcs-functions";
import { exportToExcelProjectProgress } from "./ExcelCreation_Dashboard.js";

const DashboardDetails = forwardRef((props, ref) => {

    const [loading, setLoading] = useState(false);
    const [gridApi, setGridApi] = useState(null);
    const [filteredResult, setFilteredResult] = useState([]);
    const [graphData1, setGraphData1] = useState([]);
    const [graphData2, setGraphData2] = useState([]);
    const [graphData3, setGraphData3] = useState([]);
    const [graphData4, setGraphData4] = useState([]);
    const calculatedFinalData = props.calculatedData;
    const gridRef = useRef();

    const defaultColPara = {
        flex: 1,
        sortable: false,
        resizable: false,
        filter: false,
        textAlign: "center",
        suppressMovable: true,
        enableCellChangeFlash: true,
        // autoHeight: true,
        cellClass: 'cell-wrap-textDashboard',
        wrapHeaderText: true,
        autoHeaderHeight: true,
    }

    const columnDefs = [{
        headerName: "SERVICE",
        field: "ServiceName",
        minWidth: 150,
        headerTooltip: "SERVICE",
        headerClass: "grid-sor-columns",
        wrapText: true,
        cellStyle: { wordBreak: 'normal' },
        cellRenderer: function (params) {
            const cellBlank = params.data.totalServiceRowSpan === params.data.ServiceRowSpan;
            if (cellBlank) {
                return (
                    <div className="show-name">{params.value}</div>
                )
            }
            return;
        },
        rowSpan: (params) => params.data.ServiceRowSpan !== undefined ? params.data.ServiceRowSpan : 1,
        colSpan: (params) => params.data.ServiceName === "Activity Completion %" ? 2 : 1,
        cellClassRules: {
            "show-cell": (params) => { return params.data.ServiceRowSpan !== undefined; },
            "show-data": (params) => { return params.data.totalServiceRowSpan === params.data.ServiceRowSpan; }
        },
        cellStyle: function (params) {
            if (params.data.ServiceName === "Activity Completion %") {
                return { 'background-color': '#F1F1ED' }
            }
        },
        pinned: 'left'
    },
    {
        headerName: "EQUIPMENT / ACTIVITY",
        field: "EquipmentName",
        minWidth: 150,
        headerTooltip: "EQUIPMENT / ACTIVITY",
        headerClass: "grid-sor-columns",
        wrapText: true,
        cellStyle: { wordBreak: 'normal' },
        cellStyle: function (params) {
            if (params.data.ServiceName === "Activity Completion %") {
                return { 'background-color': '#F1F1ED' }
            }
        },
        pinned: 'left'
    },
    {
        headerName: "DOCUMENTS SUBMISSION",
        field: "DocumentsSubmission",
        headerTooltip: "DOCUMENTS SUBMISSION",
        headerClass: "grid-sor-columns",
        minWidth: 150,
        cellRenderer: function (params) {
            return <LimitCheckCellRenderer selectedData={params.data.DocumentsSubmission} />
        },
        cellStyle: function (params) {
            if (params.data.ServiceName === "Activity Completion %") {
                return { 'background-color': '#F1F1ED' }
            }
        },
        wrapHeaderText: true,
        autoHeaderHeight: true,
    },
    {
        headerName: "SOR CLOSURE",
        field: "SORClosure",
        minWidth: 150,
        headerTooltip: "SOR CLOSURE",
        headerClass: "grid-sor-columns",
        cellRenderer: function (params) {
            return <LimitCheckCellRenderer selectedData={params.data.SORClosure} />
        },
        cellStyle: function (params) {
            if (params.data.ServiceName === "Activity Completion %") {
                return { 'background-color': '#F1F1ED' }
            }
        }
    },
    {
        headerName: "PRE FUNCTIONAL",
        field: "PreFunctional",
        minWidth: 150,
        headerTooltip: "PRE FUNCTIONAL",
        headerClass: "grid-sor-columns",
        cellRenderer: function (params) {
            return <LimitCheckCellRenderer selectedData={params.data.PreFunctional} />
        },
        cellStyle: function (params) {
            if (params.data.ServiceName === "Activity Completion %") {
                return { 'background-color': '#F1F1ED' }
            }
        },
        wrapHeaderText: true,
        autoHeaderHeight: true,
    },
    {
        headerName: "FUNCTIONAL",
        field: "Functional",
        minWidth: 150,
        headerTooltip: "FUNCTIONAL",
        headerClass: "grid-sor-columns",
        cellRenderer: function (params) {
            return <LimitCheckCellRenderer selectedData={params.data.Functional} />
        },
        cellStyle: function (params) {
            if (params.data.ServiceName === "Activity Completion %") {
                return { 'background-color': '#F1F1ED' }
            }
        }
    },
    {
        headerName: "FIRE INTEGRATION",
        field: "FireIntegration",
        minWidth: 150,
        headerTooltip: "FIRE INTEGRATION",
        headerClass: "grid-sor-columns",
        cellRenderer: function (params) {
            return <LimitCheckCellRenderer selectedData={params.data.FireIntegration} />
        },
        cellStyle: function (params) {
            if (params.data.ServiceName === "Activity Completion %") {
                return { 'background-color': '#F1F1ED' }
            }
        },
        wrapHeaderText: true,
        autoHeaderHeight: true,
    },
    {
        headerName: "IST",
        field: "IST",
        minWidth: 150,
        headerTooltip: "IST",
        headerClass: "grid-sor-columns",
        cellRenderer: function (params) {
            return <LimitCheckCellRenderer selectedData={params.data.IST} />
        },
        cellStyle: function (params) {
            if (params.data.ServiceName === "Activity Completion %") {
                return { 'background-color': '#F1F1ED' }
            }
        }
    },
    {
        headerName: "OVERALL % COMPLETION (PHASES WISE)",
        field: "OverallCompletionActivityWise",
        minWidth: 200,
        headerTooltip: "OVERALL % COMPLETION (PHASES WISE)",
        headerClass: "grid-sor-columns",
        cellRenderer: function (params) {
            return <LimitCheckCellRenderer selectedData={params.data.OverallCompletionActivityWise} />
        },
        cellStyle: function (params) {
            if (params.data.ServiceName === "Activity Completion %") {
                return { 'background-color': '#F1F1ED' }
            }
        },
        wrapHeaderText: true,
        autoHeaderHeight: true,
    }
    ];

    function onGridReady(params) {
        setGridApi(params.api);
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        params.api.resetRowHeights();
        gridRef.current = params.api; // <= assigned gridApi value on Grid ready
    }

    const fetchProjectServiceEqui = async () => {
        setLoading(true);
        let dashTab1Result = calculatedFinalData.dashTab1GridData;
        let pie3Data = calculatedFinalData.tab1Graph3Data;

        let avgData = calculatedFinalData.dashTab1GridData.filter(x => x.ServiceName === "Activity Completion %");
        let totalWorkDoneAvg = 0;
        if (avgData.length > 0) {
            totalWorkDoneAvg = avgData[0].OverallCompletionActivityWise
        }
        let temp = [];
        let equipArr = [];
        let equipTotalArr = [];

        let totalServicePerArr = [];
        let totalServiceCompletionArr = [];
        // let totalServiceWeightageCompletionArr = [];

        for (let item of dashTab1Result) {
            if (item.ServiceName !== "Activity Completion %") {
                equipArr.push(item.EquipmentName);
                equipTotalArr.push(parseFloat(item.OverallCompletionActivityWise));

                if (temp.indexOf(item.ServiceName) === -1) {
                    temp.push(item.ServiceName);
                    totalServicePerArr.push(item.ServicePer);
                    totalServiceCompletionArr.push({ "Service %": item.ServicePer, "Services Progress %": item.ServiceCompletion, "Services Weightage wise Progress": item.ServiceWeightageCompletion });
                    // totalServiceWeightageCompletionArr.push(item.ServiceWeightageCompletion);
                }
            }
        }

        setGraphData1({
            series: [totalWorkDoneAvg, parseFloat((100 - totalWorkDoneAvg).toFixed(2))],
            chartOptions: {
                labels: ['Work Completed', 'Work Pending'],
                colors: ["#66BB6A", "#FF4560"],
                fillColours: ["#66BB6A", "#FF4560"]
            },
            chartType: "pie"
        });


        setGraphData2({
            series: totalServicePerArr,
            series2: totalServiceCompletionArr,
            // series2: totalServiceWeightageCompletionArr,
            chartOptions: {
                labels: temp,
                customLabels: ["Service %", "Services Progress %", "Services Weightage wise Progress"]
            },
            chartType: "pie"
        });
        let pie3DataArr = [];
        for (let key of Object.keys(pie3Data)) {
            let dtObj = {
                "Phases Weightage %": pie3Data[key].MilestoneWeightage,
                "Phases Completion %": pie3Data[key].MilestoneCompletion,
                "Phases Weightage-wise Progress %": pie3Data[key].MilestoneProgress
            }
            pie3DataArr.push(dtObj);
        }

        setGraphData3({
            series: [pie3Data.DocumentsSubmission.MilestoneWeightage,
            pie3Data.SORClosure.MilestoneWeightage,
            pie3Data.PreFunctional.MilestoneWeightage,
            pie3Data.Functional.MilestoneWeightage,
            pie3Data.FireIntegration.MilestoneWeightage,
            pie3Data.IST.MilestoneWeightage
            ],
            series2: pie3DataArr,
            chartOptions: {
                labels: ['Documents Submission', 'SOR Closure', 'Pre Functional', 'Functional', 'Fire Integration', 'IST'],
                customLabels: ["Phases Weightage %", "Phases Completion %", "Phases Weightage-wise Progress %"],
                colors: ["#FFEE58", "#66BB6A", '#5C6BC0', '#FFA726', '#26C6DA', '#F06292'],
                fillColours: ["#FFEE58", "#66BB6A", '#5C6BC0', '#FFA726', '#26C6DA', '#F06292']
            },
            chartType: "pie"
        });

        setGraphData4({
            series: equipTotalArr,
            chartOptions: {
                categories: equipArr
            },
            chartType: "bar"
        });

        setLoading(false);
        setFilteredResult(dashTab1Result);
    }

    useEffect(() => {
        if (props.tabNo === 0) {
            fetchProjectServiceEqui();
        }
    }, [props.tabNo, props.calculatedFinalData]);


    useImperativeHandle(ref, () => ({
        funcOnExport: async () => {
            // debugger;
            await handleExportExcel();
        }
    }));

    const handleExportExcel = async () => {
        // debugger;
        console.log("columnDefs ===> ", columnDefs);
        console.log("calculatedFinalData ===> ", filteredResult);
        if (filteredResult?.length > 0) {
            let filename = "Project Dashboard.xlsx";
            let projectDataValue = "Project Dashboard " + props.SelectedProject.CustomerName + ", " + props.SelectedProject.Location + " - " + props.SelectedProject.ProjectName;
            projectDataValue = projectDataValue.toString().toUpperCase();
            exportToExcelProjectProgress(filename, "data", filteredResult, projectDataValue, columnDefs, "DashboardDetails");
        }
    }

    return (
        <div className="dashboardTabData">
            <DashboardProjDataContainer
                TabString={"Project Dashboard"}
                SelectedProject={props.SelectedProject}
                handleInfoClick={props.handleInfoClick}
            />
            <Row >
                <Col lg={4} xs={12} className="dashboardTabData">
                    <div className="dashboardChartDiv commonBorder">
                        {/* <div className="semiBoldToggleButton chartHeaderLabel">
                            Project Overall Status
                        </div> */}
                        {graphData1.length !== 0 && (
                            <ChartComponent chartLabel={"Project Overall Status"}
                                data={graphData1}
                            />
                        )}
                    </div>
                </Col>
                <Col lg={4} xs={12} className="dashboardTabData">
                    <div className="dashboardChartDiv commonBorder">
                        {/* <div className="semiBoldToggleButton chartHeaderLabel">
                            Project & Service-wise Completion Percentage (%)
                        </div> */}
                        {graphData2.length !== 0 && (
                            <ChartComponent chartLabel={["Project & Service-wise Completion", "Percentage (%)"]}
                                data={graphData2}
                            />
                        )}
                    </div>
                </Col>
                <Col lg={4} xs={12} className="dashboardTabData">
                    <div className="dashboardChartDiv commonBorder">
                        {/* <div className="semiBoldToggleButton chartHeaderLabel">
                            Project & Phases-wise Completion Percentage (%)
                        </div> */}
                        {graphData3.length !== 0 && (
                            <ChartComponent chartLabel={["Project & Phases-wise Completion", "Percentage (%)"]}
                                data={graphData3}
                            />
                        )}
                    </div>
                </Col>
            </Row>
            <div className="dashboardChartRow">
                <div className="semiBoldToggleButton chartHeaderLabel">
                    <Row>
                        <Col lg={6} xs={12}>
                            Overall T&C Status for {props.SelectedProject.CustomerName}, {props.SelectedProject.Location} - {props.SelectedProject.ProjectName}
                        </Col>
                        <Col lg={6} xs={12}>
                            <div className="rightAlignedButton">
                                <span className="dashboardLimitSet dashboardLimitSet1" />
                                <span className="dashboardLimitLabel">{"> 90 TO <= 100"}</span>
                                <span className="dashboardLimitSet dashboardLimitSet2" />
                                <span className="dashboardLimitLabel">{"> 70 TO <= 90"}</span>
                                <span className="dashboardLimitSet dashboardLimitSet3" />
                                <span className="dashboardLimitLabel">{"> 50 TO <= 70"}</span>
                                <span className="dashboardLimitSet dashboardLimitSet4" />
                                <span className="dashboardLimitLabel">{"> 0 TO <= 50"}</span>
                                <span className="dashboardLimitSet dashboardLimitSet5" />
                                <span className="dashboardLimitLabel">{"=0"}</span>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div id="myGrid" className="ag-theme-alpine agGridContainer">
                    <AgGridReact
                        rowData={filteredResult}
                        columnDefs={columnDefs}
                        suppressRowTransform={true}
                        defaultColDef={defaultColPara}
                        enableBrowserTooltips={true}
                        onGridReady={onGridReady}
                    />
                </div>
            </div>
            <Row className="dashboardChartRow">
                <Col xs={12} className="dashboardTabData">
                    <div className="dashboardBarChartDiv">
                        {/* <div className="semiBoldToggleButton chartHeaderLabel">
                            Equipment/Activity wise Overall Completion
                        </div> */}
                        {graphData4?.length !== 0 &&
                            <ChartComponent chartLabel={"Equipment/Activity wise Overall Completion"}
                                data={graphData4}
                            />
                        }
                        {/* <div id="html-dist"></div> */}
                    </div>
                </Col>
            </Row>
        </div>
    )

});

export default DashboardDetails;
