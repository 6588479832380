
import React, { useState, useContext } from "react";
import { Button, Container, Col, Form, Row, Spinner } from "react-bootstrap";
import BreadcrumbComp from "../layouts/BreadcrumbComp";
import { useDispatch } from "react-redux";
import { menuActions } from "../../store/menu-slice";
import { getGDPRdata, eraseGDPRDetails } from "../../services/logger-entry-service";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import GridMaster from "../masters/GridMaster";
import { getFinancialYearForDate, getFinancialYears } from "../../utils/Utility"
import { getCurrentDateTime, RenderIf } from "../../functions/gbcs-functions";
import SingleValuePopUp from "../utils/SingleValuePopUp";
import successImage from "../../icons/success-tick-logo.svg";
import errorImage from "../../icons/error-tick-logo.svg";
import { getAllProjects, getProjectsByUser, getProjectServiceEqui, getProjectTeamData, inactiveProjectTeam } from "../../services/project-service";
import { Link } from "react-router-dom";
import { ALERT_PARAMS } from "../../utils/constant.js";
import { AuthContext } from "../../contexts/AuthContext";

const GDPR = () => {
    const dispatch = useDispatch();
    dispatch(menuActions.setMenu("GDPR"));
    const context = useContext(AuthContext);
    const userDetails = { userName: context.userName, userId: context.userId }
    const MySwal = withReactContent(Swal);
    const [filteredResult, setFilteredResult] = useState([]);
    const [resetFilter, setResetFilter] = useState(true);
    const [filterColLength, setFilterColLength] = useState(0);
    const [excelData, setExcelData] = useState([]);
    const [Criteria, setCriteria] = useState("Contains");
    const [gdprObj, setGDPRObj] = useState({});
    const [showErase, setShowErase] = useState(false);
    const [showPopUpForm, setshowPopUpForm] = useState(false);
    const [loading, setLoading] = useState(false);


    let CriteriaList = [
        { Criteria: "Contains" },
        { Criteria: "Equals to" }
    ];

    let keysObject = {
        "UserMaster": { PK: "UserMaster", SK: "UserId#" },
        "CompanyDivisionMaster": { PK: "CompanyDivisionMaster", SK: "CompanyCode#" },
        "ProjectMaster": { PK: "ProjectMaster", SK: "ProjectDetails#" },
        "UserMasterLog": { PK: "UserMasterLog", SK: "Timestamp#" },
        "SinglePopUpLog": { PK: "SinglePopUpLog", SK: "Timestamp#", UniqueKey: "SK#UserId#" },
        //"CompanyDivisionMasterLog":{PK:"CompanyDivisionMasterLog", SK:"Timestamp#"},
        "ProjectMasterLog": { PK: "ProjectMasterLog", SK: "Timestamp#" },
    }

    let columnKeyMap = {
        "UserMaster": { ApiFlag: "Master", columnName: "UserMaster" },
        "CompanyDivisionMaster": { ApiFlag: "Master", columnName: "CompanyDivisionMaster" },
        "ProjectMaster": { ApiFlag: "Entry", columnName: "ProjectMaster" },
        "UserMasterLog": { ApiFlag: "Master", columnName: "UserMasterLog" },
        "SinglePopUpLog": { ApiFlag: "Master", columnName: "UserMasterLog" },
        "ProjectMasterLog": { ApiFlag: "Entry", columnName: "ProjectMasterLog" },

    };

    let columnMapForGrid = {
        "UserMaster": { UserName: "UserName", EmailId: "EmailId", MobileNo: "MobileNo" },
        "CompanyDivisionMaster": { MobileNo: "CompanyTelephoneNo" },
        "ProjectMaster": [{ UserName: "GodrejEmpName", EmailId: "GodrejEmailId", MobileNo: "GodrejContactNo" },
        { UserName: "ClientContactPerson1", EmailId: "ClientEmail1", MobileNo: "ClientContactNo1" },
        { UserName: "ClientContactPerson2", EmailId: "ClientEmail2", MobileNo: "ClientContactNo2" }],
        "UserMasterLog": { UserName: "UserName", EmailId: "EmailId", MobileNo: "MobileNo" },
        "SinglePopUpLog": { UserName: "UserName", EmailId: "EmailId", MobileNo: "MobileNo" },
        "ProjectMasterLog": { UserName: "UserName", EmailId: "EmailId", MobileNo: "MobileNo" },
    }

    let columnCheckProject = {
        "Stakeholder": { UserName: "PMCContactPerson", EmailId: "PMCEmailId", MobileNo: "PMCOfficialContNo" },
        "Team": { UserName: "UserName", EmailId: "EmailId", MobileNo: "MobileNo" },
        "Service": { UserName: "Contractor", EmailId: "EmailId", MobileNo: "MobileNo" }
    }

    const displayValue = (params) => {
        return (<Link className="gdpr-gridcell" onClick={() => handleOnCellClick(params)}>{params.value}</Link>)
    }

    const columnDefs = [
        {
            headerName: "Financial Year",
            headerTooltip: "Financial Year",
            field: "FinancialYear",
            tooltipField: "FinancialYear",
            minWidth: 200,
        },
        {
            headerName: "User Master",
            headerTooltip: "User Master",
            field: "UserMaster",
            tooltipField: "UserMaster",
            minWidth: 200,
            cellRenderer: displayValue
        },
        {
            headerName: "Company / Division Master",
            headerTooltip: "Company / Division Master",
            field: "CompanyDivisionMaster",
            tooltipField: "CompanyDivisionMaster",
            minWidth: 200,
            cellRenderer: displayValue
        },
        {
            headerName: "Project Master",
            headerTooltip: "Project Master",
            field: "ProjectMaster",
            tooltipField: "ProjectMaster",
            minWidth: 200,
            cellRenderer: displayValue
        },
        {
            headerName: "User Master Log",
            headerTooltip: "User Master Log",
            field: "UserMasterLog",
            tooltipField: "UserMasterLog",
            minWidth: 200,
            cellRenderer: displayValue
        },
        {
            headerName: "Project Master Log",
            headerTooltip: "Project Master Log",
            field: "ProjectMasterLog",
            tooltipField: "ProjectMasterLog",
            minWidth: 200,
            cellRenderer: displayValue
        }
    ];

    const headerName = (field) => {
        let name;
        switch (field) {
            case "FinancialYear":
                name = "Financial Year";
                break;
            case "UserMaster":
                name = "User Master";
                break;
            case "CompanyDivisionMaster":
                name = "Company / Division Master";
                break;
            case "ProjectMaster":
                name = "Project Master";
                break;
            case "UserMasterLog":
                name = "User Master Log";
                break;
            case "ProjectMasterLog":
                name = "Project Master Log";
                break;
            default:
                name = "No match found";
                break;
        }
        return name;
    }

    const fieldName = (header) => {
        let name;
        switch (header) {
            case "Financial Year":
                name = "FinancialYear";
                break;
            case "User Master":
                name = "UserMaster";
                break;
            case "Company / Division Master":
                name = "CompanyDivisionMaster";
                break;
            case "Project Master":
                name = "ProjectMaster"
                break;
            case "User Master Log":
                name = "UserMasterLog"
                break;
            case "Project Master Log":
                name = "ProjectMasterLog"
                break;
            default:
                name = "No match found";
                break;
        }
        return name;
    }

    const handleSubmit = async () => {
        fetchGDPRdata();
    }

    const handleErase = async () => {
        MySwal.fire({
            text: "Are you sure, do you want to erase User Profile?",
            confirmButtonText: "Erase",
            title: "Erase User Profile",
            ...ALERT_PARAMS
        }).then(async (result2) => {
            if (result2.isConfirmed) {
                try {
                    setLoading(true);
                    let results = [];
                    let inactiveUsers = [];
                    let counter = 0;
                    let successCount = 0;
                    let failedCount = 0;
                    for (let item of filteredResult) {
                        for (let key of Object.keys(columnKeyMap)) {
                            if (key !== "SinglePopUpLog") {
                                for (let dt of item[key + "Records"]) {
                                    // console.log("item", item, "key", key, "dt", dt);
                                    let paramsToUpdate = {};
                                    if (dt.hasOwnProperty("dataToUpdate")) {
                                        paramsToUpdate = dt.dataToUpdate;
                                    }
                                    else {
                                        paramsToUpdate.PK = dt.PK;
                                        paramsToUpdate.SK = dt.SK;
                                        for (let att of Object.keys(columnMapForGrid[key])) {
                                            if (att !== "UserName") {
                                                paramsToUpdate[columnMapForGrid[key][att]] = "";
                                            }
                                        }
                                        if (key === "UserMaster") {
                                            paramsToUpdate.DeleteIndication = "Inactive";
                                            paramsToUpdate.ModifiedBy = userDetails.userId;
                                            paramsToUpdate.ModifiedByName = userDetails.userName;
                                            paramsToUpdate.ModifiedOn = getCurrentDateTime("-");
                                            inactiveUsers.push(dt.UserId);
                                        }
                                    }
                                    let tempResult = eraseGDPRDetails(paramsToUpdate, columnKeyMap[key].ApiFlag);
                                    results.push(tempResult)
                                    counter++;
                                    if (counter === 5) {
                                        for (let res of results) {
                                            let tmpResult1 = await Promise.resolve(res);
                                            if (tmpResult1.status) {
                                                successCount++;
                                            }
                                            else {
                                                failedCount++;
                                            }
                                        }
                                        counter = 0;
                                        results = [];
                                    }
                                }
                            }
                        }
                        if (results.length > 0) {
                            for (let res of results) {
                                let tmpResult1 = await Promise.resolve(res)
                                if (tmpResult1.status) {
                                    successCount++;
                                }
                                else {
                                    failedCount++;
                                }
                            }
                        }
                    }
                    //inactive user in projects

                    counter = 0;
                    results = [];
                    for (let dt of inactiveUsers) {
                        let user_projectData = await getProjectsByUser(dt);
                        for (let pt of user_projectData) {
                            if (pt.DeleteIndication === "Active") {
                                let tmpObj = {
                                    PK: pt.PK,
                                    SK: pt.SK,
                                    DeleteIndication: "Inactive",
                                    ModifiedBy: userDetails.userId,
                                    ModifiedByName: userDetails.userName,
                                    ModifiedOn: getCurrentDateTime("-")
                                }
                                let tempResult = inactiveProjectTeam(tmpObj);
                                results.push(tempResult)
                                counter++;
                                if (counter === 5) {
                                    for (let res of results) {
                                        let tmpResult1 = await Promise.resolve(res);
                                        if (tmpResult1.status) {
                                            successCount++;
                                        }
                                        else {
                                            failedCount++;
                                        }
                                    }
                                    counter = 0;
                                    results = [];
                                }
                            }
                        }
                    }
                    if (results.length > 0) {
                        for (let res of results) {
                            let tmpResult1 = await Promise.resolve(res)
                            if (tmpResult1.status) {
                                successCount++;
                            }
                            else {
                                failedCount++;
                            }
                        }
                    }
                    // console.log("successCount", successCount, "failedCount", failedCount);
                    if (failedCount === 0 && successCount > 0) {
                        MySwal.fire({
                            text: "User Profile Erased Successfully",
                            imageUrl: successImage,
                            customClass: "swal-success",
                        });
                    }
                    else if (failedCount > 0 && successCount > 0) {
                        MySwal.fire({
                            text: "User Profile Erased Successfully for " + successCount + " records. Failed to erase " + failedCount + " records.",
                            imageUrl: successImage,
                            customClass: "swal-success",
                        });
                    }
                    else if (failedCount > 0 && successCount === 0) {
                        MySwal.fire({
                            text: "Failed to erase User Profile records.",
                            imageUrl: errorImage,
                            customClass: "swal-success",
                        });
                    }
                    setGDPRObj({});
                    setFilteredResult([]);
                    setShowErase(false);
                    setLoading(false);
                } catch (error) {
                    setLoading(false);
                    console.log("error", error);
                    MySwal.fire({
                        text: "Unable to process request.",
                        imageUrl: errorImage,
                        customClass: "swal-success",
                    });
                }
            }
        });
    }

    const [popUp, setPopUp] = useState({
        name: "",
        key: "",
        value: "",
    });

    const handleOnCellClick = (params) => {
        // console.log("params", params);
        if (params.data[params.colDef.field] > 0) {
            let records = params.data[params.colDef.field + "Records"];
            let name = "GDPR " + params.colDef.headerName;
            let key = params.colDef.headerName;
            let value = "";
            let additionalParams = { data: records, popupTopMasterName: params.colDef.headerName };
            setPopUp({ name, key, value, additionalParams });
            setshowPopUpForm(true);
        }
        else {
            MySwal.fire({
                text: "No records to display.",
                imageUrl: errorImage,
                customClass: "swal-success",
            });
            return;
        }
    }


    const validateRecord = async (filterObj, element, criteria) => {
        let isValid = false;

        for (let dt of Object.keys(filterObj)) {
            if (criteria === "Contains") {
                if (element[dt]?.trim().toLowerCase().includes(filterObj[dt]?.trim().toLowerCase())) {
                    isValid = true;
                }
                else {
                    isValid = false;
                    break;
                }
            }
            if (criteria === "Equals to") {
                if (element[dt]?.trim().toLowerCase() === filterObj[dt]?.trim().toLowerCase()) {
                    isValid = true;
                }
                else {
                    isValid = false;
                    break;
                }
            }
        }
        return isValid;
    }

    const validateProjDetails = async (projectData, rowData, mappingData, criteria, indication, rowNo) => {
        let filterObj = {};
        for (let key of Object.keys(mappingData)) {
            if (gdprObj[key]) {
                filterObj[mappingData[key]] = gdprObj[key];
            }
        }
        let isValidData = await validateRecord(filterObj, rowData, criteria);
        // console.log("isValidData", isValidData, filterObj, rowData);
        let dtObj = {};
        if (isValidData) {
            dtObj = await processValidRecord(projectData, rowData, mappingData, indication, rowNo);
        }
        return { isValidData, dtObj };
    }

    const processValidRecord = async (projectData, rowData, mappingData, indication, rowNo) => {
        let dtUpdate = {
            PK: rowData.PK ? rowData.PK : projectData.PK,
            SK: rowData.SK ? rowData.SK : projectData.SK
        }
        let dtObj = {
            PK: rowData.PK ? rowData.PK : projectData.PK,
            SK: rowData.SK ? rowData.SK : projectData.SK,
            ProjectCode: projectData.ProjectCode,
            ProjectName: projectData.ProjectName,
            Location: projectData.Location,
            DeleteIndication: projectData.DeleteIndication
        }
        if (indication === "ProjectMasterLog" || indication === "StakeholderLog") {
            // console.log("inside", projectData, indication);
            dtObj.LoggedBy = projectData.LoggedBy;
            dtObj.LoggedOn = projectData.LoggedOn;
            dtObj.Action = projectData.Action;
        }

        for (let key of Object.keys(mappingData)) {
            dtObj[key] = rowData[mappingData[key]];
            if (key !== "UserName") {
                dtUpdate[mappingData[key]] = "";
            }
        }
        if (indication === "Stakeholder" || indication === "StakeholderLog") {
            dtUpdate.recordNo = rowNo;
        }
        dtObj.dataToUpdate = dtUpdate;
        return dtObj;
    }

    const processGDPRData = async (projectData, rowData, mappingColumn, allGridData, criteria, indication, gridColumn, rowNo, createdOnDate) => {
        let { isValidData, dtObj } = await validateProjDetails(projectData, rowData, mappingColumn, criteria, indication, rowNo);
        // console.log("isValidData", isValidData, rowData);
        if (isValidData) {
            let dtFy = getFinancialYearForDate(createdOnDate);
            let ind = allGridData.findIndex(x => x.FinancialYear === dtFy);
            if (ind !== -1) {
                allGridData[ind][gridColumn].push(dtObj);
            }
        }
        return allGridData;
    }

    const fetchGDPRdata = async () => {
        let gridData = [];
        setLoading(true);
        for (let key of Object.keys(gdprObj)) {
            if (!gdprObj[key]) {
                delete gdprObj[key];
            }
        }

        let allFinancialYears = getFinancialYears();
        // console.log("allFinancialYears", allFinancialYears)
        for (let fy of allFinancialYears) {
            let gridRow = { FinancialYear: fy };
            for (let dt of Object.keys(columnKeyMap)) {
                // console.log("dt", dt, columnKeyMap[dt])
                gridRow[columnKeyMap[dt].columnName] = 0;
            }

            let columnWiseRecords = [];
            let records = [];
            let counter = 0;
            for (let item of Object.keys(keysObject)) {
                // console.log("item", item)
                let filterObj = {};
                let ignoreLoop = false

                if (item === "CompanyDivisionMaster" || item === "UserMaster" || item === "UserMasterLog" || item === "SinglePopUpLog" || item === "ProjectMasterLog") {
                    //company division master data to be fetched if only mobile no is given in filter
                    if (item === "CompanyDivisionMaster" && (Object.keys(gdprObj).length > 1 || (Object.keys(gdprObj).length === 1 && !gdprObj.hasOwnProperty("MobileNo")))) {
                        ignoreLoop = true;
                    }
                    if (!ignoreLoop) {
                        for (let key of Object.keys(columnMapForGrid[item])) {
                            if (gdprObj[key]) {
                                filterObj[columnMapForGrid[item][key]] = gdprObj[key];
                            }
                        }
                        // console.log("filterObj", filterObj)
                        let dataFetchObj = Object.assign({}, keysObject[item], { FY: fy });
                        let params = {
                            criteria: Criteria,
                            dataFetchObj: dataFetchObj,
                            filterObj: filterObj
                        }
                        // console.log(params, columnKeyMap[item].ApiFlag)
                        let projectsFyWise = getGDPRdata(params, columnKeyMap[item].ApiFlag);

                        records.push({ columnKey: item, records: projectsFyWise });
                        counter++;

                        if (counter === 5) {
                            for (let dt of records) {
                                let tmpResult = await Promise.resolve(dt.records);
                                columnWiseRecords.push({ columnKey: dt.columnKey, records: tmpResult });
                            }
                            counter = 0;
                            records = [];
                        }
                    }
                }
            }

            for (let dt of records) {
                let tmpResult = await Promise.resolve(dt.records);
                columnWiseRecords.push({ columnKey: dt.columnKey, records: tmpResult });
            }

            console.log("columnWiseRecords", columnWiseRecords)
            for (let dt of Object.keys(gridRow)) {
                if (dt !== "FinancialYear") {
                    let tmpData = columnWiseRecords.filter((x) => x.columnKey === dt);
                    console.log("tmpData", tmpData);
                    if (tmpData.length > 0 && (tmpData[0].records.length > 0 || Object.keys(tmpData[0].records).length > 0)) {
                        if (dt === "UserMasterLog" || dt === "SinglePopUpLog") {
                            if (!gridRow.hasOwnProperty("UserMasterLogRecords")) {
                                gridRow["UserMasterLogRecords"] = [];
                            }
                            gridRow.UserMasterLog = gridRow.UserMasterLog + tmpData[0].records.length;
                            gridRow.UserMasterLogRecords = [...gridRow.UserMasterLogRecords, ...tmpData[0].records];
                        }
                        else if (dt === "ProjectMasterLog") {
                            if (!gridRow.hasOwnProperty("ProjectMasterLogRecords")) {
                                gridRow["ProjectMasterLogRecords"] = [];
                            }

                            let projDt = []
                            for (let prj of tmpData[0].records["projDetails"]) {
                                //check project details line for Godrej ENE Employee & Customer Details
                                for (let col of columnMapForGrid.ProjectMaster) {
                                    let chkValid = await validateProjDetails(prj, prj, col, Criteria, "ProjectMasterLog", 0);
                                    if (chkValid.isValidData) {
                                        projDt.push(chkValid.dtObj);
                                    }
                                }
                                //check project details line for PMC / MEP Consultants / Contractor Details
                                if (prj.StakeholderDetails.length > 0) {
                                    let counter = 0;
                                    for (let stk of prj.StakeholderDetails) {
                                        let chkValid = await validateProjDetails(prj, stk, columnCheckProject.Stakeholder, Criteria, "StakeholderLog", counter);
                                        if (chkValid.isValidData) {
                                            projDt.push(chkValid.dtObj);
                                        }
                                        counter++;
                                    }
                                }
                            }

                            let teamDt = []
                            for (let tm of tmpData[0].records["teamData"]) {
                                let tmObj = await processValidRecord(tm, tm, columnCheckProject.Team, "ProjectMasterLog", 0);
                                teamDt.push(tmObj);
                            }

                            let srvDt = []
                            for (let srv of tmpData[0].records["srvData"]) {
                                let srvObj = await processValidRecord(srv, srv, columnCheckProject.Service, "ProjectMasterLog", 0);
                                srvDt.push(srvObj);
                            }

                            gridRow.ProjectMasterLog = gridRow.ProjectMasterLog + projDt.length + teamDt.length + srvDt.length;
                            gridRow.ProjectMasterLogRecords = [...gridRow.ProjectMasterLogRecords, ...projDt, ...teamDt, ...srvDt];

                        }
                        else {
                            gridRow[dt] = tmpData[0].records.length;
                            gridRow[dt + "Records"] = tmpData[0].records;
                        }
                    }
                    else {
                        gridRow[dt] = 0;
                        gridRow[dt + "Records"] = [];
                    }
                }
            }
            gridData.push(gridRow);
        }

        //project master 
        let allProjects = await getAllProjects();
        if (allProjects.length > 0) {
            for (let dt of allProjects) {

                const [teamData, serviceData] = await Promise.all([getProjectTeamData(dt.ProjectCode), getProjectServiceEqui(dt.ProjectCode)]);

                //check project details line for Godrej ENE Employee & Customer Details
                for (let col of columnMapForGrid.ProjectMaster) {
                    gridData = await processGDPRData(dt, dt, col, gridData, Criteria, "ProjectMaster", "ProjectMasterRecords", 0, dt.CreatedOn);
                }
                //check project details line for PMC / MEP Consultants / Contractor Details
                if (dt.StakeholderDetails.length > 0) {
                    let counter = 0;
                    for (let stk of dt.StakeholderDetails) {
                        gridData = await processGDPRData(dt, stk, columnCheckProject.Stakeholder, gridData, Criteria, "Stakeholder", "ProjectMasterRecords", counter, dt.CreatedOn);
                        counter++;
                    }
                }
                //check project team
                if (teamData.length > 0) {
                    for (let tm of teamData) {
                        gridData = await processGDPRData(dt, tm, columnCheckProject.Team, gridData, Criteria, "Team", "ProjectMasterRecords", 0, tm.CreatedOn);
                    }

                }
                //check service data
                if (serviceData.length > 0) {
                    for (let srv of serviceData) {
                        gridData = await processGDPRData(dt, srv, columnCheckProject.Service, gridData, Criteria, "Service", "ProjectMasterRecords", 0, srv.CreatedOn);
                    }
                }
            }
        }
        let noData = true;
        for (let dt of gridData) {
            dt.ProjectMaster = dt.ProjectMasterRecords.length;
            for (let dt1 of Object.keys(dt)) {
                if (dt1.includes("Records") && dt[dt1].length > 0) {
                    noData = false;
                    break;
                }
            }
        }
        console.log("gridData", gridData)
        if (noData) {
            setFilteredResult([]);
            setShowErase(false);
            setResetFilter(!resetFilter);
            setLoading(false);
            MySwal.fire({
                text: "No records to display.",
                imageUrl: errorImage,
                customClass: "swal-success",
            });
        }
        else {
            setFilteredResult(gridData);
            setResetFilter(!resetFilter);
            setShowErase(true);
            setLoading(false);
        }
    }

    const handleChangeCriteria = (e) => {
        let { value } = e.target;
        setCriteria(value)
    }

    const handleChange = (e) => {
        let { name, value } = e.target;
        setGDPRObj((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    }


    return (
        <>
            {loading && <div className="spinner-box"> <Spinner animation="border" className="spinner" /></div>}
            <BreadcrumbComp />
            <Container fluid className="main-container">
                <div className="action-comp-container">
                    <Row className="docRevTopMargin">
                        <Col lg={2} md={2} xs={12}>
                            <Form.Group className="g-select docRevInputMargin">
                                <Form.Select className="inputHeight criteria-select"
                                    id="Criteria"
                                    name="Criteria"
                                    type="text"
                                    placeholder=" "
                                    value={Criteria}
                                    onChange={handleChangeCriteria}
                                >
                                    {CriteriaList?.map((item) => {
                                        return (<option key={item.Criteria} value={item.Criteria}>{item.Criteria}</option>)
                                    })
                                    }
                                </Form.Select>
                                <Form.Label className="frm-label input-label" htmlFor="Criteria">Select Value</Form.Label>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="docRevTopMargin">
                        <Col lg={2} md={2} xs={12}>
                            <Form.Group className="g-input docRevInputMargin">
                                <Form.Control className="inputHeight"
                                    id="UserName"
                                    type="text"
                                    name="UserName"
                                    placeholder=" "
                                    value={gdprObj.UserName ? gdprObj.UserName : ""}
                                    onChange={handleChange}
                                    required
                                />
                                <Form.Label className="frm-label input-label" htmlFor="UserName">User Name</Form.Label>
                            </Form.Group>
                        </Col>
                        <Col lg={2} md={2} xs={12}>
                            <Form.Group className="g-input docRevInputMargin">
                                <Form.Control className="inputHeight"
                                    id="EmailId"
                                    type="text"
                                    name="EmailId"
                                    placeholder=" "
                                    value={gdprObj.EmailId ? gdprObj.EmailId : ""}
                                    onChange={handleChange}
                                    required
                                />
                                <Form.Label className="frm-label input-label" htmlFor="EmailId">Official Email ID</Form.Label>
                            </Form.Group>
                        </Col>
                        <Col lg={2} md={2} xs={12}>
                            <Form.Group className="g-input docRevInputMargin">
                                <Form.Control className="inputHeight"
                                    id="MobileNo"
                                    type="text"
                                    name="MobileNo"
                                    placeholder=" "
                                    value={gdprObj.MobileNo ? gdprObj.MobileNo : ""}
                                    onChange={handleChange}
                                    required
                                />
                                <Form.Label className="frm-label input-label" htmlFor="MobileNo">Mobile Number</Form.Label>
                            </Form.Group>
                        </Col>
                        <Col lg={2} md={2} xs={6} className="gdpr-submit-btn-col" >
                            <Button
                                variant="success"
                                className="btn-add-color semiBoldToggleButton filterSubmit"
                                onClick={handleSubmit}
                                disabled={!Object.values(gdprObj).some(value => value != "")}
                            >
                                Submit
                            </Button>
                        </Col>
                        {showErase &&
                            <Col lg={2} md={2} xs={6} className="gdpr-erase-btn-col">
                                <Button className="semiBoldToggleButton filterSubmit rightAlignedButtonInResp btn-outline-erase"
                                    onClick={handleErase}>
                                    Erase
                                </Button>
                            </Col>
                        }

                    </Row>
                </div>
                <GridMaster
                    rowDetails={filteredResult}
                    colDetails={columnDefs}
                    fieldNames={fieldName}
                    headerNames={headerName}
                    getFilterColLength={(getLength) => setFilterColLength(getLength)}
                    setExcelData={setExcelData}
                    resetind={resetFilter}
                    noActionButtons={true}
                />
                <RenderIf isTrue={showPopUpForm}>
                    <SingleValuePopUp
                        show={showPopUpForm}
                        onHide={() => setshowPopUpForm(false)}
                        // setSelected={setPopUpData}
                        mastername={popUp.name}
                        paramkey={popUp.key}
                        paramvalue={popUp.value}
                        additionalParams={popUp.additionalParams}
                        backdrop="static"
                        showAddBtn={false}
                    />
                </RenderIf>
            </Container>

        </>
    );

};

export default GDPR; 