import * as XLSX from "xlsx";
import * as XlsxPopulate from "xlsx-populate/browser/xlsx-populate";

export function exportToExcel(fileName, sheetName, excelData, orderResult, teamResult, serviceResult, headerRange, colName, fieldName, colNameOrder, fieldNameOrder, colNameTeam, fieldNameTeam, colNameService, fieldNameService) {
  let wb = XLSX.utils.book_new();
  let header = colName;
  const sheetData = getSheetData(excelData, header, fieldName);
  let ws = XLSX.utils.json_to_sheet(sheetData);
  XLSX.utils.book_append_sheet(wb, ws, sheetName);

  const sheetDataOrder = getSheetData(orderResult, colNameOrder, fieldNameOrder);
  const sheetDataTeam = getSheetData(teamResult, colNameTeam, fieldNameTeam);
  const sheetDataService = getSheetData(serviceResult, colNameService, fieldNameService);

  function getSheetData(exData, header, field) {
    let sheetData = [];
    if (exData.length === 0) {
      let tmpdata = [];
      for (let i = 0; i < header.length; i++) {
        let tmpcol = header[i];
        if (i == 0) {
          console.log([tmpcol]);
          tmpdata.push({
            [tmpcol]: ""
          })
        }
        else {
          tmpdata = tmpdata.map(v => ({ ...v, [tmpcol]: "" }));
        }

      }
      sheetData = [...sheetData, ...tmpdata]
    }
    else {
      for (let n = 0; n < exData.length; n++) {
        let tmpdata = []
        for (let i = 0; i < header.length; i++) {
          let tmpcol = header[i];
          let tmpfield = field[i];
          if (i == 0) {
            tmpdata.push({
              [tmpcol]: exData[n][tmpfield]
            })
          }
          else {
            tmpdata = tmpdata.map(v => ({ ...v, [tmpcol]: exData[n][tmpfield] }));
          }
        }
        sheetData = [...sheetData, ...tmpdata]
      }
    }
    return sheetData;
  }

  let ws1 = XLSX.utils.json_to_sheet(sheetDataOrder);
  XLSX.utils.book_append_sheet(wb, ws1, "Project Order");

  //insert order details
  let ws2 = XLSX.utils.json_to_sheet(sheetDataTeam);
  XLSX.utils.book_append_sheet(wb, ws2, "Project Team");

  //insert order details
  let ws3 = XLSX.utils.json_to_sheet(sheetDataService);
  XLSX.utils.book_append_sheet(wb, ws3, "Project Service");

  const wopts = {
    bookType: "xlsx",
    bookSST: false,
    type: "binary",
  };

  const wbout = XLSX.write(wb, wopts);
  const blob = new Blob([s2ab(wbout)], {
    type: "application/octet-stream",
  });

  XlsxPopulate.fromDataAsync(blob).then((workbook) => {
    workbook.sheets().forEach((sheet) => {      
      sheet.usedRange().style({
        fontFamily: "Calibri",
        verticalAlignment: "center",
        border: "thin",
        horizontalAlignment: "left"
      });
      sheet.range(headerRange).style({
        fill: "FFFFFF",
        border: "thin",
        bold: false,
        fontColor: "000000",
        horizontalAlignment: "left",
      });
    });
    return workbook.outputAsync().then((workbookBlob) => URL.createObjectURL(workbookBlob)).then((url) => {
      const downloadAnchorNode = document.createElement("a");
      downloadAnchorNode.setAttribute("href", url);
      downloadAnchorNode.setAttribute(
        "download",
        fileName
      );
      downloadAnchorNode.click();
      downloadAnchorNode.remove();
    });
  });
}

const s2ab = (s) => {
  // The ArrayBuffer() constructor is used to create ArrayBuffer objects.
  // create an ArrayBuffer with a size in bytes
  const buf = new ArrayBuffer(s.length);

  //create a 8 bit integer array
  const view = new Uint8Array(buf);
  //charCodeAt The charCodeAt() method returns an integer between 0 and 65535 representing the UTF-16 code
  for (let i = 0; i < s.length; ++i) {
    view[i] = s.charCodeAt(i);
  }

  return buf;
};
