import { useState } from "react";
import { BiSearch, BiX } from "react-icons/bi";
import SearchIcon from "../../icons/SearchIcon.svg";
import { BsSearch } from "react-icons/bs";
import { RenderIf } from "../../functions/gbcs-functions";

const GolbalSearch = (props) => {
  const [showGlobal, setShowGlobal] = useState(false);
  const [globalText, setGlobalText] = useState("");

  return (
    <div className={`global-search-box ${showGlobal ? "show" : ""}`}  >
    
<div className="float-end parent-search-download"   style={{ paddingBottom: "20px" }}>
          <div className="records-search" >
            <BsSearch size={13} />
            <input
              type="text"
              value={globalText}
              placeholder="Search here..."
              className="records-search-input"
              onChange={(e) => {
                setGlobalText(e.target.value);
                props.filterFunc(e.target.value);
              }}
            ></input>
          </div>
          
        </div>
      <RenderIf isTrue={showGlobal}>
        <BiX
          size={20}
          color="black"
          style={{ cursor: "pointer" }}
          onClick={(e) => {
            setGlobalText("");
            setShowGlobal(false);
            props.filterFunc("");
          }}
        />
      </RenderIf>
    </div>
  );
};

export default GolbalSearch;
