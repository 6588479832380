import axios from "axios";
import encryptData from "../utils/encryption";

const API_URL = process.env.REACT_APP_API_URL;

export async function getAllUsers() {
  const response = await axios
    .get(API_URL + "/usermaster")
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      console.log("error => ", error);
      return { data: [] };
    });
  let result = response.data;
  for (let element of result) {
    const DecryptUserName = encryptData(element.UserName, "dec");
    element.UserName = DecryptUserName;
    const DecryptUserEmail = encryptData(element.EmailId, "dec");
    element.EmailId = DecryptUserEmail;
    const DecryptuserMobile = encryptData(element.MobileNo, "dec");
    element.MobileNo = DecryptuserMobile;
  }
  return result;
}

export async function getENEActiveUsers(id) {
  let temp = { val: id };
  const response = await axios
    .post(API_URL + "/usermaster/getENEActiveUsers", temp)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      console.log("error => ", error);
      return { data: [] };
    });
  let result = response.data;
  for (let element of result) {
    const DecryptUserName = encryptData(element.UserName, "dec");
    element.UserName = DecryptUserName;
    const DecryptUserEmail = encryptData(element.EmailId, "dec");
    element.EmailId = DecryptUserEmail;
    const DecryptuserMobile = encryptData(element.MobileNo, "dec");
    element.MobileNo = DecryptuserMobile;
  }
  return result;
}

export async function getActiveUsersWithoutENE() {
  const response = await axios
    .get(API_URL + "/usermaster/getActiveUsersWithoutENE")
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      console.log("error => ", error);
      return { data: [] };
    });
  let result = response.data;
  for (let element of result) {
    const DecryptUserName = encryptData(element.UserName, "dec");
    element.UserName = DecryptUserName;
    const DecryptUserEmail = encryptData(element.EmailId, "dec");
    element.EmailId = DecryptUserEmail;
    const DecryptuserMobile = encryptData(element.MobileNo, "dec");
    element.MobileNo = DecryptuserMobile;
  }
  return result;
}

export const userLogin = async (userDetails, accessToken) => {
  let url = API_URL;
  const response = await axios
    .post(url + "/login/login",
      { body: userDetails })
    .then(function (resp) {
      if (resp.status === 200) {
        sessionStorage.setItem(
          "UserData",
          resp?.data ? JSON.stringify(resp?.data) : ""
        );
        sessionStorage.setItem("Token", resp?.data?.data);
      }
      return resp;
    })
    .catch(function (error) {
      console.log("error => ", error);
      return { message: error.response.data.Message, status: false };
    });
  return response;
};


export async function getValidUserData(masterName, filterkey, filtervalue) {
  let url = `${API_URL}/usermaster/getValiddata`;
  console.log("getValidUserData ", url);
  let reqBody = { masterName, filterkey, filtervalue }
  const response = await axios
    .post(url, reqBody)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      console.log("error => ", error);
      return { data: [] };
    });
  console.log(response.data)
  return response.data;

}


export const AddNewUser = async (location) => {
  let url = `${API_URL}/usermaster/adduser`;
  const response = await axios
    .post(url, location)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      console.log("error => ", error);
      return { data: { message: "Unable to find request", status: false } };
    });
  return response.data;
};

export const DeleteUser = async (userId) => {
  let url = `${API_URL}/usermaster/deleteuser`;
  console.log(url)
  let reqBody = { userId }
  const response = await axios
    .post(url, reqBody)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      console.log("error => ", error);
      return { data: { message: "Unable to find request", status: false } };
    });
  return response.data;
};
export const UpdateUser = async (Location) => {
  console.log(Location);
  console.log(typeof (Location));
  let url = `${API_URL}/usermaster/updateuser`;
  console.log(url)
  const response = await axios
    .post(url, Location)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      console.log("error => ", error);
      return { data: { message: "Unable to find request", status: false } };
    });
  return response.data;
};
export const UpdateUserStatus = async (Location) => {
  console.log(Location);
  console.log(typeof (Location));
  let url = `${API_URL}/usermaster/updateuserstatus`;
  console.log(url)
  const response = await axios
    .post(url, Location)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      console.log("error => ", error);
      return { data: { message: "Unable to find request", status: false } };
    });
  return response.data;
};
export async function getHRISActiveUsers() {
  let url = `${API_URL}/usermaster/getactivehrisusers`;
  console.log("getactiveusers ", url);
  const response = await axios
    .get(url)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      console.log("error => ", error);
      return { data: [] };
    });
  return response.data;
}

export async function getUsersForRole(roleCode) {
  let url = `${API_URL}/usermaster/userrole`;
  let reqBody = { roleCode }
  const response = await axios
    .post(url, reqBody)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      console.log("error => ", error);
      return { data: [] };
    });
  return response.data;
}


export async function GetSelectedCompanyCodeUsers(companyCode) {
  let reqBody = { companyCode }
  let url = `${API_URL}/usermaster/getcompanyusers`;
  const resp = await axios
    .post(url, reqBody)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      console.log("error => ", error);
      return { data: [] };
    });
  let result = resp.data;
  for (let element of result) {
    const DecryptUserName = encryptData(element.UserName, "dec");
    element.UserName = DecryptUserName;
    const DecryptUserEmail = encryptData(element.EmailId, "dec");
    element.EmailId = DecryptUserEmail;
    const DecryptuserMobile = encryptData(element.MobileNo, "dec");
    element.MobileNo = DecryptuserMobile;
  }
  return result;
}