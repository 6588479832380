import React, { useEffect, useState, useContext } from "react";
import { Button, Form, Modal, Row, Col } from "react-bootstrap";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { AuthContext } from "../../../contexts/AuthContext";
import successImage from "../../../icons/success-tick-logo.svg";
import errorImage from "../../../icons/error-tick-logo.svg";
import { ConstParameterDetailsHeader } from "./ParameterFile";
import { sortByPropertyInAscending } from "../../../utils/Utility";
import { validDecimals } from "../../../functions/validations";
import { getCurrentDateTime } from "../../../functions/gbcs-functions";
import {
  AddChecklistParameterDetails,
  UpdateChecklistParameterDetails,
} from "../../../services/checklist-master-service";

const AddEditParameterDetail = (props) => {
  const MySwal = withReactContent(Swal);
  const context = useContext(AuthContext);
  const userDetails = { userName: context.userName, userId: context.userId };
  let todayDate = getCurrentDateTime("-");
  const MasterData = props.masterData;
  const selectedParameters = props.selectedParameters;
  let action = props.action;
  const [paramObj, setParamObj] = useState(
    action === "Edit" ? props.rowdata : {}
  );
  const [fieldObj, setFieldObj] = useState([]);
  const [error, setError] = useState({});

  useEffect(() => {
    let obj = [];
    let errObj = {};
    let tmpParamObj = {};
    for (let dt of selectedParameters) {
      let fieldData = ConstParameterDetailsHeader.filter(
        (item) => item.FieldName === dt && item.entryInd
      );
      if (fieldData?.length > 0) {
        obj.push(fieldData[0]);
        errObj[fieldData[0].FieldName] = "";

        if (action === "Add" && (fieldData[0].dataType === "radio" || fieldData[0].dataType === "upload")) {
          tmpParamObj[fieldData[0].FieldName] = fieldData[0].defaultOptionValue;
        }
      }
    }
    obj.sort(sortByPropertyInAscending("displaySrNo"));
    setFieldObj(obj);
    setError(errObj);
    if (action === "Add") {
      setParamObj(tmpParamObj);
    }
  }, []);

  const validateDetails = () => {
    let isError = false;
    const errorMsg = {};

    for (let dt of fieldObj) {
      errorMsg[dt.FieldName] = "";
      console.log("paramObj", paramObj, dt.FieldName, paramObj.hasOwnProperty(dt.FieldName))
      if (paramObj.hasOwnProperty(dt.FieldName)) {
        if (paramObj[dt.FieldName].toString().trim() === "") {
          paramObj[dt.FieldName] = "";
          isError = true;
          errorMsg[dt.FieldName] = {
            status: true,
            message: dt.FieldName + " is required.",
          };
        }
        if (dt.dataType === "number") {
          let err = validDecimals(paramObj[dt.FieldName].toString());
          if (err.status) {
            isError = true;
            errorMsg[dt.FieldName] = err;
          }
          else if (Number(paramObj[dt.FieldName].toString()) < 0) {
            isError = true;
            errorMsg[dt.FieldName] = {
              status: true,
              message: "Enter positive number.",
            };
          }
          else if (paramObj[dt.FieldName].toString().length > dt.maxLength) {
            isError = true;
            errorMsg[dt.FieldName] = {
              status: true,
              message: "Data length should be " + dt.maxLength + ".",
            };
          }
          else {
            paramObj[dt.FieldName] = Number(paramObj[dt.FieldName]);
          }
        }
      } else {
        isError = true;
        errorMsg[dt.FieldName] = {
          status: true,
          message: dt.FieldName + " is required.",
        };
      }
    }
    setError(errorMsg);
    return { isError, errorMsg };
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setParamObj((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let result;
    let validationResult = validateDetails();

    if (!validationResult.isError) {
      let currSeqNo = 0;
      if (action === "Add") {
        currSeqNo = props.rowNo;
      }
      else if (action === "Edit") {
        currSeqNo = paramObj.SeqNo;
      }
      console.log("MasterData", MasterData)
      if (selectedParameters.indexOf("Parameter Category") !== -1 && MasterData.filter((item) => item.hasOwnProperty("Parameter Category") && item["Parameter Category"].toLowerCase() === paramObj["Parameter Category"].toLowerCase() && item["Parameter"].toLowerCase() === paramObj["Parameter"].toLowerCase() && item.SeqNo !== currSeqNo).length > 0) {
        MySwal.fire({
          text: "Parameter Category & Parameter already exists.",
          imageUrl: errorImage,
          customClass: "swal-success",
        });
        return;
      }
      else if (selectedParameters.indexOf("Parameter Category") === -1 && MasterData.filter((item) => item["Parameter"].toLowerCase() === paramObj["Parameter"].toLowerCase() && item.SeqNo !== currSeqNo).length > 0) {
        MySwal.fire({
          text: "Parameter already exists.",
          imageUrl: errorImage,
          customClass: "swal-success",
        });
        return;
      }


      if (action === "Add") {
        paramObj.PK = props.primaryKey;
        paramObj.SK = "SeqNo#" + props.rowNo;
        paramObj.SeqNo = props.rowNo;
        paramObj.CreatedOn = todayDate;
        paramObj.CreatedBy = userDetails.userId;
        paramObj.CreatedByName = userDetails.userName;
        paramObj.ModifiedOn = todayDate;
        paramObj.ModifiedBy = userDetails.userId;
        paramObj.ModifiedByName = userDetails.userName;
        if (!paramObj.hasOwnProperty("Attachment")) {
          paramObj.Attachment = "No";
        }

        result = AddChecklistParameterDetails(paramObj);
      } else if (action === "Edit") {
        let obj = Object.assign({}, paramObj)
        delete obj.SeqNo;
        delete obj.CreatedOn;
        delete obj.CreatedBy;
        delete obj.CreatedByName;
        obj.ModifiedOn = todayDate;
        obj.ModifiedBy = userDetails.userId;
        obj.ModifiedByName = userDetails.userName;
        if (!obj.hasOwnProperty("Attachment")) {
          obj.Attachment = "No";
        }
        result = UpdateChecklistParameterDetails(obj);
      }
      result
        .then((resp) => {
          if (resp.status) {
            props.setAddNewParameter(paramObj);
            MySwal.fire({
              text: resp.message,
              imageUrl: successImage,
              customClass: "swal-success",
              didClose: () => {
                props.onHide();
              },
            });
          }
          else {
            MySwal.fire({
              text: resp.message,
              imageUrl: errorImage,
              customClass: "swal-success",
            });
          }
        })
        .catch((error) => {
          MySwal.fire({
            text: "Unable to Process Request",
            imageUrl: errorImage,
            customClass: "swal-success",
          });
        });
    }
  };

  return (
    <Modal {...props} size="lg" backdrop="static" centered>
      <Modal.Header closeButton>
        <Modal.Title>
          {action} Parameter
          <span className="req-input" >
            &nbsp;(All fields are mandatory)
          </span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form id="addparameter" name="addparameter" autoComplete="off">
          <Row>
            {fieldObj?.map((dt, ind) => {
              return (
                <Col md={4} xs={12}>
                  {dt.dataType !== "radio" && dt.dataType !== "upload" ?
                    <Form.Group className="g-input">
                      <Form.Control
                        id={"add" + dt.FieldName}
                        type={dt.dataType}
                        name={dt.FieldName}
                        maxLength={dt.maxLength}
                        placeholder=" "
                        required
                        value={paramObj[dt.FieldName]}
                        onChange={handleChange}
                      />
                      <Form.Label className="frm-label" htmlFor={"add" + dt.FieldName}>
                        {dt.FieldName}
                      </Form.Label>
                      {error[dt.FieldName].status && (
                        <Form.Text className="text-danger">
                          {error[dt.FieldName].message}
                        </Form.Text>
                      )}
                    </Form.Group>
                    :
                    <Form.Group>
                      <Row><Form.Label className="fieldLabel locationType">{dt.FieldName}</Form.Label></Row>
                      <Row>
                        {dt.optionValues.map((op) => {
                          return (
                            <Col lg={6} xs={6}>
                              <Form.Check className="fieldLabel locationType"
                                id={"add" + dt.FieldName}
                                name={dt.FieldName}
                                value={op}
                                type="radio"
                                label={op}
                                onChange={handleChange}
                                checked={paramObj[dt.FieldName] === op}
                              />
                            </Col>
                          )
                        })}
                        {error[dt.FieldName].status && (
                          <Form.Text className="text-danger">
                            {error[dt.FieldName].message}
                          </Form.Text>
                        )}
                      </Row>
                    </Form.Group>
                  }
                </Col>
              );
            })}
          </Row>
          <Row>
            <Col>
              <Button
                variant="success"
                className="btn-add-color semiBoldToggleButton rightAlignedButton"
                onClick={handleSubmit}
              >
                {action === "Add" ? "Add" : "Update"}
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default AddEditParameterDetail;
