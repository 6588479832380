import React, { useState } from "react";
import { Container, Spinner } from "react-bootstrap";
import ActionComp from "../layouts/ActionComp";
import BreadcrumbComp from "../layouts/BreadcrumbComp";
import StatusRenderer from "../masters/StatusRenderer.jsx";
import ActionRenderer from "../masters/ActionRenderer";
import GridMaster from "../masters/GridMaster";
import { useDispatch } from "react-redux";
import { menuActions } from "../../store/menu-slice";
import { exportToExcel } from "../utils/ExcelCreation";
import AddEditDesignReviewTracker from "./AddEditDesignReviewTracker";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import successImage from "../../icons/success-tick-logo.svg";
import errorImage from "../../icons/error-tick-logo.svg";
import { getDesignReviewTrackerData, deleteDesignReviewTracker } from "../../services/designreviewtracker-service"
import { formatDateInDDMMYYYY, formatDateInYYYYMMDD, formatDateDD_MM_YYYY } from "../../functions/gbcs-functions";
import { ServiceMasterAllData, deletS3Attachment } from "../../services/common-master-service"
import { ALERT_PARAMS } from "../../utils/constant.js";

const DesignReviewTracker = () => {

    const dispatch = useDispatch();
    dispatch(menuActions.setMenu("Design Review Tracker"));
    const MySwal = withReactContent(Swal);

    const [rowData, setRowData] = useState({});
    const [filteredResult, setFilteredResult] = useState([]);
    const [masterData, setMasterData] = useState([]);
    const [filteredResulttemp, setFilteredResulttemp] = useState([]);
    const [filterColLength, setFilterColLength] = useState(0);
    const [action, setAction] = useState("");
    const [showForm, setShowForm] = useState(false);
    const [excelData, setExcelData] = useState([]);
    const [SelectedProject, setSelectedProject] = useState([]);
    const [loading, setLoading] = useState(false);
    const [actionButtons, setActionButtons] = useState({});

    const columnDefs = [
        {
            headerName: "Sr.No.",
            field: "SrNo",
            valueFormatter: (params) => {
                return params.node.rowIndex + 1;
            },
            minWidth: 100
        },
        {
            headerName: "Status",
            field: "DRTStatus",
            flex: false,
            width: 150,
            resizable: false,
            tooltipField: "DRTStatus",
            cellRenderer: StatusRenderer,
        },
        {
            headerName: "Issue Date",
            field: "IssueDate",
            minWidth: 150,
            tooltipField: "IssueDate",
            filter: "agDateColumnFilter",
            valueFormatter: (params) => {
                return formatDateInDDMMYYYY(params.data.IssueDate);
            },
            filterParams: {
                comparator: (filterLocalDateAtMidnight, cellValue) => {
                    const dateAsString = cellValue;
                    if (dateAsString == null) {
                        return 0;
                    }
                    const dateCell = new Date(formatDateInYYYYMMDD(cellValue) + " 00:00:00");

                    if (dateCell < filterLocalDateAtMidnight) {
                        return -1;
                    } else if (dateCell > filterLocalDateAtMidnight) {
                        return 1;
                    }
                    return 0;
                },
            },
        },
        {
            headerName: "Document Refrence",
            field: "DocumentRefrence",
            tooltipField: "DocumentRefrence",
            minWidth: 150,
        },
        {
            headerName: "Revision No",
            field: "RevisionNo",
            minWidth: 130,
            tooltipField: "RevisionNo",
        },
        {
            headerName: "Page No",
            field: "PageNo",
            minWidth: 150,
            tooltipField: "PageNo",
            filter: "agNumberColumnFilter",
            sortable: true,
            comparator: ""
        },
        {
            headerName: "Service Name",
            field: "ServiceName",
            minWidth: 150,
            tooltipField: "ServiceName",
        },
        {
            headerName: "Section No",
            field: "SectionNo",
            minWidth: 150,
            tooltipField: "SectionNo",
        },
        {
            headerName: "Company/Division",
            field: "CompanyName",
            minWidth: 150,
            tooltipField: "CompanyName",
        },
        {
            headerName: "Criticality",
            field: "Criticality",
            minWidth: 130,
            tooltipField: "Criticality",
        },
        {
            headerName: "Godrej Comment",
            field: "GodrejComment",
            minWidth: 150,
            tooltipField: "GodrejComment",
        },
        {
            headerName: "Consultant Response",
            field: "ConsultantResponse",
            minWidth: 150,
            tooltipField: "ConsultantResponse",
        },
        {
            headerName: "Last Update/Closure Date",
            field: "LastUpdateClosureDate",
            minWidth: 150,
            tooltipField: "LastUpdateClosureDate",
            filter: "agDateColumnFilter",
            valueFormatter: (params) => {
                return formatDateInDDMMYYYY(params.data.LastUpdateClosureDate);
            },
            filterParams: {
                comparator: (filterLocalDateAtMidnight, cellValue) => {
                    const dateAsString = cellValue;
                    if (dateAsString == null) {
                        return 0;
                    }
                    const dateCell = new Date(formatDateInYYYYMMDD(cellValue) + " 00:00:00");

                    if (dateCell < filterLocalDateAtMidnight) {
                        return -1;
                    } else if (dateCell > filterLocalDateAtMidnight) {
                        return 1;
                    }
                    return 0;
                },
            },
        },
        {
            headerName: "Closing Remark",
            field: "ClosingRemark",
            minWidth: 150,
            tooltipField: "ClosingRemark",
        },
        {
            field: "",
            width: 120,
            cellRenderer: function (params) {
                return <ActionRenderer selectedData={params.data} handleViewEvent={handleView} handleEditEvent={handleEdit} handleDeleteEvent={handleDelete} actionName={actionButtons} />
            },
            sortable: false,
            filter: false,
            pinned: 'right',
            resizable: false
        },
    ];

    const headerName = (field) => {
        let name;
        switch (field) {
            case "DRTStatus":
                name = "Status";
                break;
            case "IssueDate":
                name = "Issue Date";
                break;
            case "DocumentRefrence":
                name = "Document Refrence";
                break;
            case "RevisionNo":
                name = "Revision No";
                break;
            case "PageNo":
                name = "Page No";
                break;
            case "ServiceName":
                name = "Service Name";
                break;
            case "SectionNo":
                name = "Section No";
                break;
            case "CompanyName":
                name = "Company/Division";
                break;
            case "Criticality":
                name = "Criticality";
                break;
            case "GodrejComment":
                name = "Godrej Comment";
                break;
            case "ConsultantResponse":
                name = "Consultant Response";
                break;
            case "LastUpdateClosureDate":
                name = "Last Update/Closure Date";
                break;
            case "ClosingRemark":
                name = "Closing Remark";
                break
            default:
                name = "No match found";
                break;
        }
        return name;
    };

    const fieldName = (header) => {
        let name;
        switch (header) {
            case "Status":
                name = "DRTStatus";
                break;
            case "Issue Date":
                name = "IssueDate";
                break;
            case "Document Refrence":
                name = "DocumentRefrence";
                break;
            case "Revision No":
                name = "RevisionNo";
                break;
            case "Page No":
                name = "PageNo";
                break;
            case "Service Name":
                name = "ServiceName";
                break;
            case "Section No":
                name = "SectionNo";
                break;
            case "Company/Division":
                name = "CompanyName";
                break;
            case "Criticality":
                name = "Criticality";
                break;
            case "Godrej Comment":
                name = "GodrejComment";
                break;
            case "Consultant Response":
                name = "ConsultantResponse";
                break;
            case "Last Update/Closure Date":
                name = "LastUpdateClosureDate";
                break;
            case "Closing Remark":
                name = "ClosingRemark";
                break;
            default:
                name = "No match found";
                break;
        }
        return name;
    };
    const handleView = async (row) => {
        setAction("View");
        setRowData(row);
        setShowForm(true);
    }

    const handleEdit = async (row) => {
        if (row.FreezeInd === "Yes") {
            MySwal.fire({
                text: "Status of the document is " + row.DRTStatus + ".",
                imageUrl: errorImage,
                customClass: "swal-success",
            });
        }
        else {
            setAction("Edit");
            setRowData(row);
            setShowForm(true);
        }

    };

    const handleGlobalExport = async () => {
        if (excelData.length > 0) {

            let filename = "DesignRevicewTracker";
            let excelDownoadData = [];
            let temp = [];
            excelData.forEach(element => {
                const found = masterData.find(test => test.DocumentRefrence === element.DocumentRefrence);
                if (found !== undefined) {
                    temp.push(found);
                }
            })
            for (let i = 0; i < temp.length; i++) {
                excelDownoadData.push({
                    "ProjectCode": temp[i].ProjectCode,
                    "ProjectName": temp[i].ProjectName,
                    "SeqNo": temp[i].SeqNo,
                    "IssueDate": temp[i].IssueDate,
                    "DocumentRefrence": temp[i].DocumentRefrence,
                    "RevisionNo": temp[i].RevisionNo,
                    "PageNo": temp[i].PageNo,
                    "ServiceName": temp[i].ServiceName,
                    "SectionNo": temp[i].SectionNo,
                    "CompanyDivision": temp[i].CompanyName,
                    "Criticality": temp[i].Criticality,
                    "GodrejComment": temp[i].GodrejComment,
                    "ConsultantResponse": temp[i].ConsultantResponse,
                    "RefrenceDocument": temp[i].RefrenceDocument,
                    "LastUpdateClosureDate": temp[i].LastUpdateClosureDate,
                    "ClosingRemark": temp[i].ClosingRemark,
                    "DRTStatus": temp[i].DRTStatus,
                    "CreatedByName": temp[i].CreatedByName,
                    "CreatedOn": formatDateInDDMMYYYY(temp[i].CreatedOn),
                    "ModifiedByName": temp[i].ModifiedByName,
                    "ModifiedOn": formatDateInDDMMYYYY(temp[i].ModifiedOn ? temp[i].ModifiedOn : ""),
                });
            }

            let colName = ["Project", "Sr No.", "Issue Date", "Document Reference", "Revision No.", "Page No.", "Service Name", "Section No", "Company/Division", "Criticality", "Godrej Comments", "Consutant Response", "Reference Document", "Last Update / Closure Date", "Closing Remarks", "Status", "Inserted By", "Inserted On", "Modified By", "Modified On"];
            let Fieldname = ["ProjectName", "SeqNo", "IssueDate", "DocumentRefrence", "RevisionNo", "PageNo", "ServiceName", "SectionNo", "CompanyDivision", "Criticality", "GodrejComment", "ConsultantResponse", "RefrenceDocument", "LastUpdateClosureDate", "ClosingRemark", "DRTStatus", "CreatedByName", "CreatedOn", "ModifiedByName", "ModifiedOn"];
            exportToExcel(filename, "Data", excelDownoadData, "A1:T1", colName, Fieldname);
        }
    };

    const handleDelete = async (params) => {
        let result;
        if (params.DRTStatus === "Open") {
            MySwal.fire({
                text: "Do you want to Delete the Record?",
                confirmButtonText: "Delete",
                title: "Delete Design Review Tracker",
                ...ALERT_PARAMS
            }).then(async (result2) => {
                if (result2.isConfirmed) {
                    setLoading(true);
                    result = deleteDesignReviewTracker(params);
                    result.then((resp) => {
                        if (params.RefrenceDocument != "") {
                            let fileObj = { fileName: params.RefrenceDocument };
                            let result3 = deletS3Attachment(fileObj);
                        }
                        setLoading(false);
                        MySwal.fire({
                            text: resp.message,
                            imageUrl: successImage,
                            customClass: "swal-success",
                            didClose: () => {
                                getDesignReviewTracker(params.ProjectCode);
                            },
                        });

                    }).catch((error) => {
                        setLoading(false);
                        MySwal.fire({
                            text: "Unable to process request.",
                            imageUrl: errorImage,
                            customClass: "swal-success",
                        });
                    })
                        .finally(() => {
                            getDesignReviewTracker(params.ProjectCode);
                        });
                }
            });
        }
        else {
            MySwal.fire({
                text: "Status of the document is " + params.DRTStatus + ".",
                imageUrl: errorImage,
                customClass: "swal-success",
            });
        }

    }

    const handleAdd = () => {
        setAction("Add");
        setShowForm(true);
    };

    const SelectedPopUpProject = async (data) => {
        getDesignReviewTracker(data.ProjectCode);
        setSelectedProject(data);
        if (data.DeleteIndication !== "Active") {
            setActionButtons({
                ViewAction: true,
                EditAction: false,
                DownloadAction: false,
                DeleteAction: false,
            });
        }
        else {
            setActionButtons({});
        }
    }

    const getDesignReviewTracker = async (projCode) => {
        setLoading(true);
        const result = await getDesignReviewTrackerData(projCode);
        const serviceMaster = await ServiceMasterAllData();
        for (let dt of result) {
            let serviceData = serviceMaster.filter((item) => item.ServiceCode === dt.ServiceCode);
            if (serviceData.length > 0) {
                dt.ServiceName = serviceData[0].ServiceName;
            } else {
                dt.ServiceName = "";
            }
            // dt.IssueDate = formatDateInDDMMYYYY(dt.IssueDate);
            // dt.LastUpdateClosureDate = formatDateInDDMMYYYY(dt.LastUpdateClosureDate);
        }
        setMasterData(result);
        setFilteredResult(result);
        setExcelData(result);
        setLoading(false);
    }

    return (
        <>
            {loading && <div className="spinner-box"> <Spinner animation="border" className="spinner" /></div>}
            <Container fluid className="main-container">
                <BreadcrumbComp />
                <ActionComp label={"Add New"} isProjectTrue={true} exportEvent={() => handleGlobalExport()} handleAdd={handleAdd} setSelectedProject={SelectedPopUpProject} />
                <GridMaster
                    rowDetails={filteredResult}
                    colDetails={columnDefs}
                    fieldNames={fieldName}
                    headerNames={headerName}
                    getDataEvent={(getFilteredData) => setFilteredResulttemp(getFilteredData)}
                    getFilterColLength={(getLength) => setFilterColLength(getLength)}
                    setExcelData={setExcelData}
                    handleView={handleView}
                    handleEdit={handleEdit}
                    openConfirmBox={handleDelete}
                    actionName={actionButtons}
                />
                {showForm && (
                    <AddEditDesignReviewTracker
                        show={showForm}
                        action={action}
                        rowdata={rowData}
                        masterData={masterData}
                        SelectedProject={SelectedProject}
                        onHide={() => setShowForm(false)}
                        getDesignReviewTracker={getDesignReviewTracker}
                    />
                )}
            </Container>
        </>
    );

}

export default DesignReviewTracker;
