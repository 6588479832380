import GridMaster from "../components/masters/GridMaster";
import { useDispatch } from "react-redux";
import { roleActions } from "../store/role-slice";
import { useState } from "react";
import { getUserInitials } from "../utils/Utility";
import infoImage from "../icons/infoblue.svg";

export const RenderIf = ({ children, isTrue }) => {
  return isTrue ? children : null;
};

export function getInitials(userName) {
  if (userName) {
    let names = userName.split(" "),
      initials = names[0].substring(0, 1).toUpperCase();

    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
  }
}

export function getFirstAlphabet(userName) {
  if (userName) {
    let names = userName.split(" "),
      initials = names[0].substring(0, 1).toUpperCase();
    return initials;
  }
}


export function getCurrentDateTime(separator = "/") {
  let newDate = new Date();
  let date = newDate.getDate();
  let month = newDate.getMonth() + 1;
  let year = newDate.getFullYear();
  let hours = newDate.getHours();
  let minutes = newDate.getMinutes();
  let seconds = newDate.getSeconds();
  date = date < 10 ? "0" + date : date;
  month = month < 10 ? "0" + month : month;
  hours = hours < 10 ? "0" + hours : hours;
  minutes = minutes < 10 ? "0" + minutes : minutes;
  seconds = seconds < 10 ? "0" + seconds : seconds;

  return `${year}${separator}${month}${separator}${date} ${hours}:${minutes}:${seconds}`;
}

export function getTodaysDate() {
  let todayDate = new Date();
  todayDate = todayDate.toISOString().substring(0, 10);
  return todayDate;
}

export function formatDateInDDMMYYYY(dateStr) {
  if (dateStr !== undefined && dateStr !== "") {
    let newDate = "";
    let separator = "/";
    let str = "";
    let time = "";
    if (dateStr.indexOf(" ") !== -1) {
      [str] = dateStr.split(" ");
      time = dateStr.split(" ")[1];
    } else {
      str = dateStr;
    }
    if (str !== "") {
      let [year, month, day] = str.split("-");
      newDate = `${day}${separator}${month}${separator}${year} ${time}`;
    }
    return newDate;
  } else {
    return "";
  }
}

const contains = (target, lookingFor) => {
  return target && target.indexOf(lookingFor) >= 0;
};

export function myTextMatchFunc(value, filterText, aliases) {
  let filterTextLowerCase = filterText ? filterText.toLowerCase() : "";
  let valueLowerCase = value.toString().toLowerCase();
  let literalMatch = contains(valueLowerCase, filterTextLowerCase);
  return (
    !!literalMatch || !!contains(valueLowerCase, aliases[filterTextLowerCase])
  );
}

export const resetAllFilter = (gridRef) => {
  gridRef.current.api.setFilterModel(null);
};

export function resetFilter(gridRef, item, feildName) {
  let item1 = item.substring(0, item.indexOf(":") - 1);
  let col = gridRef.current.api.getFilterModel();
  for (const key of Object.keys(col)) {
    if (key === feildName(item1)) {
      delete col[key];
    }
  }
  gridRef.current.api.setFilterModel(col);
}

const customComparator = (valueA, valueB) => {
  return valueA?.toLowerCase().localeCompare(valueB?.toLowerCase());
};

export function defaultColParas() {
  return {
    flex: 1,
    sortable: true,
    resizable: true,
    textAlign: "center",
    suppressMovable: true,
    suppressRowTransform: true,
    //width: 100,
    // minWidth: 150,
    // maxWidth: 180,
    enableCellChangeFlash: true,
    filterParams: {
      buttons: ["reset", "apply"],
      closeOnApply: true,
      defaultJoinOperator: "OR",
    },
    filter: "agTextColumnFilter",
    comparator: customComparator
  };
}

function pad2(n) {
  return (n < 10 ? "0" : "") + n;
}

export function formatDateDD_MM_YYYY(input) {
  if (input !== "") {
    let input1 = new Date(input);
    const newDate = `${pad2(input1.getDate())}-${pad2(
      input1.getMonth() + 1
    )}-${pad2(input1.getFullYear())}`;
    //console.log("dates", input, newDate);

    return newDate;
  }
  else {
    return "";
  }
}

export function formatDateInYYYYMMDD(dtStr, dateSeperator = "/") {
  if (dtStr !== "") {
    let newDate = "";
    let separator = "-";
    let str = "";
    let time = "";
    if (dtStr.indexOf(" ") !== -1) {
      [str] = dtStr.split(" ");
      time = dtStr.split(" ")[1];
    } else {
      str = dtStr;
    }
    if (str !== "") {
      let [day, month, year] = str.split(dateSeperator);
      newDate = `${year}${separator}${month}${separator}${day}`;
    }
    return newDate;
  } else {
    return "";
  }
}

export const FilterChange = (param, setFilteredCol, headerName, setRowData, setExcelData) => {
  setFilteredCol([]);
  setRowData([]);
  let col = param.api.getFilterModel();
  console.log("col", col);

  if (param.api.getDisplayedRowCount() === 0) {
    param.api.showNoRowsOverlay();
  } else {
    param.api.hideOverlay();
  }
  if (Object.keys(col).length === 0) {
    //   a.classList.add("d-none");
    console.log("hi");
  } else {
    // a.classList.remove("d-none");
    for (const key of Object.keys(col)) {
      let filter;
      if (col[key].filterType === "date") {
        if (col[key].condition1) {
          let fromDate1 = col[key].condition1.dateFrom
            ? formatDateDD_MM_YYYY(col[key].condition1.dateFrom)
            : col[key].condition1.dateFrom;
          let toDate1 = col[key].condition1.dateTo
            ? formatDateDD_MM_YYYY(col[key].condition1.dateTo)
            : col[key].condition1.dateTo;
          let fromDate2 = col[key].condition2.dateFrom
            ? formatDateDD_MM_YYYY(col[key].condition2.dateFrom)
            : col[key].condition2.dateFrom;
          let toDate2 = col[key].condition2.dateTo
            ? formatDateDD_MM_YYYY(col[key].condition2.dateTo)
            : col[key].condition2.dateTo;
          filter =
            headerName(key) +
            " : " +
            fromDate1 +
            (toDate1 ? " To " + toDate1 : "") +
            ", " +
            fromDate2 +
            (toDate2 ? " To " + toDate2 : "");
          console.log("true date");
        } else {
          filter =
            headerName(key) +
            " : " +
            col[key].dateFrom?.substring(0, 10) +
            (col[key].dateTo ? " To " + col[key].dateTo?.substring(0, 10) : "");
          console.log("false date", col[key].dateFrom?.substring(0, 10));
        }
      } else if (col[key].filterType === "number") {
        if (col[key].condition1) {
          filter =
            headerName(key) +
            " : " +
            col[key].condition1.filter +
            (col[key].condition1.filterTo
              ? " To " + col[key].condition1.filterTo
              : "") +
            ", " +
            col[key].condition2.filter +
            (col[key].condition2.filterTo
              ? " To " + col[key].condition2.filterTo
              : "");
        } else {
          filter =
            headerName(key) +
            " : " +
            col[key].filter +
            (col[key].filterTo ? " To " + col[key].filterTo : "");
        }
      } else {
        if (col[key].condition1) {
          filter =
            headerName(key) +
            " : " +
            col[key].condition1.filter +
            ", " +
            col[key].condition2.filter;
        } else {
          filter = headerName(key) + " : " + col[key].filter;
        }
      }

      let temp = [];
      param.api.forEachNodeAfterFilter(node => {
        temp.push(node.data);
      });

      setRowData(temp);
      setExcelData(temp);
      setFilteredCol((prev) => {
        return [...prev, filter];
      });
    }

  }
};

export function formatDateYYYY_MM_DD(input) {
  let input1 = new Date(input);
  const newDate = `${pad2(input1.getFullYear())}-${pad2(
    input1.getMonth() + 1
  )}-${pad2(input1.getDate())}`;
  //console.log("dates", input, newDate);
  return newDate;
}

export function getData(param) {
  // let col = param.api.getRenderedNodes();
  let filteredData = [];
  let col = param.forEachNodeAfterFilter(node => filteredData.push(node.data));

  // console.log("col", col.length); 
  // let filteredData = [];
  // for( let dt of col ) {
  //   filteredData.push(dt.data)
  // }
  //console.log("filteredData gbcs func",filteredData)
  return filteredData;
}

export function defaultEditableCol() {
  return {
    flex: 1,
    resizable: true,
    textAlign: "center",
    //sortable: true,
    editable: true
  };
}

export function getTimeAMPMFormat(date) {
  if (date !== undefined && date !== "") {
    let hours = new Date(date).getHours();
    let minutes = new Date(date).getMinutes();
    // Check whether AM or PM
    let newformat = hours >= 12 ? 'pm' : 'am';
    // Find current hour in AM-PM Format
    hours = hours % 12;
    // To display "0" as "12"
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    return (hours + ':' + minutes + newformat);
  }
  else {
    return "";
  }
}

export function UserNameInitials(params) {
  if (params.value != undefined && params.value != "") {
    let initials = getUserInitials(params.value);
    return (
      <div className="user-name-initials">
        <div className={"user-initials user-initials-color"}>
          <span>{initials}</span>
        </div>
      </div>)
  }
};

export function DashboardProjDataContainer(params) {
  let currentDate = formatDateInDDMMYYYY(getTodaysDate());
  return (
    <div className="dashboard-projects commonBorder">
      <span className="modalTitle" >
        {params.TabString} {params.SelectedProject.CustomerName}, {params.SelectedProject.Location} - {params.SelectedProject.ProjectName}
        &nbsp;
        <img
          src={infoImage}
          id="info"
          alt="info"
          onClick={params.handleInfoClick}
        />
      </span>
      <br />
      <span className="service-equip-labeltext">
        Project Completion Date - {formatDateInDDMMYYYY(params.SelectedProject.ProjEndDate)}
        |
        Today's Date - {currentDate}
      </span>
    </div>
  );
};