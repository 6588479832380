import React, { useEffect, useState, useRef, useMemo, forwardRef, useImperativeHandle, useContext } from "react";
import { Link } from 'react-router-dom';
import { Container, Card, Form, Row, Col, Spinner } from "react-bootstrap";
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import "../masters/GridMaster.css";
import { AgGridReact } from "ag-grid-react";
import { defaultColParas, getCurrentDateTime } from "../../functions/gbcs-functions";
import { getProjDocTabsData } from "../../services/project-document-service";
import Pagination from "../utils/Pagination";
import { addEquipmentParameterDetails, getEquipmentParameterData } from "../../services/equipmentactivity-master-service";
import { AuthContext } from "../../contexts/AuthContext";

const ProjectDocumentLeftPanel = forwardRef((props, ref) => {
    const [gridApi, setGridApi] = useState(null);
    const gridRef = useRef();
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize] = useState(3);
    const [tmpSrvType, setTmpSrvType] = useState("");
    const [tmpEquipCode, setTmpEquipCode] = useState("");
    const [equipArr, setEquipArr] = useState([]);
    const [equipList, setEquipList] = useState([]);
    let tabName = props.paramtabname;
    const [projectServiceData, setProjectServiceData] = useState([]);
    const [loading, setLoading] = useState(false);
    let todayDate = getCurrentDateTime("-");
    const context = useContext(AuthContext);
    const userDetails = { userId: context.userId, userName: context.userName }

    const columnDefs = [
        {
            headerName: "Service Name",
            headerTooltip: "Service Name",
            field: "ServiceName",
            tooltipField: "ServiceName",
            minWidth: 130
        },
        {
            headerName: "Service Type",
            field: "ServiceType",
            width: 150,
            minWidth: 130,
            tooltipField: "ServiceType"
        },
        {
            headerName: "Equipment / Activity Code",
            headerTooltip: "Equipment / Activity Code",
            field: "EquipmentCode",
            tooltipField: "EquipmentCode",
            minWidth: 150
        },
        {
            headerName: "Equipment / Activity Name",
            headerTooltip: "Equipment / Activity Name",
            field: "EquipmentName",
            tooltipField: "EquipmentName",
            minWidth: 180
        },
        {
            headerName: "FAT Applicable Checklist Category",
            headerTooltip: "FAT Applicable Checklist Category",
            field: "FATEquipApplicableChecklist",
            tooltipField: "FATEquipApplicableChecklist",
            minWidth: 180
        },
        {
            headerName: "SAT Applicable Checklist Category",
            headerTooltip: "SAT Applicable Checklist Category",
            field: "SATEquipApplicableChecklist",
            tooltipField: "SATEquipApplicableChecklist",
            minWidth: 180
        }
    ];

    ///Check grid master
    function onGridReady(params) {
        setGridApi(params.api);
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        params.api.resetRowHeights();
        gridRef.current = params.api;// <= assigned gridApi value on Grid ready
    }

    const defaultColDef = useMemo(() => {
        return defaultColParas();
    }, []);


    useEffect(() => {
        setLoading(true);
        let tempArr = [];
        let ProjectServiceDt = props.projectservicedata;
        if (ProjectServiceDt?.length > 0) {
            for (let item of ProjectServiceDt) {
                let findobj = tempArr.filter(x => x.EquipmentCode === item.EquipmentCode);
                if (findobj.length < 1) {
                    tempArr.push(item);
                }
            }
        }
        setProjectServiceData(tempArr);
        setEquipArr(tempArr);
        setEquipList(tempArr);
        setLoading(false);
    }, []);

    const onRowClicked = async (event) => {
        setLoading(true)
        let requiredt;
        console.log("event", event)
        if (props.webscreenflag) {
            requiredt = event.node.data;
        }
        else {
            requiredt = event;
        }
        let keyObj = { "PK": "ProjectDocuments#ProjectCode#" + props.projectdata.ProjectCode, "SK": tabName + "#EquipmentCode#" + requiredt.EquipmentCode + "#SeqNo#" }
        let equipPrereqbuisiteData = await getProjDocTabsData(keyObj);
        if (equipPrereqbuisiteData?.length === 0 && props.projectdata.DeleteIndication === "Active") {
            equipPrereqbuisiteData = await getEquipmentParameterData({ "code": requiredt.EquipmentCode, "tabName": tabName }, "master")
            if (equipPrereqbuisiteData?.length > 0) {
                let counter = 0;
                let array = [];
                for (let dt of equipPrereqbuisiteData) {
                    dt.ProjectCode = props.projectdata.ProjectCode;
                    dt.ServiceCode = requiredt.ServiceCode;
                    dt.ServiceName = requiredt.ServiceName;
                    dt.EquipmentName = requiredt.EquipmentName;
                    dt.EquipmentCode = requiredt.EquipmentCode;
                    dt.CreatedOn = todayDate;
                    dt.CreatedBy = userDetails.userId;
                    dt.CreatedByName = userDetails.userName;
                    dt.ModifiedOn = todayDate;
                    dt.ModifiedBy = userDetails.userId;
                    dt.ModifiedByName = userDetails.userName;
                    let tmpRes = addEquipmentParameterDetails(dt, tabName, requiredt.EquipmentCode, "new", "ProjectDocuments");
                    array.push(tmpRes);
                    counter++;

                    if (counter === 5) {
                        await Promise.all(array);
                        counter = 0;
                        array = [];
                    }
                }
                if (array.length > 0) {
                    await Promise.all(array);
                }
                equipPrereqbuisiteData = await getProjDocTabsData(keyObj);
            }
        }
        props.equipobj(requiredt);
        props.enteredTabData(equipPrereqbuisiteData);

        if (!props.webscreenflag) {
            props.panelrespshow(false);
        }
        setLoading(false);
    };

    const handleServiceTypeChange = (e) => {
        let { value } = e.target;
        let temparr = [];
        let tmpEqui = [];
        if (value === "") {
            if (tmpEquipCode) {
                for (let item of projectServiceData) {
                    if (item.EquipmentCode === tmpEquipCode) {
                        temparr.push(item);
                    }
                }
            }
            else {
                temparr = projectServiceData;
            }
            tmpEqui = projectServiceData;
        }
        else {
            for (let item of projectServiceData) {
                if (item.ServiceType === value) {
                    if (tmpEquipCode === "" || item.EquipmentCode === tmpEquipCode) {
                        temparr.push(item);
                    }
                    tmpEqui.push(item);
                }
            }
        }
        setEquipArr(temparr);
        setEquipList(tmpEqui);
        setTmpSrvType(value);
    }

    const handleEquipCodeChange = (e) => {
        let { value } = e.target;
        let temparr = [];
        if (value === "") {
            if (tmpSrvType) {
                for (let item of projectServiceData) {
                    if (item.ServiceType === tmpSrvType) {
                        temparr.push(item);
                    }
                }
            }
            else {
                temparr = projectServiceData;
            }
        }
        else {
            for (let item of projectServiceData) {
                if (item.EquipmentCode === value) {
                    if (tmpSrvType === "" || item.ServiceType === tmpSrvType) {
                        temparr.push(item);
                    }
                }
            }
        }
        setEquipArr(temparr);
        setTmpEquipCode(value);
    }

    return (
        <>
            {loading && <div className="spinner-box"> <Spinner animation="border" className="spinner" /></div>}
            <Container fluid className="projdoc-comp-container right-padding-resize">
                <div className="projDocLeftPanel wordBreak">Equipment Details</div>
                <div className="ag-theme-alpine agGridContainerEquip d-none d-md-none d-lg-block">
                    <AgGridReact
                        ref={gridRef}
                        onRowClicked={onRowClicked}
                        rowData={equipArr}
                        columnDefs={columnDefs}
                        defaultColDef={defaultColDef}
                        enableBrowserTooltips={true}
                        onGridReady={onGridReady}
                    />
                </div>
                <div className="market-wrapper d-md-none d-lg-none cardContainerResponsive">
                    <Row>
                        <Col xs={12}>
                            <Form.Group className="g-select">
                                <Form.Select
                                    id="tmpSrvType"
                                    name="tmpSrvType"
                                    type="text"
                                    placeholder=" "
                                    value={tmpSrvType}
                                    onChange={(e) => { handleServiceTypeChange(e) }}
                                >
                                    <option value="" >Select</option>
                                    <option key="Equipment" value="Equipment">Equipment</option>
                                    <option key="Activity" value="Activity">Activity</option>
                                </Form.Select>
                                <Form.Label className="frm-label" for="tmpSrvType">Service Type</Form.Label>
                            </Form.Group>
                        </Col>
                        <Col xs={12}>
                            <Form.Group className="g-select">
                                <Form.Select
                                    id="tmpEquipCode"
                                    name="tmpEquipCode"
                                    type="text"
                                    placeholder=" "
                                    value={tmpEquipCode}
                                    onChange={(e) => { handleEquipCodeChange(e) }}
                                >
                                    <option value="" >Select</option>
                                    {equipList?.map((item) => {
                                        return (<option key={item.EquipmentCode} value={item.EquipmentCode}>{item.EquipmentCode}</option>)
                                    })
                                    }
                                </Form.Select>
                                <Form.Label className="frm-label" for="tmpEquipCode">Equipment / Activity Code</Form.Label>
                            </Form.Group>
                        </Col>
                    </Row>
                    {
                        equipArr.map((selection, index) =>
                            <Card className="cardContainer" key={index}>
                                <Card.Body className="card-left-panel">
                                    {columnDefs.map((header, index) =>
                                        <Row key={index} className="responsive-row">
                                            <Col className="colHeader">{header.headerName}</Col>
                                            <Col className="colData">{selection[header.field]}</Col>
                                        </Row>
                                    )}
                                    <Row><Col>
                                        <Link onClick={() => onRowClicked(selection)} className="blueColor rightAlignedButton">
                                            Click to view {props.headername}
                                        </Link>
                                    </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        )}
                    {equipArr?.length > 0 && (
                        <Pagination
                            currentPage={currentPage}
                            totalCount={equipArr ? equipArr.length : 0}
                            data={equipArr}
                            pageSize={pageSize}
                            onPageChange={(page) => setCurrentPage(page)}
                        />)}
                </div>
            </Container>
        </>
    );
});

export default ProjectDocumentLeftPanel;
