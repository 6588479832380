import * as XLSX from "xlsx";
import * as XlsxPopulate from "xlsx-populate/browser/xlsx-populate";

export async function exportToExcelDashboardSOR(fileName, sheetName, excelData, projectDataCellValue, headersData) {

    let data = []

    data.push([projectDataCellValue]);
    // data.push([]);

    const merge = [];
    let svcs = [];
    let equips = [];
    let rs = 1;
    let re = 1;
    let cs = 0;
    let ce = 0;
    for (let item of headersData) {
        let i = 0;
        let start = { r: rs, c: cs };
        let end = { r: re, c: ce };
        for (let child of item.children) {
            equips.push(child.headerName);
            svcs.push([item.headerName]);
            i++;
        }
        if (item.headerName != "Services") {
            ce = ce + i;
        }
        end.c = ce;
        merge.push({ s: start, e: end })
        cs = ce + 1;
    }
    data.push(svcs);
    data.push(equips);


    for (let item of excelData) {
        delete item.SeqNo;
        let rowData = [];
        for (let property in item) {
            rowData.push([item[property]]);
        }
        data.push(rowData);
    }

    const wb = new Workbook();
    const ws = XLSX.utils.aoa_to_sheet(data);
    merge.push({ s: { r: 0, c: 0 }, e: { r: 0, c: (equips.length - 1) } })

    ws["!merges"] = merge;
    XLSX.utils.book_append_sheet(wb, ws, sheetName);
    const wopts = {
        bookType: "xlsx",
        bookSST: false,
        type: "binary",
    };

    const wbout = XLSX.write(wb, wopts);
    const blob = new Blob([s2ab(wbout)], {
        type: "application/octet-stream",
    });

    XlsxPopulate.fromDataAsync(blob).then((workbook) => {
        let sheet = workbook.sheets()[0];
        sheet.range("A1:A14").style({
            bold: true
        });
        sheet.usedRange().style({
            fontFamily: "Calibri",
            verticalAlignment: "center",
            border: "thin",
            horizontalAlignment: "left"
        });

        return workbook.outputAsync().then((workbookBlob) => URL.createObjectURL(workbookBlob)).then((url) => {
            const downloadAnchorNode = document.createElement("a");
            downloadAnchorNode.setAttribute("href", url);
            downloadAnchorNode.setAttribute(
                "download",
                fileName
            );
            downloadAnchorNode.click();
            downloadAnchorNode.remove();
        });
    });

}


export async function exportToExcelProjectProgress(filename, sheetname, listData, projectDetailsValue, headersdata, tabName) {
    let mergeColumnNo = {
        "DashboardDetails": { "ServiceColumns": [0], "EquipmentColumns": [1] },
        "ProjectProgress": { "ServiceColumns": [0], "EquipmentColumns": [1] },
        "Weightage": { "ServiceColumns": [0, 1, 2, 3, 4, 5, 21, 22], "EquipmentColumns": [6, 12, 15, 20] }
    }
    let data = []

    data.push([projectDetailsValue]);

    let cols = [];
    for (let headers of headersdata) {
        cols.push([headers.headerName])
    };
    data.push(cols);
    let merge = [];

    let rowCnt = 1;

    for (let item of listData) {
        let rowData = [];
        rowCnt++;

        if (item.totalServiceRowSpan === item.ServiceRowSpan) {
            for (let mt of mergeColumnNo[tabName].ServiceColumns) {
                merge.push({ s: { r: rowCnt, c: mt }, e: { r: rowCnt + item.totalServiceRowSpan - 1, c: mt } })
            }
        }
        if (item.totalEquipRowSpan && item.totalEquipRowSpan === item.EquipRowSpan) {
            for (let mt of mergeColumnNo[tabName].EquipmentColumns) {
                merge.push({ s: { r: rowCnt, c: mt }, e: { r: rowCnt + item.totalEquipRowSpan - 1, c: mt } })
            }
        }

        for (let hd of headersdata) {
            rowData.push([item[hd.field]]);
        }
        data.push(rowData);
    }

    const wb = new Workbook();
    const ws = XLSX.utils.aoa_to_sheet(data);

    ws["!merges"] = merge;
    XLSX.utils.book_append_sheet(wb, ws, sheetname);
    const wopts = {
        bookType: "xlsx",
        bookSST: false,
        type: "binary",
    };

    const wbout = XLSX.write(wb, wopts);
    const blob = new Blob([s2ab(wbout)], {
        type: "application/octet-stream",
    });

    let columnSize = headersdata.length - 1;

    let uptoRange = numberToLetters(columnSize);
    XlsxPopulate.fromDataAsync(blob).then((workbook) => {
        let sheet = workbook.sheets()[0];
        // sheet.range("A1:A14").style({
        //     bold: true
        // });
        sheet.usedRange().style({
            fontFamily: "Calibri",
            verticalAlignment: "center",
            border: "thin",
            horizontalAlignment: "left"
        });

        sheet.range("A1:" + uptoRange + "2").style({
            border: "thin",
            bold: true,
        });
        return workbook.outputAsync().then((workbookBlob) => URL.createObjectURL(workbookBlob)).then((url) => {
            const downloadAnchorNode = document.createElement("a");
            downloadAnchorNode.setAttribute("href", url);
            downloadAnchorNode.setAttribute(
                "download",
                filename
            );
            downloadAnchorNode.click();
            downloadAnchorNode.remove();
        });
    });

}

function numberToLetters(num) {
    let letters = ''
    while (num >= 0) {
        letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'[num % 26] + letters
        num = Math.floor(num / 26) - 1
    }
    return letters
}

const getRowSpanProjectDetails = async (data, start, end, merge) => {

    let rs = start.r;
    let re = end.r;
    let cs = start.c;
    let ce = end.c;
    for (let dt of data) {
        if (dt.RowSpan > 1) {
            re = re + (dt.RowSpan - 1);
            start = { r: rs, c: cs };
            end = { r: re, c: ce };
            rs = re + 1;
            re = rs;

        }
        else {
            start = { r: rs, c: cs };
            end = { r: re, c: ce };
            rs = rs + dt.RowSpan;
            re = re + dt.RowSpan;
        }
        merge.push({ s: start, e: end })
    }
    return merge
}


const s2ab = (s) => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < s.length; ++i) {
        view[i] = s.charCodeAt(i);
    }
    return buf;
};

function Workbook() {
    if (!(this instanceof Workbook))
        return new Workbook()
    this.SheetNames = []
    this.Sheets = {}
}