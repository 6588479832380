import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL; 

export async function getAllRoles(){
  const response = await axios
    .get(API_URL + "/rolemaster")
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      console.log("error => ",error );
      return {data: []};
    });
  return response.data;
}

export async function getActiveRoles() {
  let url = `${API_URL}/rolemaster/getActiveRole`;
  console.log("getActiveRole ",  url);
  const response = await axios
    .get(url)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      console.log("error => ",error );
      return {data: []};
    });
  return response.data;
}

export const AddNewRole = async (Role) => {
  let url = `${API_URL}/rolemaster/addrole`;
  const response = await axios
    .post(url, Role)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      console.log("error => ",error );
      return {data: { message: "Unable to find request", status: false }};
    });
  return response.data;
};

export async function deleteRole(role_id) {
  let reqBody = {role_id}
  let url = `${API_URL}/rolemaster/DeleteRole`;
  const resp = await axios
    .post(url, reqBody)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      console.log("error => ",error );
      return {data: { message: error.message, status: false }};
    });
  return resp.data;
}

export async function updateRoleId(role) {
  let url = `${API_URL}/rolemaster/UpdateRoleId`;
  const resp = await axios
    .post(url,role)
    .then(function (response) {
      return response;
    })
    .catch(function (error) {
      console.log("error => ",error );
      return {data: { message: error.message, status: false }};
    });
  return resp.data;
}
