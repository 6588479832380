import * as XLSX from "xlsx";
import * as XlsxPopulate from "xlsx-populate/browser/xlsx-populate";

export async function exportToExcel(fileName, excelData, colName, fieldName) {
  let wb = XLSX.utils.book_new();
  let header = colName;
  const sheetData = getSheetData(excelData, header, fieldName);
  let ws = XLSX.utils.json_to_sheet(sheetData);
  XLSX.utils.book_append_sheet(wb, ws, "Project Transaction Checklist");


  function getSheetData(exData, header, field) {
    let sheetData = [];
    if (exData.length === 0) {
      let tmpdata = [];
      for (let i = 0; i < header.length; i++) {
        let tmpcol = header[i];
        if (i == 0) {
          tmpdata.push({
            [tmpcol]: ""
          })
        }
        else {
          tmpdata = tmpdata.map(v => ({ ...v, [tmpcol]: "" }));
        }

      }
      sheetData = [...sheetData, ...tmpdata]
    }
    else {
      for (let n = 0; n < exData.length; n++) {
        let tmpdata = []
        for (let i = 0; i < header.length; i++) {
          let tmpcol = header[i];
          let tmpfield = field[i];
          if (i == 0) {
            tmpdata.push({
              [tmpcol]: exData[n][tmpfield]
            })
          }
          else {
            tmpdata = tmpdata.map(v => ({ ...v, [tmpcol]: exData[n][tmpfield] }));
          }
        }
        sheetData = [...sheetData, ...tmpdata]
      }
    }
    return sheetData;
  }

  const wopts = {
    bookType: "xlsx",
    bookSST: false,
    type: "binary",
  };

  const wbout = XLSX.write(wb, wopts);
  const blob = new Blob([s2ab(wbout)], {
    type: "application/octet-stream",
  });

  XlsxPopulate.fromDataAsync(blob).then((workbook) => {
    workbook.sheets().forEach((sheet) => {
      let rows = sheet._rows
      rows.forEach(function (row) {
        row._cells.forEach(function (cell) {          
          if (cell._value === "Pending") {            
            cell.style({ fontColor: "D1CA00",bold:true});
          }
          if (cell._value === "NotStarted") {
            cell.style({ fontColor: "E06454",bold:true});
          }
          if (cell._value === "Cancel") {
            cell.style({ fontColor: "767980",bold:true});
          }
          if (cell._value === "Submitted") {
            cell.style({ fontColor: "16911B",bold:true});           
          }
          if(cell._columnNumber ===8){
            cell._value = "Prefunctional";
          }
          if(cell._columnNumber ===9){
            cell._value = "Functional";
          }
        })
      });
      // sheet.range("H").style({
      //   fill: "FFFFFF",
      //   border: "thin",
      //   bold: false,
      //   fontColor: "000000",
      //   horizontalAlignment: "left",
      // });
      // sheet.range("A1:I1").style({
      //   fill: "FFFFFF",
      //   border: "thin",
      //   bold: false,
      //   fontColor: "000000",
      //   horizontalAlignment: "left",
      // });
    });
    return workbook.outputAsync().then((workbookBlob) => URL.createObjectURL(workbookBlob)).then((url) => {
      const downloadAnchorNode = document.createElement("a");
      downloadAnchorNode.setAttribute("href", url);
      downloadAnchorNode.setAttribute(
        "download",
        fileName
      );
      downloadAnchorNode.click();
      downloadAnchorNode.remove();
    });
  });

}


const s2ab = (s) => {
  // The ArrayBuffer() constructor is used to create ArrayBuffer objects.
  // create an ArrayBuffer with a size in bytes
  const buf = new ArrayBuffer(s.length);

  //create a 8 bit integer array
  const view = new Uint8Array(buf);
  //charCodeAt The charCodeAt() method returns an integer between 0 and 65535 representing the UTF-16 code
  for (let i = 0; i < s.length; ++i) {
    view[i] = s.charCodeAt(i);
  }

  return buf;
};



