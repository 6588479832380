import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import { Col, Modal, Row, Spinner } from "react-bootstrap";
import { BsPrinter } from "react-icons/bs";
import godrejLogo from "../../../icons/Godrej_logo_1.svg";
import { ConstParameterDetailsHeader } from "./ParameterFile";
import { sortByPropertyInAscending } from "../../../utils/Utility";
import ReactToPrint from "react-to-print";
import { formatDateInDDMMYYYY, getCurrentDateTime } from "../../../functions/gbcs-functions";

const ChecklistPDF = (props) => {
  let componentRef = useRef();
  let componentRefNew = useRef();
  let tableRef = useRef();

  let header = props.displayPDFData.header;
  let paramData = props.displayPDFData.paramData;
  paramData.sort(sortByPropertyInAscending("SeqNo"));
  let calledFrom = props.calledFrom;

  let allStatus = "";
  let fieldObj = [];
  let footerArr = [];
  let columnWidth = 0;
  const [loading, setLoading] = useState(false);
  const [pagewiseHtml, setPagewiseHtml] = useState(false)
  const [pageWiseDivs1, setPageWiseDivs] = useState({})

  const isColumnWidthLarge = {
    "Parameter": true,
    "Status": false,
    "Remarks": true,
    "Parameter Category": true,
    "UOM": false,
    "Result": false,
    "Readings": false,
    "Attachment": false
  }

  let obj = [];
  let highWidthColumnsCount = 0
  let lowWidthColumnsCount = 0

  if (header.hasOwnProperty("ParameterDetailsHeader")) {
    for (let dt of header.ParameterDetailsHeader) {

      let fieldData = ConstParameterDetailsHeader.filter(
        (item) => item.FieldName === dt && item.displayInd
      );
      if (fieldData.length > 0) {
        if (isColumnWidthLarge[fieldData[0].FieldName]) {
          highWidthColumnsCount += 1
          fieldData[0].columnWidth = "high"
        } else {
          lowWidthColumnsCount += 1
          fieldData[0].columnWidth = "low"
        }
        obj.push(fieldData[0]);
      }

    }
    const widthUsedBySmallColumns = 5 + (10 * lowWidthColumnsCount)
    const largeColumnsWidth = (100 - widthUsedBySmallColumns) / highWidthColumnsCount
    columnWidth = String(largeColumnsWidth.toFixed(2)) + "%";

    obj.sort(sortByPropertyInAscending("displaySrNo"));

    fieldObj = obj;
  }
  if (calledFrom === "ChecklistMaster") {
    if (header.hasOwnProperty("StatusDetails")) {
      let tmpAllStatus = "";
      header.StatusDetails.map((dt, ind) => {
        tmpAllStatus += dt.Status;
        if (ind !== header.StatusDetails.length - 1) {
          tmpAllStatus += " / ";
        }
      });
      tmpAllStatus = "(" + tmpAllStatus + ")";
      allStatus = tmpAllStatus;
    }
  }

  if (header.hasOwnProperty("ChecklistFooter")) {
    let tmpFooterArr = header.ChecklistFooter;
    let index = tmpFooterArr.findIndex((obj) => obj.FieldName === "Remarks");
    if (index !== -1) {
      tmpFooterArr.splice(index, 1);
    }
    let tmpSelectedFooter = tmpFooterArr.filter((item) => item.IsSelected);
    footerArr = tmpSelectedFooter;
  }

  useEffect(() => {
    createPages()
  }, []);


  const createPages = () => {

    const pageHeight = 800;
    if (componentRef.current) {
      const divs = Array.from(componentRef.current.querySelectorAll(".dynamic"))
      const table = Array.from(tableRef.current.querySelectorAll("tr"))
      console.log("divs", divs, table)

      let pageHeaderDiv = divs.splice(0, 1)[0];
      let headerDiv = divs.splice(0, 1)[0];
      let remarkDiv = divs.splice(0, 1)[0];
      let pageFooterDiv = divs.splice(divs.length - 1, 1)[0];
      let tableHeaderDiv = table.splice(0, 1)[0];

      // console.log("divs after", divs, table)
      console.log("pageHeaderDiv", pageHeaderDiv, headerDiv, remarkDiv, pageFooterDiv, tableHeaderDiv)

      let pageNo = 0;
      let pageWiseDivs = { [pageNo]: { parameter: [] } };
      let totalHeight = 0;

      //for page 0 - Add page header, checklist header, parameter table header, page footer
      let tmpHeight = pageHeaderDiv.offsetHeight + headerDiv.offsetHeight + pageFooterDiv.offsetHeight;
      if (table.length > 0) {
        tmpHeight = tmpHeight + tableHeaderDiv.offsetHeight;
      }
      totalHeight = tmpHeight * (72 / 96);

      let srNo = 0;

      for (let tr of table) {
        const heightPx = tr.offsetHeight;
        const heightPt = heightPx * (72 / 96);

        // console.log("tr height", heightPx, heightPt);

        if (totalHeight + heightPt > pageHeight) {
          pageNo++;
          pageWiseDivs[pageNo] = { parameter: [] };
          totalHeight = pageHeaderDiv.offsetHeight + tableHeaderDiv.offsetHeight + pageFooterDiv.offsetHeight;
        }
        pageWiseDivs[pageNo].parameter.push(srNo);
        totalHeight += heightPt;
        srNo++;
        // console.log("total height", totalHeight);
      }

      //add footer remark
      let tmpRemarkPx = remarkDiv.offsetHeight;
      let tmpRemarkPt = tmpRemarkPx * (72 / 96);

      if (totalHeight + tmpRemarkPt > pageHeight) {
        pageNo++;
        pageWiseDivs[pageNo] = {};
        totalHeight = pageHeaderDiv.offsetHeight + pageFooterDiv.offsetHeight;
      }
      pageWiseDivs[pageNo].remark = 1;
      totalHeight += tmpRemarkPt;

      pageWiseDivs[pageNo].footer = [];

      srNo = 0;
      let tmpSrNo = 0;
      let footerCnt = 0;
      for (let div of divs) {
        footerCnt++;
        tmpSrNo++;
        if (footerCnt === 2 || tmpSrNo === divs.length) {
          const heightPx = div.offsetHeight
          const heightPt = heightPx * (72 / 96)

          // console.log("height in px", heightPx, heightPt);

          if (totalHeight + heightPt > pageHeight) {
            pageNo++;
            pageWiseDivs[pageNo] = { footer: [] };
            totalHeight = pageHeaderDiv.offsetHeight + pageFooterDiv.offsetHeight;
          }
          pageWiseDivs[pageNo].footer.push(srNo);
          pageWiseDivs[pageNo].footer.push(srNo + 1);
          totalHeight += heightPt;
          srNo = srNo + 2;
          footerCnt = 0;
        }
        // console.log("total height", totalHeight);
      }

      setPageWiseDivs(pageWiseDivs)
      setPagewiseHtml(true)
      setLoading(false);
    }
    // console.log("pagewise divs 1", pageWiseDivs1);
  }

  // console.log("paramData", paramData);
  // console.log("fieldObj", fieldObj);
  // console.log("allStatus", allStatus);
  // console.log("footerArr", footerArr);
  // console.log("columnWidth", columnWidth);
  // console.log("page wise divs", pageWiseDivs1);

  const dateToPrint = () => {
    try {
      if (calledFrom === "ChecklistMaster") {
        if (header.ChecklistVersion.startsWith("TMP")) {
          return formatDateInDDMMYYYY(((getCurrentDateTime('-'))?.split(' '))[0])
        } else {
          return formatDateInDDMMYYYY(((header.chklstFreezeDate)?.split(' '))[0])
        }
      }
      if ((calledFrom === "FAT" || calledFrom === "SAT") && header.IsFreeze) {
        return formatDateInDDMMYYYY(((header.frozenDate)?.split(' '))[0])
      } else if (!header.IsFreeze) {
        return formatDateInDDMMYYYY(((getCurrentDateTime('-'))?.split(' '))[0])
      }
    } catch (error) {
      return formatDateInDDMMYYYY(((getCurrentDateTime('-'))?.split(' '))[0]);
    }
  }

  const printIcon = () => {
    return <BsPrinter className="printButton" size={20} />;
  };

  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      size="xl"
    >
      <Modal.Header closeButton>
        <Modal.Title>Checklist</Modal.Title>
        <ReactToPrint
          bodyClass='pdf-css'
          trigger={printIcon}
          // pageStyle={"@page {size: landscape}"}
          content={() => componentRefNew.current}
          documentTitle="Checklist"
        />
      </Modal.Header>
      <Modal.Body>
        {loading ? (
          <div className="lazy-spinner height-20">
            <Spinner animation="border" className="spinner-color" />
          </div>
        ) : (<>
          {!pagewiseHtml &&
            <div>
              <div ref={componentRef}>
                <div className='pdfDiv main-pdf-sizing'>
                  <div>
                    <Row className="dynamic">
                      <Col xs={2} className="footerBootomBorder header1style">
                        <img src={godrejLogo} />
                      </Col>
                      <Col xs={10} className="footerBootomBorder align-right">
                        {/* <h4>{header.ChecklistCategory} check list for {header.EquipmentName}<br /></h4> */}
                        <h4 className="top-margin-8">{calledFrom === "ChecklistMaster" ? "FAT/SAT" : calledFrom === "FAT" ? "FAT" : calledFrom === "SAT" ? "SAT" : ""} {header.ChecklistCategory} Checklist for {header.EquipmentName}</h4>

                        <span className="semiBoldToggleButton">Service</span>: {header.ServiceName}, <span className="semiBoldToggleButton">Equipment / Activity Name</span>: {header.EquipmentName}, <span className="semiBoldToggleButton">Checklist Version</span>: {header.ChecklistVersion}<br />
                      </Col>
                    </Row>
                    <div>
                      <Row className="dynamic">
                        {header.ChecklistHeader?.map((dt, ind) => {
                          return (
                            dt.IsSelected && (
                              <Col xs={6}>
                                <span className="semiBoldToggleButton">{dt.FieldName}</span> : {header[dt.FieldName]}
                              </Col>
                            )
                          );
                        })}
                      </Row>
                      <div className="pdfDiv border-hidden">
                        <Row>
                          <table className="pdfTable" ref={tableRef}>
                            {header.hasOwnProperty("ParameterDetailsHeader") && (
                              <tr>
                                <th width="5%" className="cell">Sr No</th>
                                {fieldObj?.map((dt, ind) => {
                                  return (
                                    <th className="cell" width={dt.columnWidth === "high" ? columnWidth : "10%"}>
                                      {dt.FieldName}
                                      {dt.FieldName === "Status" && (
                                        <>
                                          <br />
                                          {allStatus}
                                        </>
                                      )}
                                    </th>
                                  );
                                })}
                              </tr>
                            )}
                            {paramData?.map((dt, ind) => {
                              return (
                                <tr>
                                  <td className="cell">{dt.SeqNo}</td>
                                  {fieldObj?.map((dt1, ind) => {
                                    return <td className="cell">{calledFrom === "ChecklistMaster" && dt1.FieldName === "Attachment" ? "" : dt[dt1.FieldName]}</td>;
                                  })}
                                </tr>
                              );
                            })}
                          </table>
                        </Row>
                      </div>
                      <div>
                        <Row className="footerRow dynamic">
                          <Col xs={12} className="footerBootomBorder"><span className="semiBoldToggleButton">Remarks</span> : {header.Remarks}</Col>
                        </Row>
                        <Row className="footerRow bottom-border">
                          {footerArr?.map((dt, ind) => {
                            let customClassName = "dynamic ";
                            if (ind % 2 === 0) { customClassName += "footerRightBorder " }
                            if ((footerArr.length % 2 === 0 && ind < footerArr.length - 2) || (footerArr.length % 2 !== 0 && ind < footerArr.length - 1)) { customClassName += "footerBootomBorder" }
                            // console.log(customClassName, ind, footerArr.length)
                            return (
                              dt.IsSelected && (
                                <Col className={customClassName} xs={6}>
                                  <span className="semiBoldToggleButton">
                                    {dt.FieldName}
                                  </span>
                                  <br />
                                  Name : {header[dt.FieldName]}
                                  <br />
                                  Signature : {header[dt.FieldName + " Signature"]}
                                  <br />
                                  Date : {formatDateInDDMMYYYY(header[dt.FieldName + " Date"])}
                                </Col>
                              )
                            );
                          })}
                        </Row>
                      </div>

                    </div>
                  </div>
                  <Row className="dynamic position-relative">
                    <Col>Version: {header.ChecklistVersion}</Col>
                    <Col className="textCenterAlign">Date: {dateToPrint()}</Col>
                    <Col className="align-right">Page </Col>
                  </Row>
                </div>

              </div>
            </div>
          }
          {pagewiseHtml &&
            <div ref={componentRefNew}>
              {Object.keys(pageWiseDivs1).map((pt, pageInd) =>
                <div className='pdfDiv main-pdf-sizing'>
                  <div className="pdf-height">
                    <Row>
                      <Col xs={2} className="footerBootomBorder header1style">
                        <img src={godrejLogo} />
                      </Col>
                      <Col xs={10} className="footerBootomBorder  align-right">
                        <h4 className="top-margin-8">{calledFrom === "ChecklistMaster" ? "FAT/SAT" : calledFrom === "FAT" ? "FAT" : calledFrom === "SAT" ? "SAT" : ""} {header.ChecklistCategory} Checklist for {header.EquipmentName}</h4>

                        <span className="semiBoldToggleButton">Service</span>: {header.ServiceName}, <span className="semiBoldToggleButton">Equipment / Activity Name</span>: {header.EquipmentName}, <span className="semiBoldToggleButton">Checklist Version</span>: {header.ChecklistVersion}<br />
                      </Col>
                    </Row>
                    <div>
                      {pageInd === 0 &&
                        <Row>
                          {header.ChecklistHeader?.map((dt, ind) => {
                            return (
                              dt.IsSelected && (
                                <Col xs={6}>
                                  <span className="semiBoldToggleButton">{dt.FieldName}</span> : {header[dt.FieldName]}
                                </Col>
                              )
                            );
                          })}
                        </Row>
                      }
                      {pageWiseDivs1[pt].parameter &&
                        <div className="pdfDiv border-hidden">
                          <Row>
                            <table className="pdfTable">
                              {header.hasOwnProperty("ParameterDetailsHeader") && (
                                <tr>
                                  <th width="5%" className="cell">Sr No</th>
                                  {fieldObj?.map((dt, ind) => {
                                    return (
                                      <th className="cell" width={dt.columnWidth === "high" ? columnWidth : "10%"}>
                                        {dt.FieldName}
                                        {dt.FieldName === "Status" && (
                                          <>
                                            <br />
                                            {allStatus}
                                          </>
                                        )}
                                      </th>
                                    );
                                  })}
                                </tr>
                              )}
                              {paramData?.map((dt, ind) => {
                                return (
                                  pageWiseDivs1[pt].parameter.indexOf(ind) !== -1 &&
                                  <tr>
                                    <td className="cell">{dt.SeqNo}</td>
                                    {fieldObj?.map((dt1, ind) => {
                                      return <td className="cell">{calledFrom === "ChecklistMaster" && dt1.FieldName === "Attachment" ? "" : dt[dt1.FieldName]}</td>;
                                    })}
                                  </tr>
                                );
                              })}
                            </table>
                          </Row>
                        </div>
                      }
                      <div>
                        {pageWiseDivs1[pt].remark &&
                          <Row className="footerRow">
                            <Col xs={12} className="footerBootomBorder"><span className="semiBoldToggleButton">Remarks</span> : {header.Remarks}</Col>
                          </Row>
                        }
                        {pageWiseDivs1[pt].footer &&
                          <Row className="footerRow bottom-border">
                            {footerArr?.map((dt, ind) => {
                              let customClassName = "";
                              if (ind % 2 === 0) { customClassName += "footerRightBorder " }
                              if ((footerArr.length % 2 === 0 && ind < footerArr.length - 2) || (footerArr.length % 2 !== 0 && ind < footerArr.length - 1)) { customClassName += "footerBootomBorder" }
                              // console.log(customClassName, ind, footerArr.length)
                              return (
                                (dt.IsSelected && pageWiseDivs1[pt].footer.indexOf(ind) !== -1) && (
                                  <Col className={customClassName} xs={6}>
                                    <span className="semiBoldToggleButton">
                                      {dt.FieldName}
                                    </span>
                                    <br />
                                    Name : {header[dt.FieldName]}
                                    <br />
                                    Signature : {header[dt.FieldName + " Signature"]}
                                    <br />
                                    Date : {formatDateInDDMMYYYY(header[dt.FieldName + " Date"])}
                                  </Col>
                                )
                              );
                            })}
                          </Row>
                        }
                      </div>

                    </div>
                  </div>
                  <Row className="position-relative">
                    <Col>Version: {header.ChecklistVersion}</Col>
                    <Col className="textCenterAlign">Date: {dateToPrint()}</Col>
                    <Col className="align-right">Page {(pageInd + 1)} / {Object.keys(pageWiseDivs1).length}</Col>
                  </Row>
                </div>
              )}
            </div>
          }
        </>
        )}
      </Modal.Body>
    </Modal >
  );
};

export default ChecklistPDF;
