import * as XLSX from "xlsx";
import * as XlsxPopulate from "xlsx-populate/browser/xlsx-populate";
import { formatDateInDDMMYYYY } from "../../../functions/gbcs-functions";
import escapeExcelData from "../../utils/customValidation";
import { getRolesForUnmaskedData } from "../../../services/common-master-service";
import { addLogDetails } from "../../../services/logger-entry-service";

export async function exportToExcel(fileName, userDetails, excelData, orderResult, teamResult, serviceResult, colNameOrder, fieldNameOrder, colNameTeam, fieldNameTeam, colNameService, fieldNameService) {


  let rolesData = await getRolesForUnmaskedData().catch((err) => { console.log(err) });
  let showMaskedDataInd = -1;
  if (rolesData.length > 0) {
    showMaskedDataInd = rolesData[0].RoleCode.trim().split(",").indexOf(userDetails.role);
  }

  let mskedClientContactNo1 = excelData[0].ClientContactNo1;
  let mskedClientContactNo2 = excelData[0].ClientContactNo2;
  let mskedClientEmail1 = excelData[0].ClientEmail1;
  let mskedClientEmail2 = excelData[0].ClientEmail2;
  let mskedGodrejEmailId = excelData[0].GodrejEmailId;
  let mskedGodrejContactNo = excelData[0].GodrejContactNo;

  if (showMaskedDataInd == -1) {
    mskedClientContactNo1 = escapeExcelData(excelData[0].ClientContactNo1, true);
    mskedClientContactNo2 = escapeExcelData(excelData[0].ClientContactNo2, true);
    mskedClientEmail1 = escapeExcelData(excelData[0].ClientEmail1, true);
    mskedClientEmail2 = escapeExcelData(excelData[0].ClientEmail2, true);
    mskedGodrejEmailId = escapeExcelData(excelData[0].GodrejEmailId, true);
    mskedGodrejContactNo = escapeExcelData(excelData[0].GodrejContactNo, true);
  }
  else {
    await addLogDetails(excelData[0], "Download", "SK#" + excelData[0].SK, "ProjectMasterLog", userDetails, "Entry");
  }
  const data = [["LOB", excelData[0].LOB, "Project Status", excelData[0].DeleteIndication, "Project Type", excelData[0].ProjectType],
  ["Project Code", excelData[0].ProjectCode, "Project", excelData[0].ProjectName],
  ["Location Type", excelData[0].LocationType, "Location", excelData[0].Location, "Country", excelData[0].Country],
  ["Site Address", excelData[0].SiteAddress],
  ["Sector", excelData[0].Sector, "Project Area", excelData[0].ProjArea, "Zone", excelData[0].Zone],
  ["Project Start Date", formatDateInDDMMYYYY(excelData[0].ProjStartDate), "Project End Date", formatDateInDDMMYYYY(excelData[0].ProjEndDate), "Revised Project End Date", formatDateInDDMMYYYY(excelData[0].RevisedProjEndDate)],
  ["Original Order Value", excelData[0].OriginalOrderValue + " " + excelData[0].OriginalOrderUnit, "Revised Order Value", excelData[0].RevisedOrderValue + " " + excelData[0].RevisedOrderUnit, "IST Applicable for Critical Area", excelData[0].ISTApplicable],
  ["Godrej ENE Employee", excelData[0].GodrejEmpName, "Email Id", mskedGodrejEmailId, "Contact No", mskedGodrejContactNo],
  [" "],
  ["Customer Details"],
  ["Customer Contact Person - 1", excelData[0].ClientContactPerson1, "Contact No - 1", "'" + mskedClientContactNo1, "Email Id - 1", mskedClientEmail1],
  ["Customer Contact Person - 2", excelData[0].ClientContactPerson2, "Contact No - 2", "'" + mskedClientContactNo2, "Email Id - 2", mskedClientEmail2]
  ];

  if (excelData[0].StakeholderDetails.length > 0) {
    data.push([" "]);
    data.push(["PMC / MEP / Contractor Details"]);
    for (let n = 0; n < excelData[0].StakeholderDetails.length; n++) {
      let element = excelData[0].StakeholderDetails[n];
      if (showMaskedDataInd == -1) {
        element.PMCOfficialContNo = escapeExcelData(element.PMCOfficialContNo, true);
        element.PMCEmailId = escapeExcelData(element.PMCEmailId, true);
      }
      if (n === 0) {
        data.push(["Stakeholder Type", element.StakeholderType, "Company / Division", element.StakeCompanyDivision]);
        data.push(["Contact Person", element.PMCContactPerson, "Contact No", element.PMCOfficialContNo, "Email Id", element.PMCEmailId]);
      }
      else {
        data.push(["Stakeholder Type", element.StakeholderType, "Company / Division", element.StakeCompanyDivision]);
        data.push(["Contact Person - " + n, element.PMCContactPerson, "Contact No - " + n, element.PMCOfficialContNo, "Email Id - " + n, element.PMCEmailId]);
      }
    }
  }
  data.push([" "]);
  data.push(["Inserted By", excelData[0].CreatedBy, "Inserted On", formatDateInDDMMYYYY(excelData[0].CreatedOn)]);
  data.push(["Modified By", excelData[0].ModifiedByName, "Modified On", formatDateInDDMMYYYY(excelData[0].ModifiedOn)]);
  const wb = new Workbook();
  const ws = XLSX.utils.aoa_to_sheet(data);
  XLSX.utils.book_append_sheet(wb, ws, "Project Details");

  for (let dt of orderResult) {
    dt.CreatedOn = formatDateInDDMMYYYY(dt.CreatedOn);
    dt.ModifiedOn = formatDateInDDMMYYYY(dt.ModifiedOn);
  }

  for (let dt of teamResult) {
    if (showMaskedDataInd == -1) {
      dt.MobileNo = escapeExcelData(dt.MobileNo, true);
      dt.EmailId = escapeExcelData(dt.EmailId, true);
    }
    else {
      await addLogDetails(dt, "Download", "PK#" + dt.PK + "#SK#" + dt.SK, "ProjectMasterLog", userDetails, "Entry");
    }
    dt.CreatedOn = formatDateInDDMMYYYY(dt.CreatedOn);
    dt.ModifiedOn = formatDateInDDMMYYYY(dt.ModifiedOn);
  }

  for (let dt of serviceResult) {
    if (showMaskedDataInd == -1) {
      dt.MobileNo = escapeExcelData(dt.MobileNo, true);
      dt.EmailId = escapeExcelData(dt.EmailId, true);
    }
    else {
      await addLogDetails(dt, "Download", "PK#" + dt.PK + "#SK#" + dt.SK, "ProjectMasterLog", userDetails, "Entry");
    }
    dt.CreatedOn = formatDateInDDMMYYYY(dt.CreatedOn);
    dt.ModifiedOn = formatDateInDDMMYYYY(dt.ModifiedOn);
  }

  const sheetDataOrder = getSheetData(orderResult, colNameOrder, fieldNameOrder);
  const sheetDataTeam = getSheetData(teamResult, colNameTeam, fieldNameTeam);
  const sheetDataService = getSheetData(serviceResult, colNameService, fieldNameService);

  function getSheetData(exData, header, field) {
    let sheetData = [];
    if (exData.length === 0) {
      let tmpdata = [];
      for (let i = 0; i < header.length; i++) {
        let tmpcol = header[i];
        if (i == 0) {
          console.log([tmpcol]);
          tmpdata.push({
            [tmpcol]: ""
          })
        }
        else {
          tmpdata = tmpdata.map(v => ({ ...v, [tmpcol]: "" }));
        }

      }
      sheetData = [...sheetData, ...tmpdata]
    }
    else {
      for (let n = 0; n < exData.length; n++) {
        let tmpdata = [];
        for (let i = 0; i < header.length; i++) {
          let tmpcol = header[i];
          let tmpfield = field[i];
          if (i == 0) {
            tmpdata.push({
              [tmpcol]: exData[n][tmpfield]
            })
          }
          else {
            tmpdata = tmpdata.map(v => ({ ...v, [tmpcol]: exData[n][tmpfield] }));
          }
        }
        sheetData = [...sheetData, ...tmpdata]
      }
    }
    return sheetData;
  }

  //insert order details
  let ws1 = XLSX.utils.json_to_sheet(sheetDataOrder);
  XLSX.utils.book_append_sheet(wb, ws1, "Project Order");

  //insert order details
  let ws2 = XLSX.utils.json_to_sheet(sheetDataTeam);
  XLSX.utils.book_append_sheet(wb, ws2, "Project Team");

  //insert order details
  let ws3 = XLSX.utils.json_to_sheet(sheetDataService);
  XLSX.utils.book_append_sheet(wb, ws3, "Project Service");

  const wopts = {
    bookType: "xlsx",
    bookSST: false,
    type: "binary",
  };

  const wbout = XLSX.write(wb, wopts);
  const blob = new Blob([s2ab(wbout)], {
    type: "application/octet-stream",
  });


  XlsxPopulate.fromDataAsync(blob).then((workbook) => {
    let sheet = workbook.sheets()[0];
    sheet.range("A1:A24").style({
      bold: true
    });
    sheet.range("C1:C24").style({
      bold: true
    });
    sheet.range("E1:E24").style({
      bold: true
    });

    return workbook.outputAsync().then((workbookBlob) => URL.createObjectURL(workbookBlob)).then((url) => {
      const downloadAnchorNode = document.createElement("a");
      downloadAnchorNode.setAttribute("href", url);
      downloadAnchorNode.setAttribute(
        "download",
        fileName
      );
      downloadAnchorNode.click();
      downloadAnchorNode.remove();
    });
  });

}

const s2ab = (s) => {
  const buf = new ArrayBuffer(s.length);
  const view = new Uint8Array(buf);
  for (let i = 0; i < s.length; ++i) {
    view[i] = s.charCodeAt(i);
  }
  return buf;
};

function Workbook() {
  if (!(this instanceof Workbook))
    return new Workbook()
  this.SheetNames = []
  this.Sheets = {}
}

const download = (url, name) => {
  let a = document.createElement('a')
  a.href = url
  a.download = name
  a.click();
  window.URL.revokeObjectURL(url)
}