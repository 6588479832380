import React, { useState } from "react";
import { Form } from "react-bootstrap";
import Dropzone from "react-dropzone";
import Upload from "../../../icons/Upload.svg";

function DragDropRender(props) {
  const maxSize = 5097520;
  let disableFlag = props.hasOwnProperty("disabled") ? props.disabled : false;
  return (
    <Form.Group>
      <Dropzone
        // maxSize={maxSize}
        onDrop={(acceptedFiles) =>
          props.handleChangeEvent(acceptedFiles, props.val, props.winType, props.itemData)
        }
        multiple={false}
        maxFiles={1}
        disabled={disableFlag}
      >
        {({ getRootProps, getInputProps }) =>
        (
          <div
            {...getRootProps()}
            className={props.isProjectDocuemnts ? "documents-dropZoneContainer1" : "dropZoneContainer1"}
          >
            <input {...getInputProps()} />
            {
              props.isProjectDocuemnts ?
                <div className="dropTextContainer">
                  <span className="DocUploaddropText">
                    {props.label}
                  </span>
                </div>
                :
                <>
                  <img src={Upload} alt="Upload" />
                  <div className="dropTextContainer1">
                    <span className="dropText">
                      Browse file here..<span className="italicFont">{props.optionalFlag ? "(Optional)" : ""}</span>
                    </span>
                  </div>
                </>
            }
          </div>
        )}
      </Dropzone>
    </Form.Group>
  );
}

export { DragDropRender };