import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;
const TRANS_URL = process.env.REACT_APP_TRANSACTION_API_URL;


export async function getChecklistData(){ 
  const response = await axios
    .get(TRANS_URL + "/checklistmaster")
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      console.log("error => ",error );
      return {data:[]};
    });
  return response.data;
}

export const AddChecklistDetails = async (ChecklistData) => {
  let url = `${TRANS_URL}/checklistmaster/addChecklistDetails`;
  const response = await axios
    .post(url, ChecklistData)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      console.log("error => ",error );
      return {data: { message: "Unable to find request", status: false }};
    });
  return response.data;
};

export const UpdateChecklistDetails = async (ChecklistData) => {
  let url = `${TRANS_URL}/checklistmaster/updateChecklistDetails`;
  const response = await axios
    .post(url, ChecklistData)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      console.log("error => ",error );
      return {data: { message: "Unable to find request", status: false }};
    });
  return response.data;
};

export const DeleteChecklistDetails = async (ChecklistData) => {
  let url = `${TRANS_URL}/checklistmaster/deleteChecklistDetails`;
  const response = await axios
    .post(url, ChecklistData)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      console.log("error => ",error );
      return {data: { message: "Unable to find request", status: false }};
    });
  return response.data;
};

export const UpdateChecklistHeaderData = async (ChecklistData,dbFieldName) => {
  let reqBody = {ChecklistData, dbFieldName}
  let url = `${TRANS_URL}/checklistmaster/updateChecklistHeaderData`;
  const response = await axios
    .post(url, reqBody)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      console.log("error => ",error );
      return {data: { message: "Unable to find request", status: false }};
    });
  return response.data;
};

export const UpdateParameterDetailsHeader = async (ChecklistData) => {
  let url = `${TRANS_URL}/checklistmaster/updateParameterDetailsHeader`;
  const response = await axios
    .post(url, ChecklistData)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      console.log("error => ",error );
      return {data: { message: "Unable to find request", status: false }};
    });
  return response.data;
};

export const UpdateStatusDetails = async (ChecklistData) => {
  let url = `${TRANS_URL}/checklistmaster/updateStatusDetails`;
  const response = await axios
    .post(url, ChecklistData)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      console.log("error => ",error );
      return {data: { message: "Unable to find request", status: false }};
    });
  return response.data;
};

export async function getActiveChklstVersion(equipCode){
  let reqBody = {"EquipmentCode": equipCode }
  const response = await axios
    .post(TRANS_URL + "/checklistmaster/getActiveChklstVersion",reqBody)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      console.log("error => ",error );
      return {data:[]};
    });
    return response.data;
}

export async function getChecklistMasterDetails(equipCode,chklstcat){
  let reqBody = {"EquipmentCode": equipCode,"ChklstCat": chklstcat }
  const response = await axios
    .post(TRANS_URL + "/checklistmaster/getChecklistMasterDetails",reqBody)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      console.log("error => ",error );
      return {data:[]};
    });
    return response.data;
}

export const AddChecklistParameterDetails = async (paramData) => {
  let url = `${TRANS_URL}/checklistmaster/addChecklistParameterDetails`;
  const response = await axios
    .post(url, paramData)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      console.log("error => ",error );
      return {data: { message: "Unable to find request", status: false }};
    });
  return response.data;
};

export const UpdateChecklistParameterDetails = async (paramData) => {
  console.log("paramData",paramData)
  let url = `${TRANS_URL}/checklistmaster/updateChecklistParameterDetails`;
  const response = await axios
    .post(url, paramData)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      console.log("error => ",error );
      return {data: { message: "Unable to find request", status: false }};
    });
  return response.data;
};

export const DeleteChecklistParameterDetails = async (paramData) => {
  console.log("paramData",paramData)
  let url = `${TRANS_URL}/checklistmaster/deleteChecklistParameterDetails`;
  const response = await axios
    .post(url, paramData)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      console.log("error => ",error );
      return {data: { message: "Unable to find request", status: false }};
    });
  return response.data;
};

export async function getChecklistParameterDetails(paramObj){ 
  const response = await axios
    .post(TRANS_URL + "/checklistmaster/getChecklistParameterDetails",paramObj)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      console.log("error => ",error );
      return {data:[]};
    });
  return response.data;
}

export const RenewChecklistEdit = async (ChecklistData) => {
  let url = `${TRANS_URL}/checklistmaster/renewChecklistEdit`;
  const response = await axios
    .post(url, ChecklistData)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      console.log("error => ",error );
      return {data: { message: "Unable to find request", status: false }};
    });
  return response.data;
};