import React, { useState } from "react";
import "./CSS/Home.css";
import { Container } from "react-bootstrap";
import { BrowserRouter as Router } from "react-router-dom";
import Header from "./Header";
import MainComp from "./MainComp";
import SideBar from "./SideBar";
import AuthContextProvider from "../../contexts/AuthContext";


const Home = () => {

  const [showSidebar, setShowSidebar] = useState(false);

  return (
    <Container fluid className={showSidebar ? "app-layout openBar" : "app-layout closeBar"}>
      <Router>
        <AuthContextProvider>
          <SideBar showSidebar={showSidebar} setShowSidebar={setShowSidebar} />
          <Container bsPrefix="app-container">
            <Header toggle={() => setShowSidebar(!showSidebar)} />
            <MainComp />

          </Container>

          {/* <Header />
        <SideBar />
        <MainComp /> */}
        </AuthContextProvider>
      </Router>
    </Container>
  );
};

export default Home;
