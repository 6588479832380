import React, { useEffect, useState, useContext } from "react";
import { Container, Col, Row, Spinner } from "react-bootstrap";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import successImage from "../../icons/success-tick-logo.svg";
import errorImage from "../../icons/error-tick-logo.svg";
import { AuthContext } from "../../contexts/AuthContext";
import { BsPlusLg } from "react-icons/bs";
import { Link } from 'react-router-dom';
import ActionRenderer from "../masters/ActionRenderer";
import GridMaster from "../masters/GridMaster";
import IntegratedAddEditVendor from "./IntegratedAddEditVendor";
import { getIntegratedTabsData, deleteISTTabData, addIntegratedSystemTestingDetails } from "../../services/integrated-system-testing-service";
import { getProjectActiveTeamData } from "../../services/project-service";
import { getCurrentDateTime } from "../../functions/gbcs-functions";
import { useDispatch } from "react-redux";
import { searchActions } from "../../store/grid-slice";
import { sortByPropertyInAscending } from "../../utils/Utility";
import { ALERT_PARAMS } from "../../utils/constant.js";

const IntegratedVendorContact = (props) => {
    const dispatch = useDispatch();
    const MySwal = withReactContent(Swal);
    const context = useContext(AuthContext);
    const userDetails = { userName: context.userName, userId: context.userId }
    const [loading, setLoading] = useState(false);
    const [filteredResulttemp, setFilteredResulttemp] = useState([]);
    const [filterColLength, setFilterColLength] = useState(0);
    const [excelData, setExcelData] = useState([]);
    const [showForm, setShowForm] = useState(false);
    const [tabData, setTabData] = useState([]);
    const [action, setAction] = useState("");
    const [rowData, setRowData] = useState({});
    const vendorLimitVal = props.limitValue.VendorLimitValue;
    let selectedProject = props.SelectedProject;
    const [actionButtons, setActionButtons] = useState({});

    const columnDefs = [
        {
            headerName: "Sr.No.",
            field: "SrNo",
            valueFormatter: (params) => {
                return params.node.rowIndex + 1;
            },
            minWidth: 100
        },
        {
            headerName: "Stakeholder Type",
            headerTooltip: "Stakeholder Type",
            field: "StakeholderType",
            minWidth: 150,
            tooltipField: "StakeholderType"
        },
        {
            headerName: "Contact Name",
            headerTooltip: "Contact Name",
            field: "UserName",
            tooltipField: "UserName",
            minWidth: 150
        },
        {
            headerName: "Company / Division",
            headerTooltip: "Company / Division",
            field: "CompanyOrDivision",
            minWidth: 150,
            tooltipField: "CompanyOrDivision"
        },
        {
            headerName: "Designation",
            headerTooltip: "Designation",
            field: "Designation",
            minWidth: 150,
            tooltipField: "Designation"
        },
        {
            headerName: "Email Id",
            headerTooltip: "Email Id",
            field: "EmailId",
            minWidth: 150,
            tooltipField: "EmailId"
        },
        {
            headerName: "Mobile No.",
            headerTooltip: "Mobile No.",
            field: "MobileNo",
            minWidth: 150,
            tooltipField: "MobileNo"
        },
        {
            headerName: "Applicable for Critical/Non Critical Report",
            headerTooltip: "Applicable for Critical/Non Critical Report",
            field: "ApplicableforCriNonCriReport",
            minWidth: 230,
            tooltipField: "ApplicableforCriNonCriReport"
        },
        {
            field: "",
            width: 80,
            cellRenderer: function (params) {
                return <ActionRenderer selectedData={params.data} handleEditEvent={handleEdit} handleDeleteEvent={handleDelete} actionName={actionButtons} />
            },
            sortable: false,
            filter: false,
            pinned: 'right',
            resizable:false
        },
    ];

    const headerName = (field) => {
        let name;
        switch (field) {
            case "SrNo":
                name = "Sr.No.";
                break;
            case "StakeholderType":
                name = "Stakeholder Type";
                break;
            case "UserName":
                name = "Contact Name";
                break;
            case "CompanyOrDivision":
                name = "Company / Division";
                break;
            case "Designation":
                name = "Designation";
                break;
            case "EmailId":
                name = "Email Id";
                break;
            case "MobileNo":
                name = "Mobile No.";
                break;
            case "ApplicableforCriNonCriReport":
                name = "Applicable for Critical/Non Critical Report";
                break;
            default:
                name = "No match found";
                break;
        }
        return name;
    };

    const fieldName = (header) => {
        let name;
        switch (header) {
            case "Sr.No.":
                name = "SrNo";
                break;
            case "Stakeholder Type":
                name = "StakeholderType";
                break;
            case "Contact Name":
                name = "UserName";
                break;
            case "Company / Division":
                name = "CompanyOrDivision";
                break;
            case "Designation":
                name = "Designation";
                break;
            case "Email Id":
                name = "EmailId";
                break;
            case "Mobile No.":
                name = "MobileNo";
                break;
            case "Applicable for Critical/Non Critical Report":
                name = "ApplicableforCriNonCriReport";
                break;
            default:
                name = "No match found";
                break;
        }
        return name;
    };

    useEffect(() => {
        if (props.tabNo === 0) {
            getProjectVendorDetails();
        }
        if (selectedProject.DeleteIndication !== "Active") {
            setActionButtons({
                ViewAction: false,
                EditAction: false,
                DownloadAction: false,
                DeleteAction: false,
            });
        }
        else {
            setActionButtons({
                ViewAction: false,
                EditAction: true,
                DownloadAction: false,
                DeleteAction: true
            });
        }
        dispatch(searchActions.setSearch(""));
    }, [props.tabNo]);

    const getProjectVendorDetails = async () => {
        let temp = [];
        setLoading(true);
        let vendorISTresult = await getIntegratedTabsData({ "ProjectCode": selectedProject.ProjectCode, "flag": "Stakeholder" });
        if (vendorISTresult.length <= 0 && selectedProject.DeleteIndication === "Active") {
            const result = await getProjectActiveTeamData(selectedProject.ProjectCode);
            if (result?.length > 0) {
                let srNo = 1;
                let counter = 0;
                let array = [];
                for (let item of result) {
                    let objTemp = {};
                    objTemp["ProjectCode"] = item.ProjectCode;
                    objTemp["ProjectName"] = item.ProjectName;
                    objTemp["StakeholderType"] = item.StakeholderType;
                    objTemp["UserName"] = item.UserName;
                    objTemp["CompanyOrDivision"] = item.CompanyOrDivision;
                    objTemp["CompanyCode"] = item.CompanyCode;
                    objTemp["Designation"] = item.Designation;
                    objTemp["EmailId"] = item.EmailId;
                    objTemp["MobileNo"] = item.MobileNo;
                    objTemp["ApplicableforCriNonCriReport"] = "Non-Critical";
                    objTemp["CreatedOn"] = getCurrentDateTime("-");
                    objTemp["CreatedBy"] = userDetails.userId;
                    objTemp["CreatedByName"] = userDetails.userName;
                    objTemp["ModifiedOn"] = getCurrentDateTime("-");
                    objTemp["ModifiedBy"] = userDetails.userId;
                    objTemp["ModifiedByName"] = userDetails.userName;
                    objTemp["SeqNo"] = item.SeqNo;
                    objTemp["PK"] = "IntegratedSystemTesting#ProjectCode#" + item.ProjectCode;
                    objTemp["SK"] = "Stakeholder#SeqNo#" + srNo;
                    temp.push(objTemp);
                    let tmpRes = addIntegratedSystemTestingDetails(objTemp);
                    array.push(tmpRes);
                    counter++;

                    if (counter === 5) {
                        await Promise.all(array);
                        counter = 0;
                        array = [];
                    }
                    srNo++;
                }
                if (array.length > 0) {
                    await Promise.all(array);
                }

                //to add the ist data gereation date record
                let tmpDataObj = {
                    PK: "IntegratedSystemTesting#ProjectCode#" + selectedProject.ProjectCode,
                    SK: "IntegratedSystemTesting",
                    ISTDate: getCurrentDateTime("-"),
                    CreatedOn: getCurrentDateTime("-"),
                    CreatedBy: userDetails.userId,
                    CreatedByName: userDetails.userName,
                    ModifiedOn: getCurrentDateTime("-"),
                    ModifiedBy: userDetails.userId,
                    ModifiedByName: userDetails.userName
                }
                let istresult = await addIntegratedSystemTestingDetails(tmpDataObj);

                setTabData(temp.sort(sortByPropertyInAscending("SeqNo")));
            }
            else {
                setTabData([]);
            }
        }
        else {
            setTabData(vendorISTresult.sort(sortByPropertyInAscending("SeqNo")));
        }
        setLoading(false);
    }

    const addMoreVendors = () => {
        if (tabData.length < vendorLimitVal) {
            setAction("Add");
            setShowForm(true);
        }
        else {
            MySwal.fire({
                text: "You can add only " + vendorLimitVal + " Stakeholder Contact per project.",
                imageUrl: errorImage,
                customClass: "swal-success",
            });
        }
    }

    const handleEdit = (params) => {
        setAction("Edit");
        setRowData(params);
        setShowForm(true);
    }
    const handleDelete = async (params) => {
        debugger;
        let result;
        let confirmBoxText = "Are you sure, do you want to delete Stakeholder Contact?";
        let confirmButtonText = "Delete";
        MySwal.fire({
            text: confirmBoxText,
            confirmButtonText: confirmButtonText,
            title: "Delete Stakeholder Contact",
            ...ALERT_PARAMS
        }).then(async (result2) => {
            if (result2.isConfirmed) {
                setLoading(true);
                let obj = { PK: params.PK, SK: params.SK };
                result = await deleteISTTabData(obj);
                if (result?.status) {
                    setLoading(false);
                    MySwal.fire({
                        text: "Stakeholder Contact deleted successfully.",
                        imageUrl: successImage,
                        customClass: "swal-success",
                        didClose: () => {
                            getProjectVendorDetails();
                        },
                    });
                }
                else {
                    setLoading(false);
                    MySwal.fire({
                        text: "Unable to Process Request",
                        imageUrl: errorImage,
                        customClass: "swal-success"
                    });
                }

            }
        })
    }

    return (
        <>
            {loading && <div className="spinner-box"> <Spinner animation="border" className="spinner" /></div>}
            <Container fluid className="ist-comp-container">
                {selectedProject.DeleteIndication === "Active" &&
                    <Row>
                        <Col lg={12} md={12} xs={12} className="addNewRow">
                            <BsPlusLg size={20} className="plusIcon" />
                            &nbsp;
                            <Link onClick={() => addMoreVendors()} className="blueColor">Add more</Link>
                        </Col>
                    </Row>
                }
                <GridMaster
                    rowDetails={tabData}
                    colDetails={columnDefs}
                    fieldNames={fieldName}
                    headerNames={headerName}
                    getDataEvent={(getFilteredData) => setFilteredResulttemp(getFilteredData)}
                    getFilterColLength={(getLength) => setFilterColLength(getLength)}
                    setExcelData={setExcelData}
                    handleEdit={handleEdit}
                    openConfirmBox={handleDelete}
                    actionName={actionButtons}
                    resetind={true}
                />
                {showForm && (
                    <IntegratedAddEditVendor
                        show={showForm}
                        action={action}
                        rowdata={rowData}
                        SelectedProject={props.SelectedProject}
                        onHide={() => setShowForm(false)}
                        getProjectVendorDetails={getProjectVendorDetails}
                    />
                )}
            </Container>
        </>
    );
};

export default IntegratedVendorContact;