import React, { useEffect, useContext, useState, useRef } from "react";
import { Modal, Row, Tabs, Tab, Container } from "react-bootstrap";
import "../../utils/AddEditProjectMaster.css";
import ProjectDetails from "./ProjectDetails";
import ProjectOrderDocuments from "./ProjectOrderDocuments";
import ProjectTeam from "./ProjectTeam";
import ProjectServicesEqpmnts from "./ProjectServicesEqpmnts";
import topArrow from "../../../icons/topArrow.svg";
import view_download from "../../../icons/downloadIcon.svg";
import { Link } from 'react-router-dom';
import { exportToExcel } from "./ExcelCreation_Projectwise";
import { getProjectData } from "../../../services/project-service";
import { AuthContext } from "../../../contexts/AuthContext";
import TabButtons from "../../utils/TabButtons";

const AddEditProjectMaster = (props) => {
    let action = props.action;
    const context = useContext(AuthContext);
    const userDetails = { role: context.role }
    let tab0Ref = useRef();
    let tab1Ref = useRef();
    let tab2Ref = useRef();
    let tab3Ref = useRef();
    const [currentTab, setCurrentTab] = useState(0);
    const [code, setCode] = useState(action !== "Add" ? props.rowdata.ProjectCode : "");
    const [name, setName] = useState(action !== "Add" ? props.rowdata.ProjectName : "");
    const [orderResult, setOrderResult] = useState();
    const [teamResult, setTeamResult] = useState();
    const [serviceResult, setServiceResult] = useState();

    useEffect(() => {
        setCode(code);
        setName(name);
    }, [code, name, currentTab]);

    useEffect(() => {
        fetchProjectOverallDetails();
    }, [action === "View"])

    const goToTop = () => {
        document.getElementById('projectModal').scrollIntoView();
    };

    const handleSelect = async (key, direction) => {
        let res = false;
        let saveFlag = true;

        if (direction !== "save") {
            saveFlag = false;
            res = true;
        }

        if (saveFlag) {
            switch (currentTab) {
                case 0:
                    res = await tab0Ref.current.funcOnNext();
                    break;
                case 1:
                    res = await tab1Ref.current.funcOnNext();
                    break;
                case 2:
                    res = await tab2Ref.current.funcOnNext();
                    break;
                case 3:
                    res = await tab3Ref.current.funcOnNext(direction);
                    break;
                default:
                    break;
            }
        }

        if (res) {
            if (direction == "prev") {
                if (currentTab !== 0) setCurrentTab(currentTab - 1)
            }
            else if (direction == "next") {
                if (currentTab !== 3) setCurrentTab(currentTab + 1)
            }
            else if (direction == "save") {
                setCurrentTab(currentTab)
            }
            else {
                setCurrentTab(Number(key))
            }
            if (key === "2") {
                let teamArr = [];
                let ele = { "ProjectCode": code }
                let projectresult = await getProjectData(ele);
                for (let k = 0; k < projectresult.length; k++) {
                    if ((projectresult[k].SK).startsWith("ProjectTeam#SeqNo#")) {
                        teamArr.push(projectresult[k]);
                    }
                }
                setTeamResult(teamArr);
            }
        }
    }

    const fetchProjectOverallDetails = async () => {
        let orderArr = [];
        let teamArr = [];
        let serviceArr = [];
        let ele = { "ProjectCode": props.rowdata.ProjectCode }
        let projectresult = await getProjectData(ele);
        for (let k = 0; k < projectresult.length; k++) {
            if ((projectresult[k].SK).startsWith("ProjectOrder#SeqNo#")) {
                orderArr.push(projectresult[k]);
            }
            else if ((projectresult[k].SK).startsWith("ProjectTeam#SeqNo#")) {
                teamArr.push(projectresult[k]);
            }
            else if ((projectresult[k].SK).startsWith("ProjectService#SeqNo#")) {
                serviceArr.push(projectresult[k]);
            }
        }
        setOrderResult(orderArr);
        setTeamResult(teamArr);
        setServiceResult(serviceArr);
    };

    const downloadExcel = async () => {
        let filename = "Project Wise Master Details";
        let excelData = [];
        excelData.push(props.rowdata);
        let colNameOrder = ["Project Status", "Project Code", "Project", "Sr. No.", "Document Type", "Document Name", "Attachment", "Inserted By", "Inserted On", "Modified By", "Modified On"];
        let FieldnameOrder = ["DeleteIndication", "ProjectCode", "ProjectName", "SeqNo", "DocumentType", "DocumentName", "Attachment", "CreatedBy", "CreatedOn", "ModifiedBy", "ModifiedOn"];
        let colNameTeam = ["User Status", "Project Code", "Project", "Sr. No.", "Stakeholder Type", "User Name", "Designation", "Company Or Division", "Role", "Mobile No.", "Email Id", "Inserted By", "Inserted On", "Modified By", "Modified On"];
        let FieldnameTeam = ["DeleteIndication", "ProjectCode", "ProjectName", "SeqNo", "StakeholderType", "UserName", "Designation", "CompanyOrDivision", "Role", "MobileNo", "EmailId", "CreatedBy", "CreatedOn", "ModifiedBy", "ModifiedOn"];
        let colNameService = ["Project Equipment / Activity Status", "Project Code", "Project", "Sr. No.", "Service Name", "Equipment / Activity", "Equipment/ Activity Tag", "Contractor Or Vendor", "Mobile No", "Email Id", "Inserted By", "Inserted On", "Modified By", "Modified On"];
        let FieldnameService = ["DeleteIndication", "ProjectCode", "ProjectName", "SeqNo", "ServiceName", "EquipmentName", "EquipmentTag", "Contractor", "MobileNo", "EmailId", "CreatedBy", "CreatedOn", "ModifiedBy", "ModifiedOn"];
        exportToExcel(filename, userDetails, excelData, orderResult, teamResult, serviceResult, colNameOrder, FieldnameOrder, colNameTeam, FieldnameTeam, colNameService, FieldnameService);
    }

    const closeOnDone = () => {
        props.onHide();
    }

    return (
        <Modal
            {...props}
            size="xl"
            show={true}
            backdrop="static"
            id="projectModal"
        >
            <Modal.Header closeButton>
                <Modal.Title className="modalTitle">
                    {props.action} Project
                    <div className="viewDownload">
                        <span className={props.action === "View" ? "" : "prevHideButton"}>
                            <span>
                                <img src={view_download} alt={view_download}
                                    onClick={() => downloadExcel()} className="icon-border"></img></span>
                            &nbsp;
                            <Link className="viewExcelLink" onClick={() => downloadExcel()}>Download Project Document</Link>
                        </span>
                    </div>
                </Modal.Title>
            </Modal.Header>
            <Container className="add-container">
                <Tabs activeKey={currentTab} onSelect={handleSelect} className="tabCssheader">
                    <Tab className="headerTabKey" eventKey={0} title={
                        <>
                            <span className="numberbg">1</span>
                            <span>Project Details</span>
                        </>
                    }>
                        <ProjectDetails action={props.action}
                            ref={tab0Ref}
                            rowdata={props.rowdata}
                            onHide={props.onHide}
                            // setCustomClass={() => handleSwitchChange()}
                            // setCustomClass1={() => tabSwitchChange()}
                            projectCodeData={setCode}
                            projectNameData={setName}
                            tabKey={() => handleSelect(currentTab, "next")}
                            setreload={props.setreload}
                            reload={props.reload}
                        />
                    </Tab>
                    <Tab eventKey={1} className="nav-link" title={
                        <>
                            <span className="numberbg">2</span>
                            <span>Order Documents</span>
                        </>
                    }>
                        <ProjectOrderDocuments action={props.action}
                            ref={tab1Ref}
                            rowdata={props.rowdata}
                            codedata={code}
                            namedata={name}
                            // setCustomClass={() => handleSwitchChange()}
                            // setCustomClass1={() => tabSwitchChange()}
                            tabKey={() => handleSelect(currentTab, "next")}
                            setreload={props.setreload}
                            reload={props.reload} />
                    </Tab>
                    <Tab eventKey={2} title={
                        <>
                            <span className="numberbg">3</span>
                            <span>Team Members</span>
                        </>
                    }>
                        <ProjectTeam action={props.action}
                            ref={tab2Ref}
                            rowdata={props.rowdata}
                            codedata={code}
                            namedata={name}
                            // setCustomClass={() => handleSwitchChange()}
                            // setCustomClass1={() => tabSwitchChange()}
                            tabKey={() => handleSelect(currentTab, "next")}
                            setreload={props.setreload}
                            reload={props.reload}
                            paramData={teamResult} />
                    </Tab>
                    <Tab eventKey={3} title={
                        <>
                            <span className="numberbg">4</span>
                            <span>Equipment / Activity</span>
                        </>
                    }>
                        <ProjectServicesEqpmnts action={props.action}
                            ref={tab3Ref}
                            rowdata={props.rowdata}
                            codedata={code}
                            namedata={name}
                            // setCustomClass={() => handleSwitchChange()}
                            // setCustomClass1={() => tabSwitchChange()}
                            tabKey={() => handleSelect(currentTab, "next")}
                            setreload={props.setreload}
                            reload={props.reload} />
                    </Tab>
                </Tabs>
                <br />
                <Row className="tabDirectionbtnRow">
                    <TabButtons
                        currentTab={currentTab}
                        lastTab={3}
                        handleSelect={handleSelect}
                        handleBack={closeOnDone}
                        action={action}
                        showSave={currentTab === 0 || window.innerWidth > 768}
                    />
                </Row>
            </Container>
            <div className="top-to-btm" style={{ marginLeft: "58rem" }}>
                <img
                    src={topArrow}
                    alt="topArrow"
                    onClick={goToTop}
                />
            </div>
        </Modal>
    );
}

export default AddEditProjectMaster;
