import React, { useEffect, useState, useRef, useContext } from "react";
import { Row, Container, Spinner } from "react-bootstrap";
import { BsPlusLg } from "react-icons/bs";
import { Link } from 'react-router-dom';
import ActionRenderer from "../masters/ActionRenderer";
import GridMaster from "../masters/GridMaster";
import { getCurrentDateTime } from "../../functions/gbcs-functions";
import { getProjectActiveServiceEqui } from "../../services/project-service";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import successImage from "../../icons/success-tick-logo.svg";
import errorImage from "../../icons/error-tick-logo.svg";
import { AuthContext } from "../../contexts/AuthContext";
import IntegratedAddEditEquipment from "./IntegratedAddEditEquipment";
import { getIntegratedTabsData, deleteISTTabData, addIntegratedSystemTestingDetails } from "../../services/integrated-system-testing-service";
import { useDispatch } from "react-redux";
import { searchActions } from "../../store/grid-slice";
import { sortByPropertyInAscending } from "../../utils/Utility";
import { getSelectedEquipDetails } from "../../services/equipmentactivity-master-service";
import { ALERT_PARAMS } from "../../utils/constant.js";

const IntegratedEquipment = (props) => {
    const dispatch = useDispatch();
    const MySwal = withReactContent(Swal);
    const context = useContext(AuthContext);
    const userDetails = { email: context.userEmail, userName: context.userName, dataControl: context.dataControl, userId: context.userId };
    const [showForm, setShowForm] = useState(false);
    const [filteredResulttemp, setFilteredResulttemp] = useState([]);
    const [filterColLength, setFilterColLength] = useState(0);
    const [excelData, setExcelData] = useState([]);
    const [tabData, setTabData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [action, setAction] = useState("");
    const [rowData, setRowData] = useState({});
    const selectedProject = props.selectedProject;
    const equipmentLimitValue = props.limitValue.EquipmentLimitValue;
    const [actionButtons, setActionButtons] = useState({});

    const columnDefs = [
        {
            headerName: "Sr.No.",
            field: "SrNo",
            valueFormatter: (params) => {
                return params.node.rowIndex + 1;
            },
            minWidth: 100
        },
        {
            headerName: "Service Name",
            headerTooltip: "Service Name",
            field: "ServiceName",
            tooltipField: "ServiceName",
            minWidth: 150
        },
        {
            headerName: "Equipment / Activity",
            headerTooltip: "Equipment / Activity",
            field: "EquipmentName",
            tooltipField: "EquipmentName",
            minWidth: 180
        },
        {
            headerName: "Equipment / Activity Tag",
            headerTooltip: "Equipment / Activity Tag",
            field: "EquipmentTag",
            tooltipField: "EquipmentTag",
            minWidth: 180
        },
        {
            headerName: "Contractor / Vendor",
            headerTooltip: "Contractor/Vendor",
            field: "Contractor",
            tooltipField: "Contractor",
            minWidth: 180
        },
        {
            headerName: "System Ready for IST",
            headerTooltip: "System Ready for IST",
            field: "SystemReadyForIST",
            tooltipField: "SystemReadyForIST",
            minWidth: 180
        },
        {
            headerName: "Auto Fetched / Manual Entry",
            headerTooltip: "Auto Fetched / Manual Entry",
            field: "RecordEntry",
            tooltipField: "RecordEntry",
            minWidth: 180
        },
        {
            headerName: "Applicable for Critcal / Non-Critical Report",
            headerTooltip: "Applicable for Critcal / Non-Critical Report",
            field: "ApplicableforCriNonCriReport",
            tooltipField: "Applicable for Critcal / Non-Critical Report",
            minWidth: 220
        },
        {
            headerName: "Remarks",
            field: "Remarks",
            tooltipField: "Remarks",
            minWidth: 180
        },
        {
            field: "",
            width: 80,
            sortable: false,
            filter: false,
            cellRenderer: function (params) {
                return <ActionRenderer selectedData={params.data} actionName={actionButtons} handleEditEvent={handleEdit} handleDeleteEvent={handleDelete} />
            },
            pinned: 'right',
            resizable:false
        }
    ];

    const headerName = (field) => {
        let name;
        switch (field) {
            case "SrNo":
                name = "Sr.No.";
                break;
            case "ServiceName":
                name = "Service Name";
                break;
            case "EquipmentName":
                name = "Equipment / Activity";
                break;
            case "EquipmentTag":
                name = "Equipment / Activity Tag";
                break;
            case "Contractor":
                name = "Contractor / Vendor";
                break;
            case "SystemReadyForIST":
                name = "System Ready for IST";
                break;
            case "RecordEntry":
                name = "Auto Fetched / Manual Entry";
                break;
            case "ApplicableforCriNonCriReport":
                name = "Applicable for Critcal / Non-Critical Report";
                break;
            case "Remarks":
                name = "Remarks";
                break;
            default:
                name = "No match found";
                break;
        }
        return name;
    };

    const fieldName = (header) => {
        let name;
        switch (header) {
            case "Sr.No.":
                name = "SrNo";
                break;
            case "Service Name":
                name = "ServiceName";
                break;
            case "Equipment / Activity":
                name = "EquipmentName";
                break;
            case "Equipment / Activity Tag":
                name = "EquipmentTag";
                break;
            case "Contractor / Vendor":
                name = "Contractor";
                break;
            case "System Ready for IST":
                name = "SystemReadyForIST";
                break;
            case "Auto Fetched / Manual Entry":
                name = "RecordEntry";
                break;
            case "Applicable for Critcal / Non-Critical Report":
                name = "ApplicableforCriNonCriReport";
                break;
            case "Remarks":
                name = "Remarks";
                break;
            default:
                name = "No match found";
                break;
        }
        return name;
    };

    const addNewRow = () => {
        if (tabData.length < equipmentLimitValue) {
            setAction("Add");
            setShowForm(true);
        }
        else {
            MySwal.fire({
                text: "You can add only " + equipmentLimitValue + " Equipments per project.",
                imageUrl: errorImage,
                customClass: "swal-success",
            });
        }
    };

    const handleEdit = (params) => {
        setAction("Edit");
        setRowData(params);
        setShowForm(true);
    }

    const handleDelete = async (params) => {
        let result;
        let confirmBoxText = "Are you sure, do you want to delete Equipment / Activity?";
        let confirmButtonText = "Delete";
        MySwal.fire({
            text: confirmBoxText,
            confirmButtonText: confirmButtonText,
            title: "Delete IST Equipment",
            ...ALERT_PARAMS
        }).then(async (result2) => {
            if (result2.isConfirmed) {
                setLoading(true);
                let obj = { PK: params.PK, SK: params.SK };
                result = await deleteISTTabData(obj);
                if (result?.status) {
                    setLoading(false);
                    MySwal.fire({
                        text: "Equipment / Activity deleted successfully.",
                        imageUrl: successImage,
                        customClass: "swal-success",
                        didClose: () => {
                            fetchProjectServiceEqui(selectedProject?.ProjectCode);
                        },
                    });
                }
                else {
                    setLoading(false);
                    MySwal.fire({
                        text: "Unable to Process Request",
                        imageUrl: errorImage,
                        customClass: "swal-success"
                    });
                }

            }
        })
    }

    const fetchProjectServiceEqui = async () => {
        setLoading(true);
        let temp = [];
        let equipISTresult = await getIntegratedTabsData({ "ProjectCode": selectedProject.ProjectCode, "flag": "Equipment" });
        if (equipISTresult?.length <= 0 && selectedProject.DeleteIndication === "Active") {
            const result = await getProjectActiveServiceEqui(selectedProject.ProjectCode);
            if (result?.length !== 0) {
                let srNo = 1;
                let counter = 0;
                let array = [];

                let arr = [];
                for (let item of result) {
                    if (arr.indexOf(item.EquipmentCode) === -1) {
                        arr.push(item.EquipmentCode)
                    }
                }
                const equipMasterResult = await getSelectedEquipDetails(arr);

                for (let item of result) {
                    let equipDetails = equipMasterResult?.filter(x => x.EquipmentCode === item.EquipmentCode);
                    if (equipDetails.length > 0 && equipDetails[0].IntegrationSystemTest) {
                        let objTemp = {};
                        objTemp["ProjectCode"] = item.ProjectCode;
                        objTemp["ProjectName"] = item.ProjectName;
                        objTemp["ServiceCode"] = item.ServiceCode;
                        objTemp["ServiceName"] = item.ServiceName;
                        objTemp["EquipmentCode"] = item.EquipmentCode;
                        objTemp["EquipmentName"] = item.EquipmentName;
                        objTemp["EquipmentTag"] = item.EquipmentTag;
                        objTemp["Contractor"] = item.Contractor;
                        objTemp["RecordEntry"] = "Auto Fetched";
                        objTemp["SystemReadyForIST"] = "Yes";
                        objTemp["ApplicableforCriNonCriReport"] = "Non-Critical";
                        objTemp["Remarks"] = "";
                        objTemp["CreatedOn"] = getCurrentDateTime("-");
                        objTemp["CreatedBy"] = userDetails.userId;
                        objTemp["CreatedByName"] = userDetails.userName;
                        objTemp["ModifiedOn"] = getCurrentDateTime("-");
                        objTemp["ModifiedBy"] = userDetails.userId;
                        objTemp["ModifiedByName"] = userDetails.userName;
                        objTemp["SeqNo"] = item.SeqNo;
                        objTemp["PK"] = "IntegratedSystemTesting#ProjectCode#" + item.ProjectCode;
                        objTemp["SK"] = "Equipment#SeqNo#" + item.SeqNo;

                        temp.push(objTemp);
                        let tmpRes = addIntegratedSystemTestingDetails(objTemp);
                        array.push(tmpRes);
                        counter++;

                        if (counter === 5) {
                            await Promise.all(array);
                            counter = 0;
                            array = [];
                        }
                        srNo++;
                    }
                }
                if (array.length > 0) {
                    await Promise.all(array);
                }
                setTabData(temp.sort(sortByPropertyInAscending("SeqNo")));
            }
            else {
                setTabData([]);
            }
        }
        else {
            setTabData(equipISTresult.sort(sortByPropertyInAscending("SeqNo")));
        }
        setLoading(false);
    }

    useEffect(() => {
        if (props.tabNo === 1) {
            dispatch(searchActions.setSearch(""));
            fetchProjectServiceEqui();
        }
        if (selectedProject.DeleteIndication !== "Active") {
            setActionButtons({
                ViewAction: false,
                EditAction: false,
                DownloadAction: false,
                DeleteAction: false,
            });
        }
        else {
            setActionButtons({
                ViewAction: false,
                EditAction: true,
                DownloadAction: false,
                DeleteAction: true
            });
        }
    }, [props.tabNo]);

    return (
        <>
            {loading && <div className="spinner-box"> <Spinner animation="border" className="spinner" /></div>}
            <Container fluid className="ist-comp-container">
                {selectedProject.DeleteIndication === "Active" &&
                    <Row>
                        <div className="addNewRow">
                            <BsPlusLg size={20} className="plusIcon" />
                            &nbsp;
                            <Link onClick={() => addNewRow()} className="blueColor">Add more</Link>
                        </div>
                    </Row>
                }
                <GridMaster
                    rowDetails={tabData}
                    colDetails={columnDefs}
                    fieldNames={fieldName}
                    headerNames={headerName}
                    getDataEvent={(getFilteredData) => setFilteredResulttemp(getFilteredData)}
                    getFilterColLength={(getLength) => setFilterColLength(getLength)}
                    setExcelData={setExcelData}
                    handleEdit={handleEdit}
                    openConfirmBox={handleDelete}
                    actionName={actionButtons}
                    resetind={true}
                />
                {showForm && (
                    <IntegratedAddEditEquipment
                        show={showForm}
                        action={action}
                        rowdata={rowData}
                        selectedProject={selectedProject}
                        onHide={() => setShowForm(false)}
                        fetchProjectServiceEqui={fetchProjectServiceEqui}
                    />
                )}
            </Container>
        </>
    )
};

export default IntegratedEquipment;