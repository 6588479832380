export const resizableComponent = (id1, id2) => {
    console.log("resizableComponent")
    // Query the table
    const leftPanel = document.getElementById(id1);
    const rightPanel = document.getElementById(id2);
    if (leftPanel) {
        // console.log("in", table)
        // Query all headers

        let resizer = document.createElement('div');
        resizer.classList.add('resizer');
        leftPanel.appendChild(resizer);

        createResizableColumn(leftPanel, rightPanel, resizer);
    }
}
const createResizableColumn = function (leftPanel, rightPanel, resizer) {

    console.log("createResizableColumn")
    // Track the current position of mouse
    let x = 0;
    let w = 0;
    let r = 0;

    const mouseDownHandler = function (e) {
        console.log("mouseDownHandler")
        // Get the current mouse position
        x = e.clientX;
        console.log("x", x)
        // Calculate the current width of column
        const styles = window.getComputedStyle(leftPanel);
        console.log("styles.width", styles.width)
        w = parseInt(styles.width, 10);

        const rstyles = window.getComputedStyle(rightPanel);
        console.log("rstyles.width", rstyles.width)
        r = parseInt(rstyles.width, 10);

        // Attach listeners for document's events
        document.addEventListener('mousemove', mouseMoveHandler);
        document.addEventListener('mouseup', mouseUpHandler);
        resizer.classList.add('resizing');
    };

    const mouseMoveHandler = function (e) {

        console.log("mouseMoveHandler")
        // Determine how far the mouse has been moved
        const dx = e.clientX - x;
        console.log(w, dx, e.clientX, x)
        // Update the width of column
        if ((w + dx) > 50) {
            leftPanel.style.width = `${w + dx}px`;
            rightPanel.style.width = `${r - dx}px`;
        }

    };

    // When user releases the mouse, remove the existing event listeners
    const mouseUpHandler = function () {
        console.log("mouseUpHandler")
        document.removeEventListener('mousemove', mouseMoveHandler);
        document.removeEventListener('mouseup', mouseUpHandler);
        resizer.classList.remove('resizing');
    };

    resizer.addEventListener('mousedown', mouseDownHandler);
};