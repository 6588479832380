import React, { useEffect, useState, useContext } from "react";
import { Button, Form, Modal, Row, Col } from "react-bootstrap";
import { AuthContext } from "../../../contexts/AuthContext";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import successImage from "../../../icons/success-tick-logo.svg";
import errorImage from "../../../icons/error-tick-logo.svg";
import { UpdateStatusDetails } from "../../../services/checklist-master-service"
import { getCurrentDateTime } from "../../../functions/gbcs-functions";

const AddEditStatusDetails = (props) => {
  const MySwal = withReactContent(Swal);
  const context = useContext(AuthContext);
  const userDetails = { userName: context.userName, userId: context.userId };
  const [toggleClass, setToggleClass] = useState("activeToggleLabel");
  let todayDate = getCurrentDateTime("-");

  const MasterData = props.masterData;

  let action = props.action;
  const [paramObj, setParamObj] = useState(
    action === "Edit"
      ? props.rowdata
      : {
        Status: "",
        Result: "Accepted",
        Remark: "",
        ParameterCategory: []
      }
  );
  const [tmpcat, setTmpcat] = useState(
    action === "Edit" ? props.rowdata.ParameterCategory : []
  );
  const [error, setError] = useState({
    Status: {},
    Result: {},
    Remark: {},
    ParameterCategory: {},
  });

  const validateDetails = () => {
    let isError = false;
    const errorMsg = {
      Status: {},
      Result: {},
      Remark: {},
      ParameterCategory: {},
    };
    if (props.IsParameterCategory && tmpcat.length === 0) {
      isError = true;
      errorMsg.ParameterCategory = {
        status: true,
        message: "Parameter Category is required.",
      };
    }

    if (paramObj.Status.trim() === "") {
      paramObj.Status = "";
      isError = true;
      errorMsg.Status = {
        status: true,
        message: "Status is required.",
      };
    }

    if (paramObj.Result === "") {
      isError = true;
      errorMsg.Result = {
        status: true,
        message: "Result is required.",
      };
    }
    if (paramObj.Remark.trim() === "") {
      paramObj.Remark = "";
      isError = true;
      errorMsg.Remark = {
        status: true,
        message: "Remark is required.",
      };
    }
    setError(errorMsg);
    return { isError, errorMsg };
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setParamObj((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleChangeCheckbox = (e) => {
    console.log(e.target.name, e.target.value, e.target.checked);

    let newArr = [...tmpcat];
    console.log("newArr", newArr, tmpcat)
    if (e.target.checked) {
      newArr.push(e.target.value)
    } else {
      let index = newArr.indexOf(e.target.value);
      newArr.splice(index, 1);
    }
    setTmpcat(newArr);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let validationResult = validateDetails();

    if (!validationResult.isError) {
      if (
        MasterData.length > 0 &&
        MasterData.filter(
          (item) =>
            (action === "Add" ||
              (action === "Edit" &&
                item.SeqNo !== paramObj.SeqNo)) &&
            item.Status.trim().toLowerCase() ===
            paramObj.Status.trim().toLowerCase()
        ).length > 0
      ) {
        MySwal.fire({
          text: "Status already exists.",
          imageUrl: errorImage,
          customClass: "swal-success",
        });
        return;
      }

      if (props.IsParameterCategory) {
        paramObj.ParameterCategory = tmpcat;
      }
      else {
        paramObj.ParameterCategory = [];

      }
      let finalData = Object.assign([], MasterData);
      if (action === "Add") {
        paramObj.SeqNo = props.rowNo;
        finalData.push(paramObj)
      }
      else if (action === "Edit") {
        let index = finalData.findIndex((item) => item.SeqNo === props.SeqNo);
        finalData[index] = paramObj;
      }
      let obj = {}
      obj.StatusDetails = finalData
      obj.PK = props.primeKey;
      obj.SK = props.secondKey;
      obj.ModifiedOn = todayDate;
      obj.ModifiedBy = userDetails.userId;
      obj.ModifiedByName = userDetails.userName;
      let result = UpdateStatusDetails(obj);

      result
        .then((resp) => {
          if (resp.status) {
            props.setAddNewParameter(paramObj);
            MySwal.fire({
              text: resp.message,
              imageUrl: successImage,
              customClass: "swal-success",
              didClose: () => {
                props.onHide();
              },
            });
          }
          else {
            MySwal.fire({
              text: resp.message,
              imageUrl: errorImage,
              customClass: "swal-success",
            });
          }
        })
        .catch((error) => {
          MySwal.fire({
            text: "Unable to Process Request",
            imageUrl: errorImage,
            customClass: "swal-success",
          });
        });
    }
  };
  const handleSwitchChange = (e) => {
    if (e.target.checked) {
      setToggleClass("activeToggleLabel");
    } else {
      setToggleClass("inactiveToggleLabel");
    }

    setParamObj((prevState) => ({
      ...prevState,
      Result: e.target.checked ? "Accepted" : "Rejected",
    }));
  };

  return (
    <Modal {...props} size="lg" backdrop="static" centered>
      <Modal.Header closeButton>
        <Modal.Title>
          {action} Status
          <span className="req-input">&nbsp;(All fields are mandatory)</span>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form id="statusdetails" name="statusdetails" autoComplete="off">
          {props.IsParameterCategory ? (
            <Row>
              <Col md={3} xs={12}>
                <Form.Label className="fieldLabel">
                  Parameter Category
                </Form.Label>
                {error.ParameterCategory.status && (
                  <Form.Text className="text-danger">
                    {error.ParameterCategory.message}
                  </Form.Text>
                )}
              </Col>
              <Col md={9} xs={12}>
                <Row>
                  {props.ParameterCaterydata.map((selection, index) => {
                    return (
                      <Col md={4} xs={12}>
                        <Form.Check
                          type="checkbox"
                          name="ParamCategory"
                          id={"ParameterCaterydata" + index}
                          label={selection}
                          value={selection}
                          checked={tmpcat.indexOf(selection) !== -1 ? true : false}
                          onChange={(e) => {
                            handleChangeCheckbox(e);
                          }}
                        />
                      </Col>
                    );
                  })}
                </Row>
              </Col>
            </Row>
          ) : (
            <></>
          )}

          <Row>
            <Col md={4} xs={12}>
              <Form.Group className="g-input">
                <Form.Control
                  id="Status"
                  type="text"
                  name="Status"
                  maxLength="20"
                  placeholder=" "
                  value={paramObj.Status}
                  onChange={handleChange}
                />
                <Form.Label className="frm-label" for="Status">
                  Status
                </Form.Label>
                {error.Status.status && (
                  <Form.Text className="text-danger">
                    {error.Status.message}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
            <Col md={4} xs={12}>
              <Row>
                <Col>
                  <Form.Label className="fieldLabel">Status</Form.Label>
                </Col>
                <Col>
                  <Form.Switch
                    className={"semiBoldToggleButton " + toggleClass}
                    label={
                      paramObj.Result === "Accepted" ? "Accepted" : "Rejected"
                    }
                    name="Result"
                    checked={paramObj.Result === "Accepted" ? true : false}
                    onChange={(e) => {
                      handleSwitchChange(e);
                    }}
                  />
                </Col>
              </Row>
            </Col>

            <Col md={4} xs={12}></Col>
            <Col md={8} xs={12}>
              <Form.Group className="g-input">
                <Form.Control
                  id="Remark"
                  type="text"
                  name="Remark"
                  maxLength="100"
                  placeholder=" "
                  value={paramObj.Remark}
                  onChange={handleChange}
                />
                <Form.Label className="frm-label" for="Remark">
                  Remarks
                </Form.Label>
                {error.Remark.status && (
                  <Form.Text className="text-danger">
                    {error.Remark.message}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Button
                variant="success"
                className="btn-add-color semiBoldToggleButton rightAlignedButton"
                onClick={handleSubmit}
              >
                {action === "Add" ? "Add" : "Update"}
              </Button>
            </Col>
          </Row>
        </Form>
        <br />
      </Modal.Body>
    </Modal>
  );
};

export default AddEditStatusDetails;
