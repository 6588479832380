import React, { useEffect, useState } from "react";
import { Row, Col, Form, Button, Alert, Spinner } from "react-bootstrap";
import godrejDarkLogo from "../../icons/godrej-logo-dark.svg"
import { LOGIN_GODREJ_EMPLOYEE, LOGIN_DEALER } from "../../utils/constant";
import { useMsal } from "@azure/msal-react";
import { compose } from "redux";
import { loginRequest } from "./authConfig";

import { extLoginRequest } from './authConfigExternal';

const LoginPage = (props) => {
  const [userLoginType, setUserLoginType] = useState("");
  const [loading, setLoading] = useState(false);
  const { instance } = useMsal();

  let err = ""
  const [errorMessage, setErrorMessage] = useState(err ? err : "");

  useEffect(() => {
    const tmpLoginType = sessionStorage.getItem("LoginType");
    if (tmpLoginType === null) {
      sessionStorage.setItem("LoginType", "Godrej Employee");
      setUserLoginType("Godrej Employee");
    }
    else {
      setUserLoginType(tmpLoginType);
    }
  }, []);

  useEffect(() => {
    setErrorMessage(props.loginErrorMessage);
    if (props.loginErrorMessage) {
      const tmpLoginType = sessionStorage.getItem("LoginType");
      sessionStorage.clear();
      sessionStorage.setItem("LoginType", tmpLoginType);
    }

  }, [props.loginErrorMessage]);

  const loginFunction = async () => {
    setErrorMessage("");
    const tmpLoginType = sessionStorage.getItem("LoginType");
    if (tmpLoginType === LOGIN_GODREJ_EMPLOYEE || tmpLoginType === null) {
      instance.loginRedirect(loginRequest).catch(e => {
        console.log("error in login", e)
      });
    }
    else if (tmpLoginType === LOGIN_DEALER) {
      instance.loginRedirect(extLoginRequest).catch(e => {
        console.log("error in login", e)
      });
    }

  };

  const onChangeLoginType = (e) => {
    sessionStorage.setItem("LoginType", e.target.value);
    setUserLoginType(e.target.value);
    window.location.href = "/";
  };

  return (
    <>
      {loading && <div className="spinner-box"> <Spinner animation="border" className="spinner" /></div>}
      <div className="d-flex logoHeader">
        <img src={godrejDarkLogo} alt="GodrejEntLogo" />
        <span className="headerLink">Godrej Enterprises Group</span>
      </div>
      <div className="login-container">
        <Row className="pb-0">
          <Col xs={12} md={8} className="d-none d-md-block d-lg-block">
            <div className="loginBg" />
          </Col>
          <Col
            xs={12}
            md={4}
          >

            <div className="loginForm">
              <div className="login-logo text-start mb-4">
                <h1>GCEM</h1>
                <h5>Comprehensive T&C Tracker</h5>
              </div>
              <div>
                <div className="mt-3">
                  {errorMessage && (
                    <div className="loginErrorDiv">
                      <div className="loginErrorDivHeader">Login Failed</div>
                      <div className="loginErrorDivBody">{errorMessage}</div>
                    </div>
                  )}
                  <div className="mt-5">
                    <div className="LoginRightRadioDivTitle mb-3">Select User Type</div>
                    <div className="LoginRightRadioDiv">
                      <div className="LoginRightRadioLabel">
                        <Form.Check
                          label={LOGIN_GODREJ_EMPLOYEE}
                          name="loginType"
                          type="radio"
                          onChange={(e) => {
                            setErrorMessage("");
                            onChangeLoginType(e);
                          }}
                          value={LOGIN_GODREJ_EMPLOYEE}
                          checked={userLoginType === LOGIN_GODREJ_EMPLOYEE}
                          className="login-form-check"
                        />
                      </div>
                      <div className="LoginRightRadioLabel">
                        <Form.Check
                          label={LOGIN_DEALER}
                          name="loginType"
                          type="radio"
                          value={LOGIN_DEALER}
                          checked={userLoginType === LOGIN_DEALER}
                          className="login-form-check"
                          onChange={(e) => {
                            setErrorMessage("");
                            onChangeLoginType(e);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-4">
                  <Button variant="success"
                    onClick={async () => {
                      await loginFunction();
                    }}
                    className="forgotButton"
                  >
                    Login
                  </Button>
                </div>
              </div>

            </div>

          </Col>
        </Row>
      </div>
    </>
  );
};
export default compose(LoginPage);
