import React, { useEffect, useState, useContext, useImperativeHandle, forwardRef } from "react";
import { Row, Container, Spinner, Form, Col } from "react-bootstrap";
import { BsPlusLg } from "react-icons/bs";
import { Link } from 'react-router-dom';
import { getCurrentDateTime } from "../../functions/gbcs-functions";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import successImage from "../../icons/success-tick-logo.svg";
import errorImage from "../../icons/error-tick-logo.svg";
import { AuthContext } from "../../contexts/AuthContext";
import Autocomplete from "../../utils/Autocomplete";
import {
    deleteISTTabData,
    getIntegratedTabsData,
    updatePowerAnalyzerDocuments
} from "../../services/integrated-system-testing-service";
import { downloadAttachment } from "../../services/designreviewtracker-service";
import DragDropRender from "../utils/DragDropRender";
import Validate from "../utils/Validation";
import axios from "axios";
import { deletS3Attachment, moveS3File } from "../../services/common-master-service"
import { sortByPropertyInAscending } from "../../utils/Utility";

const IntegratedPowerAnalyzer = forwardRef((props, ref) => {
    const MySwal = withReactContent(Swal);
    const context = useContext(AuthContext);
    const userDetails = { email: context.userEmail, userName: context.userName, dataControl: context.dataControl, userId: context.userId };
    const [loading, setLoading] = useState(false);
    const selectedProject = props.selectedProject;
    const istPowerUploadLimitVal = props.limitValue;

    const PowerdocsTitles = [{ name: "Power Analyzer Location Layout", id: "powerAnalyzer", sectionKey: "PowerAnalyzer", dataLimit: istPowerUploadLimitVal.PowerAnalyzerLimitValue },
    { name: "Power Analyzer Recorded Trend", id: "TrendpowerAnalyzer", sectionKey: "PowerAnalyzerTrend", dataLimit: istPowerUploadLimitVal.PowerAnalyzerTrendLimitValue },
    { name: "IST Screenshots", id: "istScreenshots", sectionKey: "IstScreenshots", dataLimit: istPowerUploadLimitVal.IstScreenshotsLimitValue },
    { name: "Critical Rooms Heaters and Data Loggers Location", id: "roomHeatersLocations", sectionKey: "RoomHeatersLocations", dataLimit: istPowerUploadLimitVal.RoomHeatersLocationsLimitValue },
    { name: "Critical Rooms Heaters and Data Loggers Readings", id: "roomHeatersReadings", sectionKey: "RoomHeatersReadings", dataLimit: istPowerUploadLimitVal.RoomHeatersReadingsLimitValue }];

    const [errorObj, setErrorObj] = useState({});
    const [tabDataObj, setTabDataObj] = useState({});
    const [uploadDocObj, setUploadDoc] = useState({});
    const [titles, setTitles] = useState({});

    let supportedFormats = "Supports: jpg,jpeg,png,doc,docx formats Max File Size 5MB/20MB";

    const showAlert = (message) => {
        MySwal.fire({
            text: message,
            imageUrl: errorImage,
            customClass: "swal-success",
        });
    };

    const getMaxSeqNo = (array) => {
        let maxData = array[array.length - 1];
        array.forEach(element => {
            if (element.SeqNo > maxData.SeqNo) {
                maxData = element;
            }
        });
        return maxData;
    };

    const checkFileExtension = (filename) => {
        const extension = filename.split(".")[1];
        return extension === "doc" || extension === "docs" || extension === "docx";
    };

    const deleteRow = async (item, currentSeqNo) => {
        const tempData = tabDataObj[item];
        if (tempData.length === 1) {
            showAlert(`You can not delete this line.`);
            return;
        }

        setLoading(true);
        let index = tempData.findIndex(x => x.SeqNo === currentSeqNo);

        if (index >= 0) {
            let errData = { ...errorObj[item] };
            let docData = [...uploadDocObj[item]];

            tempData.splice(index, 1);
            delete errData[currentSeqNo];

            let docIndex = docData.find(x => x.SeqNo === currentSeqNo);
            if (docIndex) {
                docIndex.NewFileName = "";
                docIndex.DelFlag = true;
            }

            setTabDataObj((prevState) => ({ ...prevState, [item]: tempData }));
            setErrorObj((prevState) => ({ ...prevState, [item]: errData }));
            setUploadDoc((prevState) => ({ ...prevState, [item]: docData }));
        }
        setLoading(false);
    }

    const addNewRow = async (item, limitVal) => {
        const tempData = tabDataObj[item];
        console.log("tempData", tempData)
        if (!tempData || tempData.length === 0) {
            return;
        }

        if (tempData.length >= limitVal) {
            showAlert(`You can add only ${limitVal} titles for this section.`);
            return;
        }

        const max = await getMaxSeqNo(tempData);
        // console.log(max);
        if (max.titlename === "" || max.filename === "") {
            showAlert("Kindly fill the previous row before adding new row.")
            return false;
        }
        else {
            tempData.push({ titlename: "", applicableforprint: "For Reference", filename: "", SeqNo: Number(max.SeqNo + 1) });
            let errData = { ...errorObj[item] };
            let docData = [...uploadDocObj[item]];
            errData[Number(max.SeqNo + 1)] = { titlename: { status: false, message: "" }, applicableforprint: { status: false, message: "" }, filename: { status: false, message: "" } };
            docData.push({ SeqNo: Number(max.SeqNo + 1), OldFile: "", NewFile: "", NewFileName: "", DelFlag: false });
            setTabDataObj((prevState) => ({ ...prevState, [item]: tempData }));
            setErrorObj((prevState) => ({ ...prevState, [item]: errData }));
            setUploadDoc((prevState) => ({ ...prevState, [item]: docData }));
        }
    };

    const fetchPowerDocData = async () => {
        setLoading(true);
        const [powerAnalyzer, powerAnalyzerTrend, istScreenshots, roomHeatersLocations, roomHeatersReadings] = await Promise.all([
            getIntegratedTabsData({ "ProjectCode": selectedProject.ProjectCode, "flag": "PowerAnalyzer" }),
            getIntegratedTabsData({ "ProjectCode": selectedProject.ProjectCode, "flag": "PowerAnalyzerTrend" }),
            getIntegratedTabsData({ "ProjectCode": selectedProject.ProjectCode, "flag": "IstScreenshots" }),
            getIntegratedTabsData({ "ProjectCode": selectedProject.ProjectCode, "flag": "RoomHeatersLocations" }),
            getIntegratedTabsData({ "ProjectCode": selectedProject.ProjectCode, "flag": "RoomHeatersReadings" })
        ]);
        let resultdocuments = {
            PowerAnalyzer: powerAnalyzer.sort(sortByPropertyInAscending("SeqNo")),
            PowerAnalyzerTrend: powerAnalyzerTrend.sort(sortByPropertyInAscending("SeqNo")),
            IstScreenshots: istScreenshots.sort(sortByPropertyInAscending("SeqNo")),
            RoomHeatersLocations: roomHeatersLocations.sort(sortByPropertyInAscending("SeqNo")),
            RoomHeatersReadings: roomHeatersReadings.sort(sortByPropertyInAscending("SeqNo"))
        }
        let tmpError = {};
        let tmpData = {};
        let tmpDocs = {};
        let tmpTitles = {};
        for (let section of PowerdocsTitles) {
            tmpError[section.id] = { 1: { titlename: { status: false, message: "" }, applicableforprint: { status: false, message: "" }, filename: { status: false, message: "" } } };
            tmpData[section.id] = [{ SeqNo: 1, filename: "", applicableforprint: "For Reference", titlename: "" }];
            tmpDocs[section.id] = [{ SeqNo: 1, OldFile: "", NewFile: "", NewFileName: "", DelFlag: false }];
            tmpTitles[section.id] = [];
            if (resultdocuments[section.sectionKey].length > 0) {
                let tmpErr = {};
                resultdocuments[section.sectionKey].map(dt => { (tmpErr[dt.SeqNo] = { titlename: { status: false, message: "" }, applicableforprint: { status: false, message: "" }, filename: { status: false, message: "" } }) });
                let tmpSecDocs = resultdocuments[section.sectionKey].map(dt => ({ SeqNo: dt.SeqNo, OldFile: dt.filename, NewFile: "", NewFileName: "", DelFlag: false }));
                let tmpSecTittle = resultdocuments[section.sectionKey].map(dt => dt.titlename);

                tmpError[section.id] = tmpErr;
                tmpData[section.id] = resultdocuments[section.sectionKey].sort(sortByPropertyInAscending("SeqNo"));
                tmpDocs[section.id] = tmpSecDocs;
                tmpTitles[section.id] = [...new Set(tmpSecTittle)];
            }
        }
        console.log("tmpError", tmpError)
        console.log("tmpData", tmpData)
        console.log("tmpDocs", tmpDocs)
        console.log("tmpTitles", tmpTitles)
        setErrorObj(tmpError);
        setTabDataObj(tmpData);
        setUploadDoc(tmpDocs);
        setTitles(tmpTitles);

        setLoading(false);
    }

    useEffect(() => {
        if (props.tabNo === 4) {
            fetchPowerDocData();
        }
    }, [props.tabNo]);

    const ValidateDocuments = async () => {
        let isError = false;
        let tmpErr = {};
        for (let section of PowerdocsTitles) {
            let secId = section.id;
            tmpErr[secId] = {};
            console.log("tmp", tabDataObj[secId])
            for (let secData of tabDataObj[secId]) {
                tmpErr[secId][secData.SeqNo] = { titlename: { status: false, message: "" }, applicableforprint: { status: false, message: "" }, filename: { status: false, message: "" } };
                if (!secData.titlename) {
                    isError = true;
                    tmpErr[secId][secData.SeqNo].titlename = { status: true, message: "Title is required." }
                }
                if (!secData.applicableforprint) {
                    isError = true;
                    tmpErr[secId][secData.SeqNo].applicableforprint = { status: true, message: "Applicable for Print / Reference Type is required." }
                }
                if (!secData.filename) {
                    isError = true;
                    tmpErr[secId][secData.SeqNo].filename = { status: true, message: "File is required." }
                }
                // if (secData.titlename) {
                //     let chk = false;
                //     chk = tabDataObj[secId].filter((ele) => ele.titlename === secData.titlename && ele.SeqNo !== secData.SeqNo).length > 0;
                //     if (chk) {
                //         isError = true;
                //         tmpErr[secId][secData.SeqNo].titlename = { status: true, message: "Title already exists." }
                //     }
                // }
            }
        }
        setErrorObj(tmpErr);
        return isError;
    };

    const uploadFiles = async (signedURL, file) => {
        try {
            if (signedURL) {
                await axios.put(signedURL, file);
            }
            return "";
        } catch (error) {
            return " Error in file upload.";
        }
    }

    const handleSubmit = async () => {
        setLoading(true);
        let validationResult = await ValidateDocuments();

        if (!validationResult) {

            let counter = 0;
            let array = [];
            let outputArray = [];
            let successCount = 0;
            let failedSections = [];

            for (let section of PowerdocsTitles) {
                let tmpOutputArray = [];
                let moveFiles = [];
                let srNo = 1
                let currSecData = await getIntegratedTabsData({ "ProjectCode": selectedProject.ProjectCode, "flag": section.sectionKey })
                for (let tabDt of tabDataObj[section.id]) {
                    let obj = Object.assign({}, tabDt);

                    obj.PK = "IntegratedSystemTesting#ProjectCode#" + selectedProject?.ProjectCode;
                    obj.SK = section.sectionKey + "#SeqNo#" + srNo;
                    obj.SeqNo = srNo;
                    obj.ProjectCode = selectedProject?.ProjectCode;
                    obj.ProjectName = selectedProject?.ProjectName;
                    obj.sectionKey = section.sectionKey;
                    obj.ModifiedBy = userDetails.userId;
                    obj.ModifiedByName = userDetails.userName;
                    obj.ModifiedOn = getCurrentDateTime("-");

                    if (!obj.hasOwnProperty("CreatedBy")) {
                        obj.CreatedBy = userDetails.userId;
                        obj.CreatedByName = userDetails.userName;
                        obj.CreatedOn = getCurrentDateTime("-");
                    }

                    if (srNo !== tabDt.SeqNo) {
                        moveFiles.push({ oldSeqNo: tabDt.SeqNo, newSeqNo: srNo });
                    }
                    let tmpRes = updatePowerAnalyzerDocuments(obj)
                    array.push({ SeqNo: tabDt.SeqNo, result: tmpRes });
                    counter++;
                    srNo++;

                    if (counter === 5) {
                        for (let dt of array) {
                            let tmpResult = await Promise.resolve(dt.result);
                            tmpOutputArray.push({ SeqNo: dt.SeqNo, output: tmpResult });
                        }
                        counter = 0;
                        array = [];
                    }
                }
                for (let dt of array) {
                    let tmpResult = await Promise.resolve(dt.result);
                    console.log(tmpResult)
                    tmpOutputArray.push({ SeqNo: dt.SeqNo, output: tmpResult });
                }

                counter = 0;
                array = [];
                //move files whose SeqNo has changed
                for (let mf of moveFiles) {
                    let fileDetails = uploadDocObj[section.id].find(x => x.SeqNo === mf.oldSeqNo);
                    if (fileDetails && !fileDetails.NewFile && fileDetails.OldFile) {
                        let obj = {
                            oldPath: "IntegratedSystemTesting/" + selectedProject.ProjectCode + "/" + section.sectionKey + "/" + mf.oldSeqNo + "/" + fileDetails.OldFile,
                            newPath: "IntegratedSystemTesting/" + selectedProject.ProjectCode + "/" + section.sectionKey + "/" + mf.newSeqNo + "/" + fileDetails.OldFile
                        }
                        let tmpRes = moveS3File(obj)
                        array.push(tmpRes);
                        counter++;

                        if (counter >= 5) {
                            await Promise.all(array);
                            counter = 0;
                            array = [];
                        }

                    }
                }
                if (array.length > 0) {
                    await Promise.all(array);
                }

                //delete additional records
                counter = 0;
                array = [];
                if (srNo <= currSecData.length) {
                    for (let del of currSecData) {
                        if (del.SeqNo >= srNo) {
                            let tmpRes = deleteISTTabData(del);
                            array.push(tmpRes);
                            counter++;

                            let fileObj = { fileName: "IntegratedSystemTesting/" + selectedProject.ProjectCode + "/" + section.sectionKey + "/" + del.SeqNo + "/" + del.filename };
                            let tmpDelete = deletS3Attachment(fileObj);
                            array.push(tmpDelete);
                            counter++;

                            if (counter >= 5) {
                                await Promise.all(array);
                                counter = 0;
                                array = [];
                            }
                        }
                    }
                }
                if (array.length > 0) {
                    await Promise.all(array);
                }

                counter = 0;
                array = [];
                let signedUrlObj = {};
                for (let dt of tmpOutputArray) {
                    console.log("dt tmpOutputArray", dt)
                    if (dt.output.status) {
                        successCount++;
                        signedUrlObj[dt.SeqNo] = dt.output.signedURL;
                    }
                    else {
                        if (failedSections.indexOf(section.name) === -1) {
                            failedSections.push(section.name);
                        }
                    }
                }
                outputArray.push({ sectionId: section.id, allsignedURL: signedUrlObj });
            }

            counter = 0;
            array = [];
            for (let dt of outputArray) {
                console.log("dt outputArray", dt)
                console.log("uploadDocObj[dt.sectionId]", uploadDocObj[dt.sectionId])
                for (let item of uploadDocObj[dt.sectionId]) {
                    console.log(item);
                    if (item.NewFile) {
                        let tmpUpload = uploadFiles(dt.allsignedURL[item.SeqNo], item.NewFile);
                        array.push(tmpUpload);
                        counter++;
                    }
                    if (item.DelFlag && item.OldFile !== item.NewFileName) {
                        let secName = PowerdocsTitles.filter(x => x.id === dt.sectionId);
                        let fileObj = { fileName: "IntegratedSystemTesting/" + selectedProject.ProjectCode + "/" + secName[0]?.sectionKey + "/" + item.SeqNo + "/" + item.OldFile };
                        let tmpDelete = deletS3Attachment(fileObj);
                        array.push(tmpDelete);
                        counter++;
                    }
                    if (counter >= 5) {
                        await Promise.all(array);
                        counter = 0;
                        array = [];
                    }
                }
                if (array.length > 0) {
                    await Promise.all(array);
                    counter = 0;
                    array = [];
                }
            }
            let msg = "";
            if (successCount > 0) {
                msg = "Power Analyzer & IST Screenshot details added successfully."
            }

            if (failedSections.length > 0) {
                msg = msg + " Data update failed for " + failedSections.toString() + "."
            }
            setLoading(false);
            MySwal.fire({
                text: msg,
                imageUrl: successImage,
                customClass: "swal-success",
                didClose: () => {
                    fetchPowerDocData();
                }
            });
        }
        else {
            setLoading(false);
        }
    }

    useImperativeHandle(ref, () => ({
        funcOnNext: async () => {
            await handleSubmit();
        }
    }));

    const handleDownloadDocument = async (fileName, folderName, itemSeqNo) => {

        let downloadURL = "IntegratedSystemTesting/" + selectedProject.ProjectCode + "/" + folderName + "/" + itemSeqNo + "/" + fileName;

        downloadAttachment(
            downloadURL,
            fileName
        ).catch((err) => {
            console.log("File download error", err)
            showAlert("Error in Fetching file.")
        });
    }

    const handleChangeTitles = (name, value) => {
        for (let section of PowerdocsTitles) {
            if (name.startsWith("title" + section.id)) {
                let tmpSeq = Number(name.replace("title" + section.id, ""));
                for (let tmpData of tabDataObj[section.id]) {
                    if (tmpData.SeqNo === tmpSeq) {
                        tmpData.titlename = value;
                        break;
                    }
                }
                setTabDataObj((prev) => ({ ...prev, [section.id]: tabDataObj[section.id] }));
                break;
            }
        }
    }
    const handleTitles = (name, value) => {
        console.log("handleTitles", name, value)
        for (let section of PowerdocsTitles) {
            if (name.startsWith("title" + section.id)) {
                let tmpTitles = [...titles[section.id]];
                tmpTitles.push(value);
                console.log("tmpTitles", tmpTitles);
                setTitles((prev) => ({ ...prev, [section.id]: [...new Set(tmpTitles)] }));
                break;
            }
        }
    }

    const handleChangeRadio = (name, value) => {
        for (let section of PowerdocsTitles) {
            if (name.startsWith("radio" + section.id)) {
                let tmpSeq = Number(name.replace("radio" + section.id, ""));
                for (let tmpData of tabDataObj[section.id]) {
                    if (tmpData.SeqNo === tmpSeq) {
                        tmpData.applicableforprint = value;
                        break;
                    }
                }
                setTabDataObj((prev) => ({ ...prev, [section.id]: tabDataObj[section.id] }));
                break;
            }
        }
    }

    const handleChangeUpload = (files, section, itemSeqNo) => {
        console.log("handleChangeUpload", files, section, itemSeqNo);
        const newFile = files[0];
        const fileName = newFile.name;
        let fileExt =
            fileName.indexOf(".") > 0
                ? fileName.split(".").pop().toLowerCase()
                : "undefined";
        let fileError = "";
        let photoExtensions = process.env.REACT_APP_FILE_PHOTO_EXTENSIONS;
        let docExtensions = process.env.REACT_APP_FILE_DOCS_EXTENSIONS;
        if (photoExtensions.includes(fileExt)) {
            fileError = Validate(newFile, "imgFormats");
        }
        else if (docExtensions.includes(fileExt)) {
            fileError = Validate(newFile, "docsFormats", 20971520);
        }
        else {
            showAlert("Unsupported file format.");
            return;
        }
        if (fileError === "") {
            if (checkFileExtension(fileName)) {
                for (let itemDoc of tabDataObj[section]) {
                    if (checkFileExtension(itemDoc.filename)) {
                        showAlert("You can add only one single document file and multiple image files.");
                        return;
                    }
                }
            }

            for (let tmpData of tabDataObj[section]) {
                if (tmpData.SeqNo === itemSeqNo) {
                    tmpData.filename = fileName;
                    break;
                }
            }
            setTabDataObj((prev) => ({ ...prev, [section]: tabDataObj[section] }));

            for (let tmpData of uploadDocObj[section]) {
                if (tmpData.SeqNo === itemSeqNo) {
                    tmpData.NewFile = newFile;
                    tmpData.NewFileName = fileName;
                    break;
                }
            }
            setUploadDoc((prev) => ({ ...prev, [section]: uploadDocObj[section] }));
        } else {
            showAlert(fileError);
        }
    }

    const handleDeleteFile = (fileName, section, itemSeqNo) => {
        if (selectedProject.DeleteIndication === "Active") {
            console.log("handleDeleteFile", fileName, section, itemSeqNo)
            for (let tmpData of tabDataObj[section]) {
                if (tmpData.SeqNo === itemSeqNo) {
                    tmpData.filename = "";
                    break;
                }
            }
            setTabDataObj((prev) => ({ ...prev, [section]: tabDataObj[section] }));

            for (let tmpData of uploadDocObj[section]) {
                if (tmpData.SeqNo === itemSeqNo) {
                    tmpData.DelFlag = true;
                    break;
                }
            }
            setUploadDoc((prev) => ({ ...prev, [section]: uploadDocObj[section] }));
        }
    }

    return (
        <>
            {loading && <div className="spinner-box"><Spinner animation="border" className="spinner" /></div>}
            <Container fluid className="grid-comp-containerCss">
                <Form
                    id="istPower"
                    name="istPower"
                    autoComplete="off"
                >
                    {PowerdocsTitles.map((item, index) => {
                        return (
                            <>
                                {index !== 0 &&
                                    <hr className="istseparatorLine d-none d-md-block d-lg-block" />
                                }
                                <Row>
                                    <Col xs={7}>
                                        <span className="projDocRightPanelEquipName">{item.name}</span>
                                    </Col>
                                    {selectedProject.DeleteIndication === "Active" &&
                                        <Col xs={5}>
                                            <div className="addNewRow">
                                                <BsPlusLg size={20} className="plusIcon" />
                                                &nbsp;
                                                <Link onClick={() => addNewRow(item.id, item.dataLimit)} className="blueColor">Add more</Link>
                                            </div>
                                        </Col>
                                    }
                                </Row>
                                {
                                    tabDataObj[item.id]?.map((element, index) => {
                                        return (
                                            <>
                                                <Row key={element}>
                                                    <Col md={4} xs={12}>
                                                        <Form.Group className="g-input">
                                                            <Autocomplete
                                                                id={element.SeqNo}
                                                                type="text"
                                                                name={"title" + item.id + element.SeqNo}
                                                                maxLength="50"
                                                                placeholder=" "
                                                                value={element.titlename}
                                                                onChangeEv={handleChangeTitles}
                                                                options={titles[item.id]} fieldName="Title"
                                                                optionalField={false}
                                                                onBlurFunc={handleTitles}
                                                                readonly={selectedProject.DeleteIndication !== "Active"} />
                                                            {errorObj[item.id][element.SeqNo].titlename?.status && (
                                                                <Form.Text className="text-danger">
                                                                    {errorObj[item.id][element.SeqNo].titlename?.message}
                                                                </Form.Text>
                                                            )}
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={3} xs={12} className="IstPowerRadio">
                                                        <span className="fieldLabel">Applicable for Print / Reference Type</span>
                                                        <Row key={index}>
                                                            <Col xs={6}>
                                                                <Form.Check className="fieldLabel locationType"
                                                                    type="radio"
                                                                    name={"radio" + item.id + "" + element.SeqNo}
                                                                    checked={element.applicableforprint === "Print in Report"}
                                                                    disabled={selectedProject.DeleteIndication !== "Active"}
                                                                    onClick={(event) => handleChangeRadio(event.target.name, event.target.value)}
                                                                    label="Print in Report"
                                                                    value="Print in Report"
                                                                ></Form.Check>
                                                            </Col>
                                                            <Col xs={6}>
                                                                <Form.Check className="fieldLabel locationType"
                                                                    type="radio"
                                                                    name={"radio" + item.id + "" + element.SeqNo}
                                                                    checked={element.applicableforprint === "For Reference"}
                                                                    disabled={selectedProject.DeleteIndication !== "Active"}
                                                                    onClick={(event) => handleChangeRadio(event.target.name, event.target.value)}
                                                                    label="For Reference"
                                                                    value="For Reference"
                                                                ></Form.Check>
                                                            </Col>
                                                            {errorObj[item.id][element.SeqNo].applicableforprint?.status && (
                                                                <Form.Text className="text-danger">
                                                                    {errorObj[item.id][element.SeqNo].applicableforprint?.message}
                                                                </Form.Text>
                                                            )}
                                                        </Row>
                                                    </Col >
                                                    <Col md={4} xs={12} className="pb-3 istdropZoneMainDiv istdropTextContainer">
                                                        <DragDropRender
                                                            key={element}
                                                            val={element.filename !== "" ? element.filename : ""}
                                                            handleChangeEvent={(params) => handleChangeUpload(params, item.id, element.SeqNo)}
                                                            handleDeleteDocEvent={(params) => handleDeleteFile(params, item.id, element.SeqNo)}
                                                            handleDownloadDocEvent={(params) => handleDownloadDocument(params, item.sectionKey, element.SeqNo)}
                                                            supportedFilesLabel={supportedFormats}
                                                            disabled={selectedProject.DeleteIndication !== "Active"}
                                                        />
                                                        {errorObj[item.id][element.SeqNo].filename?.status && (
                                                            <Form.Text className="text-danger">
                                                                {errorObj[item.id][element.SeqNo].filename?.message}
                                                            </Form.Text>
                                                        )}
                                                    </Col>
                                                    <Col md={1} xs={12} className="pe-0 pt-2">
                                                        <div>
                                                            {/* <div className="addNewRow">
                                                        <BsPlusLg size={20} className="plusIcon" />
                                                        &nbsp; */}
                                                            <Link onClick={() => deleteRow(item.id, element.SeqNo)} className="blueColor">Delete Row</Link>
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <hr className="istseparatorLine d-md-none d-lg-none" />
                                            </>
                                        )
                                    })
                                }

                            </>
                        )
                    })
                    }
                </Form>
            </Container >
        </>
    )
});

export default IntegratedPowerAnalyzer;