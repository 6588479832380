import React, { useState, useContext, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import "./App.css";
import Home from "./components/layouts/Home";
import LoginPage from "./components/login/LoginPage";
import { AuthenticatedTemplate, UnauthenticatedTemplate, useIsAuthenticated, useMsal } from "@azure/msal-react";
import { userLogin } from "./services/user-service";
import "./interceptor";
import { loginRequest } from "./components/login/authConfig";
import { extLoginRequest } from './components/login/authConfigExternal';
import { LOGIN_GODREJ_EMPLOYEE, LOGIN_DEALER } from "./utils/constant";
import AutoLogOut from "./utils/AutoLogOut";
import withClearCache from "./ClearCache";
const ClearCacheComponent = withClearCache(MainApp);

function App() {
  return <ClearCacheComponent />;
}

function MainApp() {
  const isAuthenticated = useIsAuthenticated();
  const { instance, accounts } = useMsal();
  const [ssoAuthorized, setSSOAuthorized] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [idToken, setIdToken] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    console.log("isAuthenticated in useeffects", isAuthenticated, idToken);
    if (isAuthenticated) {
      RequestProfileData();
    }
    if (idToken && idToken !== "") {
      sessionStorage.setItem("Token", idToken);
      getUserData();
    }
  }, [isAuthenticated, idToken]); // eslint-disable-line react-hooks/exhaustive-deps
  function RequestProfileData() {
    let request = {}
    const tmpLoginType = sessionStorage.getItem("LoginType");
    if (tmpLoginType === LOGIN_GODREJ_EMPLOYEE) {
      request = {
        ...loginRequest,
        account: accounts[0],
      };
    }
    else if (tmpLoginType === LOGIN_DEALER) {
      request = {
        ...extLoginRequest,
        account: accounts[0],
      };
    }
    // Silently acquires an access token which is then attached to a request for Microsoft Graph data
    instance
      .acquireTokenSilent(request)
      .then((response) => {
        setIdToken(response.idToken);
      })
      .catch((e) => {
        instance.acquireTokenPopup(request).then((response) => {
          setIdToken(response.idToken);
        });
      });
  }

  const getUserData = async () => {
    setLoading(true);
    let email = accounts[0].username;
    const tmpLoginType = sessionStorage.getItem("LoginType");
    let value = {
      User: email,
      UserType: tmpLoginType
    };
    try {
      let response = await userLogin(value, idToken);
      console.log("response", response);
      console.log("response status", response?.status);
      if (response?.status === false) {
        console.log("reponse as false", response.message);
        setSSOAuthorized(false);
        setErrorMessage(response.message);
      }
      else {
        setSSOAuthorized(true);
      }
      setLoading(false);
    }
    catch (e) {
      console.log(e?.response?.message);
      setLoading(false);
    }
  }


  return (
    <>
      {loading && <div className="spinner-box"> <Spinner animation="border" className="spinner" /></div>}
      <AuthenticatedTemplate>
        {ssoAuthorized ? <><AutoLogOut /><Home /></> : <LoginPage loginErrorMessage={errorMessage} />}
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <LoginPage loginErrorMessage={errorMessage} />
      </UnauthenticatedTemplate>
    </>
  );
}

export default App;
