import React from "react";
import "./ProjectChecklist.css";
import { Col, Form, Row } from "react-bootstrap";

function FireIntegrationStatusRenderer(props) {
    let firedata = props.selectedData.data.FireIntegrationFlag;
    // console.log("-----------------", props, firedata);
    let chkflg = props.selectedData.data.ChkFlag;
    let chkflgNA = props.selectedData.data.ChkFlagNA;

    let winflag = Math.floor(Math.random() * 10000);
    // console.log(Math.floor(Math.random() * 10000))

    // if (window.innerWidth > 768) {
    //     winflag = "win";
    // } else {
    //     winflag = "mob";
    // }

    return (
        <Row>
            <Col md={4} xs={12}>
                <Form.Check className="fireIntRadio"
                    type="radio"
                    name={winflag + "rad" + props.selectedData.data.SK}
                    value={"Yes"}
                    label={"Yes"}
                    checked={firedata === "Yes"}
                    disabled={chkflg}
                    onChange={e => props.handleradiochange(props.selectedData, e)}
                ></Form.Check>
            </Col>
            <Col md={4} xs={12}>
                <Form.Check className="fireIntRadio"
                    type="radio"
                    name={winflag + "rad" + props.selectedData.data.SK}
                    value={"No"}
                    label={"No"}
                    checked={firedata === "No"}
                    disabled={chkflg}
                    onChange={e => props.handleradiochange(props.selectedData, e)}
                ></Form.Check>
            </Col>
            <Col md={4} xs={12}>
                <Form.Check className="fireIntRadio"
                    type="radio"
                    name={winflag + "rad" + props.selectedData.data.SK}
                    value={"NA"}
                    label={"NA"}
                    checked={firedata === "NA"}
                    disabled={chkflgNA}
                    onChange={e => props.handleradiochange(props.selectedData, e)}
                ></Form.Check>
            </Col>
        </Row>
    );
}

export { FireIntegrationStatusRenderer };