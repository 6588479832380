import React from "react";
import { usePagination } from "../../hooks/usePagination";
import { ChevronBarLeft, ChevronBarRight, ChevronLeft, ChevronRight } from "react-bootstrap-icons";
import "../../components/masters/pagination.css"
import { Pagination as PaginationReact} from "react-bootstrap"; 

export default function Pagination(props) {
  const { onPageChange, totalCount, currentPage, pageSize } = props;
  const paginationRange = usePagination({
    currentPage,
    totalCount,
    pageSize,
  });

  let lastPage = paginationRange?.totalPageCount;
  let pageText = `${paginationRange?.startInd} to ${paginationRange?.endInd} of ${totalCount}`;
  let pageText2 = ` Page ${currentPage} of ${paginationRange?.totalPageCount}`;

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    if (currentPage > 1) {
      onPageChange(currentPage - 1);
    } else {
      onPageChange(1);
    }
  };

  return (
    <div className="pagintion-bar">
      <PaginationReact>
      <span>{pageText}</span>
      <ChevronBarLeft size={14} onClick={() => onPageChange(1)} 
      className={currentPage === 1 ? "disabled" : ""}
      ></ChevronBarLeft>
      <ChevronLeft
        size={14}
        onClick={onPrevious}
        className={currentPage === 1 ? "disabled" : ""}
      />
      <span>{pageText2}</span>
      <ChevronRight
        size={14}
        onClick={onNext}
        className={currentPage === lastPage ? "disabled" : ""}
      />
      <ChevronBarRight size={14} onClick={() => onPageChange(paginationRange?.totalPageCount)}
       className={currentPage === lastPage ? "disabled" : ""}
      ></ChevronBarRight>
      </PaginationReact>
    </div>
  );
};


