import * as XLSX from "xlsx";
import * as XlsxPopulate from "xlsx-populate/browser/xlsx-populate";

export function exportToExcel(fileName, sheetName, excelData, headerRange, colName, fieldName) {
  let wb = XLSX.utils.book_new();
  let header = colName;
  const sheetData = getSheetData(excelData, header);
  let ws = XLSX.utils.json_to_sheet(sheetData);
  XLSX.utils.book_append_sheet(wb, ws, sheetName);
  const wopts = {
    bookType: "xlsx",
    bookSST: false,
    type: "binary",
  };

  const wbout = XLSX.write(wb, wopts);
  const blob = new Blob([s2ab(wbout)], {
    type: "application/octet-stream",
  });

  function getSheetData(excelData, header) {
    let sheetData = [];
    for (let n = 0; n < excelData.length; n++) {
      let tmpdata = []
      for (let i = 0; i < header.length; i++) {
        let tmpcol = header[i];
        let tmpfield = fieldName[i];
        if (i == 0) {
          tmpdata.push({
            [tmpcol]: excelData[n][tmpfield]
          })
        }
        else {
          tmpdata = tmpdata.map(v => ({ ...v, [tmpcol]: excelData[n][tmpfield] }));
        }
      }
      sheetData = [...sheetData, ...tmpdata]
    }
    return sheetData;
  }

  XlsxPopulate.fromDataAsync(blob).then((workbook) => {

    workbook.sheets().forEach((sheet) => {
      sheet.usedRange().style({
        fontFamily: "Calibri",
        verticalAlignment: "center",
        border: "thin",
        horizontalAlignment: "left"
      });
      sheet.range(headerRange).style({
        fill: "0577b",
        border: "thin",
        bold: true,
        fontColor: "FFFFFF",
        horizontalAlignment: "left",
      });
    });
    return workbook.outputAsync().then((workbookBlob) => URL.createObjectURL(workbookBlob)).then((url) => {
      const downloadAnchorNode = document.createElement("a");
      downloadAnchorNode.setAttribute("href", url);
      downloadAnchorNode.setAttribute(
        "download",
        fileName
      );
      downloadAnchorNode.click();
      downloadAnchorNode.remove();
    });
  });
}

const s2ab = (s) => {
  // The ArrayBuffer() constructor is used to create ArrayBuffer objects.
  // create an ArrayBuffer with a size in bytes
  const buf = new ArrayBuffer(s.length);

  //create a 8 bit integer array
  const view = new Uint8Array(buf);
  //charCodeAt The charCodeAt() method returns an integer between 0 and 65535 representing the UTF-16 code
  for (let i = 0; i < s.length; ++i) {
    view[i] = s.charCodeAt(i);
  }

  return buf;
};
