import { Button, Container, Col, Form, Row } from "react-bootstrap";
import React, { useState, useContext, useEffect } from "react";
import "./CSS/ActionComp.css";
import { BsPlusLg, BsSearch } from "react-icons/bs";
import { useDispatch } from "react-redux";
import { searchActions } from "../../store/grid-slice";
import { BiSelectMultiple } from "react-icons/bi";
import SingleValuePopUp from "../utils/SingleValuePopUp";
import { RenderIf } from "../../functions/gbcs-functions";
import { useSelector } from "react-redux";
import { AuthContext } from "../../contexts/AuthContext";
import downloadicon from "../../icons/downloadIcon.svg";

const ActionComp = (props) => {
  const isProjectTrue = props.isProjectTrue ? props.isProjectTrue : false
  const dispatch = useDispatch();
  const searchReText = useSelector((state) => state.grid.searchQuery);
  const [showForm, setShowForm] = useState(false);
  const [ProjSelectionObj, setProjSelectionObj] = useState(
    {
      ProjectCode: "",
      ProjectName: "",
      DeleteIndication: ""
    });

  const { accessibleMenus } = useContext(AuthContext);
  const menuName = useSelector((state) => state.menu.menuName);
  const menuAccess = accessibleMenus.filter(item => item.SessionName === menuName)

  let enableSearch = true;
  if (props.hasOwnProperty("enablesearch")) {
    enableSearch = props.enablesearch;
  }

  useEffect(() => {
    if (props.hasOwnProperty("setparams") && Object.keys(props.setparams).length > 0) {
      setProjSelectionObj({
        ProjectCode: props.setparams.ProjectCode,
        ProjectName: props.setparams.ProjectName,
        DeleteIndication: props.setparams.DeleteIndication
      });
    } else {
      setProjSelectionObj({
        ProjectCode: "",
        ProjectName: "",
        DeleteIndication: ""
      });
    }
  }, [props.setparams])

  let addShow = true;
  let showData = false;
  if (props.hasOwnProperty("addshowflag")) {
    addShow = props.addshowflag;
  }
  if (props.hasOwnProperty("isDataShowOnButtonClick")) {
    showData = props.isDataShowOnButtonClick;
  }

  const onFilterTextChange = (e) => {
    dispatch(searchActions.setSearch(e.target.value));
  };

  const [popUp, setPopUp] = useState({
    name: "",
    type: "",
    key: "",
    value: "",
  });

  const showPopUp = (name, type, key, value) => {
    setPopUp({ name, type, key, value });
    setShowForm(true);
  };

  const setPopUpData = async (data) => {
    if (data.name === "Project Master") {
      setProjSelectionObj((prevState) => ({
        ...prevState,
        ISTApplicable: data.result.ISTApplicable ? data.result.ISTApplicable : "No",
        ProjectCode: data.result.ProjectCode,
        ProjectName: data.result.ProjectName,
        DeleteIndication: data.result.DeleteIndication
      }));
      if (!showData) {
        props.setSelectedProject(data.result);
      }
      if (props.hasOwnProperty("resetData")) {
        props.resetData();
      }

    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProjSelectionObj((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <Container fluid className="action-comp-container">
      <div className="action-main">
        {
          isProjectTrue &&
          <Col lg={3} md={3} xs={12}>
            <Form.Group className="g-input">
              <Form.Control className="inputHeight"
                id="ProjectName"
                type="text"
                name="ProjectName"
                placeholder=" "
                required
                value={ProjSelectionObj.ProjectName ? ProjSelectionObj.ProjectName : ""}
                onChange={handleChange}
                readOnly
              />
              <Form.Label className="frm-label input-label" for="ProjectName">Project</Form.Label>
              <BiSelectMultiple className="modalPopUpIconActionComp"
                size={18}
                onClick={() => showPopUp("Project Master",
                  "Single",
                  "ProjectName",
                  ProjSelectionObj.ProjectName ? ProjSelectionObj.ProjectName : "")}
                backdrop="static"
              />
            </Form.Group>
          </Col>
        }
        <Col lg={3} md={3} xs={12}>
          <div className="parent-add-btn">
            {addShow &&
              menuAccess[0]?.Add &&
              <Button className="add-btn" onClick={() => props.handleAdd(ProjSelectionObj)} disabled={(isProjectTrue && (!ProjSelectionObj.ProjectCode || ProjSelectionObj.DeleteIndication !== "Active")) ? true : false}>
                <BsPlusLg color={(isProjectTrue && (!ProjSelectionObj.ProjectCode || ProjSelectionObj.DeleteIndication !== "Active")) ? "#707070" : "#FFFFFF"} size={20} className="plus-icon" />
                <span className="add-btn-label">{props.label}</span>
              </Button>
            }
          </div>
          {
            showData &&
            <Button variant="success" className="btn-add-color semiBoldToggleButton filterSubmit" onClick={() => props.handleButtonClick(ProjSelectionObj)} disabled={(isProjectTrue && !ProjSelectionObj.ProjectCode) ? true : false}>
              {props.label}
            </Button>
          }
        </Col>
        <Col lg={2} md={2} xs={12}>
          &nbsp;
        </Col>
        <Col lg={3} md={4} xs={12}>
          <div className="parent-search-download">
            <div className="records-search">
              <BsSearch size={16} color="#707070" />
              <input
                type="text"
                placeholder="Search here..."
                className="records-search-input"
                onChange={onFilterTextChange}
                value={searchReText}
                readOnly={!enableSearch}
              ></input>
            </div>
            {menuAccess[0]?.Export ?
              <img src={downloadicon}
                alt="downloadimg"
                className="icon-border"
                onClick={props.exportEvent}
              /> : <></>
            }
          </div>
        </Col>
      </div>
      <RenderIf isTrue={showForm}>
        <SingleValuePopUp
          show={showForm}
          onHide={() => setShowForm(false)}
          setSelected={setPopUpData}
          mastername={popUp.name}
          paramkey={popUp.key}
          paramvalue={popUp.value}
          backdrop="static"
        />
      </RenderIf>
    </Container>
  );
};

export default ActionComp;
