import { useDispatch } from "react-redux";
import { menuActions } from "../../store/menu-slice";

export default function UnauthorizedAccess(props) {
  
  const dispatch = useDispatch();
  dispatch(menuActions.setMenu(props.sessionName));

    return (
      <>
        <div id="errorPage">
            <h1>{"Access Denied"}</h1>
        </div>
      </>
    );
  }
  