import React, { useState } from "react";
import { Form, Row, Col } from "react-bootstrap";
import LockIcon from "../../../icons/locksign.svg";

const ParameterCheckboxComponent = (props) => {
  const [toggleClass, setToggleClass] = useState("mandatoryToggleLabel");
  let ParamData = props.paramdata;
  let action = props.action;
  let IsSelected = false;
  let IsMandatory = false;
  if (props.selectedParameters.indexOf(ParamData.FieldName) !== -1) {
    IsSelected = true;
  }
  if (ParamData.FieldType !== "Default") {
    if (props.selectedParametersMandatory.indexOf(ParamData.FieldName) !== -1) {
      IsMandatory = true;
    }
  }

  const handleChangeCheckbox = (e) => {
    if (ParamData.FieldType !== "Default") {
      props.setCheckBoxValue({
        FieldName: ParamData.FieldName,
        FieldType: ParamData.FieldType,
        IsSelected: e.target.checked,
        IsMandatory: e.target.checked
      });
      if (!e.target.checked) {
        setToggleClass("nonMandatoryToggleLabel");
      }
    }
  };

  const handleChangeSwitchbox = (e) => {
    if (e.target.checked) {
      setToggleClass("mandatoryToggleLabel");
    } else {
      setToggleClass("nonMandatoryToggleLabel");
    }
    props.setCheckBoxValue({
      FieldName: ParamData.FieldName,
      FieldType: ParamData.FieldType,
      IsSelected: true,
      IsMandatory: e.target.checked,
    });
  };

  return (
    <Col lg={3} xs={6}>
      <Row className="pb-0">
        <Col>
          <Form.Check className="mb-0"
            type="checkbox"
            id={ParamData.FieldName}
            label={ParamData.FieldName}
            disabled={action === "View" ? true : false}
            checked={
              ParamData.FieldType === "Default" ? true : IsSelected
            }
            onChange={(e) => {
              handleChangeCheckbox(e);
            }}
          />
        </Col>
      </Row>
      {(ParamData.FieldType === "Default" || (!ParamData.transEntryInd && ParamData.FieldType === "Optional")) &&
        (<Row>
          <Col>
            <Form.Group>
              <img
                src={LockIcon}
                width={24}
                height={24}
                id="lockimg"
                name="lockimg"
                className="lockSignCss"
              ></img>
              <Form.Label className="lblmandatory" htmlFor="lockimg">
                Mandatory
              </Form.Label>
            </Form.Group>
          </Col>
        </Row>
        )}
      {
        ParamData.transEntryInd && ParamData.FieldType === "Optional" &&
        (

          <Row>
            <Col>
              <Form.Switch
                id={ParamData.FieldName}
                className={"lblmandatory" + toggleClass}
                name={ParamData.FieldName}
                checked={IsMandatory}
                disabled={!IsSelected || action === "View" ? true : false}
                label="Mandatory"
                onChange={(e) => {
                  handleChangeSwitchbox(e);
                }}
              />
            </Col>
          </Row>
        )
      }
    </Col>
  );
};

export default ParameterCheckboxComponent;
