import axios from "axios";
const TRANS_URL = process.env.REACT_APP_TRANSACTION_API_URL;

export async function getDocumentReviewTracker(docObj) {
  const response = await axios
    .post(TRANS_URL + "/documentreviewtracker/getdocumentreviewtracker", docObj)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      console.log("error => ", error);
      return { data: [] };
    });
  return response.data;
}

//======================Add new Document Review Tracker record ==========================
export const addDocumentReviewTracker = async (docObject) => {
  let url = `${TRANS_URL}/documentreviewtracker/adddocumentreviewtracker`;
  const response = await axios
    .post(url, docObject)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      console.log("error => ", error);
      return { data: { message: "Unable to find request", status: false } };
    });
  return response.data;
};

//======================Upadte new Document Review Tracker record ==========================
export const updateDocumentReviewTracker = async (documentObj) => {
  let url = `${TRANS_URL}/documentreviewtracker/updatedocumentreviewtracker`;
  const response = await axios
    .post(url, documentObj)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      console.log("error => ", error);
      return { data: { message: "Unable to find request", status: false } };
    });
  return response.data;
};

//======================get getDocReviewEquipmentTagData ==========================
export async function getDocReviewEquipmentTagData(projequipObj) {
  const response = await axios
    .post(TRANS_URL + "/documentreviewtracker/getdocreviewequipmenttag", projequipObj)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      console.log("error => ", error);
      return { data: [] };
    });
  return response.data;
}

//======================Add new Document equipment tag  record ==========================
export const addDocumentEquipmentTag = async (docObject) => {
  let url = `${TRANS_URL}/documentreviewtracker/adddocumentqquipmenttag`;
  const response = await axios
    .post(url, docObject)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      console.log("error => ", error);
      return { data: { message: "Unable to find request", status: false } };
    });
  return response.data;
};

//======================get existing snapshot data ==========================
export async function getExistingSnapShotData(params) {
  const response = await axios
    .post(TRANS_URL + "/documentreviewtracker/getexistingsnapshotdata", params)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      console.log("error => ", error);
      return { data: [] };
    });
  return response.data;
}

//======================Add new snapshot ==========================
export const addSnapShot = async (snapshot) => {
  let url = `${TRANS_URL}/documentreviewtracker/addsnapshot`;
  const response = await axios
    .post(url, snapshot)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      console.log("error => ", error);
      return { data: { message: "Unable to find request", status: false } };
    });
  return response.data;
};

//----------------Delete Document review tracker ----------------------------
export const deleteDocumentReviewTracker = async (delBody) => {
  let url = `${TRANS_URL}/documentreviewtracker/deletedocumentdeviewtracker`;
  //console.log(url)
  const response = await axios
    .post(url, delBody)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      console.log("error => ", error);
      return { data: { message: "Unable to find request", status: false } };
    });
  return response.data;
};

export async function getDocumentReviewTrackerProjectWise(docObj) {
  const response = await axios
    .post(TRANS_URL + "/documentreviewtracker/getdocreviewtrackerprojectwise", docObj)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      console.log("error => ", error);
      return { data: [] };
    });
  return response.data;
}
