import * as CryptoJS from "crypto-js";

// function to encrypt and decrypt a string using crypto
const encryptData = (value, type) => {
    let Key = process.env.REACT_APP_ENC_KEY;
    let data
    if (type === "enc") {
        data = value
        let cryptedData = CryptoJS.AES.encrypt(data, Key).toString()
        return cryptedData;
    }
    if (type === "dec") {
        data = value
        let bytes = CryptoJS.AES.decrypt(data, Key);
        return bytes.toString(CryptoJS.enc.Utf8);
    }
}
export default encryptData;