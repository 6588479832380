const getMaskedNumber = (number) => {
    const endDigits = number.slice(-4);
    return endDigits.padStart(number.length, '*');
}

export default function escapeExcelData(data, maskData) {
  if (data == null || data == "") return data;
  if (maskData === true) {
    data = getMaskedNumber(data.toString());
  }
    return data;
}