import React, { useContext } from "react";
import { Route, Routes } from "react-router-dom";
import RoleMaster from "../masters/RoleMaster";
import UserMaster from "../masters/UserMaster";
import CurrencyMaster from "../masters/CurrencyMaster";
import LocationMaster from "../masters/LocationMaster";
import EquipmentActivityMaster from "../masters/EquipmentActivityMaster";
import CompanyDivisionMaster from "../masters/CompanyDivisionMaster"
import DesignReviewTracker from "../transaction/DesignReviewTracker"
import ProjectMaster from "../masters/ProjectMaster/ProjectMaster";
//import Dashboard from "../masters/Dashboard";
import { AuthContext } from "../../contexts/AuthContext";
import UnauthorizedAccess from "./UnauthorizedAccess";
import ProjectChecklist from "../transaction/ProjectChecklist";
import ChecklistMaster from "../masters/ChecklistMaster/ChecklistMaster";
import SiteObservationReport from "../transaction/SiteObservationReport";
import FireIntegrationVerification from "../transaction/FireIntegrationVerification";
import DocumentReviewTracker from "../transaction/DocumentReviewTracker";
import ProjectDocuments from "../transaction/ProjectDocuments";
import GDPR from "../gdpr/GDPR";
import IntegratedSystemTesting from "../transaction/IntegratedSystemTesting";
import Dashboard from "../dashboard/Dashboard";

const MainComp = () => {
  const context = useContext(AuthContext);
  const menuList = context?.menuName;

  return (
    <Routes>
      <Route exact path="/" element={menuList?.includes("Dashboard (Godrej)") ? <Dashboard sessionName="Dashboard (Godrej)" /> : <UnauthorizedAccess sessionName="Dashboard (Godrej)" />} />
      <Route exact path="/customerdashboard" element={menuList?.includes("Dashboard (Customer)") ? <Dashboard sessionName="Dashboard (Customer)" /> : <UnauthorizedAccess sessionName="Dashboard (Customer)" />} />
      <Route exact path="/rolemaster" element={menuList?.includes("Role & Authorization Master") ? <RoleMaster /> : <UnauthorizedAccess sessionName="Role & Authorization Master" />} />
      <Route exact path="/usermaster" element={menuList?.includes("User Master") ? <UserMaster /> : <UnauthorizedAccess sessionName="User Master" />} />
      <Route exact path="/currencymaster" element={menuList?.includes("Currency Master") ? <CurrencyMaster /> : <UnauthorizedAccess sessionName="Currency Master" />} />
      <Route exact path="/locationmaster" element={menuList?.includes("Location Master") ? <LocationMaster /> : <UnauthorizedAccess sessionName="Location Master" />} />
      <Route exact path="/equipmentactivitymaster" element={menuList?.includes("Equipment & Activity Master") ? <EquipmentActivityMaster /> : <UnauthorizedAccess sessionName="Equipment & Activity Master" />} />
      <Route exact path="/companydivisionmaster" element={menuList?.includes("Company / Division Master") ? <CompanyDivisionMaster /> : <UnauthorizedAccess sessionName="Company / Division Master" />} />
      <Route exact path="/designreviewtracker" element={menuList?.includes("Design Review Tracker") ? <DesignReviewTracker /> : <UnauthorizedAccess sessionName="Design Review Tracker" />} />
      <Route exact path="/siteobservationreport" element={menuList?.includes("Site Observation Report") ? <SiteObservationReport /> : <UnauthorizedAccess sessionName="Site Observation Report" />} />
      <Route exact path="/projectmaster" element={menuList?.includes("Project Master") ? <ProjectMaster /> : <UnauthorizedAccess sessionName="Project Master" />} />
      <Route exact path="/fatchecklist" element={menuList?.includes("Factory Acceptance Test") ? <ProjectChecklist checklisttype="FAT" /> : <UnauthorizedAccess sessionName="Factory Acceptance Test" />} />
      <Route exact path="/satchecklist" element={menuList?.includes("Site Acceptance Test") ? <ProjectChecklist checklisttype="SAT" /> : <UnauthorizedAccess sessionName="Site Acceptance Test" />} />
      <Route exact path="/checklistmaster" element={menuList?.includes("Checklist Master") ? <ChecklistMaster /> : <UnauthorizedAccess sessionName="Checklist Master" />} />
      <Route exact path="/fireintegration" element={menuList?.includes("Fire Integration Verification") ? <FireIntegrationVerification /> : <UnauthorizedAccess sessionName="Fire Integration Verification" />} />
      <Route exact path="/documentreviewtracker" element={menuList?.includes("Document Review Tracker") ? <DocumentReviewTracker /> : <UnauthorizedAccess sessionName="Document Review Tracker" />} />
      <Route exact path="/projectdocuments" element={menuList?.includes("Project Documents") ? <ProjectDocuments /> : <UnauthorizedAccess sessionName="Project Documents" />} />
      <Route exact path="/gdpr" element={menuList?.includes("GDPR") ? <GDPR /> : <UnauthorizedAccess sessionName="GDPR" />} />
      <Route exact path="/integratedsystesting" element={menuList?.includes("Integrated System Testing") ? <IntegratedSystemTesting /> : <UnauthorizedAccess sessionName="Integration System Testing" />} />
    </Routes>
  );
};

export default MainComp;
