import { createSlice } from "@reduxjs/toolkit";

const menuSlice = createSlice({
  name: "menu",
  initialState: {
    menuName: "Role & Authorization Master",
  },
  reducers: {
    setMenu(state, action) {
      state.menuName = action.payload;
    },
  },
});

export const menuActions = menuSlice.actions;

export default menuSlice;
