import axios from "axios";

const TRANS_URL = process.env.REACT_APP_TRANSACTION_API_URL;

export const AddProjTransChklstParameter = async (ProjTrans) => {
  let url = `${TRANS_URL}/projecttransactionchecklist/addProjTransChklstParameter`;
  const response = await axios
    .post(url, ProjTrans)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      console.log("error => ",error );
      return {data: { message: "Unable to find request", status: false }};
    });
  return response.data;
};

export const AddProjTransChklst = async (ProjTrans) => {
  let url = `${TRANS_URL}/projecttransactionchecklist/addProjTransChklst`;
  const response = await axios
    .post(url, ProjTrans)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      console.log("error => ",error );
      return {data: { message: "Unable to find request", status: false }};
    });
  return response.data;
};

//=============== get temp proj transaction checklist equipments ============================
export async function getProjTranChklst(checklistType, projectCode) {
  let reqBody = { checklistType, projectCode }
  const response = await axios
    .post(TRANS_URL + "/projecttransactionchecklist/getProjTranChklst", reqBody)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      console.log("error => ",error );
      return {data: []};
    });
  return response.data;
}

//=============== get proj transaction checklist parameters ============================
export async function getProjTranChklstParameterData(checklistType, projCode, chklstCat, equipTag) {
  let reqBody = { "checklistType": checklistType, "projCode": projCode, "equipTag": equipTag, "chklstCat": chklstCat };
  const response = await axios
    .post(TRANS_URL + "/projecttransactionchecklist/getProjTranChklstParameterData", reqBody)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      console.log("error => ",error );
      return {data: []};
    });
  return response.data;
}

//=============== delete proj transaction checklist parameter ============================
export const deleteProjTranChklstParameter = async (obj) => {
  let url = `${TRANS_URL}/projecttransactionchecklist/deleteprojtranchklstparameter`;
  const response = await axios
    .post(url, obj)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      console.log("error => ",error );
      return {data: { message: "Unable to find request", status: false }};
    });
  return response.data;
};

//=============== get proj transaction checklist parameters ============================
export async function getProjTranChklstofEquip(checklistType, projCode, chklstCat, equipTag) {
  let reqBody = { "checklistType": checklistType, "projCode": projCode, "chklstCat": chklstCat, "equipTag": equipTag };
  const response = await axios
    .post(TRANS_URL + "/projecttransactionchecklist/getProjTranChklstofEquip", reqBody)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      console.log("error => ",error );
      return {data: []};
    });
  return response.data;
}

//================ Get Proj Transaction checklist Report data by Project Code ==============
export async function getProjTransChklstReportData(ProjectCode) {
  let url = `${TRANS_URL}/projecttransactionchecklist/getProjTransChklstReportData`;
  let reqBody = { ProjectCode }
  const response = await axios
    .post(url, reqBody)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      console.log("error => ",error );
      return {data: []};
    });
  return response.data;
}

export async function getTransactionForChecklistVersion(paramObj) {
  let url = `${TRANS_URL}/projecttransactionchecklist/getTransactionForChecklistVersion`;
  const response = await axios
    .post(url, paramObj)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      console.log("error => ",error );
      return {data: []};
    });
  return response.data;
}
