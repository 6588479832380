import { Row, Col, Container, Button } from "react-bootstrap";
import { formatDateInDDMMYYYY, getTimeAMPMFormat, UserNameInitials } from "../../functions/gbcs-functions";
import Delete_green from "../../icons/Delete_green.svg";
import Delete from "../../icons/Delete.svg";
import { getUserInitials } from "../../utils/Utility";


const ProjectDocumentFileDetails = (props) => {

    let formattedDate = formatDateInDDMMYYYY(props.documentDate)
    formattedDate = formattedDate.split(" ")[0];
    let am_pm = getTimeAMPMFormat(props.documentDate);
    const initials = getUserInitials(props.documentCreatedBy);



    return (
        <>
            <Col>
                <div className="document-file-name">
                    <span className={!props.isDocUploadFlg ? "document-label ellipsised-label" : "document-label-uploaded ellipsised-label"} title={props.fileName}
                        onClick={() => props.handleDownloadDocEvent(props.fileName)}
                    >
                        {props.fileName}
                    </span>
                    {
                        props.showDelete &&
                        <img
                            className="action-icon"
                            src={Delete}
                            alt="delete"
                            onClick={() => props.handleDeleteDocEvent(props.fileName)}
                        />
                    }
                </div>
            </Col>
            <div className="document-details">
                <Col md={3} xs={2} className="initials-auther-inline">
                    <UserNameInitials value={props.documentCreatedBy} documentRowIndex={props.documentRowIndex} />
                    &nbsp;
                </Col>
                <Col md={9} xs={11} >
                    <div className="ellipsised-label-auther" title={props.documentCreatedBy}>{props.documentCreatedBy}</div>
                    <div className="document-date-time">{formattedDate} | {am_pm}</div>
                </Col>
            </div>
        </>
    );

}

export default ProjectDocumentFileDetails;