import React, { useEffect, useState, useContext } from "react";
import { Button, Form, Modal, Row, Col, Spinner } from "react-bootstrap";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { AuthContext } from "../../contexts/AuthContext";
import { getTodaysDate, getCurrentDateTime } from "../../functions/gbcs-functions";
import { isFutureDate } from "../../functions/validations";
import {
    addDocumentEquipmentTag,
    getDocReviewEquipmentTagData,
    updateDocumentReviewTracker
} from "../../services/documentreviewtracker-service";
import successImage from "../../icons/success-tick-logo.svg";
import errorImage from "../../icons/error-tick-logo.svg";
import { getEquipmentTagDataForEquipment } from "../../services/project-service"
import { ALERT_PARAMS } from "../../utils/constant";

const AddEditDocumentEquipmentTag = (props) => {
    const MySwal = withReactContent(Swal);
    const context = useContext(AuthContext);
    const userDetails = { userName: context.userName, userId: context.userId }
    let action = props.action;
    const [loading, setLoading] = useState(false);
    let currentDate = getTodaysDate();
    let todayDate = getCurrentDateTime("-");

    const [DocumentEquipmentTagObj, setDocumentEquipmentTagObj] = useState(props.rowdata);
    const [HeaderRecord, setHeaderRecord] = useState(props.headerRecord)
    let StatusList = [
        { Status: "Pending" },
        { Status: "Completed" }
    ]

    const [error, setError] = useState({
        DocumentSubmissionStatus: false,
        DocumentSubmissionReviewedDate: false,
        ReSubmissionStatus: false,
        ReSubmissionReviewedDate: false,
        ApprovalStatus: false,
        ApprovalReviewedDate: false,
        Remarks: false,
    });

    const handleChange = (e) => {
        let { name, value } = e.target;
        setDocumentEquipmentTagObj((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    }


    const fieldTemp = {
        "DocumentSubmissionStatus": [
            {
                "fieldName": "DocumentSubmissionReviewedById"
            },
            {
                "fieldName": "DocumentSubmissionReviewedBy"
            },
            {
                "fieldName": "DocumentSubmissionReviewedDate"
            }
        ],
        "ReSubmissionStatus": [
            {
                "fieldName": "ReSubmissionReviewedById"
            },
            {
                "fieldName": "ReSubmissionReviewedBy"
            },
            {
                "fieldName": "ReSubmissionReviewedDate"
            }
        ],
        "ApprovalStatus": [
            {
                "fieldName": "ApprovalReviewedById"
            },
            {
                "fieldName": "ApprovalReviewedBy"
            },
            {
                "fieldName": "ApprovalReviewedDate"
            }
        ]
    }
    const handleChangeSubStatus = (e) => {
        let { name, value } = e.target;
        // setDocumentEquipmentTagObj((prevState) => ({
        //     ...prevState,
        //     [name]: value,
        // }));
        let fieldArr = fieldTemp[name];
        let fieldsObj = {};
        fieldsObj[name] = value;
        if (value !== "Completed") {
            for (let element of fieldArr) {
                fieldsObj[element.fieldName] = ""
            };
        }
        else {
            fieldsObj[fieldArr[0].fieldName] = userDetails.userId;
            fieldsObj[fieldArr[1].fieldName] = userDetails.userName;
            fieldsObj[fieldArr[2].fieldName] = currentDate;
            let errObj = { status: false, message: "" }
            setError((prevState) => ({
                ...prevState,
                DocumentSubmissionStatus: errObj,
                ReSubmissionStatus: errObj,
                ApprovalStatus: errObj
            }))
            if (name === "ReSubmissionStatus") {
                setError((prevState) => ({
                    ...prevState,
                    ApprovalStatus: errObj
                }))
            }
        }
        setDocumentEquipmentTagObj((prevState) => ({ ...prevState, ...fieldsObj }));
    }

    const handleReset = () => {
        setDocumentEquipmentTagObj(props.rowdata);
        setError({
            DocumentSubmissionStatus: false,
            DocumentSubmissionReviewedDate: false,
            ReSubmissionStatus: false,
            ReSubmissionReviewedDate: false,
            ApprovalStatus: false,
            ApprovalReviewedDate: false,
            Remarks: false,
        });
    }

    const handleChangeDate = (e) => {
        let { name, value } = e.target;
        let error1 = validateDate(name, value);
        // if (!error1.status) {
        setDocumentEquipmentTagObj((prevState) => ({
            ...prevState,
            [name]: value,
        }));
        // }
        setError((prevState) => ({
            ...prevState,
            [name]: error1
        }));
    }


    const validateDate = (name, dt) => {
        let error = { status: false, message: "" }
        if (!dt) {
            error = { status: true, message: "Please select the Date." };
            return error;
        }
        error = isFutureDate(dt);
        if (error.status) {
            return error;
        }
        else if (props.SelectedProjEquipObj.ProjStartDate !== "" && new Date(dt) < new Date(props.SelectedProjEquipObj.ProjStartDate)) {
            error = { status: true, message: "Date should be greater than or equal to Project Start Date." }
            return error;
        }
        else if ((props.SelectedProjEquipObj.ProjClosureDate !== undefined || props.SelectedProjEquipObj.ProjClosureDate !== "") && new Date(dt) > new Date(props.SelectedProjEquipObj.ProjClosureDate)) {
            error = { status: true, message: "Date should be less than or equal to Project Closure Date." }
            return error;
        }
        else {
            if (name === "DocumentSubmissionReviewedDate") {
                if (!DocumentEquipmentTagObj.DocumentSubmissionStatus) {
                    error = { status: true, message: "Select Document Submission Status first." }
                    return error;
                }
                else if (new Date(dt) > new Date(DocumentEquipmentTagObj.ReSubmissionReviewedDate)) {
                    error = { status: true, message: "Date should be less than or equal to Re Submission Reviewed Date." }
                    return error;
                }
                else if (new Date(dt) > new Date(DocumentEquipmentTagObj.ApprovalReviewedDate)) {
                    error = { status: true, message: "Date should be less than or equal to Approval Reviewed Date." }
                    return error;
                }
            }
            if (name === "ReSubmissionReviewedDate") {
                if (!DocumentEquipmentTagObj.ReSubmissionStatus) {
                    error = { status: true, message: "Select Re Submission Status first." }
                    return error;
                }
                else if (new Date(dt) < new Date(DocumentEquipmentTagObj.DocumentSubmissionReviewedDate)) {
                    error = { status: true, message: "Date should be greater than or equal to Document Submission Reviewed Date." }
                    return error;
                }
                else if (new Date(dt) > new Date(DocumentEquipmentTagObj.ApprovalReviewedDate)) {
                    error = { status: true, message: "Date should be less than or equal to Approval Reviewed Date." }
                    return error;
                }
            }
            if (name === "ApprovalReviewedDate") {
                if (!DocumentEquipmentTagObj.ApprovalStatus) {
                    error = { status: true, message: "Select Approval Status first." }
                    return error;
                }
                else if (new Date(dt) < new Date(DocumentEquipmentTagObj.DocumentSubmissionReviewedDate)) {
                    error = { status: true, message: "Date should be greater than or equal to Submission Reviewed Date." }
                    return error;
                }
                else if (new Date(dt) < new Date(DocumentEquipmentTagObj.ReSubmissionReviewedDate)) {
                    error = { status: true, message: "Date should be greater than or equal to Re Submission Reviewed Date." }
                    return error;
                }
            }
        }
        return error;
    }

    const validateDetails = () => {
        let isError = false;
        let errorMsg = {
            DocumentSubmissionStatus: false,
            DocumentSubmissionReviewedDate: false,
            ReSubmissionStatus: false,
            ReSubmissionReviewedDate: false,
            ApprovalStatus: false,
            ApprovalReviewedDate: false,
            Remarks: false
        };
        if (!DocumentEquipmentTagObj.DocumentSubmissionStatus) {
            isError = true;
            errorMsg.DocumentSubmissionStatus = { status: true, message: "Document Submission Status is required." }
        }
        if (DocumentEquipmentTagObj.DocumentSubmissionStatus === "Completed") {
            let dtErr = validateDate("DocumentSubmissionReviewedDate", DocumentEquipmentTagObj.DocumentSubmissionReviewedDate);
            if (dtErr.status) {
                isError = true;
                errorMsg.DocumentSubmissionReviewedDate = dtErr;
            }
        }
        console.log(!DocumentEquipmentTagObj.DocumentSubmissionReviewedDate)
        if (DocumentEquipmentTagObj.DocumentSubmissionStatus !== "Completed" && DocumentEquipmentTagObj.DocumentSubmissionReviewedDate) {
            isError = true;
            errorMsg.DocumentSubmissionReviewedDate = { status: true, message: "Document Submission Status is not Completed." }
        }
        if (DocumentEquipmentTagObj.ReSubmissionStatus === "Completed") {
            let err1 = validateDate("ReSubmissionReviewedDate", DocumentEquipmentTagObj.ReSubmissionReviewedDate);
            if (err1.status) {
                isError = true;
                errorMsg.ReSubmissionReviewedDate = err1;
            }
            if (DocumentEquipmentTagObj.DocumentSubmissionStatus === "Pending") {
                isError = true;
                errorMsg.ReSubmissionStatus = { status: true, message: "Document Submission status is Pending" };
            }
        }
        if (DocumentEquipmentTagObj.ReSubmissionStatus !== "Completed" && DocumentEquipmentTagObj.ReSubmissionReviewedDate) {
            isError = true;
            errorMsg.ReSubmissionReviewedDate = { status: true, message: "Re Submission Status is not Completed." }
        }
        if (DocumentEquipmentTagObj.ApprovalStatus === "Completed") {
            let err1 = validateDate("ApprovalStatus", DocumentEquipmentTagObj.ApprovalReviewedDate);
            if (err1.status) {
                isError = true;
                errorMsg.ApprovalReviewedDate = err1;
            }
            if (DocumentEquipmentTagObj.DocumentSubmissionStatus !== "Completed" || DocumentEquipmentTagObj.ReSubmissionStatus !== "Completed") {
                isError = true;
                errorMsg.ApprovalStatus = { status: true, message: "Document Submission or Re submission status is Pending" };
            }
        }
        if (DocumentEquipmentTagObj.ApprovalStatus !== "Completed" && DocumentEquipmentTagObj.ApprovalReviewedDate) {
            isError = true;
            errorMsg.ApprovalReviewedDate = { status: true, message: "Approval Status is not Completed." }
        }
        if (!DocumentEquipmentTagObj.Remarks) {
            isError = true;
            errorMsg.Remarks = { status: isError, message: "Remark is required" };
        }
        setError(errorMsg);
        return isError;
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        let result;
        if (!validateDetails()) {
            let confirmBoxText = "";
            confirmBoxText = "Are you sure, so you want to save the record?";
            MySwal.fire({
                text: confirmBoxText,
                confirmButtonText: "Yes",
                title: action,
                ...ALERT_PARAMS
            }).then(async (result2) => {
                if (result2.isConfirmed) {
                    setLoading(true);
                    if (DocumentEquipmentTagObj.RecordType === "New") {
                        DocumentEquipmentTagObj.CreatedOn = todayDate;
                        DocumentEquipmentTagObj.CreatedBy = userDetails.userId;
                        DocumentEquipmentTagObj.CreatedByName = userDetails.userName;
                        DocumentEquipmentTagObj.ModifiedBy = userDetails.userId;
                        DocumentEquipmentTagObj.ModifiedByName = userDetails.userName;
                        DocumentEquipmentTagObj.ModifiedOn = todayDate;
                        DocumentEquipmentTagObj.DocumentName = HeaderRecord.DocumentName;
                        DocumentEquipmentTagObj.DocumentCategory = HeaderRecord.DocumentCategory;
                        DocumentEquipmentTagObj.PK = "DocumentReviewTracker#ProjectCode#" + DocumentEquipmentTagObj.ProjectCode + "#EquipmentCode#" + DocumentEquipmentTagObj.EquipmentCode;
                        DocumentEquipmentTagObj.SK = "DocumentName#" + HeaderRecord.DocumentName + "#EquipmentTag#" + DocumentEquipmentTagObj.EquipmentTag;
                    }
                    else {
                        DocumentEquipmentTagObj.ModifiedBy = userDetails.userId;
                        DocumentEquipmentTagObj.ModifiedByName = userDetails.userName;
                        DocumentEquipmentTagObj.ModifiedOn = todayDate;
                    }
                    delete DocumentEquipmentTagObj.RecordType;
                    result = await addDocumentEquipmentTag(DocumentEquipmentTagObj);
                    if (result?.status) {

                        let docReviewTgData = [];
                        let projEqipTgData = [];
                        let paramObj = {
                            primekey: "DocumentReviewTracker#ProjectCode#" + DocumentEquipmentTagObj.ProjectCode + "#EquipmentCode#" + DocumentEquipmentTagObj.EquipmentCode,
                            sortkey: "DocumentName#" + HeaderRecord.DocumentName + "#EquipmentTag#",
                            // EquipmentCode: DocumentEquipmentTagObj.EquipmentCode,
                            // ServiceCode: DocumentEquipmentTagObj.ServiceCode
                        }
                        docReviewTgData = await getDocReviewEquipmentTagData(paramObj);

                        let obj = {
                            primekey: "ProjectCode#" + props.SelectedProjEquipObj.ProjectCode,
                            sortkey: "ProjectService#SeqNo#",
                            EquipmentCode: DocumentEquipmentTagObj.EquipmentCode,
                        }
                        projEqipTgData = await getEquipmentTagDataForEquipment(obj);

                        let headerStatus = {
                            DocumentSubmissionStatus: "Completed",
                            ReSubmissionStatus: "Completed",
                            ApprovalStatus: "Completed"
                        }
                        for (let element of projEqipTgData) {
                            let existRecord = docReviewTgData.filter((item) => item.EquipmentTag === element.EquipmentTag);

                            if (existRecord.length === 0) {
                                headerStatus = {
                                    DocumentSubmissionStatus: "Pending",
                                    ReSubmissionStatus: "Pending",
                                    ApprovalStatus: "Pending"
                                }
                                break;
                            }
                            else {
                                if (existRecord[0].DocumentSubmissionStatus === "Pending") {
                                    headerStatus.DocumentSubmissionStatus = "Pending"
                                }
                                if (existRecord[0].ReSubmissionStatus === "" || existRecord[0].ReSubmissionStatus === "Pending") {
                                    headerStatus.ReSubmissionStatus = "Pending"
                                }
                                if (existRecord[0].ApprovalStatus === "" || existRecord[0].ApprovalStatus === "Pending") {
                                    headerStatus.ApprovalStatus = "Pending"
                                }
                            }
                        }

                        HeaderRecord.DocumentSubmissionStatus = headerStatus.DocumentSubmissionStatus;
                        HeaderRecord.ReSubmissionStatus = headerStatus.ReSubmissionStatus;
                        HeaderRecord.ApprovalStatus = headerStatus.ApprovalStatus;

                        await updateDocumentReviewTracker(HeaderRecord);
                        setLoading(false);
                        MySwal.fire({
                            text: result.message,
                            imageUrl: successImage,
                            customClass: "swal-success",
                            didClose: () => {
                                props.fetchEquipmentTagsData();
                                props.onHide();
                            },
                        });
                    }
                    else {
                        setLoading(false);
                        MySwal.fire({
                            text: "Unable to Process Request",
                            imageUrl: errorImage,
                            customClass: "swal-success",
                        });
                    }
                }
            });
        }
    }

    return (
        <>
            {loading && <div className="spinner-box"> <Spinner animation="border" className="spinner" /></div>}
            <Modal
                {...props}
                size="xl"
                backdrop="static" centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {action}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form
                        id="documentequipmenttag"
                        name="documentequipmenttag"
                        autoComplete="off"
                    >
                        <Row >
                            <Col md={4} xs={12}>
                                <Form.Group className="g-input">
                                    <Form.Control
                                        id="EquipmentTag"
                                        type="text"
                                        name="EquipmentTag"
                                        placeholder=" "
                                        value={DocumentEquipmentTagObj.EquipmentTag}
                                        readOnly
                                    />
                                    <Form.Label className="frm-label" htmlFor="EquipmentTag">Equipment Tag</Form.Label>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={4} xs={12}>
                                <Form.Group className="g-select">
                                    <Form.Select
                                        id="DocumentSubmissionStatus"
                                        name="DocumentSubmissionStatus"
                                        type="text"
                                        placeholder=" "
                                        value={DocumentEquipmentTagObj.DocumentSubmissionStatus ? DocumentEquipmentTagObj.DocumentSubmissionStatus : ""}
                                        onChange={handleChangeSubStatus}
                                    >
                                        {StatusList?.map((item) => {
                                            return (<option key={item.Status} value={item.Status}>{item.Status}</option>)
                                        })
                                        }
                                    </Form.Select>
                                    <Form.Label className="frm-label" htmlFor="DocumentSubmissionStatus">Document Submission Status</Form.Label>
                                    {error.DocumentSubmissionStatus.status && (
                                        <Form.Text className="text-danger">
                                            {error.DocumentSubmissionStatus.message}
                                        </Form.Text>
                                    )}
                                </Form.Group>
                            </Col>
                            <Col md={4} xs={12}>
                                <Form.Group className="g-input">
                                    <Form.Control
                                        id="DocumentSubmissionReviewedBy"
                                        type="text"
                                        name="DocumentSubmissionReviewedBy"
                                        placeholder=" "
                                        value={DocumentEquipmentTagObj.DocumentSubmissionReviewedBy ? DocumentEquipmentTagObj.DocumentSubmissionReviewedBy : ""}
                                        readOnly
                                    />
                                    <Form.Label className="frm-label" htmlFor="DocumentSubmissionReviewedBy">Document Submission Reviewed By<span className="italicFont"> (optional)</span></Form.Label>
                                </Form.Group>
                            </Col>
                            <Col md={4} xs={12}>
                                <Form.Group className="g-input docReview">
                                    <Form.Control
                                        id="DocumentSubmissionReviewedDate"
                                        type="date"
                                        name="DocumentSubmissionReviewedDate"
                                        placeholder=" "
                                        value={DocumentEquipmentTagObj.DocumentSubmissionReviewedDate ? DocumentEquipmentTagObj.DocumentSubmissionReviewedDate : ""}
                                        onChange={handleChangeDate}
                                        disabled={DocumentEquipmentTagObj.DocumentSubmissionStatus !== "Completed"}
                                    />
                                    <Form.Label className="frm-label" htmlFor="DocumentSubmissionReviewedDate">Document Submission Reviewed Date<span className="italicFont"> (optional)</span></Form.Label>
                                    {error.DocumentSubmissionReviewedDate.status && (
                                        <Form.Text className="text-danger">
                                            {error.DocumentSubmissionReviewedDate.message}
                                        </Form.Text>
                                    )}
                                </Form.Group>
                            </Col>
                            <Col md={4} xs={12}>
                                <Form.Group className="g-select">
                                    <Form.Select
                                        id="ReSubmissionStatus"
                                        name="ReSubmissionStatus"
                                        type="text"
                                        placeholder=" "
                                        value={DocumentEquipmentTagObj.ReSubmissionStatus ? DocumentEquipmentTagObj.ReSubmissionStatus : ""}
                                        onChange={handleChangeSubStatus}
                                    >
                                        <option value="">Select</option>
                                        {StatusList?.map((item) => {
                                            return (<option key={item.Status} value={item.Status}>{item.Status}</option>)
                                        })
                                        }
                                    </Form.Select>
                                    <Form.Label className="frm-label" htmlFor="ReSubmissionStatus">Re Submission Status<span className="italicFont"> (optional)</span></Form.Label>
                                    {error.ReSubmissionStatus.status && (
                                        <Form.Text className="text-danger">
                                            {error.ReSubmissionStatus.message}
                                        </Form.Text>
                                    )}
                                </Form.Group>
                            </Col>
                            <Col md={4} xs={12}>
                                <Form.Group className="g-input">
                                    <Form.Control
                                        id="ReSubmissionReviewedBy"
                                        type="text"
                                        name="ReSubmissionReviewedBy"
                                        placeholder=" "
                                        value={DocumentEquipmentTagObj.ReSubmissionReviewedBy ? DocumentEquipmentTagObj.ReSubmissionReviewedBy : ""}
                                        readOnly
                                    />
                                    <Form.Label className="frm-label" htmlFor="ReSubmissionReviewedBy">Re Submission Reviewed By<span className="italicFont"> (optional)</span></Form.Label>
                                </Form.Group>
                            </Col>
                            <Col md={4} xs={12}>
                                <Form.Group className="g-input docReview">
                                    <Form.Control
                                        id="ReSubmissionReviewedDate"
                                        type="date"
                                        name="ReSubmissionReviewedDate"
                                        placeholder=" "
                                        value={DocumentEquipmentTagObj.ReSubmissionReviewedDate ? DocumentEquipmentTagObj.ReSubmissionReviewedDate : ""}
                                        onChange={handleChangeDate}
                                        disabled={DocumentEquipmentTagObj.ReSubmissionStatus !== "Completed"}
                                    />
                                    <Form.Label className="frm-label" htmlFor="ReSubmissionReviewedDate">Re Submission Reviewed Date<span className="italicFont"> (optional)</span></Form.Label>
                                    {error.ReSubmissionReviewedDate.status && (
                                        <Form.Text className="text-danger">
                                            {error.ReSubmissionReviewedDate.message}
                                        </Form.Text>
                                    )}
                                </Form.Group>
                            </Col>
                            <Col md={4} xs={12}>
                                <Form.Group className="g-select">
                                    <Form.Select
                                        id="ApprovalStatus"
                                        name="ApprovalStatus"
                                        type="text"
                                        placeholder=" "
                                        value={DocumentEquipmentTagObj.ApprovalStatus ? DocumentEquipmentTagObj.ApprovalStatus : ""}
                                        onChange={handleChangeSubStatus}
                                    >
                                        <option value="">Select</option>
                                        {StatusList?.map((item) => {
                                            return (<option key={item.Status} value={item.Status}>{item.Status}</option>)
                                        })
                                        }
                                    </Form.Select>
                                    <Form.Label className="frm-label" htmlFor="ApprovalStatus">Approval Status<span className="italicFont"> (optional)</span></Form.Label>
                                    {error.ApprovalStatus.status && (
                                        <Form.Text className="text-danger">
                                            {error.ApprovalStatus.message}
                                        </Form.Text>
                                    )}
                                </Form.Group>
                            </Col>
                            <Col md={4} xs={12}>
                                <Form.Group className="g-input">
                                    <Form.Control
                                        id="ApprovalReviewedBy"
                                        type="text"
                                        name="ApprovalReviewedBy"
                                        placeholder=" "
                                        value={DocumentEquipmentTagObj.ApprovalReviewedBy ? DocumentEquipmentTagObj.ApprovalReviewedBy : ""}
                                        readOnly
                                    />
                                    <Form.Label className="frm-label" htmlFor="ApprovalReviewedBy">Approval Reviewed By<span className="italicFont"> (optional)</span></Form.Label>
                                </Form.Group>
                            </Col>
                            <Col md={4} xs={12}>
                                <Form.Group className="g-input docReview">
                                    <Form.Control
                                        id="ApprovalReviewedDate"
                                        type="date"
                                        name="ApprovalReviewedDate"
                                        placeholder=" "
                                        value={DocumentEquipmentTagObj.ApprovalReviewedDate ? DocumentEquipmentTagObj.ApprovalReviewedDate : ""}
                                        onChange={handleChangeDate}
                                        readOnly={action === "View"}
                                        disabled={DocumentEquipmentTagObj.ApprovalStatus !== "Completed"}
                                    />
                                    <Form.Label className="frm-label" htmlFor="ApprovalReviewedDate">Approval Reviewed Date<span className="italicFont"> (optional)</span></Form.Label>
                                    {error.ApprovalReviewedDate.status && (
                                        <Form.Text className="text-danger">
                                            {error.ApprovalReviewedDate.message}
                                        </Form.Text>
                                    )}
                                </Form.Group>
                            </Col>
                            <Col xs={12}>
                                <Form.Group className="g-input">
                                    <Form.Control
                                        id="Remarks"
                                        as="textarea"
                                        rows={2}
                                        name="Remarks"
                                        maxLength="200"
                                        placeholder=" "
                                        value={DocumentEquipmentTagObj.Remarks ? DocumentEquipmentTagObj.Remarks : ""}
                                        onChange={handleChange}
                                    />
                                    <Form.Label className="frm-label" htmlFor="Remarks">Remarks</Form.Label>
                                    {error.Remarks.status && (
                                        <Form.Text className="text-danger">
                                            {error.Remarks.message}
                                        </Form.Text>
                                    )}
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row >
                            <Col md={9} xs={6}>
                                <Row>
                                    <Col xs={12} md={6} >
                                        <Button className="semiBoldToggleButton cancelBtn"
                                            variant="outline-success"
                                            onClick={handleReset}
                                        >
                                            Reset
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={3} xs={6}>
                                <Button variant="success" className="btn-add-color semiBoldToggleButton rightAlignedButton" onClick={handleSubmit} >
                                    Save
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
            </Modal>

        </>
    );
}

export default AddEditDocumentEquipmentTag;
