import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;


export async function getLocationdata() {
  const response = await axios
    .get(API_URL + "/locationmaster")
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      console.log("error => ",error );
      return {data:[]};
    });
  return response.data;
}

export const AddNewLocation = async (location) => {
  let url = `${API_URL}/locationmaster/addlocation`;
  const response = await axios
    .post(url, location)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      console.log("error => ",error );
      return {data: { message: "Unable to find request", status: false }};
    });
  return response.data;
};

export const UpdateLocation = async (Location) => {
  let url = `${API_URL}/locationmaster/updatelocation`;
  console.log(url)
  const response = await axios
    .post(url, Location)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      console.log("error => ",error );
      return {data: { message: "Unable to find request", status: false }};
    });
  return response.data;
};

export const DeleteLocation = async (loccode) => {
  let url = `${API_URL}/locationmaster/deletelocation/`;
  let reqBody = { loccode }
  const response = await axios
    .post(url, reqBody)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      console.log("error => ",error );
      return {data: { message: "Unable to find request", status: false }};
    });
  return response.data;
};

export async function getActiveLocations(locationType) {
  let reqBody = { locationType }
  let url = `${API_URL}/locationmaster/getactivelocations`;
  const response = await axios
    .post(url, reqBody)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      console.log("error => ",error );
      return {data:[]};
    });
  return response.data;
}