import React, { useState } from "react";
import { Form, Row, Col } from "react-bootstrap";
import LockIcon from "../../../icons/locksign.svg";
import Delete from "../../../icons/Delete.svg";
import Delete_blue from "../../../icons/Delete_blue.svg";

const CheckboxComponent = (props) => {
  const [toggleClass, setToggleClass] = useState("mandatoryToggleLabel");
  let ParamData = props.paramdata;
  let action = props.action;

  const handleChangeCheckbox = (e) => {
    if (ParamData.FieldType !== "Default") {
      props.setCheckBoxValue({
        FieldName: ParamData.FieldName,
        FieldType: ParamData.FieldType,
        IsSelected: e.target.checked,
        IsMandatory: e.target.checked,
      });
      if (!e.target.checked) {
        setToggleClass("nonMandatoryToggleLabel");
      }
    }
  };

  const handleChangeSwitchbox = (e) => {
    if (e.target.checked) {
      setToggleClass("mandatoryToggleLabel");
    } else {
      setToggleClass("nonMandatoryToggleLabel");
    }
    props.setCheckBoxValue({
      FieldName: ParamData.FieldName,
      FieldType: ParamData.FieldType,
      IsSelected: true,
      IsMandatory: e.target.checked,
    });
  };

  return (
    <Col lg={4} xs={12} className="checklist-header-column">
      <Row>
        <Col md={10} xs={10}>
          <Form.Check
            type="checkbox"
            id={ParamData.FieldName}
            label={ParamData.FieldName}
            title={ParamData.FieldName}
            disabled={action === "View"}
            checked={
              ParamData.FieldType === "Default" ? true : ParamData.IsSelected
            }
            onChange={(e) => {
              handleChangeCheckbox(e);
            }}
          />
        </Col>
        {ParamData.FieldType === "UserAdded" && action !== "View" &&
          <Col md={2} xs={2}>
            <img
              src={Delete}
              alt="delete"
              className="rightAlignedButton action-icon"
              onClick={() => props.deleteUserAddedParameter(ParamData)}
            />
          </Col>
        }
      </Row>
      {ParamData.FieldType === "Default" ? (
        <Row>
          <Col>
            <Form.Group>
              <img
                src={LockIcon}
                width={24}
                height={24}
                id="lockimg"
                name="lockimg"
                className="lockSignCss"
              ></img>
              <Form.Label className="lblmandatory" htmlFor="lockimg">
                Mandatory
              </Form.Label>
            </Form.Group>
          </Col>
        </Row>
      ) : (
        <Row>
          <Col>
            <Form.Switch
              id={ParamData.FieldName}
              className={"lblmandatory" + toggleClass}
              name={ParamData.FieldName}
              checked={ParamData.IsMandatory}
              disabled={!ParamData.IsSelected || action === "View" ? true : false}
              label="Mandatory"
              onChange={(e) => {
                handleChangeSwitchbox(e);
              }}
            />
          </Col>
        </Row>
      )}
    </Col>
  );
};

export default CheckboxComponent;
