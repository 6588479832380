let ParameterData = {
    ConstChecklistHeader: [{ FieldName: "Project Name", IsMandatory: true, FieldType: "Default", IsSelected: true, autoPopulate: "ProjectName", entryInd: false },
    { FieldName: "Location", IsMandatory: true, FieldType: "Default", IsSelected: true, entryInd: false },
    { FieldName: "Date", IsMandatory: true, FieldType: "Default", IsSelected: true, entryInd: false },
    { FieldName: "Equipment/Activity tag", IsMandatory: true, FieldType: "Default", IsSelected: true, autoPopulate: "EquipmentTag", entryInd: false },
    { FieldName: "Factory / Site Address", IsMandatory: true, FieldType: "Default", IsSelected: true, maxLength: "500", entryInd: true },
    { FieldName: "Area", IsMandatory: true, FieldType: "Default", IsSelected: true, entryInd: true },
    { FieldName: "Floor", IsMandatory: false, FieldType: "Optional", IsSelected: false, entryInd: true },
    { FieldName: "Capacity", IsMandatory: false, FieldType: "Optional", IsSelected: false, entryInd: true }
    ],

    ConstParameterDetailsHeader: [
        { FieldName: "Parameter", FieldType: "Default", dataType: "text", maxLength: "200", displaySrNo: 2, entryInd: true, transEntryInd: false, displayInd: true },
        { FieldName: "Status", FieldType: "Default", dataType: "dropdown", maxLength: "20", displaySrNo: 4, entryInd: false, transEntryInd: true, displayInd: true },
        {
            FieldName: "Fire Integration Service", FieldType: "Default", dataType: "radio", displaySrNo: 5, entryInd: true, transEntryInd: false, displayInd: false, optionValues: ["Yes", "No"], defaultOptionValue: "No"
        },
        { FieldName: "Remarks", FieldType: "Default", dataType: "text", maxLength: "200", displaySrNo: 7, entryInd: false, transEntryInd: true, displayInd: true },
        { FieldName: "Parameter Category", FieldType: "Optional", dataType: "text", maxLength: "100", displaySrNo: 1, entryInd: true, transEntryInd: false, displayInd: true },
        { FieldName: "UOM", FieldType: "Optional", dataType: "text", maxLength: "10", displaySrNo: 3, entryInd: false, transEntryInd: true, displayInd: true },
        { FieldName: "Result", FieldType: "Optional", dataType: "text", maxLength: "50", displaySrNo: 6, entryInd: false, transEntryInd: false, displayInd: true },
        { FieldName: "Readings", FieldType: "Optional", dataType: "number", maxLength: "13", displaySrNo: 8, entryInd: false, transEntryInd: true, displayInd: true },
        { FieldName: "Threshold Min", FieldType: "Optional", dataType: "number", maxLength: "13", displaySrNo: 9, entryInd: true, transEntryInd: false, displayInd: false },
        { FieldName: "Threshold Max", FieldType: "Optional", dataType: "number", maxLength: "13", displaySrNo: 10, entryInd: true, transEntryInd: false, displayInd: false },
        { FieldName: "Attachment", FieldType: "Optional", dataType: "upload", maxLength: "", displaySrNo: 11, entryInd: true, transEntryInd: true, displayInd: true, optionValues: ["Yes", "No"], defaultOptionValue: "No" }
    ],

    ConstChecklistFooter: [{ FieldName: "Remarks", IsMandatory: true, FieldType: "Default", IsSelected: true },
    { FieldName: "Contractor", IsMandatory: false, FieldType: "Optional", IsSelected: false },
    { FieldName: "PMC", IsMandatory: false, FieldType: "Optional", IsSelected: false },
    { FieldName: "MEP Consulant", IsMandatory: false, FieldType: "Optional", IsSelected: false },
    { FieldName: "T&C", IsMandatory: false, FieldType: "Optional", IsSelected: false }
    ]
}


module.exports = Object.freeze(ParameterData);