import axios from "axios";


console.log("req inside interceptor", axios.interceptors.request);
axios.interceptors.request.use(
    config => {
        const token = sessionStorage.getItem('Token');
        if (token) {
            config.headers['Authorization'] = 'Bearer ' + token;
        }
        // config.headers['Content-Type'] = 'application/json';
        return config;
    },
    error => {
        Promise.reject(error)
    });


// axios.interceptors.response.use((response) => { // block to handle success case
//     return response
// }, function (error) { // block to handle error case
//     // const originalRequest = error.config;
//     if (error.response) {
//         return Promise.reject(error);
//     }

//     return Promise.reject(error);
// });
