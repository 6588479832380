import React from 'react';
import './SegmentedProgressBar.css';
import { Col, Modal, Row } from 'react-bootstrap';

const SegmentedProgressBar = (props) => {
    const r = 20;
    const cx = 40;
    const cy = 50;
    const strokeWidth = 5;
    const circumference = 2 * 3.14 * r;
    const offset = circumference * ((100 - props.progress) / 100);
    console.log("progress", props.progress)

    return (
        <Modal
            {...props}
            size="sm"
            backdrop="static" centered
        >
            <Modal.Body>
                <Row>
                    <Col xs={12} className='textCenterAlign'>
                        <div className='segmented-progresscircle'>
                            <svg>
                                <circle
                                    r={r}
                                    cy={cy}
                                    cx={cx}
                                    strokeWidth={strokeWidth}
                                    className="segmented-progresscircle__circles__background-dashes"
                                ></circle>
                                <circle
                                    r={r}
                                    cy={cy}
                                    cx={cx}
                                    strokeWidth={strokeWidth}
                                    className={`segmented-progresscircle__circles__progress-dashes`}
                                    stroke-dasharray={circumference + 'px'}
                                    stroke-dashoffset={offset + 'px'}
                                ></circle>
                            </svg>
                        </div>
                    </Col>
                    <Col xs={12} className='textCenterAlign semiBoldToggleButton'>
                        {props.progress}%
                    </Col>
                    <Col xs={12} className='textCenterAlign semiBoldToggleButton processing-color'>
                        Processing...
                    </Col>
                    <Col xs={12} className='textCenterAlign msg-color'>
                        <div>Please wait, run is in progress</div>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    );
};

export default SegmentedProgressBar;
