import React from "react";
import "./CSS/BreadcrumbComp.css";
import { Breadcrumb } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import menuList from "../../data/sessionmaster.json";

const BreadcrumbComp = () => {
  const menuName = useSelector((state) => state.menu.menuName);
  let parentMn = menuList.filter((item)=> item.SubMenu === menuName);
  let parentmenu;
  if(parentMn.length >0 ){
     parentmenu= menuList.filter((item)=> item.SubMenu === menuName)[0].ParentMenu;
  }
  const params = useLocation();
  return (
    <div className="bread-crumb">
      <Breadcrumb>
        <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/" }}>
          Home
        </Breadcrumb.Item>
        {
          parentMn.length > 0 ?
          <Breadcrumb.Item>
          {parentmenu}
          </Breadcrumb.Item>
        :null
        }
        <Breadcrumb.Item
          linkAs={Link}
          linkProps={{ to: params.pathname }}
          active
        >
          {menuName}
        </Breadcrumb.Item>
      </Breadcrumb>
    </div>
  );
};

export default BreadcrumbComp;
