import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;

export async function getCurrencydata() {
  const response = await axios
    .get(API_URL + "/currencymaster")
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      console.log("error => ",error );
      return {data:[]};
    });
  return response.data;
}

export async function UpdateCurrencyMaster(CurrencyData) {
  const response = await axios
    .post(API_URL + "/currencymaster/updatecurrencymaster", JSON.stringify(CurrencyData), {
      headers: { "Content-Type": "application/json" },
    })
    .then(function (resp) {
      console.log(resp);
      return resp;
    })
    .catch(function (error) {
      console.log("error => ",error );
      return {data: { message: "Unable to find request", status: false }};
    });
  return response.data;
}