import React, { useEffect, useState } from "react";
import { Container, Row, Spinner } from "react-bootstrap";
import { BsPlusLg } from "react-icons/bs";
import { NavLink } from 'react-router-dom';
import GridMaster from "../masters/GridMaster";
import StatusRenderer from "../masters/StatusRenderer.jsx";
import { getISTObservationsData } from "../../services/siteobservationreport-service.js";
import { sortByPropertyInAscending } from "../../utils/Utility";
import { useDispatch } from "react-redux";
import { searchActions } from "../../store/grid-slice";

const IntegratedISTObservations = (props) => {

    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [filteredResult, setFilteredResult] = useState([]);
    const [filteredResulttemp, setFilteredResulttemp] = useState([]);
    const [filterColLength, setFilterColLength] = useState(0);
    const [excelData, setExcelData] = useState([]);

    let SelectedProject = props.SelectedProject;

    const actionButtons =
    {
        ViewAction: false,
        EditAction: false,
        DownloadAction: false,
        DeleteAction: false
    };

    const columnDefs = [
        {
            headerName: "Sr.No.",
            field: "SrNo",
            valueFormatter: (params) => {
                return params.node.rowIndex + 1;
            },
            minWidth: 100
        },
        {
            headerName: "IST Observation",
            field: "IssueDescription",
            tooltipField: "IssueDescription",
            minWidth: 150,
        },
        {
            headerName: "Responsible Contractor OEM",
            field: "ResponsibleContractorOEM",
            tooltipField: "ResponsibleContractorOEM",
            minWidth: 150,
        },
        {
            headerName: "Remarks",
            field: "Remarks",
            tooltipField: "Remarks",
            minWidth: 150,
        },
        {
            headerName: "Status",
            field: "ISTStatus",
            flex: false,
            width: 200,
            resizable: false,
            tooltipField: "ISTStatus",
            cellRenderer: StatusRenderer,
        }
    ];

    const headerName = (field) => {
        let name;
        switch (field) {
            case "SrNo":
                name = "Sr.No.";
                break;
            case "IssueDescription":
                name = "IST Observation";
                break;
            case "ResponsibleContractorOEM":
                name = "Responsible Contractor OEM";
                break;
            case "Remarks":
                name = "Remarks";
                break;
            case "ISTStatus":
                name = "Status";
                break;
            default:
                name = "No match found";
                break;
        }
        return name;
    };

    const fieldName = (header) => {
        let name;
        switch (header) {
            case "Sr.No.":
                name = "SrNo";
                break;
            case "IST Observations":
                name = "IssueDescription";
                break;
            case "Responsible Contractor OEM":
                name = "ResponsibleContractorOEM";
                break;
            case "Remark":
                name = "Remarks";
                break;
            case "Status":
                name = "ISTStatus";
                break;
            default:
                name = "No match found";
                break;
        }
        return name;
    };

    useEffect(() => {
        dispatch(searchActions.setSearch(""));
        if (props.tabNo === 3) {
            getProjectISTObservations();
        }
    }, [props.tabNo]);

    const getProjectISTObservations = async () => {
        setLoading(true);
        let result = await getISTObservationsData(SelectedProject.ProjectCode);
        result?.sort(sortByPropertyInAscending("SeqNo"));
        if (result?.length > 0) {
            for (let dt of result) {
                if (dt.CorrectedStatus === "Yes") {
                    dt.ISTStatus = "Closed";
                }
                else if (dt.CorrectedStatus === "No") {
                    dt.ISTStatus = "Open";
                }
                else {
                    dt.ISTStatus = dt.CorrectedStatus;
                }
            }
            setFilteredResult(result);
        }
        setLoading(false);
    }

    return (
        <>
            {loading && <div className="spinner-box"> <Spinner animation="border" className="spinner" /></div>}
            <Container fluid className="ist-comp-container">
                <Row>
                    <div className="addNewRow">
                        <BsPlusLg size={20} className="plusIcon" />
                        &nbsp;
                        <NavLink className="blueColor" to={`/siteobservationreport`} state={{ SelectedProject }}>Add more</NavLink>
                    </div>
                </Row>
                <GridMaster
                    rowDetails={filteredResult}
                    colDetails={columnDefs}
                    fieldNames={fieldName}
                    headerNames={headerName}
                    getDataEvent={(getFilteredData) => setFilteredResulttemp(getFilteredData)}
                    getFilterColLength={(getLength) => setFilterColLength(getLength)}
                    setExcelData={setExcelData}
                    resetind={true}
                    actionName={actionButtons}
                />
            </Container>
        </>
    );

};


export default IntegratedISTObservations;