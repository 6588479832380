import React, { useEffect, useState, useContext, forwardRef, useImperativeHandle } from "react";
import { Container, Col, Button, Spinner } from "react-bootstrap";
import ProjectDocumentRowCollapsed from "./ProjectDocumentRowCollapsed";
import Accordion from 'react-bootstrap/Accordion';
import { deletS3Attachment, getPresignedURL } from "../../services/common-master-service"
import {
    getAllDocumentsDataForProject,
    updateDocumentRowDetails,
    generateDocument,
    getRowWiseDocumentData, getOverAllDocumentGenerationData,
    getProjDocTabsData,
    getProjDocTabServiceWiseData
} from "../../services/project-document-service";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import successImage from "../../icons/success-tick-logo.svg";
import errorImage from "../../icons/error-tick-logo.svg";
import { AuthContext } from "../../contexts/AuthContext";
import { getCurrentDateTime } from "../../functions/gbcs-functions";
import axios from "axios";
import { downloadAttachment } from "../../services/designreviewtracker-service"
import { addLogDetails } from "../../services/logger-entry-service";
import Validate from "../utils/Validation";
import { exportToExcel } from "../utils/ExcelCreation";
import SegmentedProgressBar from "../../utils/SegmentedProgressBar";
import { getActiveChklstVersion } from "../../services/checklist-master-service";
import { getIntegratedTabsData, getIstScriptDocumentsData } from "../../services/integrated-system-testing-service";
import { getISTObservationsData } from "../../services/siteobservationreport-service.js";
import { getProjTranChklst } from "../../services/project-transaction-service";

const ProjectDocumentGeneration = forwardRef((props, ref) => {

    const [projectWiseDocsRows, setProjectWiseDocsRows] = useState([]);
    const MySwal = withReactContent(Swal);
    const context = useContext(AuthContext);
    const userDetails = { userName: context.userName, userId: context.userId }
    const [loading, setLoading] = useState(false);
    let versionsSize = 5;
    const [currentProgress, setCurrentProgress] = useState();
    const [showProgress, setShowProgress] = useState();

    let DocsArr = ["Commissioning Plan", "Summary Report", "HOTO Closure Report"];
    let ServiceWiseDocsArr = ["Tender Inclusion"];
    let SvcEquipWiseDocsArr = ["Method Statement", "Detailed Report for SAT", "Detailed Report for FAT"];
    let istDocsArr = ["IST Method Statement for Critical", "IST Method Statement for Non Critical", "IST Report for Critical", "IST Report for Non Critical"];

    const documentDetailsArr = {
        "T&C": [
            "Commissioning Plan",
            "Tender Inclusion",
            "Method Statement",
            // "Detailed Report",
            "Detailed Report for SAT",
            "Detailed Report for FAT",
            "Integrated System Testing (IST)",
            "Summary Report"
        ],
        "HOTO": [
            "Commissioning Plan",
            "Integrated System Testing (IST)",
            "HOTO Closure Report"
        ]

    };

    const documentDetails = {
        "T&C": {
            "ProjectWiseDocs": [
                { "fullName": "Commissioning Plan", "shortName": "Commissioning" },
                { "fullName": "Summary Report", "shortName": "Summary" },
                // { "fullName": "Integrated System Testing (IST)", "shortName": "IST" }
            ],
            "ServiceWiseDocs": [
                { "fullName": "Tender Inclusion", "shortName": "Tender", "flag": "TenderInclusion" },
                // { "fullName": "Integrated System Testing (IST)", "shortName": "IST" }
            ],
            "EquipmentWiseDocs": [
                { "fullName": "Method Statement", "shortName": "Method", "flag": "MethodStatement" },
                { "fullName": "Detailed Report for SAT", "shortName": "Detailed SAT", "flag": "DetailedReport" },
                { "fullName": "Detailed Report for FAT", "shortName": "Detailed FAT", "flag": "DetailedReport" }
            ],
            "ISTDocs": [
                { "fullName": "Integrated System Testing (IST)", "shortName": "IST" }
            ]
        },
        "HOTO": {
            "ProjectWiseDocs": [
                { "fullName": "Commissioning Plan", "shortName": "Commissioning" },
                { "fullName": "HOTO Closure Report", "shortName": "HOTO" },
                // { "fullName": "Integrated System Testing (IST)", "shortName": "IST" }
            ],
            "ISTDocs": [
                { "fullName": "Integrated System Testing (IST)", "shortName": "IST" }
            ]
        }
    };
    const subDocumentDetails = {
        "IST": [
            { "fullName": "IST Method Statement for Critical", "shortName": "ISTMethodStatementCritical", "criticalFlag": "Critical", "Template": "IST Method Statement" },
            { "fullName": "IST Method Statement for Non Critical", "shortName": "ISTMethodStatementNonCritical", "criticalFlag": "Non-Critical", "Template": "IST Method Statement" },
            { "fullName": "IST Report for Critical", "shortName": "ISTReportCritical", "criticalFlag": "Critical", "Template": "IST Report", },
            { "fullName": "IST Report for Non Critical", "shortName": "ISTReportNonCritical", "criticalFlag": "Non-Critical", "Template": "IST Report" }
        ]
    };

    useEffect(() => {
        getProjectServiceEquipmnetDetails();
    }, [props.projectservicedata])

    const getProjectServiceEquipmnetDetails = async () => {
        setLoading(true);
        if (props.projectservicedata?.length > 0) {
            const documentsArr = {};
            const AllDocumentsData = await getAllDocumentsDataForProject(props.SelectedProject.ProjectCode);
            let serviceList = [];
            let equipList = [];
            for (let dt of props.projectservicedata) {
                if (documentDetails[props.SelectedProject.ProjectType].hasOwnProperty("ServiceWiseDocs")) {
                    if (serviceList.filter((x) => x.ServiceCode === dt.ServiceCode).length === 0) {
                        let jsonRecord = {
                            "ServiceName": dt.ServiceName,
                            "ServiceCode": dt.ServiceCode,
                            "DataKey": [{ key: "ServiceCode", keyValue: dt.ServiceName }]
                        }
                        serviceList.push(jsonRecord);
                        let docArr = documentDetails[props.SelectedProject.ProjectType]["ServiceWiseDocs"];
                        for (let docName of docArr) {
                            let tempData = AllDocumentsData.find((x) => x.DocumentType === docName.shortName && x.ServiceCode === dt.ServiceCode);
                            jsonRecord.disableFlag = false;
                            //disable for not applicable document
                            if (!dt[docName.flag]) {
                                jsonRecord.disableFlag = true;
                            }

                            //disable for Freeze document
                            // if (tempData?.FreezeIndication === "Freeze") {
                            //     jsonRecord.disableFlag = true;
                            // }

                            //disable for Inactive & Closed projects
                            if (props.SelectedProject.DeleteIndication !== "Active") {
                                jsonRecord.disableFlag = true;
                            }

                            if (documentsArr.hasOwnProperty(docName.fullName)) {
                                documentsArr[docName.fullName].push({ ...tempData, ...jsonRecord, ...{ DocumentType: docName.shortName } });
                            }
                            else {
                                documentsArr[docName.fullName] = [{ ...tempData, ...jsonRecord, ...{ DocumentType: docName.shortName } }];
                            }
                        }
                    }
                }

                if (documentDetails[props.SelectedProject.ProjectType].hasOwnProperty("EquipmentWiseDocs")) {
                    if (equipList.filter((x) => x.ServiceCode === dt.ServiceCode && x.EquipmentCode === dt.EquipmentCode).length === 0) {
                        let jsonRecord = {
                            "ServiceName": dt.ServiceName,
                            "ServiceCode": dt.ServiceCode,
                            "EquipmentCode": dt.EquipmentCode,
                            "EquipmentName": dt.EquipmentName,
                            "DataKey": [{ key: "ServiceCode", keyValue: dt.ServiceName },
                            { key: "EquipmentCode", keyValue: dt.EquipmentName }]
                        }

                        equipList.push(jsonRecord);
                        let docArr = documentDetails[props.SelectedProject.ProjectType]["EquipmentWiseDocs"];
                        for (let docName of docArr) {
                            jsonRecord.disableFlag = false;
                            let tempData = AllDocumentsData.find((x) => x.DocumentType === docName.shortName && x.ServiceCode === dt.ServiceCode && x.EquipmentCode === dt.EquipmentCode);
                            //disable for not applicable document
                            if (docName.shortName == "Detailed FAT") {
                                // console.log("inside if",docName.shortName, dt)
                                if (!dt.FatApplicable) {
                                    jsonRecord.disableFlag = true
                                }

                            }
                            if (!dt[docName.flag]) {
                                jsonRecord.disableFlag = true;
                            }

                            //disable for Freeze document
                            // if (tempData?.FreezeIndication === "Freeze") {
                            //     jsonRecord.disableFlag = true;
                            // }

                            //disable for Inactive & Closed projects
                            if (props.SelectedProject.DeleteIndication !== "Active") {
                                jsonRecord.disableFlag = true;
                            }
                            if (documentsArr.hasOwnProperty(docName.fullName)) {
                                documentsArr[docName.fullName].push({ ...tempData, ...jsonRecord, ...{ DocumentType: docName.shortName } });
                            }
                            else {
                                documentsArr[docName.fullName] = [{ ...tempData, ...jsonRecord, ...{ DocumentType: docName.shortName } }];
                            }
                        }
                    }
                }
            }

            if (documentDetails[props.SelectedProject.ProjectType].hasOwnProperty("ProjectWiseDocs")) {
                let docArr = documentDetails[props.SelectedProject.ProjectType]["ProjectWiseDocs"];
                for (let docName of docArr) {
                    let jsonRecord = { DocumentType: docName.shortName };
                    let tempData = AllDocumentsData.find((x) => x.DocumentType === docName.shortName);
                    jsonRecord.disableFlag = false;
                    //disable for Freeze document
                    // if (tempData?.FreezeIndication === "Freeze") {
                    //     jsonRecord.disableFlag = true;
                    // }

                    //disable for Inactive & Closed projects
                    if (props.SelectedProject.DeleteIndication !== "Active") {
                        jsonRecord.disableFlag = true;
                    }

                    if (documentsArr.hasOwnProperty(docName.fullName)) {
                        documentsArr[docName.fullName].push({ ...tempData, ...jsonRecord });
                    }
                    else {
                        documentsArr[docName.fullName] = [{ ...tempData, ...jsonRecord }];
                    }
                }
            }

            if (documentDetails[props.SelectedProject.ProjectType].hasOwnProperty("ISTDocs")) {
                let docArr = documentDetails[props.SelectedProject.ProjectType]["ISTDocs"];
                for (let docName of docArr) {
                    let jsonRecord = { DocumentType: docName.shortName };

                    for (let dc of subDocumentDetails[docName.shortName]) {
                        jsonRecord.DataKey = [{ key: dc.shortName, keyValue: dc.fullName }];
                        jsonRecord.SubDocumentType = dc.shortName;
                        jsonRecord.SubDocumentFullName = dc.fullName;
                        jsonRecord.CriticalFlag = dc.criticalFlag;
                        jsonRecord.Template = dc.Template;
                        jsonRecord.ISTApplicable = props.SelectedProject.ISTApplicable;
                        jsonRecord.disableFlag = false;
                        if (props.SelectedProject.ISTApplicable === "No" && dc.criticalFlag === "Critical") {
                            jsonRecord.disableFlag = true;
                        }
                        let tempData = AllDocumentsData.find((x) => x.DocumentType === docName.shortName && x.SubDocumentType === dc.shortName);
                        //disable for Freeze document
                        // if (tempData?.FreezeIndication === "Freeze") {
                        //     jsonRecord.disableFlag = true;
                        // }
                        //disable for Inactive & Closed projects
                        if (props.SelectedProject.DeleteIndication !== "Active") {
                            jsonRecord.disableFlag = true;
                        }
                        if (documentsArr.hasOwnProperty(docName.fullName)) {
                            documentsArr[docName.fullName].push({ ...tempData, ...jsonRecord });
                        }
                        else {
                            documentsArr[docName.fullName] = [{ ...tempData, ...jsonRecord }];
                        }
                    }
                }
            }
            console.log("documentsArr", documentsArr);
            setProjectWiseDocsRows(documentsArr)
            setLoading(false);
        }
        else {
            setLoading(false);
            MySwal.fire({
                text: "In Project Master, Equipments Details are not maintained completely. Kindly maintain Equipments Details.",
                imageUrl: errorImage,
                customClass: "swal-success",
            });
        }
    }

    const generateProjectDocument = async (docType, rowdata) => {
        let dataObj = {};
        dataObj.DocumentType = rowdata.DocumentType;
        dataObj.DocumentDetailedType = docType;
        dataObj.ServiceCode = rowdata.ServiceCode;
        dataObj.ServiceName = rowdata.ServiceName;
        dataObj.DocumentGeneratedByName = userDetails.userName;
        dataObj.DocumentGeneratedBy = userDetails.userId;
        dataObj.EquipmentName = rowdata.EquipmentName;
        dataObj.EquipmentCode = rowdata.EquipmentCode;
        if (rowdata.DocumentType === "IST") {
            dataObj.DocumentDetailedType = rowdata.Template;
            dataObj.SubDocumentFullName = rowdata.SubDocumentFullName;
            dataObj.SubDocumentType = rowdata.SubDocumentType;
            dataObj.CriticalFlag = rowdata.CriticalFlag;
        }
        let documentresult = await generateDocument(props.SelectedProject, dataObj);
        if (documentresult?.status) {
            return true;
        }
        else {
            return false;
        }
    }

    const hendleGenerateIndividual = async (docType, rowdata) => {
        if (rowdata.UploadVersion5DocumentName) {
            MySwal.fire({
                text: "Kindly delete oldest version to generate new template.",
                imageUrl: errorImage,
                customClass: "swal-success",
            });
            return;
        }
        if (docType === "Commissioning Plan" || docType === "Tender Inclusion" || docType === "Method Statement" || docType === "Detailed Report for SAT" || docType === "Detailed Report for FAT" || docType === "Integrated System Testing (IST)") {
            MySwal.fire({
                text: "Do you wish to generate new template?",
                showDenyButton: true,
                cancelButtonColor: "#fff",
                confirmButtonText: "Yes",
                reverseButtons: true,
                customClass: "swal-confirmation",
                title: "Generate Document"
            }).then(async (result2) => {
                if (result2.isConfirmed) {
                    setLoading(true);
                    let validateResult = await validateDetails(docType, props.SelectedProject, rowdata);
                    if (!validateResult.isError) {
                        let result = await generateProjectDocument(docType, rowdata);
                        if (result) {
                            setLoading(false);
                            MySwal.fire({
                                text: "Document has been generated successfully.",
                                imageUrl: successImage,
                                customClass: "swal-success",
                                didClose: () => {
                                    getProjectServiceEquipmnetDetails();
                                }
                            });
                        }
                        else {
                            setLoading(false);
                            MySwal.fire({
                                text: "Unable to generate document.",
                                imageUrl: errorImage,
                                customClass: "swal-success",
                            });
                        }
                    }
                    else {
                        setLoading(false)
                        MySwal.fire({
                            text: validateResult.errorMsg,
                            imageUrl: errorImage,
                            customClass: "swal-success",
                        });
                    }
                }
            });
        }
        else {
            MySwal.fire({
                text: "Development not completed for this document type.",
                imageUrl: errorImage,
                customClass: "swal-success",
            });
        }
    }

    const validateCommissioningChecklist = async () => {
        let addedChecklist = 0;
        let distService = [];
        let availableChecklist = {};
        for (let dt of props.projectservicedata) {
            if (distService.hasOwnProperty(dt.ServiceCode)) {
                let isExist = distService[dt.ServiceCode].filter((x) => x.EquipmentCode === dt.EquipmentCode);
                if (isExist.length === 0) {
                    distService[dt.ServiceCode].push(dt);
                }
            }
            else {
                distService[dt.ServiceCode] = [dt];
                availableChecklist[dt.ServiceCode] = false;
            }
        }
        for (let element of Object.keys(distService)) {
            if (addedChecklist >= 3) {
                break;
            }
            for (let equip of distService[element]) {
                if (Object.keys(distService).length > 1 && element.hasOwnProperty("checklistAdded") && availableChecklist[element]) {
                    break;
                }
                if (equip.hasOwnProperty("FatApplicable") || equip.hasOwnProperty("SatApplicable")) {
                    let checklistDetails = await getActiveChklstVersion(equip.EquipmentCode);

                    let categories = "";
                    if (equip.hasOwnProperty("FatApplicable") && equip.FatApplicable === "Yes") {
                        categories = equip.FATEquipApplicableChecklist;
                    }
                    if (equip.hasOwnProperty("SatApplicable") && equip.SatApplicable === "Yes") {
                        categories = categories + "," + equip.SATEquipApplicableChecklist;
                    }
                    categories = "," + categories + ",";

                    if (checklistDetails?.length > 0) {
                        let findobj = checklistDetails.filter(x => categories.indexOf("," + x.ChecklistCategory + ",") !== -1 && x.ChecklistVersion.startsWith("VER"));
                        if (findobj.length > 0) {
                            availableChecklist[element] = true;
                            addedChecklist++;
                        }
                    }
                    if (Object.keys(distService).length === 1 && (addedChecklist === 3 || addedChecklist === distService[element].length)) {
                        break;
                    }
                }
            }
        }
        // if only 1 service with 3 or more equipments, then 3 checklist should be added.
        if (Object.keys(distService).length === 1 && distService[props.projectservicedata[0].ServiceCode].length >= 3 && addedChecklist < 3) {
            return { isError: true, errorMsg: "Checklist details are not maintained for 3 equipment / activity in Checklist Master. " };
        }
        // if only 1 service with less than 3 equipments, then checklist should be added for all equipments.
        else if (Object.keys(distService).length === 1 && distService[props.projectservicedata[0].ServiceCode].length < 3 && addedChecklist < distService[props.projectservicedata[0].ServiceCode].length) {
            return { isError: true, errorMsg: "Checklist details are not maintained for all equipment / activity in Checklist Master. " };
        }
        // if 2 services, then checklist should be added for 2 services.
        else if (Object.keys(distService).length === 2 && addedChecklist < 2) {
            return { isError: true, errorMsg: "Checklist details are not maintained for all services in Checklist Master. " };
        }
        // if 3 or more services, then checklist should be added for 3 services.
        else if (Object.keys(distService).length >= 3 && addedChecklist < 3) {
            return { isError: true, errorMsg: "Checklist details are not maintained for all services in Checklist Master. " };
        }
        else {
            return { isError: false, errorMsg: "" };
        }
    }

    const validateCommissioning = async (proj) => {
        let isError = false;
        let errorMsg = "";
        let projResult = await getOverAllDocumentGenerationData(proj.ProjectCode);
        if (projResult?.length === 0) {
            isError = true;
            errorMsg = errorMsg + "Project Building Photo and Project Introduction are not updated in Project Documents Tab - 5 Additional Information. "
        }
        else {
            if (!projResult[0].ProjectBuildingPhoto) {
                errorMsg = errorMsg + "Project Building Photo is not uploaded in Project Documents Tab - 5 Additional Information. ";
            }

            if (!projResult[0].ProjectIntroduction) {
                errorMsg = errorMsg + "Project Introduction is not defined in Project Documents Tab - 5 Additional Information. ";
            }
        }
        let checklistResult = await validateCommissioningChecklist();
        if (checklistResult.isError) {
            isError = true;
            errorMsg = errorMsg + checklistResult.errorMsg;
        }
        return { isError, errorMsg };
    }

    const validateTender = async (proj, rowDt) => {
        let isError = false;
        let errorMsg = "";
        const [instumentsData, testDescData, preReqData] = await Promise.all([
            getProjDocTabServiceWiseData(proj.ProjectCode, rowDt.ServiceCode, "Instruments"),
            getProjDocTabServiceWiseData(proj.ProjectCode, rowDt.ServiceCode, "Testing"),
            getProjDocTabServiceWiseData(proj.ProjectCode, rowDt.ServiceCode, "Prerequisite")
        ]);
        if (preReqData?.length <= 0) {
            isError = true;
            errorMsg = errorMsg + "Pre-Requisite details are not maintained for service in Project Documents Tab - 2 Pre-Requisites. "
        }

        if (instumentsData?.length <= 0) {
            isError = true;
            errorMsg = errorMsg + "Testing Instruments details are not maintained for service in Project Documents Tab - 3 Instruments. "
        }

        if (testDescData?.length <= 0) {
            isError = true;
            errorMsg = errorMsg + "Test Description details are not maintained for service in Project Documents Tab - 4 Test Description. "
        }

        return { isError, errorMsg };
    }

    const validateMethod = async (proj, rowDt) => {
        let isError = false;
        let errorMsg = "";
        let PK = "ProjectDocuments#ProjectCode#" + proj.ProjectCode;

        let addInfoObj = {
            PK: PK,
            SK: "AdditionalInformation#EquipmentCode#" + rowDt.EquipmentCode
        }
        let instruObj = {
            PK: PK,
            SK: "Instruments#EquipmentCode#" + rowDt.EquipmentCode + "#SeqNo#"
        }
        let testObj = {
            PK: PK,
            SK: "Testing#EquipmentCode#" + rowDt.EquipmentCode + "#SeqNo#"
        }
        let preReqObj = {
            PK: PK,
            SK: "Prerequisite#EquipmentCode#" + rowDt.EquipmentCode + "#SeqNo#"
        }
        const [addInfoData, instumentsData, testDescData, preReqData, checklistDetails] = await Promise.all([
            getProjDocTabsData(addInfoObj),
            getProjDocTabsData(instruObj),
            getProjDocTabsData(testObj),
            getProjDocTabsData(preReqObj),
            getActiveChklstVersion(rowDt.EquipmentCode)
        ]);
        if (preReqData?.length <= 0) {
            isError = true;
            errorMsg = errorMsg + "Pre-Requisite details are not maintained for equipment / activity in Project Documents Tab - 2 Pre-Requisites. "
        }

        if (instumentsData?.length <= 0) {
            isError = true;
            errorMsg = errorMsg + "Testing Instruments details are not maintained for equipment / activity in Project Documents Tab - 3 Instruments. "
        }

        if (testDescData?.length <= 0) {
            isError = true;
            errorMsg = errorMsg + "Test Description details are not maintained for equipment / activity in Project Documents Tab - 4 Test Description. "
        }

        if (addInfoData?.length <= 0) {
            isError = true;
            errorMsg = errorMsg + "Additional Information details are not uploaded for equipment / activity in Project Documents Tab - 5 Additional Information. "
        }
        if (checklistDetails?.length <= 0) {
            isError = true;
            errorMsg = errorMsg + "Checklist details are not maintained for equipment / activity in Checklist Master. "
        }
        else {
            let categories = "";
            let equipData = props.projectservicedata.filter(x => x.EquipmentCode === rowDt.EquipmentCode);
            if (equipData.length > 0) {
                if (equipData[0].hasOwnProperty("FatApplicable") && equipData[0].FatApplicable === "Yes") {
                    categories = equipData[0].FATEquipApplicableChecklist;
                }
                if (equipData[0].hasOwnProperty("SatApplicable") && equipData[0].SatApplicable === "Yes") {
                    categories = categories + "," + equipData[0].SATEquipApplicableChecklist;
                }
            }
            categories = "," + categories + ",";
            let findobj = checklistDetails.filter(x => categories.indexOf("," + x.ChecklistCategory + ",") !== -1 && x.ChecklistVersion.startsWith("VER"));
            if (findobj.length <= 0) {
                isError = true;
                errorMsg = errorMsg + "Checklist details are not maintained for equipment / activity in Checklist Master. "
            }
        }

        return { isError, errorMsg };
    }

    const validateDetailedReport = async (proj, rowDt, docType) => {
        let isError = false;
        let errorMsg = "";
        let PK = "ProjectDocuments#ProjectCode#" + proj.ProjectCode;

        let instruObj = {
            PK: PK,
            SK: "Instruments#EquipmentCode#" + rowDt.EquipmentCode + "#SeqNo#"
        }

        let testObj = {
            PK: PK,
            SK: "Testing#EquipmentCode#" + rowDt.EquipmentCode + "#SeqNo#"
        }

        const [instrumentsData, testDescData] = await Promise.all([getProjDocTabsData(instruObj), getProjDocTabsData(testObj)])

        console.log("test description data", testDescData);

        if (instrumentsData?.length <= 0) {
            isError = true;
            errorMsg = errorMsg + "Testing Instruments details are not maintained for equipment / activity in Project Documents Tab - 3 Instruments. "
        }

        let containsFAT = testDescData.some((desc) => desc['FAT Applicable'] === true)
        let containsSAT = testDescData.some((desc) => desc['SAT Applicable'] === true)

        if (testDescData?.length <= 0) {
            isError = true;
            errorMsg = errorMsg + "Test Description details are not maintained for equipment / activity in Project Documents Tab - 4 Test Description. "
        } else {
            if (docType === "Detailed Report for SAT" && !containsSAT) {
                isError = true;
                errorMsg = errorMsg + "No test description for SAT available for equipment / activity in Project Documents Tab - 4 Test Description."
            }
            if (docType === "Detailed Report for FAT" && !containsFAT) {
                isError = true;
                errorMsg = errorMsg + "No test description for FAT available for equipment / activity in Project Documents Tab - 4 Test Description."
            }
        }

        let type = "";

        if (docType === "Detailed Report for FAT") {
            type = "FAT"
        }
        if (docType === "Detailed Report for SAT") {
            type = "SAT"
        }

        const checklistData = await getProjTranChklst(`${type}`, proj.ProjectCode)
        const serviceEquip = props.projectservicedata.filter((equip) => equip.EquipmentCode === rowDt.EquipmentCode)
        const errorTags = []

        for (let equip of serviceEquip) {
            // debugger
            const chklstType = equip[`${type}EquipApplicableChecklist`].split(",")

            for (let dt of chklstType) {
                let checklistExists = checklistData.filter((data) => data.EquipmentTag === equip.EquipmentTag && data.ChecklistCategory === dt)
                if (checklistExists.length > 0) {
                    if (!checklistExists[0].IsFreeze) {
                        isError = true
                        errorTags.push(` ${equip.EquipmentTag}(${dt})`)
                    }
                } else {
                    isError = true
                    errorTags.push(` ${equip.EquipmentTag}(${dt})`)
                }
            }
        }

        if (docType === "Detailed Report for FAT") {
            errorMsg = errorMsg + `Checklists for Equipment/Activity Tag - ${errorTags.toString()} is not submitted for Factory Acceptance Test in Transaction Master.`
        }
        if (docType === "Detailed Report for SAT") {
            errorMsg = errorMsg + `Checklists for Equipment/Activity Tag - ${errorTags.toString()} is not submitted for Site Acceptance Test in Transaction Master.`
        }

        return { isError, errorMsg }
    }


    const validateIst = async (proj, rowDt) => {
        let isError = false;
        let errorMsg = "";

        let istVendbj = {
            "ProjectCode": proj.ProjectCode,
            "flag": "Stakeholder"
        }
        let istEquipObj = {
            "ProjectCode": proj.ProjectCode,
            "flag": "Equipment"
        }
        let istScriptObj = {
            "ProjectCode": proj.ProjectCode,
            "flag": "Script"
        }
        let instruObj = {
            "PK": "ProjectDocuments#ProjectCode#" + proj.ProjectCode,
            "SK": "Instruments#EquipmentCode#"
        }
        const [istVendorsData, istEquipmentData, istScriptsData, istScriptDocData, instumentsData] = await Promise.all([
            getIntegratedTabsData(istVendbj),
            getIntegratedTabsData(istEquipObj),
            getIntegratedTabsData(istScriptObj),
            getIstScriptDocumentsData({ "ProjectCode": proj.ProjectCode }),
            getProjDocTabsData(instruObj)
        ]);
        if (istVendorsData?.length <= 0) {
            isError = true;
            errorMsg = errorMsg + "Stakeholder Contact details are not maintained in Integrated System Testing Tab - 1 Stakeholder Contact."
        }
        else if (istVendorsData.filter(dt => dt.ApplicableforCriNonCriReport.split(",").includes(rowDt.CriticalFlag)).length <= 0) {
            isError = true;
            errorMsg = errorMsg + "Stakeholder Contact details are not maintained for " + rowDt.CriticalFlag + " Report in Integrated System Testing Tab - 1 Stakeholder Contact. "
        }

        let sortedEquipments = istEquipmentData.filter((x) => x.ApplicableforCriNonCriReport === rowDt.CriticalFlag);

        if (sortedEquipments?.length <= 0) {
            isError = true;
            errorMsg = errorMsg + "Equipments details are not maintained in Integrated System Testing Tab - 2 Equipment. "
        }
        if (istScriptsData?.length <= 0) {
            isError = true;
            errorMsg = errorMsg + "IST Scripts Summary details are not maintained in Integrated System Testing Tab - 3 IST Scripts. "
        }
        if (instumentsData?.length <= 0) {
            isError = true;
            errorMsg = errorMsg + "Instrument details are not maintained for Equipment / Activity in Project Documents Tab - 3 Instruments. "
        }

        let instruData = false;
        for (let item of instumentsData) {
            let filteredEquip = sortedEquipments.filter((x) => x.EquipmentCode === item.EquipmentCode);
            if (filteredEquip?.length > 0) {
                for (let equip of filteredEquip) {
                    if (equip.Contractor) {
                        instruData = true;
                        break;
                    }
                }
            }
        }
        if (!instruData) {
            isError = true;
            errorMsg = errorMsg + "Contractor / Vendor details are not maintained for the Instruments in Integrated System Testing Tab - 2 Equipment. "
        }

        if (rowDt.Template === "IST Report") {
            let istObservations = await getISTObservationsData(proj.ProjectCode);
            if (istObservations.length <= 0) {
                isError = true;
                errorMsg = errorMsg + "IST Observations details are not maintained in Integrated System testing Tab - 4 IST Observations. "
            }
        }

        if (istScriptDocData.length <= 0) {
            isError = true;
            errorMsg = errorMsg + "Details are not maintained in Integrated System Testing Tab - 3 IST Script. "
        }
        else if (rowDt.Template === "IST Method Statement") {
            if (!istScriptDocData[0].istScriptUploadDoc) {
                isError = true;
                errorMsg = errorMsg + "IST Script details are not maintained in Integrated System Testing Tab - 3 IST Script. "

            }
            if (!istScriptDocData[0].istScriptCETitle) {
                isError = true;
                errorMsg = errorMsg + "C&E Matrix details are not maintained in Integrated System Testing Tab - 3 IST Script. "

            }
            if (rowDt.CriticalFlag === "Critical") {
                if (istScriptDocData[0].istScriptAlarmCriticalTitleRadio === "Applicable" && !istScriptDocData[0].istScriptAlarmCriticalTitle) {
                    isError = true;
                    errorMsg = errorMsg + "Alarm SMS List for Critical details are not maintained in Integrated System Testing Tab - 3 IST Script. "

                }
            }
            if (rowDt.CriticalFlag === "Non-Critical") {
                if (istScriptDocData[0].istScriptAlarmTitleRadio === "Applicable" && !istScriptDocData[0].istScriptAlarmTitle) {
                    isError = true;
                    errorMsg = errorMsg + "Alarm SMS List details are not maintained in Integrated System Testing Tab - 3 IST Script. "
                }
            }
        }
        else if (rowDt.Template === "IST Report") {
            if (!istScriptDocData[0].istScriptResultUploadDoc) {
                isError = true;
                errorMsg = errorMsg + "IST Script Results details are not maintained in Integrated System Testing Tab - 3 IST Script. "

            }
            if (rowDt.CriticalFlag === "Critical") {
                if (!istScriptDocData[0].istScriptCEActualCriticalTitle) {
                    isError = true;
                    errorMsg = errorMsg + "C&E Matrix with Actual Results for Critical details are not maintained in Integrated System Testing Tab - 3 IST Script. "

                }
            }
            if (rowDt.CriticalFlag === "Non-Critical") {
                if (!istScriptDocData[0].istScriptMainCEActualTitle) {
                    isError = true;
                    errorMsg = errorMsg + "C&E Matrix with Actual Results details are not maintained in Integrated System Testing Tab - 3 IST Script. "

                }
            }
        }

        if (rowDt.Template === "IST Method Statement") {
            const [powerAnalyzer, roomHeatersLocations] = await Promise.all([
                getIntegratedTabsData({ "ProjectCode": proj.ProjectCode, "flag": "PowerAnalyzer" }),
                getIntegratedTabsData({ "ProjectCode": proj.ProjectCode, "flag": "RoomHeatersLocations" })
            ]);
            if (powerAnalyzer.filter(dt => dt.applicableforprint === "Print in Report").length <= 0) {
                isError = true;
                errorMsg = errorMsg + "Power Analyzer Location Layout details are not maintained in Integrated System Testing Tab - 5 Power Analyzer & IST Screenshot to print in report. "
            }
            if (roomHeatersLocations.filter(dt => dt.applicableforprint === "Print in Report").length <= 0) {
                isError = true;
                errorMsg = errorMsg + "Critical Rooms Heaters and Data Loggers Location details are not maintained in Integrated System Testing Tab - 5 Power Analyzer & IST Screenshot to print in report. "
            }
        }
        else if (rowDt.Template === "IST Report") {
            const [powerAnalyzerTrend, istScreenshots, roomHeatersReadings] = await Promise.all([
                getIntegratedTabsData({ "ProjectCode": proj.ProjectCode, "flag": "PowerAnalyzerTrend" }),
                getIntegratedTabsData({ "ProjectCode": proj.ProjectCode, "flag": "IstScreenshots" }),
                getIntegratedTabsData({ "ProjectCode": proj.ProjectCode, "flag": "RoomHeatersReadings" })
            ]);
            if (powerAnalyzerTrend.filter(dt => dt.applicableforprint === "Print in Report").length <= 0) {
                isError = true;
                errorMsg = errorMsg + "Power Analyzer Recorded Trend details are not maintained in Integrated System Testing Tab - 5 Power Analyzer & IST Screenshot to print in report. "
            }
            if (istScreenshots.filter(dt => dt.applicableforprint === "Print in Report").length <= 0) {
                isError = true;
                errorMsg = errorMsg + "IST Screenshots details are not maintained in Integrated System Testing Tab - 5 Power Analyzer & IST Screenshot to print in report. "
            }
            if (roomHeatersReadings.filter(dt => dt.applicableforprint === "Print in Report").length <= 0) {
                isError = true;
                errorMsg = errorMsg + "Critical Rooms Heaters and Data Loggers Readings details are not maintained in Integrated System Testing Tab - 5 Power Analyzer & IST Screenshot to print in report. "
            }
        }
        return { isError, errorMsg };
    }
    const validateDetails = async (docType, proj, rowDt) => {
        let errorDetails = {};

        if (docType === "Commissioning Plan") {
            errorDetails = await validateCommissioning(proj);
        }

        if (docType === "Tender Inclusion") {
            errorDetails = await validateTender(proj, rowDt);
        }

        if (docType === "Method Statement") {
            errorDetails = await validateMethod(proj, rowDt);
        }

        if (docType === "Detailed Report for SAT" || docType === "Detailed Report for FAT") {
            errorDetails = await validateDetailedReport(proj, rowDt, docType)
        }

        if (docType === "Integrated System Testing (IST)") {
            errorDetails = await validateIst(proj, rowDt);
        }
        return errorDetails;
    }

    const getParamtersObj = async (docType, rowdata, paraObj) => {
        if (ServiceWiseDocsArr.includes(docType)) {
            paraObj.ServiceCode = rowdata.ServiceCode;
            paraObj.ServiceName = rowdata.ServiceName;
            paraObj.TenderInclusion = rowdata.TenderInclusion;
        }
        else if (SvcEquipWiseDocsArr.includes(docType)) {
            paraObj.ServiceCode = rowdata.ServiceCode;
            paraObj.ServiceName = rowdata.ServiceName;
            paraObj.EquipmentCode = rowdata.EquipmentCode;
            paraObj.EquipmentName = rowdata.EquipmentName;
        }
        return paraObj;
    }

    const getkeys = async (docType, rowdata) => {
        let key = "";
        let shrtDocName = rowdata.DocumentType;
        if (DocsArr.includes(docType)) {
            key = "PD#" + shrtDocName
        }
        if (ServiceWiseDocsArr.includes(docType)) {
            key = "PD#" + shrtDocName + "#ServiceCode#" + rowdata.ServiceCode;
        }
        if (SvcEquipWiseDocsArr.includes(docType)) {
            key = "PD#" + shrtDocName + "#ServiceCode#" + rowdata.ServiceCode + "#EquipmentCode#" + rowdata.EquipmentCode;
        }
        if (istDocsArr.includes(rowdata.SubDocumentFullName)) {
            key = "PD#" + shrtDocName + "#" + rowdata.SubDocumentType;
        }
        return key;
    }

    const getVariableFilePath = async (docType, fileName, rowData) => {
        let shortDoc = rowData.DocumentType;
        let path = "ProjectDocuments/" + props.SelectedProject.ProjectCode + "/" + shortDoc + "/";
        if (DocsArr.includes(docType)) {
            path += fileName;
        }
        if (ServiceWiseDocsArr.includes(docType)) {
            path += rowData.ServiceCode + "/" + fileName;
        }
        if (SvcEquipWiseDocsArr.includes(docType)) {
            path += rowData.ServiceCode + "/" + rowData.EquipmentCode + "/" + fileName;
        }
        if (istDocsArr.includes(rowData.SubDocumentFullName)) {
            path += rowData.SubDocumentType + "/" + fileName;
        }
        return { "path": path };
    }

    const handleDeleteUploadVersion = async (fileName, documentRowData, docType, rowindex, verId) => {
        if (!documentRowData.disableFlag && documentRowData.FreezeIndication !== "Freeze") {
            let errorFlag = false;
            let checkId = verId + 1;
            for (checkId; checkId <= versionsSize; checkId++) {
                if (documentRowData["UploadVersion" + checkId + "DocumentName"]) {
                    errorFlag = true;
                }
            }
            if (!errorFlag) {
                setLoading(true);
                let varFilePath = await getVariableFilePath(docType, fileName, documentRowData)
                let fileObj = { fileName: varFilePath.path };
                let result = await deletS3Attachment(fileObj);
                if (result) {
                    let newArray = projectWiseDocsRows[docType];
                    let updateObj = {};
                    updateObj.PK = "ProjectDocuments#ProjectCode#" + props.SelectedProject.ProjectCode;
                    updateObj.SK = await getkeys(docType, documentRowData);
                    updateObj["UploadVersion" + verId + "DocumentName"] = "";
                    updateObj["UploadVersion" + verId + "UploadedBy"] = "";
                    updateObj["UploadVersion" + verId + "UploadedByName"] = "";
                    updateObj["UploadVersion" + verId + "UploadedDate"] = "";

                    let result2 = await updateDocumentRowDetails(updateObj)
                    if (result2?.status) {
                        documentRowData["UploadVersion" + verId + "DocumentName"] = "";
                        documentRowData["UploadVersion" + verId + "UploadedBy"] = "";
                        documentRowData["UploadVersion" + verId + "UploadedByName"] = "";
                        documentRowData["UploadVersion" + verId + "UploadedDate"] = "";
                        newArray[rowindex] = documentRowData;
                        setProjectWiseDocsRows((prevState) => ({ ...prevState, docType: newArray }));
                        setLoading(false);
                        MySwal.fire({
                            text: "Version deleted Successfully",
                            imageUrl: successImage,
                            customClass: "swal-success",
                        });
                    }
                }
                else {
                    setLoading(false);
                    MySwal.fire({
                        text: "Unable to Process Request",
                        imageUrl: errorImage,
                        customClass: "swal-success",
                    });
                }
            }
            else {
                MySwal.fire({
                    text: "Please delete latest version first.",
                    imageUrl: errorImage,
                    customClass: "swal-success",
                });
            }
        }
    }

    const handleUploadVersions = async (file, rowData, documentType, rowIndex, uploadId) => {
        setLoading(true);
        let isError = false;
        let id = uploadId - 1;

        for (id; id > 0; id--) {
            if (!rowData["UploadVersion" + id + "DocumentName"]) {
                isError = true;
            }
        }
        let subKey = await getkeys(documentType, rowData);
        let paramObj = {
            PK: "ProjectDocuments#ProjectCode#" + props.SelectedProject.ProjectCode,
            SK: subKey
        }
        let getResult = await getRowWiseDocumentData(paramObj);
        if (getResult?.length <= 0) {
            setLoading(false);
            MySwal.fire({
                text: "The document has not been generated yet. Please generate the document first.",
                imageUrl: errorImage,
                customClass: "swal-success",
            });
            return;
        }
        if (!isError) {
            setLoading(true);
            const newFile = file[0];
            let fileError = Validate(newFile, "docsFormats", 26214400);
            if (fileError === "") {
                let fileName = file[0].name;
                let todayDate = getCurrentDateTime("-");
                let newArr = projectWiseDocsRows[documentType];
                let varFilePath = await getVariableFilePath(documentType, fileName, rowData)
                let filepath = varFilePath.path;
                let result = await getPresignedURL(filepath);
                if (result) {
                    await uploadFiles(result, newFile);
                    let documentObject = {};
                    documentObject.PK = "ProjectDocuments#ProjectCode#" + props.SelectedProject.ProjectCode;
                    documentObject.SK = await getkeys(documentType, rowData);

                    documentObject["UploadVersion" + uploadId + "DocumentName"] = fileName;
                    documentObject["UploadVersion" + uploadId + "UploadedBy"] = userDetails.userId;
                    documentObject["UploadVersion" + uploadId + "UploadedByName"] = userDetails.userName;
                    documentObject["UploadVersion" + uploadId + "UploadedDate"] = todayDate;
                    let result2 = await updateDocumentRowDetails(documentObject);
                    if (result2?.status) {
                        rowData["UploadVersion" + uploadId + "DocumentName"] = fileName;
                        rowData["UploadVersion" + uploadId + "UploadedBy"] = userDetails.userId;
                        rowData["UploadVersion" + uploadId + "UploadedByName"] = userDetails.userName;
                        rowData["UploadVersion" + uploadId + "UploadedDate"] = todayDate;
                        newArr[rowIndex] = rowData;
                        setProjectWiseDocsRows((prevState) => ({ ...prevState, documentType: newArr }));
                        setLoading(false);
                        MySwal.fire({
                            text: "Version Uploaded Successfully",
                            imageUrl: successImage,
                            customClass: "swal-success",
                        });
                    }
                }
                else {
                    setLoading(false);
                    MySwal.fire({
                        text: "Unable to Process Request",
                        imageUrl: errorImage,
                        customClass: "swal-success",
                    });
                }
            } else {
                setLoading(false);
                MySwal.fire({
                    text: fileError,
                    imageUrl: errorImage,
                    customClass: "swal-success",
                });
            }
        }
        else {
            setLoading(false);
            MySwal.fire({
                text: "Please upload document sequentially.",
                imageUrl: errorImage,
                customClass: "swal-success",
            });
        }
    }

    const handleDownloadDocument = async (fileName, doctype, recordData) => {
        setLoading(true);
        let variablePath = await getVariableFilePath(doctype, fileName, recordData);
        downloadAttachment(
            variablePath.path,
            fileName
        ).catch((err) => {
            setLoading(false);
            MySwal.fire({
                text: "Error in Fetching file.",
                imageUrl: errorImage,
                customClass: "swal-success",
            });
        });
        setLoading(false);
    }

    const uploadFiles = async (signedURL, file) => {
        try {
            if (signedURL) {
                await axios.put(signedURL, file);
            }
            return "";
        } catch (error) {
            return " Error in file upload.";
        }
    }

    const hendleGenerateAll = async (docTypeList, calledFrom) => {
        MySwal.fire({
            text: "Do you wish to generate new template?",
            showDenyButton: true,
            cancelButtonColor: "#fff",
            confirmButtonText: "Yes",
            reverseButtons: true,
            customClass: "swal-confirmation",
            title: "Generate Document"
        }).then(async (result2) => {
            if (result2.isConfirmed) {
                setCurrentProgress(0);
                setShowProgress(true);
                let errorLog = [];
                let errorLogInd = false;
                let failedDocs = 0;
                let successDocs = 0;
                let validCount = 0;
                let totalDocs = 0;

                for (let docType of docTypeList) {
                    totalDocs = totalDocs + projectWiseDocsRows[docType].length;
                }

                for (let docType of docTypeList) {
                    for (let record of projectWiseDocsRows[docType]) {
                        if (!record.disableFlag && record.FreezeIndication !== "Freeze") {
                            let validateResult = {};
                            if (record.UploadVersion5DocumentName) {
                                validateResult = {
                                    isError: true,
                                    errorMsg: "Kindly delete oldest version to generate new template."
                                };
                            }
                            else {
                                validateResult = await validateDetails(docType, props.SelectedProject, record);
                            }
                            if (validateResult.isError) {
                                let errItem = {
                                    docType: docType,
                                    errorMsg: validateResult.errorMsg
                                }
                                if (docType === "Commissioning Plan") {
                                    errItem.ServiceName = "";
                                    errItem.EquipmentName = "";
                                }
                                if (docType === "Tender Inclusion") {
                                    errItem.ServiceName = record.ServiceName;
                                    errItem.EquipmentName = "";
                                }
                                if (docType === "Method Statement") {
                                    errItem.ServiceName = record.ServiceName;
                                    errItem.EquipmentName = record.EquipmentCode;
                                }
                                if (docType === "Detailed Report for SAT" || docType === "Detailed Report for FAT") {
                                    errItem.ServiceName = record.ServiceName;
                                    errItem.EquipmentName = record.EquipmentCode;
                                }
                                if (docType === "Integrated System Testing (IST)") {
                                    errItem.ServiceName = record.SubDocumentFullName;
                                    errItem.EquipmentName = "";
                                }
                                errorLog.push(errItem);
                                errorLogInd = true;
                            }
                        }
                        validCount++;
                        setCurrentProgress(Math.ceil(validCount * 100 / (totalDocs * 2)));
                    }
                }
                if (!errorLogInd) {
                    let resultArr = [];
                    let completedDocs = 0;

                    for (let docType of docTypeList) {
                        for (let record of projectWiseDocsRows[docType]) {
                            if (!record.disableFlag && record.FreezeIndication !== "Freeze") {
                                let result = generateProjectDocument(docType, record);
                                resultArr.push(result);
                            }
                            else {
                                completedDocs++;
                            }
                        }
                    }

                    for (let res of resultArr) {
                        let resInd = await Promise.resolve(res);
                        if (!resInd) {
                            failedDocs++;
                        }
                        else {
                            successDocs++;
                        }
                        completedDocs++;
                        console.log("comp", completedDocs, totalDocs, Math.ceil(completedDocs * 100 / totalDocs))
                        setCurrentProgress(Math.ceil((validCount + completedDocs) * 100 / (totalDocs * 2)));
                    }

                    if (calledFrom === "generateAllDocs" && successDocs > 0) {

                        let obj = {
                            PK: "ProjectDocuments#ProjectCode#" + props.SelectedProject.ProjectCode,
                            SK: "ProjectCode#" + props.SelectedProject.ProjectCode,
                            GenerateAllBy: userDetails.userId,
                            GenerateAllByName: userDetails.userName,
                            GenerateAllOn: getCurrentDateTime("-"),
                            GenerateAll: "Yes"
                        };
                        let updateRes = await updateDocumentRowDetails(obj);

                        props.updateInfo(!props.updateInfoVal)
                    }
                    if (failedDocs === 0 && successDocs > 0) {
                        setShowProgress(false);
                        MySwal.fire({
                            text: "Documents has been generated successfully.",
                            imageUrl: successImage,
                            customClass: "swal-success",
                            didClose: () => {
                                getProjectServiceEquipmnetDetails();
                            }
                        });
                    }
                    else if (failedDocs > 0 && successDocs > 0) {
                        setShowProgress(false);
                        MySwal.fire({
                            text: successDocs + " documents has been generated successfully. " + failedDocs + " documents failed to generate.",
                            imageUrl: successImage,
                            customClass: "swal-success",
                            didClose: () => {
                                getProjectServiceEquipmnetDetails();
                            }
                        });
                    }
                    else if (failedDocs > 0 && successDocs === 0) {
                        setShowProgress(false);
                        MySwal.fire({
                            text: "Documents failed to generate.",
                            imageUrl: errorImage,
                            customClass: "swal-success",
                        });
                    }
                }
                else {
                    console.log("errorLog", errorLog);
                    let colName = ["Document Type", "Service Name", "Equipment Name", "Error Message"];
                    let Fieldname = ["docType", "ServiceName", "EquipmentName", "errorMsg"];
                    exportToExcel("Project Documents Error File", "Data", errorLog, "A1:D1", colName, Fieldname);
                    MySwal.fire({
                        text: "Documents failed to generate. Please check downloaded file for error details.",
                        imageUrl: errorImage,
                        customClass: "swal-success",
                    });
                }
                setShowProgress(false);
            }
        });
    }

    const generateAllDocsForDocType = async (e, docType) => {
        e.stopPropagation();
        if (docType === "Commissioning Plan" || docType === "Tender Inclusion" || docType === "Method Statement" || docType === "Detailed Report for SAT" || docType === "Detailed Report for FAT" || docType === "Integrated System Testing (IST)") {
            await hendleGenerateAll([docType], "generateAllDocsForDocType");
        }
    }

    const handleGenerateAllDocs = async () => {
        let docTypeArr = [];
        for (let docType of documentDetailsArr[props.SelectedProject.ProjectType]) {
            if (docType === "Commissioning Plan" || docType === "Tender Inclusion" || docType === "Method Statement" || docType === "Detailed Report for SAT" || docType === "Detailed Report for FAT" || docType === "Integrated System Testing (IST)") {
                docTypeArr.push(docType);
            }
        }
        await hendleGenerateAll(docTypeArr, "generateAllDocs");
    }

    useImperativeHandle(ref, () => ({
        funcOnGenerateAll: async () => {
            await handleGenerateAllDocs();
        }
    }));

    const handleToggleChange = async (e, documentType, rowData, rowIndex) => {
        setLoading(true);
        let CheckFlag = false;
        let newArr = projectWiseDocsRows[documentType];
        if (e.target.checked) {
            if (rowData.hasOwnProperty("UploadVersion1DocumentName") && rowData.UploadVersion1DocumentName !== "") {
                CheckFlag = true;
            }
            else {
                setLoading(false);
                MySwal.fire({
                    text: "Kindly upload at least one document to freeze the document.",
                    imageUrl: errorImage,
                    customClass: "swal-success",
                });
            }
        } else {
            setLoading(false);
            CheckFlag = true;
        }
        if (CheckFlag) {
            rowData["FreezeIndication"] = e.target.checked ? "Freeze" : "Unfreeze";
            // rowData.disableFlag = e.target.checked;
            newArr[rowIndex] = rowData;
            setProjectWiseDocsRows((prevState) => ({ ...prevState, documentType: newArr }));
            let documentObject = {};
            documentObject.PK = "ProjectDocuments#ProjectCode#" + props.SelectedProject.ProjectCode;
            documentObject.SK = await getkeys(documentType, rowData);
            documentObject.FreezeIndication = e.target.checked ? "Freeze" : "Unfreeze";
            let result2 = await updateDocumentRowDetails(documentObject);
            let msg = e.target.checked ? "Freezed" : "Unfreezed";
            if (result2?.status) {
                setLoading(false);
                MySwal.fire({
                    text: "Document " + msg + " Successfully.",
                    imageUrl: successImage,
                    customClass: "swal-success",
                });
            }
        }
    };

    return (
        <>
            {loading && <div className="spinner-box"> <Spinner animation="border" className="spinner" /></div>}
            {showProgress && <div className="segmented-progress-box"> <SegmentedProgressBar show={showProgress} progress={currentProgress} /></div>}
            <Container fluid className="main-blank-container">
                {projectWiseDocsRows &&
                    <Accordion className="project-doc-accordian" alwaysOpen>
                        {
                            documentDetailsArr[props.SelectedProject.ProjectType]?.map((item, index) => {
                                return (
                                    <Accordion.Item eventKey={index} className="project-doc-accordian-item">
                                        <Accordion.Header>
                                            <Col className="project-doc-accordian-header"><span>{index + 1 + ". "}</span>&nbsp;<span>{item}</span>
                                            </Col>
                                            <Col className="generate-all-btn">
                                                <Button className="semiBoldToggleButton"
                                                    variant="outline-success"
                                                    onClick={(e) => generateAllDocsForDocType(e, item)}
                                                    disabled={props.SelectedProject.DeleteIndication !== "Active"}
                                                >
                                                    Generate All
                                                </Button>
                                            </Col>
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            {
                                                projectWiseDocsRows[item]?.map((record, index) => {
                                                    return (
                                                        <ProjectDocumentRowCollapsed
                                                            // projDeleteInd={props.SelectedProject.DeleteIndication}
                                                            documentType={item}
                                                            documentRowData={record}
                                                            documentRowIndex={index}
                                                            handleUploadVersions={handleUploadVersions}
                                                            handleDeleteUploadVersion={handleDeleteUploadVersion}
                                                            handleDownloadDocument={handleDownloadDocument}
                                                            hendleGenerate={() => hendleGenerateIndividual(item, record, index)}
                                                            handleSwitchChange={(e, docType, rowData, rowIndex) => handleToggleChange(e, docType, rowData, rowIndex)}
                                                        />
                                                    );
                                                })
                                            }
                                        </Accordion.Body>
                                    </Accordion.Item>
                                )
                            })
                        }
                    </Accordion>
                }
            </Container>
        </>
    );

});

export default ProjectDocumentGeneration;
