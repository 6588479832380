import React, { useEffect, useState, useContext } from "react";
import { Button, Form, Modal, Row, Col } from "react-bootstrap";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import errorImage from "../../../icons/error-tick-logo.svg";

const AddParameter = (props) => {
  const MySwal = withReactContent(Swal);
  const [toggleClass, setToggleClass] = useState("mandatoryToggleLabel");
  const MasterData = props.masterData;

  const [paramObj, setParamObj] = useState({
    FieldName: "",
    FieldType: "UserAdded",
    IsSelected: true,
    IsMandatory: true,
  });

  const [error, setError] = useState({
    FieldName: {},
    IsMandatory: {},
  });

  const validateDetails = () => {
    let isError = false;
    const errorMsg = {
      FieldName: {},
      IsMandatory: {},
    };

    if (paramObj.FieldName.trim() === "") {
      paramObj.FieldName = "";
      isError = true;
      errorMsg.FieldName = {
        status: true,
        message: "Parameter Name is required.",
      };
    }

    if (paramObj.IsMandatory === "") {
      isError = true;
      errorMsg.IsMandatory = {
        status: true,
        message: "Mandatory field is required.",
      };
    }
    setError(errorMsg);
    return { isError, errorMsg };
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setParamObj((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleChangeSwitchbox = (e) => {
    if (e.target.checked) {
      setToggleClass("mandatoryToggleLabel");
    } else {
      setToggleClass("nonMandatoryToggleLabel");
    }

    const { name, value } = e.target;
    setParamObj((prevState) => ({
      ...prevState,
      [name]: e.target.checked,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let validationResult = validateDetails();
    if (!validationResult.isError) {
      if (
        MasterData.length > 0 &&
        MasterData.filter(
          (item) =>
            item.FieldName.toLowerCase() === paramObj.FieldName.toLowerCase()
        ).length > 0
      ) {
        MySwal.fire({
          text: "Parameter already exists.",
          imageUrl: errorImage,
          customClass: "swal-success",
        });
        return;
      }
      props.setUserAddedParameter(paramObj);
      props.onHide();
    }
  };

  return (
    <Modal {...props} size="lg" backdrop="static" centered>
      <Modal.Header closeButton>
        <Modal.Title>Add Parameter</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form id="addparameter" name="addparameter" autoComplete="off">
          <Row>
            <Col md={8} xs={12}>
              <Form.Group className="g-input">
                <Form.Control
                  id="FieldName"
                  type="text"
                  name="FieldName"
                  maxLength="50"
                  placeholder=" "
                  required
                  value={paramObj.FieldName}
                  onChange={handleChange}
                />
                <Form.Label className="frm-label" for="FieldName">
                  Parameter
                </Form.Label>
                {error.FieldName.status && (
                  <Form.Text className="text-danger">
                    {error.FieldName.message}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>

            <Col md={4} xs={12}>
              <Form.Switch
                className={"lblmandatory pt-2 " + toggleClass}
                label={"Mandatory"}
                name="IsMandatory"
                checked={paramObj.IsMandatory}
                onChange={(e) => {
                  handleChangeSwitchbox(e);
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Button
                variant="success"
                className="btn-add-color semiBoldToggleButton rightAlignedButton"
                onClick={handleSubmit}
              >
                Add
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default AddParameter;
