import { Button, Modal, Alert, Row, Card, Col, Spinner } from "react-bootstrap";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { BsX } from "react-icons/bs";
import { AgGridReact } from "ag-grid-react";
import { useRef, useState, useEffect, useMemo, useContext } from "react";
import { getProjectActiveTeamData, getProjectServiceEqui, getUserWiseAllProjects } from "../../services/project-service";
import { ServiceMasterData, ServiceMasterAllData, getRolesForUnmaskedData } from "../../services/common-master-service"
import StatusRenderer from "../masters/StatusRenderer.jsx";
import { getActiveRoles } from "../../services/role-service";
import GolbalSearch from "./GolbalSearch";
import { getENEActiveUsers, getHRISActiveUsers, getActiveUsersWithoutENE, GetSelectedCompanyCodeUsers } from "../../services/user-service";
import { getActiveCompanyDivisionData, getActiveCompanyWithoutENE } from "../../services/companydivision-master-service"
import { getActiveLocations } from "../../services/location-master-service"
import { getActiveEquipmentData } from "../../services/equipmentactivity-master-service.js";
import Pagination from "../utils/Pagination";
import "../masters/GridMaster.css";
import { AuthContext } from "../../contexts/AuthContext";
import { formatDateDD_MM_YYYY, formatDateInYYYYMMDD } from "../../functions/gbcs-functions";
import { addLogDetails } from "../../services/logger-entry-service";
import escapeExcelData from "../utils/customValidation";

const SingleValuePopUp = (props) => {
  const gridRef = useRef();
  const [columnDefs, setColumnDefs] = useState([]);
  const [rowData, setRowData] = useState(null);
  const [filteredCol, setFilteredCol] = useState([]);
  const [globalText, setGlobalText] = useState("");
  const [filteredResult, setFilteredResult] = useState([]);
  const [cardData, setCardData] = useState([]);
  const [selectedCard, setSelectedCard] = useState();
  const [alertMessage, setAlertMessage] = useState("");
  const context = useContext(AuthContext);
  const userDetails = { dataControl: context.dataControl, userId: context.userId, userName: context.userName, role: context.role }
  const [pageSize] = useState(3);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);

  let showAddBtn = props.hasOwnProperty("showAddBtn") ? props.showAddBtn : true;

  const getMaskingInd = async () => {
    let rolesData = await getRolesForUnmaskedData().catch((err) => { console.log(err) });
    let ismasking = true;
    if (rolesData.length > 0) {
      let showMaskedDataInd = rolesData[0].RoleCode.trim().split(",").indexOf(userDetails.role);
      if (showMaskedDataInd !== -1) {
        ismasking = false;
      }
    }
    return ismasking;
  }

  const fetchData = async () => {
    setLoading(true);
    switch (mastername) {
      case "Project Master":
        {
          const result = await getUserWiseAllProjects(userDetails, false);
          setRowData(result);
          setFilteredResult(result);
          setCardData(result);
        }
        break;
      case "Project Team Details": {
        let result = await getProjectActiveTeamData(props.additionalParams);
        setRowData(result);
        setFilteredResult(result);
        setCardData(result);
      }
        break;
      case "Service Master": {
        const result = await ServiceMasterData();
        setRowData(result);
        setFilteredResult(result);
        setCardData(result);
      }
        break;
      case "Godrej Employee Details":
        {
          const result = await getENEActiveUsers("Godrej ENE Division");
          setRowData(result);
          setFilteredResult(result);
          setCardData(result);
        }
        break;
      case "User Details without ENE":
        {
          const result = await getActiveUsersWithoutENE();
          setRowData(result);
          setFilteredResult(result);
          setCardData(result);
        }
        break;
      case "Company Master":
        {
          const result = await getActiveCompanyDivisionData();
          setRowData(result);
          setFilteredResult(result);
          setCardData(result);
        }
        break;
      case "Company Master without ENE": case "Company Division Master":
        {
          const result = await getActiveCompanyWithoutENE();
          setRowData(result);
          setFilteredResult(result);
          setCardData(result);
        }
        break;
      case "Role Master": {
        const result = await getActiveRoles();
        setRowData(result);
        setFilteredResult(result);
        setCardData(result);
      }
        break;
      case "Equipment Master": {
        let istObj = false;
        let equipObj = props.additionalParams[0];
        if (props.additionalParams.length > 1) {
          if (props.additionalParams[1].hasOwnProperty("ISTFlag"))
            istObj = props.additionalParams[1].ISTFlag;
        }
        let result = await getActiveEquipmentData(equipObj);
        let temp = [];
        if (result?.length > 0) {
          const svcData = await ServiceMasterAllData();
          for (let dt of result) {
            if (svcData?.length > 0) {
              dt.ServiceName = svcData.filter((item) => item.ServiceCode === dt.ServiceCode)[0].ServiceName;
            }
            else {
              dt.ServiceName = "";
            }
            if (dt.IntegrationSystemTest) {
              temp.push(dt);
            }
          }
        }
        if (istObj) {
          result = temp;
        }
        setRowData(result);
        setFilteredResult(result);
        setCardData(result);
      }
        break;
      case "User Details": {
        //const result = props.additionalParams.compCode;
        const result = await GetSelectedCompanyCodeUsers(props.additionalParams.compCode);
        setRowData(result);
        setFilteredResult(result);
        setCardData(result);
      }
        break;
      case "Customer User Details1": case "Customer User Details2": {
        const result = await GetSelectedCompanyCodeUsers(props.additionalParams);
        setRowData(result);
        setFilteredResult(result);
        setCardData(result);
      }
        break;
      case "Location Master": {
        const result = await getActiveLocations(props.additionalParams);
        setRowData(result);
        setFilteredResult(result);
        setCardData(result);
      }
        break;
      case "HRIS Details": {
        const result = await getHRISActiveUsers();
        setRowData(result);
        setFilteredResult(result);
        setCardData(result);
      }
        break;
      case "Project Equipment Details": {
        let ProjectCode = props.additionalParams.ProjectCode
        const result = await getProjectServiceEqui(ProjectCode);
        let result1 = [];
        if (result?.length > 0) {
          let activeTags = result.filter(item => item.DeleteIndication === "Active");
          result1 = [...new Map(activeTags.map(item =>
            [item.EquipmentCode, item])).values()];
        }
        setRowData(result1);
        setFilteredResult(result1);
        setCardData(result1);
      }
        break;
      case "GDPR User Master": case "GDPR Company / Division Master": case "GDPR Project Master":
      case "GDPR User Master Log": case "GDPR Project Master Log":  //case "GDPR Company / Division Master Log":
        {
          const result = props.additionalParams.data;
          setRowData(result);
          setFilteredResult(result);
          setCardData(result);
        }
        break;
    }
    setLoading(false);
  };
  let arr = props.mastername.split(":");
  let mastername = arr[0];

  const generateColumnDefs = async () => {
    switch (mastername) {
      case "Project Master":
        {
          let colDef = [
            {
              field: "",
              width: 25,
              cellRenderer: radioCellRenderer,
            },
            {
              field: "DeleteIndication",
              headerName: "Status",
              tooltipField: "DeleteIndication",
              cellRenderer: StatusRenderer,
              minWidth: 150
            },
            {
              field: "ProjectCode",
              headerName: "Project Code",
              tooltipField: "ProjectCode",
              minWidth: 150,
            },
            {
              field: "ProjectName",
              headerName: "Project Name",
              tooltipField: "ProjectName",
              minWidth: 150,
            },
            {
              field: "LocationType",
              headerName: "Location Type",
              tooltipField: "LocationType",
              minWidth: 150,
            },
            {
              field: "Location",
              headerName: "Location",
              tooltipField: "Location",
              minWidth: 150
            },
            {
              field: "Country",
              headerName: "Country",
              tooltipField: "Country",
              minWidth: 150
            },
            {
              field: "Sector",
              headerName: "Sector",
              tooltipField: "Sector",
              minWidth: 150
            },
            {
              field: "CustomerName",
              headerName: "Customer Name",
              tooltipField: "CustomerName",
              minWidth: 150
            }
          ];
          setColumnDefs(colDef);
        }
        break;
      case "Project Team Details":
        {
          let colDef = [
            {
              field: "",
              width: 25,
              cellRenderer: radioCellRenderer,
            },
            {
              field: "UserName",
              headerName: "User Name",
              tooltipField: "UserName",
            },
            {
              field: "UserId",
              headerName: "User ID",
              tooltipField: "UserId"
            }
          ];
          setColumnDefs(colDef);
        }
        break;
      case "Godrej Employee Details": case "User Details without ENE":
        {
          let colDef = [
            {
              field: "",
              width: 25,
              cellRenderer: radioCellRenderer,
            },
            {
              field: "UserName",
              headerName: "User Name",
              tooltipField: "UserName",
            },
            {
              field: "EmailId",
              headerName: "Email Id",
              tooltipValueGetter: (params) => escapeExcelData(params.data.EmailId, true),
              valueFormatter: (params) => {
                return escapeExcelData(params.data.EmailId, true);
              },
            },
            {
              field: "MobileNo",
              headerName: "Mobile No",
              tooltipValueGetter: (params) => escapeExcelData(params.data.MobileNo, true),
              valueFormatter: (params) => {
                return escapeExcelData(params.data.MobileNo, true);
              },
            }
          ];
          setColumnDefs(colDef);
        }
        break;
      case "User Details": case "Customer User Details1": case "Customer User Details2":
        {
          let colDef = [
            {
              field: "",
              width: 25,
              cellRenderer: radioCellRenderer,
            },
            {
              field: "UserName",
              headerName: "User Name",
              tooltipField: "UserName",
            },
            {
              field: "EmailId",
              headerName: "Email Id",
              tooltipValueGetter: (params) => escapeExcelData(params.data.EmailId, true),
              valueFormatter: (params) => {
                return escapeExcelData(params.data.EmailId, true);
              },
            },
            {
              field: "MobileNo",
              headerName: "Mobile No",
              tooltipValueGetter: (params) => escapeExcelData(params.data.MobileNo, true),
              valueFormatter: (params) => {
                return escapeExcelData(params.data.MobileNo, true);
              },
            },
            {
              field: "CompanyCode",
              headerName: "Emp Company Code",
              tooltipField: "CompanyCode",
            }
          ];
          setColumnDefs(colDef);
        }
        break;
      case "Location Master":
        {
          let colDef = [
            {
              field: "",
              width: 25,
              cellRenderer: radioCellRenderer,
            },
            {
              field: "Location",
              headerName: "Location",
              tooltipField: "Location",
            },
            {
              field: "Country",
              headerName: "Country",
              tooltipField: "Country",
            },
            {
              field: "LocationType",
              headerName: "Location Type",
              tooltipField: "LocationType",
            }
          ];
          setColumnDefs(colDef);
        }
        break;
      case "Role Master":
        {
          let colDef = [
            {
              field: "",
              width: 25,
              cellRenderer: radioCellRenderer,
            },
            {
              field: "RoleCode",
              headerName: "Role Code",
              tooltipField: "RoleCode",
            },
            {
              field: "RoleName",
              headerName: "Role Name",
              tooltipField: "RoleName",
            },
          ];
          setColumnDefs(colDef);
        }
        break;
      case "Company Master": case "Company Division Master": case "Company Master without ENE":
        {
          let colDef = [
            {
              field: "",
              width: 25,
              cellRenderer: radioCellRenderer,
            },
            {
              field: "CompanyName",
              headerName: "Company Name",
              tooltipField: "CompanyName",
            },
            {
              field: "CompanyCode",
              headerName: "Company Code",
              tooltipField: "CompanyCode",
            },
            {
              field: "CompanyTelephoneNo",
              headerName: "Telephone No",
              tooltipValueGetter: (params) => escapeExcelData(params.data.CompanyTelephoneNo, true),
              valueFormatter: (params) => {
                return escapeExcelData(params.data.CompanyTelephoneNo, true);
              },
            }
          ];
          setColumnDefs(colDef);
        }
        break;
      case "Service Master":
        {
          let colDef = [
            {
              field: "",
              width: 25,
              cellRenderer: radioCellRenderer,
            },
            {
              field: "ServiceName",
              headerName: "Service Name",
              tooltipField: "ServiceName",
            },
            {
              field: "ServiceCode",
              headerName: "Service Code",
              tooltipField: "ServiceCode",
            }
          ];
          setColumnDefs(colDef);
        }
        break;
      case "Equipment Master":
        {
          let colDef = [
            {
              field: "",
              width: 25,
              cellRenderer: radioCellRenderer,
            },
            {
              field: "EquipmentName",
              headerName: "Equipment Name",
              tooltipField: "EquipmentName",
            },
            {
              field: "EquipmentCode",
              headerName: "Equipment Code",
              tooltipField: "EquipmentCode",
            },
            {
              field: "ServiceName",
              headerName: "Service Name",
              tooltipField: "ServiceName",
            }
          ];
          setColumnDefs(colDef);
        }
        break;
      case "HRIS Details":
        {
          let colDef = [
            {
              field: "",
              width: 25,
              cellRenderer: radioCellRenderer,
            },
            {
              field: "EmpEmailId",
              headerName: "Employee User Id",
              tooltipField: "EmpEmailId"
            },
            {
              field: "EmpShortName",
              headerName: "Employee Name",
              tooltipField: "EmpShortName",
            },
            {
              field: "EmpMobileNo",
              headerName: "Employee Mobile No",
              tooltipValueGetter: (params) => escapeExcelData(params.data.EmpMobileNo, true),
              valueFormatter: (params) => {
                return escapeExcelData(params.data.EmpMobileNo, true);
              },
            }
          ];
          setColumnDefs(colDef);
        }
        break;
      case "Project Equipment Details":
        {
          let colDef = [
            {
              field: "",
              width: 25,
              cellRenderer: radioCellRenderer,
            },
            {
              field: "EquipmentName",
              headerName: "Equipment Name",
              tooltipField: "EquipmentName",
            },
            {
              field: "EquipmentCode",
              headerName: "Equipment Code",
              tooltipField: "EquipmentCode",
            }
          ];
          setColumnDefs(colDef);
        }
        break;
      case "GDPR User Master":
        {
          let isToMaskData = await getMaskingInd();
          let colDef = [
            {
              field: "UserId",
              headerName: "User Id",
              tooltipField: "UserId",
            },
            {
              field: "UserName",
              headerName: "User Name",
              tooltipField: "UserName",
            },
            {
              field: "RoleName",
              headerName: "User Role",
              tooltipField: "RoleName",
            },
            {
              field: "EmailId",
              headerName: "Email Id",
              tooltipValueGetter: (params) => escapeExcelData(params.data.EmailId, isToMaskData),
              valueFormatter: (params) => {
                return escapeExcelData(params.data.EmailId, isToMaskData);
              },
            },
            {
              field: "MobileNo",
              headerName: "Mobile No",
              tooltipValueGetter: (params) => escapeExcelData(params.data.MobileNo, isToMaskData),
              valueFormatter: (params) => {
                return escapeExcelData(params.data.MobileNo, isToMaskData);
              },
            }
          ];
          setColumnDefs(colDef);
        }
        break;
      case "GDPR Company / Division Master":
        {
          let isToMaskData = await getMaskingInd();
          let colDef = [
            {
              field: "CompanyCode",
              headerName: "Company Code",
              tooltipField: "CompanyCode",
            },
            {
              field: "CompanyName",
              headerName: "Company Name",
              tooltipField: "CompanyName",
            },
            {
              field: "CompanyLocationType",
              headerName: "Location Type",
              tooltipField: "CompanyLocationType",
            },
            {
              field: "CompanySectorName",
              headerName: "Sector",
              tooltipField: "CompanySectorName",
            },
            {
              field: "CompanyTelephoneNo",
              headerName: "Telephone No",
              tooltipField: "CompanyTelephoneNo",
              tooltipValueGetter: (params) => escapeExcelData(params.data.CompanyTelephoneNo, isToMaskData),
              valueFormatter: (params) => {
                return escapeExcelData(params.data.CompanyTelephoneNo, isToMaskData);
              },
            }
          ];
          setColumnDefs(colDef);
        }
        break;
      case "GDPR Project Master":
        {
          let isToMaskData = await getMaskingInd();
          let colDef = [
            {
              field: "DeleteIndication",
              headerName: "Status",
              tooltipField: "DeleteIndication",
              cellRenderer: StatusRenderer,
              minWidth: 150
            },
            {
              field: "ProjectCode",
              headerName: "Project Code",
              tooltipField: "ProjectCode",
              minWidth: 150,
            },
            {
              field: "ProjectName",
              headerName: "Project Name",
              tooltipField: "ProjectName",
              minWidth: 150,
            },
            {
              field: "Location",
              headerName: "Location",
              tooltipField: "Location",
              minWidth: 150
            },
            {
              field: "UserName",
              headerName: "User Name",
              tooltipField: "UserName",
              minWidth: 150
            },
            {
              field: "MobileNo",
              headerName: "Mobile No",
              tooltipField: "MobileNo",
              tooltipValueGetter: (params) => escapeExcelData(params.data.MobileNo, isToMaskData),
              valueFormatter: (params) => {
                return escapeExcelData(params.data.MobileNo, isToMaskData);
              },
            },
            {
              field: "EmailId",
              headerName: "Email Id",
              tooltipField: "EmailId",
              minWidth: 150,
              tooltipValueGetter: (params) => escapeExcelData(params.data.EmailId, isToMaskData),
              valueFormatter: (params) => {
                return escapeExcelData(params.data.EmailId, isToMaskData);
              },
            }
          ];
          setColumnDefs(colDef);
        }
        break;
      case "GDPR User Master Log":
        {
          let isToMaskData = await getMaskingInd();
          let colDef = [
            {
              field: "UserId",
              headerName: "User Id",
              tooltipField: "UserId",
            },
            {
              field: "UserName",
              headerName: "User Name",
              tooltipField: "UserName",
            },
            {
              field: "RoleName",
              headerName: "User Role",
              tooltipField: "RoleName",
            },
            {
              field: "EmailId",
              headerName: "Email Id",
              tooltipValueGetter: (params) => escapeExcelData(params.data.EmailId, isToMaskData),
              valueFormatter: (params) => {
                return escapeExcelData(params.data.EmailId, isToMaskData);
              },
            },
            {
              field: "MobileNo",
              headerName: "Mobile No",
              tooltipValueGetter: (params) => escapeExcelData(params.data.MobileNo, isToMaskData),
              valueFormatter: (params) => {
                return escapeExcelData(params.data.MobileNo, isToMaskData);
              },
            },
            {
              field: "LoggedBy",
              headerName: "Logged By",
              tooltipField: "LoggedBy",
            },
            {
              field: "LoggedOn",
              headerName: "Logged On",
              tooltipField: "LoggedOn",
              filter: "agDateColumnFilter",
              valueFormatter: (params) => {
                return formatDateDD_MM_YYYY(params.data.LoggedOn);
              },
              filterParams: {
                comparator: (filterLocalDateAtMidnight, cellValue) => {
                  const dateAsString = cellValue;
                  if (dateAsString == null) {
                    return 0;
                  }
                  // In the example application, dates are stored as dd/mm/yyyy
                  // We create a Date object for comparison against the filter date
                  const dateCell = new Date(formatDateInYYYYMMDD(cellValue) + " 00:00:00");

                  if (dateCell < filterLocalDateAtMidnight) {
                    return -1;
                  } else if (dateCell > filterLocalDateAtMidnight) {
                    return 1;
                  }
                  return 0;
                },
              },
            },
            {
              field: "Action",
              headerName: "Action Taken",
              tooltipField: "Action",
            }
          ];
          setColumnDefs(colDef);
        }
        break;
      case "GDPR Project Master Log":
        {
          let isToMaskData = await getMaskingInd();
          let colDef = [
            {
              field: "DeleteIndication",
              headerName: "Status",
              tooltipField: "DeleteIndication",
              cellRenderer: StatusRenderer,
              minWidth: 150
            },
            {
              field: "ProjectCode",
              headerName: "Project Code",
              tooltipField: "ProjectCode",
              minWidth: 150,
            },
            {
              field: "ProjectName",
              headerName: "Project Name",
              tooltipField: "ProjectName",
              minWidth: 150,
            },
            {
              field: "Location",
              headerName: "Location",
              tooltipField: "Location",
              minWidth: 150
            },
            {
              field: "UserName",
              headerName: "User Name",
              tooltipField: "UserName",
              minWidth: 150
            },
            {
              field: "MobileNo",
              headerName: "Mobile No",
              tooltipValueGetter: (params) => escapeExcelData(params.data.MobileNo, isToMaskData),
              valueFormatter: (params) => {
                return escapeExcelData(params.data.MobileNo, isToMaskData);
              },
              minWidth: 150
            },
            {
              field: "EmailId",
              headerName: "Email Id",
              tooltipValueGetter: (params) => escapeExcelData(params.data.EmailId, isToMaskData),
              valueFormatter: (params) => {
                return escapeExcelData(params.data.EmailId, isToMaskData);
              },
              minWidth: 150
            },
            {
              field: "LoggedBy",
              headerName: "Logged By",
              tooltipField: "LoggedBy",
            },
            {
              field: "LoggedOn",
              headerName: "Logged On",
              tooltipField: "LoggedOn",
              valueFormatter: (params) => {
                return formatDateDD_MM_YYYY(params.data.LoggedOn);
              },
              filter: "agDateColumnFilter",
              filterParams: {
                comparator: (filterLocalDateAtMidnight, cellValue) => {
                  const dateAsString = cellValue;
                  if (dateAsString == null) {
                    return 0;
                  }
                  // In the example application, dates are stored as dd/mm/yyyy
                  // We create a Date object for comparison against the filter date
                  const dateCell = new Date(formatDateInYYYYMMDD(cellValue) + " 00:00:00");

                  if (dateCell < filterLocalDateAtMidnight) {
                    return -1;
                  } else if (dateCell > filterLocalDateAtMidnight) {
                    return 1;
                  }
                  return 0;
                },
              },
            },
            {
              field: "Action",
              headerName: "Action Taken",
              tooltipField: "Action",
            }
          ];
          setColumnDefs(colDef);
        }
        break;
    }
    fetchData();
  }

  useEffect(() => {
    generateColumnDefs();
  }, []);

  const radioCellRenderer = (params) => {
    let cellValue = (
      <div className="radio-item">
        <input
          name="selection"
          type="radio"
          id={`selection${params.node.rowIndex}`}
          defaultChecked={
            params.data[props.paramkey] === props.paramvalue
              ? true
              : false
          }
        ></input>
      </div>
    );
    params.data[props.paramkey] === props.paramvalue
      ? params.node.setSelected(true)
      : params.node.setSelected(false);
    return cellValue;
  };

  const defaultColDef = {
    resizable: true,
    suppressMovable: true,
    filter: true,
    sortable: true,
    filterParams: {
      buttons: ["reset", "apply"],
      closeOnApply: true,
      defaultJoinOperator: "OR",
    },
  };

  const assignData = async () => {
    let selectedRows = gridRef.current.api.getSelectedRows();
    let selectedRowsCount = gridRef.current.api.getSelectedRows().length;
    if (selectedRowsCount > 0) {
      switch (mastername) {
        case "Godrej Employee Details": case "User Details without ENE":
        case "User Details": case "Customer User Details1": case "Customer User Details2":
        case "Company Master": case "Company Division Master": case "Company Master without ENE":
        case "HRIS Details":
          {
            await addLogDetails(selectedRows[0], "View", "SK#" + selectedRows[0].SK, "SinglePopUpLog", userDetails, "Master");
          }
          break;
      }
      props.setSelected({ name: props.mastername, result: selectedRows[0], key: props.paramvalue, value: props.additionalParams });
      props.onHide();
    } else
      setAlertMessage(
        "Please select a record before clicking on Assign button."
      );
  };

  const assignCustomData = async () => {
    let selectedRows = selectedCard;
    if (selectedRows !== undefined) {
      switch (mastername) {
        case "Godrej Employee Details": case "User Details without ENE":
        case "User Details": case "Customer User Details1": case "Customer User Details2":
        case "Company Master": case "Company Division Master": case "Company Master without ENE":
        case "HRIS Details":
          {
            await addLogDetails(selectedRows, "View", "SK#" + selectedRows.SK, "SinglePopUpLog", userDetails, "Master");
          }
          break;
      }
      props.setSelected({ name: props.mastername, result: selectedRows, key: props.paramvalue, value: props.additionalParams });
      props.onHide();
    }
    else {
      setAlertMessage(
        "Please select a record before clicking on Assign button."
      );
    }
  };

  const onGridReady = () => {
    gridRef.current.api.sizeColumnsToFit();
  };

  const handleRadioSelection = () => {
    if (showAddBtn) {
      let nodeEl = gridRef.current.api.getSelectedNodes();
      let node = document.getElementById(`selection${nodeEl[0].rowIndex}`);
      node.checked = true;
    }
  };

  const handleGlobalSearch = (text) => {
    let searchText = text.toLowerCase();
    setGlobalText(searchText);
    let arr = props.mastername.split(":");
    let mastername = arr[0];
    if (searchText) {
      let result = rowData ? rowData.filter((item) => {
        switch (mastername) {
          case "Project Master":
            return (
              item.DeleteIndication.toLowerCase().toString().indexOf(searchText) >= 0 ||
              item.ProjectCode.toLowerCase().toString().indexOf(searchText) >= 0 ||
              item.ProjectName.toLowerCase().toString().indexOf(searchText) >= 0 ||
              item.LocationType.toLowerCase().toString().indexOf(searchText) >= 0 ||
              item.Location.toLowerCase().toString().indexOf(searchText) >= 0 ||
              item.Country.toLowerCase().toString().indexOf(searchText) >= 0 ||
              item.Sector.toLowerCase().toString().indexOf(searchText) >= 0 ||
              item.CustomerName.toLowerCase().toString().indexOf(searchText) >= 0
            );
          case "Project Team Details":
            return (
              item.UserName.toLowerCase().toString().indexOf(searchText) >= 0 ||
              item.UserId.toLowerCase().indexOf(searchText) >= 0
            );
          case "Role Master":
            return (
              item.RoleCode.toString().toLowerCase().indexOf(searchText) >= 0 ||
              item.RoleName.toLowerCase().indexOf(searchText) >= 0
            );
          case "Godrej Employee Details": case "User Details": case "Customer User Details1": case "Customer User Details2": case "User Details without ENE":
            return (
              item.UserName.toString().toLowerCase().indexOf(searchText) >= 0 ||
              item.EmailId.toString().toLowerCase().indexOf(searchText) >= 0 ||
              item.MobileNo.toString().toLowerCase().indexOf(searchText) >= 0
            );
          case "Location Master":
            return (
              item.Location.toString().toLowerCase().indexOf(searchText) >= 0 ||
              item.Country.toString().toLowerCase().indexOf(searchText) >= 0 ||
              item.LocationType.toString().toLowerCase().indexOf(searchText) >= 0
            );
          case "Company Master": case "Company Division Master": case "Company Master without ENE":
            return (
              item.CompanyName.toString().toLowerCase().indexOf(searchText) >= 0 ||
              item.CompanyCode.toString().toLowerCase().indexOf(searchText) >= 0 ||
              item.CompanyTelephoneNo.toString().toLowerCase().indexOf(searchText) >= 0
            );
          case "Equipment Master":
            return (
              item.EquipmentName.toString().toLowerCase().indexOf(searchText) >= 0 ||
              item.EquipmentCode.toString().toLowerCase().indexOf(searchText) >= 0
            );
          case "Service Master":
            return (
              item.ServiceName.toLowerCase().toString().indexOf(searchText) >= 0 ||
              item.ServiceCode.toLowerCase().toString().indexOf(searchText) >= 0
            );
          case "HRIS Details":
            return (
              item.EmpEmailId.toString().toLowerCase().indexOf(searchText) >= 0 ||
              item.EmpShortName.toString().toLowerCase().indexOf(searchText) >= 0 ||
              item.EmpMobileNo.toString().indexOf(searchText) >= 0
            );
          case "Project Equipment Details":
            return (
              item.EquipmentName.toString().toLowerCase().indexOf(searchText) >= 0 ||
              item.EquipmentCode.toString().toLowerCase().indexOf(searchText) >= 0
            );
          case "GDPR User Master": case "GDPR User Master Log":
            return (
              item.UserId?.toString().toLowerCase().indexOf(searchText) >= 0 ||
              item.UserName?.toString().toLowerCase().indexOf(searchText) >= 0 ||
              item.RoleName?.toString().toLowerCase().indexOf(searchText) >= 0 ||
              item.EmailId?.toString().toLowerCase().indexOf(searchText) >= 0 ||
              item.MobileNo?.toString().toLowerCase().indexOf(searchText) >= 0 ||
              item.LoggedBy?.toString().toLowerCase().indexOf(searchText) >= 0 ||
              item.LoggedOn?.toString().toLowerCase().indexOf(searchText) >= 0 ||
              item.Action?.toString().toLowerCase().indexOf(searchText) >= 0
            );
          case "GDPR Company / Division Master": //case "GDPR Company / Division Master Log":
            //console.log("item ==> ", item)
            return (
              item.CompanyCode?.toString().toLowerCase().indexOf(searchText) >= 0 ||
              item.CompanyName?.toString().toLowerCase().indexOf(searchText) >= 0 ||
              item.CompanyLocationType?.toString().toLowerCase().indexOf(searchText) >= 0 ||
              item.CompanySectorName?.toString().toLowerCase().indexOf(searchText) >= 0 ||
              item.CompanyTelephoneNo?.toString().toLowerCase().indexOf(searchText) >= 0 //||
              //item.TelephoneNo?.toString().toLowerCase().indexOf(searchText) >= 0  //||
              //item.LoggedBy?.toString().toLowerCase().indexOf(searchText) >= 0 ||
              //item.LoggedOn?.toString().toLowerCase().indexOf(searchText) >= 0 ||
              //item.Action?.toString().toLowerCase().indexOf(searchText) >= 0 
            );
          case "GDPR Project Master": case "GDPR Project Master Log":
            return (
              item.DeleteIndication?.toLowerCase().toString().indexOf(searchText) >= 0 ||
              item.ProjectCode?.toLowerCase().toString().indexOf(searchText) >= 0 ||
              item.ProjectName?.toLowerCase().toString().indexOf(searchText) >= 0 ||
              item.Location?.toLowerCase().toString().indexOf(searchText) >= 0 ||
              item.UserName?.toLowerCase().toString().indexOf(searchText) >= 0 ||
              item.MobileNo?.toLowerCase().toString().indexOf(searchText) >= 0 ||
              item.EmailId?.toLowerCase().toString().indexOf(searchText) >= 0 ||
              item.LoggedBy?.toString().toLowerCase().indexOf(searchText) >= 0 ||
              item.LoggedOn?.toString().toLowerCase().indexOf(searchText) >= 0 ||
              item.Action?.toString().toLowerCase().indexOf(searchText) >= 0
            );

        }
      }) : null;
      setFilteredResult(result);
      setCardData(result);
    } else {
      setFilteredResult(rowData);
      setCardData(rowData);
    }
  };

  const resetAllFilter = () => {
    gridRef.current.api.setFilterModel(null);
  };

  function resetFilter(item) {
    let item1 = item.substring(0, item.indexOf(":") - 1);

    let col = gridRef.current.api.getFilterModel();
    for (const key of Object.keys(col)) {
      if (key === feildName(item1)) {
        delete col[key];
      }
    }
    gridRef.current.api.setFilterModel(col);
  }

  const headerName = (field) => {
    let name;
    switch (field) {
      //Project Master
      case "DeleteIndication":
        name = "Status";
        break
      case "ProjectCode":
        name = "Project Code";
        break;
      case "ProjectName":
        name = "Project Name";
        break;
      case "LocationType":
        name = "Location Type";
        break;
      case "Location":
        name = "Location";
        break
      case "Country":
        name = "Country";
        break;
      case "Sector":
        name = "Sector";
        break;
      case "CustomerName":
        name = "Customer Name";
        break;
      //Service Master
      case "ServiceName":
        name = "Service Name";
        break
      case "ServiceCode":
        name = "Service Code "
        break
      case "RoleCode":
        name = "Role Code";
        break;
      case "RoleName":
        name = "Role Name";
        break;
      case "EmpEmailId":
        name = "Employee User Id";
        break;
      case "EmailId":
        name = "Email Id";
        break;
      case "EmpShortName":
        name = "Employee Name";
        break;
      case "EmpMobileNo":
        name = "Employee Mobile No";
        break;
      case "MobileNo":
        name = "Mobile No";
        break;
      case "CompanyName":
        name = "Company Name";
        break;
      case "CompanyCode":
        name = "Company Code";
        break;
      case "CompanyTelephoneNo":
        name = "Telephone No";
        break;
      case "UserName":
        name = "User Name";
        break;
      case "UserId":
        name = "User ID";
        break;
      case "LoggedBy":
        name = "Logged By";
        break;
      case "LoggedOn":
        name = "Logged On";
        break;
      case "Action":
        name = "Action Taken";
        break;
      case "CompanyLocationType":
        name = "Location Type";
        break;
      case "CompanySectorName":
        name = "Sector";
        break;
      default:
        name = "No match found";
        break;
    }
    return name;
  };

  const feildName = (header) => {
    let name;
    switch (header) {
      // Project Master
      case "DeleteIndication":
        name = "Status";
        break
      case "ProjectCode":
        name = "Project Code";
        break;
      case "ProjectName":
        name = "Project Name";
        break;
      case "LocationType":
        name = "Location Type";
        break;
      case "Location":
        name = "Location";
        break;
      case "Country":
        name = "Country";
        break;
      case "Sector":
        name = "Sector";
        break;
      case "CustomerName":
        name = "Customer Name";
        break;
      //Service Master
      case "ServiceName":
        name = "Service Name";
        break
      case "ServiceCode":
        name = "Service Code "
        break
      case "Role Code":
        name = "RoleCode";
        break;
      case "Role Name":
        name = "RoleName";
        break;
      case "Employee User Id":
        name = "EmpEmailId";
        break;
      case "Email Id":
        name = "EmailId";
        break;
      case "Employee Name":
        name = "EmpShortName";
        break;
      case "Employee Mobile No":
        name = "EmpMobileNo";
        break;
      case "Mobile No":
        name = "MobileNo";
        break;
      case "Company Name":
        name = "CompanyName";
        break;
      case "Company Code":
        name = "CompanyCode";
        break;
      case "Telephone No":
        name = "CompanyTelephoneNo";
        break;
      case "User Name":
        name = "UserName";
        break;
      case "User ID":
        name = "UserId";
        break;
      case "Logged By":
        name = "LoggedBy";
        break;
      case "Logged On":
        name = "LoggedOn";
        break;
      case "Action Taken":
        name = "Action";
        break;
      case "Location Type":
        name = "CompanyLocationType";
        break;
      case "Sector":
        name = "CompanySectorName";
        break;
      default:
        name = "No match found";
        break;
    }
    return name;
  };

  const onFilterChanged = (param) => {
    let a = document.getElementById("filterDisplayPop");
    setFilteredCol([]);
    let col = param.api.getFilterModel();

    if (Object.keys(col).length === 0) {
      a.classList.add("d-none");
    } else {
      a.classList.remove("d-none");
      for (const key of Object.keys(col)) {
        let filter;
        if (col[key].filterType === "date") {
          if (col[key].condition1) {
            let fromDate1 = col[key].condition1.dateFrom
              ? formatDateDD_MM_YYYY(col[key].condition1.dateFrom)
              : col[key].condition1.dateFrom;
            let toDate1 = col[key].condition1.dateTo
              ? formatDateDD_MM_YYYY(col[key].condition1.dateTo)
              : col[key].condition1.dateTo;
            let fromDate2 = col[key].condition2.dateFrom
              ? formatDateDD_MM_YYYY(col[key].condition2.dateFrom)
              : col[key].condition2.dateFrom;
            let toDate2 = col[key].condition2.dateTo
              ? formatDateDD_MM_YYYY(col[key].condition2.dateTo)
              : col[key].condition2.dateTo;
            filter =
              headerName(key) +
              " : " +
              fromDate1 +
              (toDate1 ? " To " + toDate1 : "") +
              ", " +
              fromDate2 +
              (toDate2 ? " To " + toDate2 : "");
            console.log("true date");
          } else {
            filter =
              headerName(key) +
              " : " +
              col[key].dateFrom?.substring(0, 10) +
              (col[key].dateTo ? " To " + col[key].dateTo?.substring(0, 10) : "");
            console.log("false date", col[key].dateFrom?.substring(0, 10));
          }
        }
        else if (col[key].filterType === "number") {
          if (col[key].condition1) {
            filter =
              headerName(key) +
              " : " +
              col[key].condition1.filter +
              (col[key].condition1.filterTo
                ? " To " + col[key].condition1.filterTo
                : "") +
              ", " +
              col[key].condition2.filter +
              (col[key].condition2.filterTo
                ? " To " + col[key].condition2.filterTo
                : "");
          } else {
            filter =
              headerName(key) +
              " : " +
              col[key].filter +
              (col[key].filterTo ? " To " + col[key].filterTo : "");
          }
        }
        else {
          if (col[key].condition1) {
            filter =
              headerName(key) +
              " : " +
              col[key].condition1.filter +
              ", " +
              col[key].condition2.filter;
          } else {
            filter = headerName(key) + " : " + col[key].filter;
          }
        }
        setFilteredCol((prev) => {
          return [...prev, filter];
        });
      }
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setAlertMessage("");
    }, 10000);
    return () => clearTimeout(timer);
  }, [alertMessage]);

  const filteredResult1 = useMemo(() => {
    let firstPageIndex = 0;
    if (currentPage > 1) {
      firstPageIndex = (currentPage - 1) * pageSize;
    }
    if (firstPageIndex >= cardData.length) {
      firstPageIndex = 0;
      const totalPageCount = Math.ceil(cardData.length / pageSize);
      if (totalPageCount > 0) {
        setCurrentPage(totalPageCount)
      } else {
        setCurrentPage(1)
      }
    }
    let lastPageIndex = parseInt(firstPageIndex) + parseInt(pageSize);
    return cardData ? cardData.slice(firstPageIndex, lastPageIndex) : [];
  }, [currentPage, cardData, pageSize]);

  return (
    <>
      {loading && <div className="spinner-box"> <Spinner animation="border" className="spinner" /></div>}
      <Modal {...props} size="lg" backdrop="static" centered>
        <Modal.Header closeButton>
          <Modal.Title style={{ fontFamily: "G&B-BodyCopy" }}>
            {props.mastername.split(":")[0] === "Customer User Details1"
              ? "Customer User Details"
              : props.mastername.split(":")[0] === "Customer User Details2"
                ? "Customer User Details"
                : props.mastername.split(":")[0] === "Company Master without ENE"
                  ? "Company Master"
                  : props.mastername.split(":")[0] === "User Details without ENE"
                    ? "User Details"
                    : props.additionalParams?.popupTopMasterName
                      ? props.additionalParams.popupTopMasterName
                      : props.mastername.split(":")[0]}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <Row>
              <div className="global-search-container">
                <GolbalSearch filterFunc={handleGlobalSearch} />
              </div>
              <div
                className="d-none mt-3 d-flex"
                id="filterDisplayPop"
                style={{ gap: "8px" }}
              >
                {filteredCol &&
                  filteredCol.map((item, index) => {
                    return (
                      <div className="filter-col pr-0  me-2" key={index}>
                        <span className="ps-2 btn-text">{item}</span>
                        <div onClick={() => resetFilter(item)}>
                          <BsX size={32} />
                        </div>
                      </div>
                    );
                  })}
                <Button
                  variant="outline-primary"
                  className="ms-2 lh-1 clear-all-btn"
                  onClick={resetAllFilter}
                >
                  Clear All
                </Button>
              </div>
            </Row>
            <div className="ag-theme-alpine d-none d-md-block d-lg-block">
              <AgGridReact
                ref={gridRef}
                rowData={filteredResult}
                columnDefs={columnDefs}
                rowSelection={"single"}
                defaultColDef={defaultColDef}
                onGridReady={onGridReady}
                headerHeight={40}
                rowHeight={40}
                pagination={true}
                paginationPageSize={5}
                onSelectionChanged={handleRadioSelection}
                onFilterChanged={onFilterChanged}
                enableBrowserTooltips={true}
                domLayout="autoHeight"
              ></AgGridReact>
            </div>
            <div className="market-wrapper d-md-none d-lg-none table-container1">
              {
                filteredResult1.map((selection, index) =>
                  <Card key={index} className="cardContainer">
                    <Card.Body className="cardbodycontainerResponsive">
                      {
                        showAddBtn &&
                        <Col> <div className="radio-item">
                          <input
                            name="selection"
                            type="radio"
                            onChange={() => setSelectedCard(selection)}
                            id={`selection${index}`}
                          ></input>
                        </div></Col>
                      }
                      {columnDefs.map((header) => {
                        let formattedValue = header.field && header.field !== "" && header.valueFormatter ? header.valueFormatter({ "data": selection, "colDef": { field: header.field }, "node": { "rowIndex": index }, "value": selection[header.field] }) : selection[header.field];
                        let displayValue = header.field && header.field !== "" && header.cellRenderer ? header.cellRenderer({ "data": selection, "colDef": { field: header.field }, "node": { "rowIndex": index }, "value": selection[header.field] }) : formattedValue;
                        return (header.field && header.field !== "" &&
                          <Row key={header.field + "" + index} className="responsive-row">
                            <Col className="colHeader">{header.headerName}</Col>
                            <Col className="colData">{displayValue}</Col>
                          </Row>
                        )
                      }
                      )}
                    </Card.Body>
                  </Card>
                )}
              <Pagination
                currentPage={currentPage}
                totalCount={cardData ? cardData.length : 0}
                data={cardData}
                pageSize={pageSize}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </div>
          </div>
        </Modal.Body>
        {alertMessage && (
          <Alert variant="light">
            {alertMessage}
          </Alert>
        )}

        <Modal.Footer>
          {
            showAddBtn &&
            <>
              <Button variant="success"
                className="float-end me-2 d-none d-md-block d-lg-block greenButton"
                onClick={assignData}
              >
                Add
              </Button>
              <Button variant="success"
                className="float-end me-2 d-md-none d-lg-none greenButton"
                onClick={assignCustomData}
              >
                Assign
              </Button>
              {/* <Button
            size="sm"
            className="float-start me-2"
            variant="outline-primary"
            onClick={() => props.onHide()}
          >
            Cancel
          </Button> */}
            </>
          }
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SingleValuePopUp;
