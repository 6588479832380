import axios from "axios";
const TRANS_URL = process.env.REACT_APP_TRANSACTION_API_URL;

//================ Get Site Observation Report data by Project Code ==============
export async function getSiteObservationReportData(ProjectCode) {
  let url = `${TRANS_URL}/siteobservationreport/getSiteObservationReportData`;
  let reqBody = { ProjectCode }
  const response = await axios
    .post(url, reqBody)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      console.log("error => ", error);
      return { data: [] };
    });
  return response.data;
}

//====================== Delete site observation record =============
export const deleteSiteObservationRecord = async (delBody) => {
  let url = `${TRANS_URL}/siteobservationreport/deleteSiteObservationRecord`;
  const response = await axios
    .post(url, delBody)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      console.log("error => ", error);
      return { data: { message: "Unable to find request", status: false } };
    });
  return response.data;
};


//======================Add new DRT record ==========================
export const addSiteObservationReport = async (sor) => {
  let url = `${TRANS_URL}/siteobservationreport/addSiteObservationReport`;
  const response = await axios
    .post(url, sor)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      console.log("error => ", error);
      return { data: { message: "Unable to find request", status: false } };
    });
  return response.data;
};

//======================Update SOR record ==========================
export const updateSiteObservationReport = async (sorObj) => {
  let url = `${TRANS_URL}/siteobservationreport/updateSiteObservationReport`;
  const response = await axios
    .post(url, sorObj)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      console.log("error => ", error);
      return { data: { message: "Unable to find request", status: false } };
    });
  return response.data;
};

export async function getSorFireData(ProjectCode, EquipTag, FireFlg) {
  let url = `${TRANS_URL}/siteobservationreport/getSorFireData`;
  let reqBody = { "ProjectCode": ProjectCode, "EquipTag": EquipTag, "FireFlg": FireFlg }
  const response = await axios
    .post(url, reqBody)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      console.log("error => ", error);
      return { data: [] };
    });
  return response.data;
}

export async function getISTObservationsData(ProjectCode) {
  let url = `${TRANS_URL}/siteobservationreport/getistobservationsdata`;
  let reqBody = { ProjectCode }
  const response = await axios
    .post(url, reqBody)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      console.log("error => ", error);
      return { data: [] };
    });
  return response.data;
}

export async function getSORDashboardData(ProjectCode) {
  let url = `${TRANS_URL}/siteobservationreport/getsordashboarddata`;
  let reqBody = { ProjectCode }
  const response = await axios
    .post(url, reqBody)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      console.log("error => ", error);
      return { data: [] };
    });
  return response.data;
}