import * as XLSX from "xlsx";
import * as XlsxPopulate from "xlsx-populate/browser/xlsx-populate";

export async function exportToExcel(fileName, excelData, projectData, colNameOrder, fieldNameOrder) {

  let baseData = Object.assign([], excelData);
  let distComb = [];
  let addedServices = [];

  for (let dt of baseData) {
    if (distComb.indexOf(dt.ServiceName + dt.ResponsibleContractorOEMUserId) === -1) {
      distComb.push(dt.ServiceName + dt.ResponsibleContractorOEMUserId);
      addedServices.push({ ServiceName: dt.ServiceName, ContractorName: dt.ResponsibleContractorOEM })
    }
  }

  let summaryReportHead1 = ["Service Name"];
  let summaryReportHead2 = ["Contractor / Vendor Name"];
  let totalObservations = ["Total Number of Observations"];
  let totalCritical = ["Critical Observations"];
  let totalNonCritical = ["Non Critical Snags"];
  let totalClose = ["Snags Closed"]
  let closedCritical = ["Critical Snags Closed"];
  let closedNonCritical = ["Non-Critical Snags Closed"];
  let totalOpen = ["Snags Open"]
  let openCritical = ["Critical Snags Open"];
  let openNonCritical = ["Non-Critical Snags Open"];
  let closedPerc = ["Snags Closed (in %)"];
  let openPerc = ["Snags Open (in %)"];


  for (let dt of addedServices) {
    summaryReportHead1.push(dt.ServiceName);
    summaryReportHead2.push(dt.ContractorName);

    //total observations for service & contractor
    let obsList = baseData.filter((item) => item.ServiceName === dt.ServiceName && item.ResponsibleContractorOEM === dt.ContractorName);
    totalObservations.push(obsList.length);

    //critical closed
    let closedCriticalObs = obsList.filter((item) => item.Criticality === "Critical" && (item.CorrectedStatus === "Yes" || item.CorrectedStatus === "NA"));
    closedCritical.push(closedCriticalObs.length);

    //critical open
    let openCriticalObs = obsList.filter((item) => item.Criticality === "Critical" && item.CorrectedStatus === "No");
    openCritical.push(openCriticalObs.length);

    //non-critical closed
    let closedNonCriticalObs = obsList.filter((item) => item.Criticality === "Non-Critical" && (item.CorrectedStatus === "Yes" || item.CorrectedStatus === "NA"));
    closedNonCritical.push(closedNonCriticalObs.length);

    //noncritical open
    let openNonCriticalObs = obsList.filter((item) => item.Criticality === "Non-Critical" && item.CorrectedStatus === "No");
    openNonCritical.push(openNonCriticalObs.length);

    //total critical
    totalCritical.push(closedCriticalObs.length + openCriticalObs.length);

    //total non-critical
    totalNonCritical.push(closedNonCriticalObs.length + openNonCriticalObs.length);

    //total closed
    totalClose.push(closedCriticalObs.length + closedNonCriticalObs.length);

    //total open
    totalOpen.push(openCriticalObs.length + openNonCriticalObs.length);

    //close perc
    closedPerc.push(parseFloat((parseFloat(closedCriticalObs.length + closedNonCriticalObs.length)) * 100 / parseFloat(obsList.length)).toFixed(2));

    //open perc
    openPerc.push(parseFloat((parseFloat(openCriticalObs.length + openNonCriticalObs.length)) * 100 / parseFloat(obsList.length)).toFixed(2));
  }

  // total column added
  summaryReportHead1.push("Total");
  summaryReportHead2.push("");
  totalObservations.push(calculateRowTotal(totalObservations));
  totalCritical.push(calculateRowTotal(totalCritical));
  totalNonCritical.push(calculateRowTotal(totalNonCritical));
  totalClose.push(calculateRowTotal(totalClose));
  closedCritical.push(calculateRowTotal(closedCritical));
  closedNonCritical.push(calculateRowTotal(closedNonCritical));
  totalOpen.push(calculateRowTotal(totalOpen));
  openCritical.push(calculateRowTotal(openCritical));
  openNonCritical.push(calculateRowTotal(openNonCritical));

  let totalClosedPerc = parseFloat((parseFloat(totalClose[totalClose.length - 1])) * 100 / parseFloat(totalObservations[totalObservations.length - 1])).toFixed(2);
  let totalOpenPerc = parseFloat((parseFloat(totalOpen[totalOpen.length - 1])) * 100 / parseFloat(totalObservations[totalObservations.length - 1])).toFixed(2);
  closedPerc.push(totalClosedPerc);
  openPerc.push(totalOpenPerc);

  const data = [["Project", projectData.ProjectName],
  ["Location", projectData.Location],
  ["Customer Name", projectData.CustomerName], [],
    summaryReportHead1, summaryReportHead2, totalObservations, totalCritical, totalNonCritical, [], totalClose, closedCritical, closedNonCritical, [], totalOpen, openCritical, openNonCritical, [], closedPerc, openPerc];

  const wb = new Workbook();
  const ws = XLSX.utils.aoa_to_sheet(data);
  XLSX.utils.book_append_sheet(wb, ws, "Summary");


  const detailedSheet = getSheetData(baseData, colNameOrder, fieldNameOrder);

  //insert order details
  let ws1 = XLSX.utils.json_to_sheet(detailedSheet);
  XLSX.utils.book_append_sheet(wb, ws1, "Details");

  const wopts = {
    bookType: "xlsx",
    bookSST: false,
    type: "binary",
  };

  const wbout = XLSX.write(wb, wopts);
  const blob = new Blob([s2ab(wbout)], {
    type: "application/octet-stream",
  });


  let columnSize = addedServices.length + 1;
  let uptoRange = numberToLetters(columnSize);

  XlsxPopulate.fromDataAsync(blob).then((workbook) => {
    let sheet = workbook.sheets()[0];
    sheet.range("A1:A24").style({
      bold: true
    });
    sheet.range("A5:" + uptoRange + "6").style({
      bold: true
    });
    // sheet.range("E1:E24").style({
    //   bold: true
    // });

    return workbook.outputAsync().then((workbookBlob) => URL.createObjectURL(workbookBlob)).then((url) => {
      const downloadAnchorNode = document.createElement("a");
      downloadAnchorNode.setAttribute("href", url);
      downloadAnchorNode.setAttribute(
        "download",
        fileName
      );
      downloadAnchorNode.click();
      downloadAnchorNode.remove();
    });
  });

}

function getSheetData(exData, header, field) {
  let sheetData = [];
  if (exData.length === 0) {
    let tmpdata = [];
    for (let i = 0; i < header.length; i++) {
      let tmpcol = header[i];
      if (i == 0) {
        console.log([tmpcol]);
        tmpdata.push({
          [tmpcol]: ""
        })
      }
      else {
        tmpdata = tmpdata.map(v => ({ ...v, [tmpcol]: "" }));
      }

    }
    sheetData = [...sheetData, ...tmpdata]
  }
  else {
    for (let n = 0; n < exData.length; n++) {
      let tmpdata = [];
      for (let i = 0; i < header.length; i++) {
        let tmpcol = header[i];
        let tmpfield = field[i];
        if (i == 0) {
          tmpdata.push({
            [tmpcol]: exData[n][tmpfield]
          })
        }
        else {
          tmpdata = tmpdata.map(v => ({ ...v, [tmpcol]: exData[n][tmpfield] }));
        }
      }
      sheetData = [...sheetData, ...tmpdata]
    }
  }
  return sheetData;
}

const s2ab = (s) => {
  const buf = new ArrayBuffer(s.length);
  const view = new Uint8Array(buf);
  for (let i = 0; i < s.length; ++i) {
    view[i] = s.charCodeAt(i);
  }
  return buf;
};

function Workbook() {
  if (!(this instanceof Workbook))
    return new Workbook()
  this.SheetNames = []
  this.Sheets = {}
}

const download = (url, name) => {
  let a = document.createElement('a')
  a.href = url
  a.download = name
  a.click();
  window.URL.revokeObjectURL(url)
}

function numberToLetters(num) {
  let letters = ''
  while (num >= 0) {
    letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'[num % 26] + letters
    num = Math.floor(num / 26) - 1
  }
  return letters
}

function calculateRowTotal(arr) {
  let total = 0;
  for (let dt = 1; dt < arr.length; dt++) {
    total = total + arr[dt];
  }
  return total;
}