import React, { Component } from 'react';

export default (props) => {
    let limit = props.selectedData;
    let backcolorclass = "";
    if (limit > 90 && limit <= 100) {
        backcolorclass = "dashboardLimitCheck1";
    }
    else if (limit > 70 && limit <= 90) {
        backcolorclass = "dashboardLimitCheck2";
    }
    else if (limit > 50 && limit <= 70) {
        backcolorclass = "dashboardLimitCheck3";
    }
    else if (limit > 0 && limit <= 50) {
        backcolorclass = "dashboardLimitCheck4";
    }
    else if (limit === 0) {
        backcolorclass = "dashboardLimitCheck5";
    }

    return (
        <div className={'dashboardTab1Limit ' + backcolorclass}>
            {limit}
        </div>
    );
};
