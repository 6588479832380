import React, { useEffect, useState, useRef, useContext, useImperativeHandle, forwardRef } from "react";
import { Form, Modal, Row, Col } from "react-bootstrap";
import { ServiceMasterData } from "../../../services/common-master-service";
import { AddChecklistDetails, UpdateChecklistDetails, getChecklistData, getChecklistParameterDetails } from "../../../services/checklist-master-service";
import { RenderIf, formatDateYYYY_MM_DD, getCurrentDateTime } from "../../../functions/gbcs-functions";
import Swal from "sweetalert2";
// import "./ChecklistMaster.css";
import withReactContent from "sweetalert2-react-content";
import { AuthContext } from "../../../contexts/AuthContext";
import successImage from "../../../icons/success-tick-logo.svg";
import errorImage from "../../../icons/error-tick-logo.svg";
import SingleValuePopUp from "../../utils/SingleValuePopUp";
import { BiSelectMultiple } from "react-icons/bi";
import TabButtons from "../../utils/TabButtons"
import { getAllServiceEquipmentData } from "../../../services/equipmentactivity-master-service";

const ChecklistDetails = forwardRef((props, ref) => {
    const MySwal = withReactContent(Swal);
    const [ServicesList, setServicesList] = useState([]);
    const [showForm, setShowForm] = useState(false);
    const [addDataSaved, setAddDataSaved] = useState(false);

    const [toggleClass, setToggleClass] = useState("activeToggleLabel")

    let action = props.action;
    let MasterData = props.masterData
    const context = useContext(AuthContext);
    const userDetails = { userName: context.userName, userId: context.userId };
    let todayDate = getCurrentDateTime("-");

    const [checklistDetailsObj, setChecklistDetailsObj] = useState(
        action === "Edit" || action === "View"
            ? props.rowdata
            : {
                SK: "",
                PK: "",
                DeleteIndication: "Active",
                ChecklistCode: "",
                ChecklistName: "",
                ChecklistVersion: "TMP.0001",
                ChecklistCategory: "",
                ServiceCode: "",
                ServiceType: "",
                EquipmentName: "",
                EquipmentCode: "",
                CreatedOn: todayDate,
                CreatedBy: userDetails.userId,
                CreatedByName: userDetails.userName,
                ModifiedOn: todayDate,
                ModifiedBy: userDetails.userId,
                ModifiedByName: userDetails.userName
            }
    );
    const [popUp, setPopUp] = useState({
        name: "",
        type: "",
        key: "",
        value: "",
        additionalParams: "",
    });


    const [error, setError] = useState({
        ChecklistCode: {},
        ChecklistName: {},
        ChecklistVersion: {},
        ChecklistCategory: {},
        ServiceCode: {},
        ServiceType: {},
        EquipmentName: {},
    })

    useEffect(() => {
        getServiceMasterData();
    }, []);

    const getServiceMasterData = async () => {
        const result = await ServiceMasterData();
        setServicesList(result);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setChecklistDetailsObj((prevState) => ({
            ...prevState,
            [name]: value,
        }));
        if (name === "ChecklistCategory" && checklistDetailsObj.EquipmentCode !== "") {
            setChecklistDetailsObj((prevState) => ({
                ...prevState,
                ChecklistCode: checklistDetailsObj.EquipmentCode + "#" + value,
            }));
        }
        if (name === "ServiceType" || name === "ServiceCode") {
            setChecklistDetailsObj((prevState) => ({
                ...prevState,
                EquipmentCode: "",
                EquipmentName: "",
                ChecklistCode: "",
            }));
        }
    }

    const handleSubmit = async () => {
        // e.preventDefault();
        let result;
        let res = false;
        try {
            if (!validateDetails()) {
                let checklistData = [];
                const equipMaster = await getAllServiceEquipmentData()

                let results = []
                let counter = 0;
                if (equipMaster?.length > 0) {
                    for (let dt of equipMaster) {
                        counter++;
                        let obj = {
                            PK: "EquipmentCode#" + dt.EquipmentCode,
                            SK: "ChecklistCategory#"
                        }
                        let tmpRes = getChecklistParameterDetails(obj);
                        results.push(tmpRes)

                        if (counter === 5) {
                            for (let item of results) {
                                let tmpRes1 = await Promise.resolve(item)
                                checklistData = [...checklistData, ...tmpRes1];
                            }
                            counter = 0;
                            results = [];
                        }
                    }
                }
                if (results.length > 0) {
                    for (let item of results) {
                        let tmpRes1 = await Promise.resolve(item)
                        checklistData = [...checklistData, ...tmpRes1];
                    }
                }

                // let checklistData = await getChecklistData()
                let currentChecklistData = checklistData?.filter((item) => item.ChecklistCode === checklistDetailsObj.ChecklistCode && item.ChecklistVersion === "TMP.0001");

                if (action === "Add" && !addDataSaved && currentChecklistData?.length > 0) {
                    MySwal.fire({
                        text: "Checklist is already checked out by " + currentChecklistData[0].CreatedByName + " and is not yet frozen. You are not allowed to create revision in Checked Out mode.",
                        imageUrl: errorImage,
                        customClass: "swal-success",
                    });
                    res = false;
                    return;
                }
                if (checklistData.length > 0 && checklistData.filter((item) => item.ChecklistCode !== checklistDetailsObj.ChecklistCode && item.ChecklistName.toLowerCase() === checklistDetailsObj.ChecklistName.toLowerCase()).length > 0) {
                    MySwal.fire({
                        text: "Equipment / Activity Name already exists.",
                        imageUrl: errorImage,
                        customClass: "swal-success",
                    });
                    res = false;
                    return;
                }
                if (action === "Edit" && checklistDetailsObj.DeleteIndication === "Active" && checklistDetailsObj.ChecklistVersion.startsWith("VER")) {
                    let activeVersions = checklistData?.filter((item) => item.ChecklistCode === checklistDetailsObj.ChecklistCode && item.ChecklistVersion !== checklistDetailsObj.ChecklistVersion && item.ChecklistVersion.startsWith("VER") && item.DeleteIndication === "Active");
                    if (activeVersions?.length > 0) {
                        MySwal.fire({
                            text: "Checklist Version " + activeVersions[0].ChecklistVersion + " is active for Checklist " + activeVersions[0].ChecklistName + ", you are not allowed to change status of current version as Active.",
                            imageUrl: errorImage,
                            customClass: "swal-success",
                        });
                        res = false;
                        return;
                    }
                }
                if (action === "Add") {
                    checklistDetailsObj.PK = "EquipmentCode#" + checklistDetailsObj.EquipmentCode;
                    checklistDetailsObj.SK = "ChecklistCategory#" + checklistDetailsObj.ChecklistCategory + "#ChecklistVersion#" + checklistDetailsObj.ChecklistVersion;
                    result = await AddChecklistDetails(checklistDetailsObj);
                    props.primeKeyData(checklistDetailsObj.PK);
                    props.secondKeyData(checklistDetailsObj.SK);
                    props.checklistCodeData(checklistDetailsObj.ChecklistCode);
                    props.checklistVersionData(checklistDetailsObj.ChecklistVersion);
                    props.setEquipCodeData(checklistDetailsObj.EquipmentCode);
                    props.setChecklistCat(checklistDetailsObj.ChecklistCategory);
                }
                else if (action === "Edit") {
                    checklistDetailsObj.ModifiedOn = todayDate;
                    checklistDetailsObj.ModifiedBy = userDetails.userId;
                    checklistDetailsObj.ModifiedByName = userDetails.userName;
                    result = await UpdateChecklistDetails(checklistDetailsObj);
                }
                if (result?.status) {
                    res = true;
                    setAddDataSaved(true);
                    MySwal.fire({
                        text: result.message,
                        imageUrl: successImage,
                        customClass: "swal-success",
                    });
                } else {
                    res = false;
                    MySwal.fire({
                        text: "Unable to Process Request",
                        imageUrl: errorImage,
                        customClass: "swal-success",
                    });
                }
            }
        } catch (err) {
            console.log("err", err)
            res = false;
            MySwal.fire({
                text: "Unable to Process Request",
                imageUrl: errorImage,
                customClass: "swal-success",
            });
        }
        return res;
    }

    useImperativeHandle(ref, () => ({
        funcOnNext: async () => {
            let res = false
            res = await handleSubmit();
            return res
            // return handleSubmit();
        },
        funcOnReset: () => {
            handleReset();
        }
    }));

    const validateDetails = () => {
        let isError = false;
        const errorMsg = {
            ChecklistCode: {},
            ChecklistName: {},
            ChecklistVersion: {},
            ChecklistCategory: {},
            ServiceCode: {},
            ServiceType: {},
            EquipmentName: {},
        };
        if (checklistDetailsObj.ChecklistCode.trim() === "") {
            checklistDetailsObj.ChecklistCode = "";
            isError = true;
            errorMsg.ChecklistCode = {
                status: true,
                message: "Checklist Code is required.",
            };
        }
        if (checklistDetailsObj.ChecklistName.trim() === "") {
            checklistDetailsObj.ChecklistName = "";
            isError = true;
            errorMsg.ChecklistName = {
                status: true,
                message: "Checklist Name is required.",
            };
        }
        if (checklistDetailsObj.ChecklistVersion.trim() === "") {
            checklistDetailsObj.ChecklistVersion = "";
            isError = true;
            errorMsg.ChecklistVersion = {
                status: true,
                message: "Checklist Version is required.",
            };
        }
        if (checklistDetailsObj.ChecklistCategory.trim() === "") {
            checklistDetailsObj.ChecklistCategory = "";
            isError = true;
            errorMsg.ChecklistCategory = {
                status: true,
                message: "Checklist Category is required.",
            };
        }
        if (checklistDetailsObj.ServiceCode.trim() === "") {
            checklistDetailsObj.ServiceCode = "";
            isError = true;
            errorMsg.ServiceCode = {
                status: true,
                message: "Service Name is required.",
            };
        }
        if (checklistDetailsObj.ServiceType.trim() === "") {
            checklistDetailsObj.ServiceType = "";
            isError = true;
            errorMsg.ServiceType = {
                status: true,
                message: "Equipment Type is required.",
            };
        }
        if (checklistDetailsObj.EquipmentName.toString().trim() === "") {
            checklistDetailsObj.EquipmentName = "";
            isError = true;
            errorMsg.EquipmentName = {
                status: true,
                message: "Equipment Name is required.",
            };
        }
        setError(errorMsg);
        return isError;
    }

    const handleSwitchChange = (e) => {
        if (e.target.checked) {
            setToggleClass("activeToggleLabel")
        } else {
            setToggleClass("inactiveToggleLabel")
        }

        setChecklistDetailsObj((prevState) => ({
            ...prevState,
            DeleteIndication: e.target.checked ? "Active" : "Inactive",
        }));
    };
    const setPopUpData = async (data) => {
        if (data.name === "Equipment Master") {
            let category = checklistDetailsObj.ChecklistCategory;
            setChecklistDetailsObj((prevState) => ({
                ...prevState,
                EquipmentName: data.result.EquipmentName,
                EquipmentCode: data.result.EquipmentCode,
                ServiceCode: data.result.ServiceCode,
                ServiceType: data.result.ServiceType,
                ChecklistCode: category !== "" ? data.result.EquipmentCode + "#" + category : ""
            }));
        }
    };

    const showPopUp = (name, type, key, value, additionalParams) => {
        setPopUp({ name, type, key, value, additionalParams });
        setShowForm(true);
    };

    const handleReset = () => {
        if (!addDataSaved) {
            setChecklistDetailsObj({
                SK: "",
                PK: "",
                DeleteIndication: "Active",
                ChecklistCode: "",
                ChecklistName: "",
                ChecklistVersion: "TMP.0001",
                ChecklistCategory: "",
                ServiceCode: "",
                ServiceType: "",
                EquipmentName: "",
                EquipmentCode: "",
                CreatedOn: todayDate,
                CreatedBy: userDetails.userId,
                CreatedByName: userDetails.userName,
                ModifiedOn: todayDate,
                ModifiedBy: userDetails.userId,
                ModifiedByName: userDetails.userName
            });
            setError({
                ChecklistCode: {},
                ChecklistName: {},
                ChecklistVersion: {},
                ChecklistCategory: {},
                ServiceCode: {},
                ServiceType: {},
                EquipmentName: {},
            });
        }
        else {
            MySwal.fire({
                text: "Temp version is created. You can not reset data.",
                imageUrl: errorImage,
                customClass: "swal-success",
            });
            return;
        }
    }

    return (
        <>
            <div>
                <Form
                    id="checklistdetails"
                    name="checklistdetails"
                    onSubmit={handleSubmit}
                    autoComplete="off"
                >
                    <div>
                        <Row>
                            <Col lg={4} xs={12}>
                                <Form.Group className="g-input">
                                    <Form.Control
                                        id="ChecklistCode"
                                        type="text"
                                        name="ChecklistCode"
                                        maxLength="150"
                                        placeholder=" "
                                        value={checklistDetailsObj.ChecklistCode}
                                        readOnly={true}
                                    />
                                    {error.ChecklistCode.status && (
                                        <Form.Text className="text-danger">
                                            {error.ChecklistCode.message}
                                        </Form.Text>
                                    )}
                                    <Form.Label htmlFor="ChecklistCode">Checklist Code</Form.Label>
                                </Form.Group>
                            </Col>
                            <Col lg={8} xs={12} >
                                <Form.Group className="g-input">
                                    <Form.Control
                                        id="ChecklistName"
                                        type="text"
                                        name="ChecklistName"
                                        maxLength="100"
                                        placeholder=" "
                                        value={checklistDetailsObj.ChecklistName}
                                        onChange={handleChange}
                                        readOnly={action === "View" || checklistDetailsObj.ChecklistVersion.startsWith("VER")}
                                    />
                                    <Form.Label htmlFor="ChecklistName">Checklist Name</Form.Label>
                                    {error.ChecklistName.status && (
                                        <Form.Text className="text-danger">
                                            {error.ChecklistName.message}
                                        </Form.Text>
                                    )}
                                </Form.Group>
                            </Col>
                            <Col lg={4} xs={12}>
                                <Form.Group className="g-input">
                                    <Form.Control
                                        id="ChecklistVersion"
                                        type="text"
                                        name="ChecklistVersion"
                                        maxLength="100"
                                        placeholder=" "
                                        value={checklistDetailsObj.ChecklistVersion}
                                        onChange={handleChange}
                                        readOnly={true}
                                    />
                                    <Form.Label htmlFor="ChecklistVersion">Checklist Version</Form.Label>
                                </Form.Group>
                            </Col>
                            <Col md={4} xs={12}>
                                <Form.Group>
                                    <Row>
                                        <Col md={12} xs={12}>
                                            <Form.Label className="fieldLabel locationType">Checklist Category</Form.Label>
                                        </Col>
                                        <Col md={6} xs={6}>
                                            <Form.Check className="fieldLabel locationType"
                                                type="radio"
                                                name="ChecklistCategory"
                                                checked={checklistDetailsObj.ChecklistCategory === "Pre-Functional"}
                                                onChange={handleChange}
                                                label="Pre-Functional"
                                                value="Pre-Functional"
                                                disabled={action !== "Add"}
                                            ></Form.Check>
                                        </Col>
                                        <Col md={6} xs={6}>
                                            <Form.Check className="fieldLabel locationType"
                                                type="radio"
                                                name="ChecklistCategory"
                                                checked={checklistDetailsObj.ChecklistCategory === "Functional"}
                                                onChange={handleChange}
                                                label="Functional"
                                                value="Functional"
                                                disabled={action !== "Add"}
                                            ></Form.Check>
                                        </Col>
                                    </Row>
                                    {error.ChecklistCategory.status && (
                                        <Row>
                                            <Form.Text className="text-danger">
                                                {error.ChecklistCategory.message}
                                            </Form.Text>
                                        </Row>
                                    )}
                                </Form.Group>
                            </Col>
                            <Col md={4} xs={12}>
                                <Form.Group className="g-select">
                                    <Form.Select
                                        id="ServiceCode"
                                        name="ServiceCode"
                                        type="text"
                                        placeholder=" "
                                        value={checklistDetailsObj.ServiceCode}
                                        onChange={(e) => { handleChange(e) }}
                                        disabled={action !== "Add"}
                                    >
                                        <option value="" >Select</option>
                                        {ServicesList?.map((item) => {
                                            return (<option key={item.ServiceCode} value={item.ServiceCode}>{item.ServiceName}</option>)
                                        })
                                        }
                                    </Form.Select>
                                    <Form.Label className="frm-label" for="ServiceCode">Service Name</Form.Label>
                                    {error.ServiceCode.status && (
                                        <Form.Text className="text-danger">
                                            {error.ServiceCode.message}
                                        </Form.Text>
                                    )}
                                </Form.Group>
                            </Col>
                            <Col md={4} xs={12}>
                                <Form.Group>
                                    <Row>
                                        <Col md={12} xs={12}>
                                            <Form.Label className="fieldLabel locationType">Service Type</Form.Label>
                                        </Col>
                                        <Col md={6} xs={6}>
                                            <Form.Check className="fieldLabel locationType"
                                                type="radio"
                                                name="ServiceType"
                                                checked={checklistDetailsObj.ServiceType === "Equipment"}
                                                onChange={handleChange}
                                                label="Equipment"
                                                value="Equipment"
                                                disabled={action !== "Add"}
                                            ></Form.Check>
                                        </Col>
                                        <Col md={6} xs={6}>
                                            <Form.Check className="fieldLabel locationType"
                                                type="radio"
                                                name="ServiceType"
                                                checked={checklistDetailsObj.ServiceType === "Activity"}
                                                onChange={handleChange}
                                                label="Activity"
                                                value="Activity"
                                                disabled={action !== "Add"}
                                            ></Form.Check>
                                        </Col>
                                    </Row>
                                    {error.ServiceType.status && (
                                        <Row>
                                            <Form.Text className="text-danger">
                                                {error.ServiceType.message}
                                            </Form.Text>
                                        </Row>
                                    )}
                                </Form.Group>
                            </Col>
                            <Col lg={8} ></Col>
                            <Col lg={4} xs={12}>
                                <Form.Group className="g-input">
                                    <Form.Control
                                        id="EquipmentName"
                                        type="text"
                                        name="EquipmentName"
                                        placeholder=" "
                                        value={checklistDetailsObj.EquipmentName}
                                        onChange={handleChange}
                                        maxLength="100"
                                        readOnly
                                    />
                                    <Form.Label htmlFor="EquipmentName">Equipment / Activity Name</Form.Label>
                                    {action === "Add" &&
                                        <BiSelectMultiple
                                            className="modalPopUpIconProjDetails"
                                            size={20}
                                            style={{ marginLeft: "-2rem" }}
                                            onClick={() => {
                                                showPopUp(
                                                    "Equipment Master",
                                                    "Single",
                                                    "EquipmentName",
                                                    checklistDetailsObj.EquipmentName,
                                                    [{ ServiceCode: checklistDetailsObj.ServiceCode, ServiceType: checklistDetailsObj.ServiceType }]
                                                );
                                            }}
                                        />}
                                    {error.EquipmentName.status && (
                                        <Form.Text className="text-danger">
                                            {error.EquipmentName.message}
                                        </Form.Text>
                                    )}
                                </Form.Group>
                            </Col>
                            <Col md={4} xs={12}>
                                <Row>
                                    <Col>
                                        <Form.Label className="fieldLabel">Status</Form.Label>
                                    </Col>
                                    <Col>
                                        <Form.Switch className={"semiBoldToggleButton " + toggleClass}
                                            label={checklistDetailsObj.DeleteIndication === "Active" ? "Active" : "Inactive"}
                                            checked={checklistDetailsObj.DeleteIndication === "Active"}
                                            onChange={(e) => {
                                                handleSwitchChange(e);
                                            }}
                                            disabled={action === "View"}
                                            name="DeleteIndication"
                                        />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <br />
                    </div>
                    {/* <TabButtons currentTab={1} handleSelect={props.tabKey} action={action}/> */}
                </Form>
            </div>
            <RenderIf isTrue={showForm}>
                <SingleValuePopUp
                    show={showForm}
                    onHide={() => setShowForm(false)}
                    setSelected={setPopUpData}
                    mastername={popUp.name}
                    paramkey={popUp.key}
                    paramvalue={popUp.value}
                    additionalParams={popUp.additionalParams}
                    backdrop="static"
                />
            </RenderIf>
        </>
    );
}
);

export default ChecklistDetails;
