import axios from "axios";
const API_URL = process.env.REACT_APP_API_URL;
const TRANS_URL = process.env.REACT_APP_TRANSACTION_API_URL;

export async function getRolesForUnmaskedData() {
  const response = await axios
    .get(API_URL + "/commonmaster/getroles")
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      console.log("error => ", error);
      return { data: [] };
    });
  return response.data;
}

//============================ Get design review tracker status master data ====================================
export async function DesignReviewTrackerStatusMaster() {
  const response = await axios
    .get(API_URL + "/commonmaster/DesignReviewTrackerStatusMaster")
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      console.log("error => ", error);
      return { data: [] };
    });
  return response.data;
}

//============================ Get Service Master data ====================================
export async function ServiceMasterData() {
  const response = await axios
    .get(API_URL + "/commonmaster/getservicesdata")
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      console.log("error => ", error);
      return { data: [] };
    });
  return response.data;
}

export async function ServiceMasterAllData() {
  const response = await axios
    .get(API_URL + "/commonmaster/getallservicesdata")
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      console.log("error => ", error);
      return { data: [] };
    });
  return response.data;
}

//Delete the S3 bucket document 
// export const deletS3Document = async (drtFile)=>{
export const deletS3Attachment = async (drtFile) => {
  let url = `${TRANS_URL}/attachment/deletS3Attachment`;
  console.log(url)
  const response = await axios
    .post(url, drtFile)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      console.log("error => ", error);
      return { data: { message: "Unable to find request", status: false } };
    });
  return response.data;
}

//Move the S3 bucket document 
// export const deletS3Document = async (drtFile)=>{
export const moveS3File = async (fileObj) => {
  let url = `${TRANS_URL}/attachment/movefile`;
  console.log(url)
  const response = await axios
    .post(url, fileObj)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      console.log("error => ", error);
      return { data: { message: "Unable to find request", status: false } };
    });
  return response.data;
}

export async function getStakeholderData() {
  const response = await axios
    .get(API_URL + "/commonmaster/getStakeholder")
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      console.log("error => ", error);
      return { data: [] };
    });
  return response.data;
}

export async function getStakeholderTab1() {
  const response = await axios
    .get(API_URL + "/commonmaster/getStakeholderTab1")
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      console.log("error => ", error);
      return { data: [] };
    });
  return response.data;
}

export async function getDocumentTypeData() {
  const response = await axios
    .get(API_URL + "/commonmaster/getDocumentType")
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      console.log("error => ", error);
      return { data: [] };
    });
  return response.data;
}

export async function getZoneData() {
  const response = await axios
    .get(API_URL + "/commonmaster/getZoneMaster")
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      console.log("error => ", error);
      return { data: [] };
    });
  return response.data;
}

export async function getSectorData() {
  const response = await axios
    .get(API_URL + "/companydivisionmaster/activesectordata")
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      console.log("error => ", error);
      return { data: [] };
    });
  return response.data;
}

export async function getLOBMaster() {
  const response = await axios
    .get(API_URL + "/commonmaster/getLOBMaster")
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      console.log("error => ", error);
      return { data: [] };
    });
  return response.data;
}

export async function getChecklistMasterLimitdata() {
  const response = await axios
    .get(API_URL + "/commonmaster/getChecklistMasterLimitValues")
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      console.log("error => ", error);
      return { data: [] };
    });
  return response.data;
}

export async function getPresignedURL(filename) {
  let reqBody = { filename }
  const response = await axios
    .post(TRANS_URL + "/attachment/getPresignURL", reqBody)
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      console.log("error => ", error);
      return { data: { message: "Unable to find request", status: false } };
    });
  console.log("response", response)
  return response.data.result;
}

//==================== Get Equipment Parameter limits ====================
export async function getEquipmentParameterLimitData() {
  const response = await axios
    .get(API_URL + "/commonmaster/getequipmentparameterlimitdata")
    .then(function (resp) {
      return resp;
    })
    .catch(function (error) {
      console.log("error => ", error);
      return { data: [] };
    });
  return response.data;
}
