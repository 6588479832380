import React, { useEffect, useState, useContext } from "react";
import { Button, Form, Modal, Row, Col, Spinner } from "react-bootstrap";
import "./AddEditRoleMaster.css"
import { getAllUsers, AddNewUser, UpdateUser, getValidUserData } from "../../services/user-service";
import { getCurrentDateTime, RenderIf } from "../../functions/gbcs-functions";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import successImage from "../../icons/success-tick-logo.svg";
import errorImage from "../../icons/error-tick-logo.svg";
import { BiSelectMultiple } from "react-icons/bi";
import SingleValuePopUp from "../utils/SingleValuePopUp";
import encryptData from "../../utils/encryption";
import { AuthContext } from "../../contexts/AuthContext";
import { addLogDetails } from "../../services/logger-entry-service";
import { isEmail, isMobNumber, isAlphanumericWithDotHyphenUnderscore } from "../../functions/validations";
import { ALERT_PARAMS } from "../../utils/constant.js";

const AddEditUserMaster = (props) => {
    const MySwal = withReactContent(Swal);
    const context = useContext(AuthContext);
    const userDetails = { email: context.userEmail, userName: context.userName, userId: context.userId }
    let action = props.action;
    const [toggleClass, setToggleClass] = useState("activeToggleLabel")
    const [showForm, setShowForm] = useState(false);
    const UserPopUp = "HRIS Details";
    const RolePopUp = "Role Master";
    const CompanyDivisionPopUp = "Company Master";
    const [ShowMobileNo, setShowMobileNo] = useState(!(action === "Edit" || action === "View"));
    const [loading, setLoading] = useState(false);

    const [error, setError] = useState({
        UserType: false,
        UserId: false,
        UserIdMessage: "",
        UserName: false,
        CompanyName: false,
        CompanyCode: false,
        RoleName: false,
        RoleCode: false,
        EmailId: false,
        MobileNo: false,
        Designation: false,
        DisplayContractFinInfo: false,
        DeleteIndication: false
    });

    const [userObj, setUserObj] = useState(
        action === "Edit" || action === "View"
            ? props.rowdata
            : {
                UserType: "Employee",
                UserId: "",
                EmpCode: "",
                UserName: "",
                CompanyName: "Godrej ENE Division",
                CompanyCode: "",
                Password: "",
                RoleName: "",
                RoleCode: "",
                EmailId: "",
                MobileNo: "",
                Designation: "",
                DisplayContractFinInfo: "No",
                DeleteIndication: "Active",
            }
    );

    useEffect(() => {
        if (action === "Edit" || action === "View") {
            if (props.rowdata.DeleteIndication === "Active") {
                setToggleClass("activeToggleLabel")
            }
            else {
                setToggleClass("inactiveToggleLabel")
            }
        }
    }, []);

    const validateDetails = async () => {
        const errorMsg = {
            UserType: false,
            UserId: false,
            UserName: false,
            CompanyName: false,
            CompanyCode: false,
            RoleName: false,
            RoleCode: false,
            EmailId: false,
            MobileNo: false,
            Designation: false,
            DisplayContractFinInfo: false,
            DeleteIndication: false
        };

        let isError = false;

        if (userObj.UserType.trim() === "") {
            userObj.UserType = "";
            isError = true;
            errorMsg.UserType = true;

        }
        if (userObj.UserId.trim() !== "" && userObj.UserType === "Employee") {
            const data = await getValidUserData("HRISuserData", "EmpEmailId", userObj.UserId.trim())

            if (data.length <= 0) {
                isError = true;
                userObj.EmailId = "";
                userObj.EmpCode = "";
                userObj.MobileNo = "";
                errorMsg.UserId = true;
                errorMsg.UserIdMessage = "User Not Found"

            }
        }
        if (userObj.UserId.trim() !== "" && userObj.UserType === "User") {
            let validateUserId = isAlphanumericWithDotHyphenUnderscore(userObj.UserId)
            if (validateUserId.status) {
                isError = true;
                errorMsg.UserId = true;
                errorMsg.UserIdMessage = validateUserId.message;
            }
        }
        if (userObj.CompanyName.trim() !== "") {
            const data = await getValidUserData("CompanyDivisionMaster", "CompanyName", userObj.CompanyName.trim())

            if (data.length <= 0) {
                isError = true;
                userObj.CompanyCode = "";
                errorMsg.CompanyName = true;
            }
            else {
                userObj.CompanyCode = data[0].CompanyCode;
            }
        }

        if (userObj.RoleName.trim() !== "") {
            const data = await getValidUserData("RoleMaster", "RoleName", userObj.RoleName.trim())

            if (data.length <= 0) {
                isError = true;
                userObj.RoleCode = "";
                errorMsg.RoleName = true;
            }
        }

        if (isEmail(userObj.EmailId).status) {
            isError = true;
            errorMsg.EmailId = true;
        }

        if (isMobNumber(userObj.MobileNo + "")) {
            isError = true;
            errorMsg.MobileNo = true;
        }

        if (userObj.DisplayContractFinInfo.trim() === "") {
            isError = true;
            errorMsg.DisplayContractFinInfo = true;
        }
        if (userObj.DeleteIndication === "") {
            isError = true;
            errorMsg.DeleteIndication = true;
        }
        setError(errorMsg)
        return isError;
    }
    const [popUp, setPopUp] = useState({
        name: "",
        type: "",
        key: "",
        value: "",
    });
    const setPopUpData = (data) => {
        if (data.name === UserPopUp) {
            setUserObj((prevState) => ({
                ...prevState,
                UserId: data.result.EmpEmailId,
                EmpCode: data.result.EmpCode,
                UserName: data.result.EmpShortName,
                EmailId: data.result.EmpEmailId + "@godrej.com",
                MobileNo: data.result.EmpMobileNo,
            }));

            setError((prevState) => ({
                ...prevState,
                UserId: false,
                EmailId: false,
                UserName: false,
                MobileNo: false,
                UserIdMessage: ""
            }));
        }
        if (data.name === RolePopUp) {
            setUserObj((prevState) => ({
                ...prevState,
                RoleCode: data.result.RoleCode,
                RoleName: data.result.RoleName,

            }));
            setError((prevState) => ({
                ...prevState,
                RoleName: false,
            }));
        }

        if (data.name === CompanyDivisionPopUp) {
            setUserObj((prevState) => ({
                ...prevState,
                CompanyCode: data.result.CompanyCode,
                CompanyName: data.result.CompanyName,

            }));
            setError((prevState) => ({
                ...prevState,
                CompanyName: false,
            }));
        }
    }


    const showPopUp = (name, type, key, value) => {
        setPopUp({ name, type, key, value });
        setShowForm(true);
    };

    const checkValidData = async (e) => {
        const { name, value } = e.target;
        if ((action !== "View")) {
            if (value.trim() === "") {
                if (name === "UserId") {
                    setUserObj((prevState) => ({
                        ...prevState,
                        UserId: "",
                        EmailId: "",
                        EmpCode: "",
                        UserName: "",
                        MobileNo: "",

                    })
                    )
                    setError((prevState) => ({
                        ...prevState,
                        UserId: false,
                        EmailId: false,
                        UserName: false,
                        MobileNo: false,
                        UserIdMessage: ""
                    }));

                }
                if (name === "CompanyName") {
                    setUserObj((prevState) => ({
                        ...prevState,
                        CompanyCode: "",
                        CompanyName: "",
                    })
                    )
                    setError((prevState) => ({
                        ...prevState,
                        CompanyName: false,
                    }));
                }
                if (name === "RoleName") {
                    setUserObj((prevState) => ({
                        ...prevState,
                        RoleCode: "",
                        RoleName: "",
                    })
                    )
                    setError((prevState) => ({
                        ...prevState,
                        RoleName: false,
                    }));
                }
            }
            else {
                setLoading(true);
                if (name === "UserId" && userObj.UserType === "Employee" && action === "Add") {
                    const data = await getValidUserData("HRISuserData", "EmpEmailId", value.trim())
                    if ((data.length > 0)) {
                        setUserObj((prevState) => ({
                            ...prevState,
                            UserName: data[0].EmpShortName,
                            EmpCode: data[0].EmpCode,
                            EmailId: data[0].EmpEmailId + "@godrej.com",
                            MobileNo: data[0].EmpMobileNo
                        })
                        )
                        setError((prevState) => ({
                            ...prevState,
                            UserId: false,
                            EmailId: false,
                            UserName: false,
                            MobileNo: false,
                            UserIdMessage: ""
                        }));

                        // return;
                    } else {
                        setUserObj((prevState) => ({
                            ...prevState,
                            // UserId: "",
                            EmailId: "",
                            EmpCode: "",
                            UserName: "",
                            MobileNo: "",

                        })
                        )

                        setError((prevState) => ({
                            ...prevState,
                            UserId: true,
                            UserIdMessage: "User Not Found"
                        }));
                    }
                }
                if (name === "CompanyName") {
                    const data = await getValidUserData("CompanyDivisionMaster", "CompanyName", value.trim())

                    if ((data.length > 0)) {
                        setUserObj((prevState) => ({
                            ...prevState,
                            CompanyCode: data[0].CompanyCode,
                            CompanyName: data[0].CompanyName,

                        })
                        )
                        setError((prevState) => ({
                            ...prevState,
                            CompanyName: false,
                        }));

                        // return;
                    } else {
                        setUserObj((prevState) => ({
                            ...prevState,
                            CompanyCode: "",
                            // CompanyName: "",
                        })
                        )

                        setError((prevState) => ({
                            ...prevState,
                            CompanyName: true,
                        }));
                    }
                }
                if (name === "RoleName") {
                    const data = await getValidUserData("RoleMaster", "RoleName", value.trim())

                    if ((data.length > 0)) {
                        setUserObj((prevState) => ({
                            ...prevState,
                            RoleCode: data[0].RoleCode,
                            RoleName: data[0].RoleName,

                        })
                        )
                        setError((prevState) => ({
                            ...prevState,
                            RoleName: false,
                        }));

                        // return;
                    } else {
                        setUserObj((prevState) => ({
                            ...prevState,
                            RoleCode: "",
                            // RoleName: "",
                        })
                        )

                        setError((prevState) => ({
                            ...prevState,
                            RoleName: true,
                        }));
                    }
                }
                setLoading(false);
            }
        }

    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name === "UserType") {
            setUserObj((prevState) => ({
                ...prevState,
                UserId: "",
                EmpCode: "",
                UserName: "",
                EmailId: "",
                MobileNo: "",
            })
            )
            setError((prevState) => ({
                ...prevState,
                UserId: "",
                UserIdMessage: "",
                UserName: "",
                EmailId: "",
                MobileNo: "",
            })
            )
        }
        setUserObj((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleCancel = () => {
        props.onHide();
    }

    const handleReset = () => {
        setUserObj((prevState) => ({
            ...prevState,
            UserType: "Employee",
            UserId: "",
            EmpCode: "",
            UserName: "",
            Password: "",
            CompanyName: "",
            CompanyCode: "",
            RoleName: "",
            RoleCode: "",
            EmailId: "",
            MobileNo: "",
            Designation: "",
            DisplayContractFinInfo: "No",
            DeleteIndication: "Active",
        }));
        setToggleClass("activeToggleLabel")
    }

    const handleSwitchChange = (e) => {
        if (e.target.checked) {
            setToggleClass("activeToggleLabel")
        } else {
            setToggleClass("inactiveToggleLabel")
        }

        setUserObj((prevState) => ({
            ...prevState,
            DeleteIndication: e.target.checked ? "Active" : "Inactive",
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (action == "View") {
            props.onHide();
        }
        else {
            setLoading(true);
            let result;
            const validationResult = await validateDetails()

            if (!validationResult) {
                const result1 = await getAllUsers();
                if (result1.length > 0) {
                    let errMsg = "";

                    if (result1.filter((item) => ((action === "Add" || (action === "Edit" && item.UserId.toLowerCase() !== userObj.UserId.toLowerCase())) && (item.UserId.toLowerCase() === userObj.UserId.toLowerCase()))).length > 0) {
                        errMsg = errMsg + "User already exists. ";
                    }

                    if (result1.filter((item) => ((action === "Add" || (action === "Edit" && item.UserId.toLowerCase() !== userObj.UserId.toLowerCase())) && (item.EmailId.toLowerCase() === userObj.EmailId.toLowerCase()))).length > 0) {
                        errMsg = errMsg + "Official Email Id already exists. ";
                    }

                    if (result1.filter((item) => ((action === "Add" || (action === "Edit" && item.UserId.toLowerCase() !== userObj.UserId.toLowerCase())) && (item.MobileNo.toLowerCase() === userObj.MobileNo.toLowerCase()))).length > 0) {
                        errMsg = errMsg + "Official Mobile No already exists. ";
                    }

                    if (errMsg !== "") {
                        setLoading(false);
                        MySwal.fire({
                            text: errMsg,
                            imageUrl: errorImage,
                            customClass: "swal-success",
                        });
                        return;
                    }
                }

                setLoading(false);
                let confirmBoxText = "";
                let confirmButtonText = "";
                if (action === "Add") {
                    confirmBoxText = "Are you sure, do you want to add new User?";
                    confirmButtonText = "Add";
                }
                else if (action == "Edit") {
                    confirmBoxText = "Are you sure, do you want to update User?";
                    confirmButtonText = "Update";
                }

                MySwal.fire({
                    text: confirmBoxText,
                    confirmButtonText: confirmButtonText,
                    title: action + " User",
                    ...ALERT_PARAMS
                }).then(async (result2) => {
                    if (result2.isConfirmed) {
                        setLoading(true);
                        let tempObj = Object.assign({}, userObj);

                        let maskeduserName = encryptData(userObj.UserName, "enc");
                        let maskeduserEmail = encryptData(userObj.EmailId, "enc");
                        let maskeduserMobile = encryptData(userObj.MobileNo, "enc");

                        tempObj.UserName = maskeduserName;
                        tempObj.EmailId = maskeduserEmail;
                        tempObj.MobileNo = maskeduserMobile;
                        let todayDate = getCurrentDateTime("-");
                        if (action === "Add") {


                            tempObj.CreatedBy = userDetails.userId;
                            tempObj.CreatedByName = userDetails.userName;
                            tempObj.CreatedOn = todayDate;
                            tempObj.ModifiedBy = userDetails.userId;
                            tempObj.ModifiedByName = userDetails.userName;
                            tempObj.ModifiedOn = todayDate;
                            tempObj.PK = "UserMaster";
                            tempObj.SK = "UserId#" + tempObj.UserId;

                            result = await AddNewUser(tempObj);
                        } else if (action == "Edit") {

                            tempObj.ModifiedBy = userDetails.userId;
                            tempObj.ModifiedByName = userDetails.userName;
                            tempObj.ModifiedOn = todayDate;
                            result = await UpdateUser(tempObj);
                        }
                        if (result.status) {
                            //For logger details  
                            let tempUnique = "SK#" + tempObj.SK;
                            let logObj = Object.assign({}, tempObj);
                            logObj.UserName = userObj.UserName;
                            logObj.EmailId = userObj.EmailId;
                            logObj.MobileNo = userObj.MobileNo;
                            await addLogDetails(logObj, action, tempUnique, "UserMasterLog", userDetails, "Master")
                            setLoading(false);
                            MySwal.fire({
                                text: result.message,
                                imageUrl: successImage,
                                customClass: "swal-success",
                                didClose: () => {
                                    props.setReload(!props.reload);
                                    props.onHide();
                                },
                            });
                        }
                        else {
                            setLoading(false);
                            MySwal.fire({
                                text: "Unable to Process Request",
                                imageUrl: errorImage,
                                customClass: "swal-success"
                            });
                        }
                    }
                });

            }
            else {
                setLoading(false);
                return;
            }
        }
    }

    return (
        <>
            {loading && <div className="spinner-box"> <Spinner animation="border" className="spinner" /></div>}
            <Modal
                {...props}
                size="lg"
                backdrop="static" centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        {action} User
                        <span className="req-input" >
                            {props.action !== "View" ? " (All fields are mandatory)" : ""}
                        </span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form
                        id="usermaster"
                        name="usermaster"
                        onSubmit={handleSubmit}
                        autoComplete="off"
                    >
                        <Row >
                            {(action === "Edit" || action === "View") && (<>

                                <Col md={4} xs={12}>
                                    <Row>
                                        <Col md={7} xs={6}><Form.Label className="fieldLabel">User Type</Form.Label></Col>
                                        <Col md={5} xs={6}><Form.Label className="fieldLabel semiBoldToggleButton">{userObj.UserType}</Form.Label></Col>
                                    </Row>
                                </Col>
                            </>
                            )}
                            {action === "Add" && (
                                <Col md={4} xs={12}>
                                    <Row>
                                        <Form.Label className="fieldLabel locationType">User Type</Form.Label>
                                    </Row>
                                    <Row>
                                        <Col md={6} xs={6}>
                                            <Form.Check className="fieldLabel locationType"
                                                type="radio"
                                                name="UserType"
                                                checked={userObj.UserType === "Employee"}
                                                onChange={(e) => { handleChange(e) }}
                                                label="Employee"
                                                value="Employee"
                                            ></Form.Check>
                                        </Col>
                                        <Col md={6} xs={6}>
                                            <Form.Check className="fieldLabel locationType"
                                                type="radio"
                                                name="UserType"
                                                checked={userObj.UserType === "User"}
                                                onChange={(e) => { handleChange(e) }}
                                                label="User"
                                                value="User"
                                            ></Form.Check>
                                        </Col>
                                    </Row>
                                </Col>
                            )}
                            <Col md={4} xs={12}>
                                <Form.Group className="g-input ">
                                    <div className="d-flex align-items-center">
                                        <Form.Control
                                            id="UserId"
                                            type="text"
                                            name="UserId"
                                            maxLength="50"
                                            placeholder=" "
                                            required
                                            value={userObj.UserId}
                                            onBlur={(e) => { checkValidData(e) }}
                                            onChange={(e) => { handleChange(e) }}

                                            onInput={(e) => {
                                                let error = isAlphanumericWithDotHyphenUnderscore(e.target.value);
                                                setError((prevState) => ({
                                                    ...prevState,
                                                    UserId: error.status,
                                                    UserEmail: error.status,
                                                    UserIdMessage: error.message
                                                }));
                                            }}
                                            readOnly={action === "View" || action === "Edit"} />
                                        <Form.Label className="frm-label" for="UserId">User ID</Form.Label>
                                        <BiSelectMultiple
                                            className={(userObj.UserType === "User") || (action === "View" || action === "Edit") ? "d-none" : ""}
                                            size={20}
                                            onClick={() => showPopUp(UserPopUp, "Single", "EmpEmailId", userObj.UserId)}
                                            color={"#707070"}
                                            style={{ marginLeft: "-2rem" }}
                                            backdrop="static"
                                        />
                                    </div>
                                    {error.UserId && (
                                        <Form.Text className="text-danger">
                                            {error.UserIdMessage}
                                        </Form.Text>
                                    )}
                                </Form.Group>
                            </Col>
                            <Col md={4} xs={12}>
                                <Form.Group className="g-input">
                                    <Form.Control
                                        id="UserName"
                                        type="text"
                                        name="UserName"
                                        maxLength="100"
                                        placeholder=" "
                                        required
                                        value={userObj.UserName}
                                        onChange={(e) => { handleChange(e) }}
                                        readOnly={action === "View"}
                                    />
                                    <Form.Label className="frm-label" for="UserName">User Name</Form.Label>
                                    {error.UserName && (
                                        <Form.Text className="text-danger">
                                            Enter User Name.
                                        </Form.Text>
                                    )}
                                </Form.Group>
                            </Col>
                            <Col md={4} xs={12}>
                                <Form.Group className="g-input">
                                    <div className="d-flex align-items-center">
                                        <Form.Control
                                            id="CompanyName"
                                            type="text"
                                            name="CompanyName"
                                            maxLength="100"
                                            placeholder=" "
                                            required
                                            value={userObj.CompanyName}
                                            onBlur={(e) => { checkValidData(e) }}
                                            onChange={(e) => { handleChange(e) }}
                                            readOnly={action === "View"}
                                        />
                                        <Form.Label className="frm-label" for="CompanyName">Company/Division</Form.Label>
                                        <BiSelectMultiple
                                            className={action === "View" ? "d-none" : ""}
                                            size={20}
                                            onClick={() => showPopUp(CompanyDivisionPopUp, "Single", "CompanyName", userObj.CompanyName)}
                                            color={"#707070"}
                                            style={{ marginLeft: "-2rem" }}
                                            backdrop="static"
                                        />
                                    </div>
                                    {error.CompanyName && (
                                        <Form.Text className="text-danger">
                                            Enter Valid Company Name.
                                        </Form.Text>
                                    )}
                                </Form.Group>
                            </Col>
                            <Col md={4} xs={12}>
                                <Form.Group className="g-input">
                                    <div className="d-flex align-items-center">
                                        <Form.Control
                                            id="RoleName"
                                            type="text"
                                            name="RoleName"
                                            maxLength="100"
                                            placeholder=" "
                                            required
                                            value={userObj.RoleName}
                                            onBlur={(e) => { checkValidData(e) }}
                                            onChange={(e) => { handleChange(e) }}
                                            readOnly={action === "View"}
                                        />
                                        <Form.Label className="frm-label" for="RoleName">User Role</Form.Label>
                                        <BiSelectMultiple
                                            className={action === "View" ? "d-none" : ""}
                                            size={20}
                                            onClick={() => showPopUp(RolePopUp, "Single", "RoleName", userObj.RoleName)}
                                            color={"#707070"}
                                            style={{ marginLeft: "-2rem" }}
                                            backdrop="static"
                                        />
                                    </div>
                                    {error.RoleName && (
                                        <Form.Text className="text-danger">
                                            Enter Valid Role Name.
                                        </Form.Text>
                                    )}
                                </Form.Group>
                            </Col>
                            <Col md={4} xs={12}>
                                <Form.Group className="g-input">
                                    <Form.Control
                                        id="Designation"
                                        type="text"
                                        name="Designation"
                                        maxLength="100"
                                        placeholder=" "
                                        required
                                        value={userObj.Designation}
                                        onChange={(e) => { handleChange(e) }}
                                        readOnly={action === "View"}
                                    />
                                    <Form.Label className="frm-label" for="Designation">Designation</Form.Label>
                                    {error.Designation && (
                                        <Form.Text className="text-danger">
                                            Enter Designation.
                                        </Form.Text>
                                    )}
                                </Form.Group>
                            </Col>
                            <Col md={4} xs={12}>
                                <Form.Group className="g-input">
                                    <Form.Control
                                        id="EmailId"
                                        type="text"
                                        name="EmailId"
                                        maxLength="100"
                                        placeholder=" "
                                        required
                                        value={userObj.EmailId}

                                        onChange={(e) => { handleChange(e) }}
                                        onBlur={(e) => {
                                            let error = isEmail(e.target.value);
                                            setError((prevState) => ({
                                                ...prevState,
                                                EmailId: error.status,
                                            }));
                                        }}
                                        readOnly={(action === "View" || userObj.UserType === "Employee")}
                                    />
                                    <Form.Label className="frm-label" for="EmailId">Official Email ID</Form.Label>
                                    {error.EmailId && (
                                        <Form.Text className="text-danger">
                                            Enter valid Email Id.
                                        </Form.Text>
                                    )}
                                </Form.Group>
                            </Col>

                            <Col md={4} xs={12}>
                                <Form.Group className="g-input">
                                    <Form.Control
                                        id="MobileNo"
                                        type="text"
                                        name="MobileNo"
                                        maxLength="20"
                                        placeholder=" "
                                        required
                                        value={userObj.MobileNo}
                                        onClick={() => setShowMobileNo(false)}
                                        onChange={(e) => { handleChange(e) }}
                                        onBlur={(e) => {
                                            let error = isMobNumber(e.target.value);
                                            console.log("errot", error)
                                            setError((prevState) => ({
                                                ...prevState,
                                                MobileNo: error,
                                            }));
                                            userObj.MobileNo ?
                                                setShowMobileNo(false) : setShowMobileNo(true)
                                        }}
                                        readOnly={action === "View"}
                                    />
                                    <Form.Label className="frm-label" for="MobileNo">Official Mobile No.
                                        {ShowMobileNo && ShowMobileNo === true ?
                                            <span className="telephone-no"> +91 8888888888</span>
                                            : null
                                        }
                                    </Form.Label>
                                    {error.MobileNo && (
                                        <Form.Text className="text-danger">
                                            Enter country code with Mobile no.
                                        </Form.Text>
                                    )}
                                </Form.Group>
                            </Col>
                            {(action === "Add" && userObj.UserType === "User") && (
                                <Col md={4} xs={12}>
                                    <Form.Group className="g-input">
                                        <Form.Control
                                            id="Password"
                                            type="Password"
                                            name="Password"
                                            maxLength="20"
                                            placeholder=" "
                                            value={userObj.Password}
                                            onChange={(e) => { handleChange(e) }}
                                            readOnly
                                        />
                                        <Form.Label className="frm-label" for="Password">Password</Form.Label>
                                    </Form.Group>
                                </Col>
                            )}
                            {((action === "Add" && userObj.UserType === "Employee") || (action === "Edit" || action === "View")) && (
                                <Col md={4} xs={12}>
                                </Col>
                            )}
                            <Col md={4} xs={12}><Row>
                                <Col>
                                    <Form.Label className="fieldLabel">Status</Form.Label>
                                </Col>
                                <Col>
                                    <Form.Switch className={"semiBoldToggleButton " + toggleClass}
                                        label={userObj.DeleteIndication === "Active" ? "Active" : "Inactive"}
                                        name="DeleteIndication"
                                        checked={userObj.DeleteIndication === "Active"}
                                        onChange={(e) => {
                                            handleSwitchChange(e);
                                        }}
                                        disabled={action === "View"}
                                    />
                                </Col>
                            </Row>
                            </Col>

                            {(action === "View") && (<>
                                <Col md={6} xs={12}>
                                    <Row>
                                        <Col md={9} xs={10}><Form.Label className="fieldLabel">Display Contract Financial Information </Form.Label></Col>
                                        <Col md={3} xs={2}><Form.Label className="fieldLabel semiBoldToggleButton">{userObj.DisplayContractFinInfo}</Form.Label></Col>
                                    </Row>
                                </Col>
                            </>
                            )}
                            {(action === "Add" || action === "Edit") && (
                                <Col md={6} xs={12}>
                                    <Row>
                                        <Form.Label className="fieldLabel locationType">Display Contract Financial Information</Form.Label>
                                    </Row>
                                    <Row>
                                        <Col md={3} xs={6}>
                                            <Form.Check className="fieldLabel locationType"
                                                type="radio"
                                                name="DisplayContractFinInfo"
                                                checked={userObj.DisplayContractFinInfo === "Yes"}
                                                onChange={(e) => { handleChange(e) }}
                                                label="Yes"
                                                value="Yes"
                                            ></Form.Check>
                                        </Col>
                                        <Col md={6} xs={6}>
                                            <Form.Check className="fieldLabel locationType"
                                                type="radio"
                                                name="DisplayContractFinInfo"
                                                checked={userObj.DisplayContractFinInfo === "No"}
                                                onChange={(e) => { handleChange(e) }}
                                                label="No"
                                                value="No"
                                            ></Form.Check>
                                        </Col>
                                    </Row>
                                </Col>
                            )}
                            {/* <Col md={4} xs={12}>
                                </Col> */}
                        </Row>
                        <Row >
                            <Col md={9} xs={6}><Row>
                                <Col md={3} xs={12}>{action === "View" || action === "Edit" ? null :
                                    <Button className="semiBoldToggleButton cancelBtn"
                                        variant="outline-success"
                                        onClick={handleCancel}
                                    >
                                        Cancel
                                    </Button>
                                }
                                </Col>
                                <Col xs={12} md={6}>{action === "View" || action === "Edit" ? <></> :
                                    <Button className="resetBtn semiBoldToggleButton"
                                        variant="link"
                                        onClick={handleReset}
                                    >Reset Fields
                                    </Button>
                                }
                                </Col>
                            </Row>
                            </Col>
                            <Col md={3} xs={6}>
                                {action === "View" ?
                                    <Button variant="success" className="btn-add-color semiBoldToggleButton rightAlignedButton" type="submit">
                                        Done
                                    </Button>
                                    :
                                    <Button variant="success" className="btn-add-color semiBoldToggleButton rightAlignedButton" type="submit">
                                        {action === "Edit" ? "Update" : "Add"}
                                    </Button>
                                }
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
            </Modal>
            {/* <RenderIf isTrue={popUp.type === "Single" && showForm}>
                    <MultiValuePopUp
                        show={showForm}
                        onHide={() => setShowForm(false)}
                        setSelected={setPopUpData}
                        selectionType={popUp.type.toLowerCase()}
                        masterName={popUp.name}
                        paramkey={popUp.key}
                        paramvalue={popUp.value}
                    //    locationData={companydivisionObj.CompanyCountry}
                    />
                </RenderIf> */}
            <RenderIf isTrue={popUp.type === "Single" && showForm}>
                <SingleValuePopUp
                    show={showForm}
                    onHide={() => setShowForm(false)}
                    setSelected={setPopUpData}
                    selectionType={popUp.type.toLowerCase()}
                    mastername={popUp.name}
                    paramkey={popUp.key}
                    paramvalue={popUp.value}
                //    locationData={companydivisionObj.CompanyCountry}
                />
            </RenderIf>
        </>
    );
}

export default AddEditUserMaster;
