import React from "react";
import { Row, Col, Button } from "react-bootstrap";
import { ChevronLeft, ChevronRight } from "react-bootstrap-icons";

const TabButtons = (props) => {
    let currentTab = props.currentTab;
    let action = props.action;
    return (<>
        {/* <Row className="tabButtonPosition"> */}
        <Col md={2} xs={12} className="d-none d-md-block d-lg-block">
            {(props.showFreeze && currentTab === props.lastTab && action !== "View") && (
                <Button variant="outline-success" className="semiBoldToggleButton cancelBtn" onClick={() => props.handleFreeze()}>
                    Freeze
                </Button>
            )
            }
            {
                (props.handleReset && currentTab === 0 && action === "Add") && (
                    <Button variant="outline-success" className="semiBoldToggleButton cancelBtn" onClick={(e) => props.handleReset(e)}>
                        Reset
                    </Button>
                )
            }
        </Col>
        <Col md={8} xs={12} className="centerAlign hstack">
            <ChevronLeft
                size={10}
                onClick={(e) => props.handleSelect(e, "prev")}
                className={`prevArrow ${currentTab === 0 ? "disabledArrow" : "activeArrow"}`}
            />
            <Button variant="link" className={`resetBtn semiBoldToggleButton prevNext ${currentTab === 0 ? "disabledArrow" : "activeArrow"}`} onClick={(e) => props.handleSelect(e, "prev")}>
                Prev
            </Button>&nbsp;<div className="verticalLine"></div>&nbsp;
            <Button variant="link" className={`resetBtn semiBoldToggleButton prevNext ${currentTab === props.lastTab ? "disabledArrow" : "activeArrow"}`} onClick={(e) => props.handleSelect(e, "next")}>
                Next
            </Button>
            <ChevronRight
                size={10}
                onClick={(e) => props.handleSelect(e, "next")}
                className={`nextArrow ${currentTab === props.lastTab ? "disabledArrow" : "activeArrow"}`}
            />
        </Col>
        <Col md={2} xs={6} className="d-md-none d-lg-none">
            {(props.showFreeze && currentTab === props.lastTab && action !== "View") && (
                <Button variant="outline-success" className="semiBoldToggleButton cancelBtn" onClick={() => props.handleFreeze()}>
                    Freeze
                </Button>
            )}
            {(props.handleReset && currentTab === 0 && action === "Add") && (
                <Button variant="outline-success" className="semiBoldToggleButton cancelBtn" onClick={(e) => props.handleReset(e)}>
                    Reset
                </Button>
            )
            }
        </Col>
        <Col md={2} xs={6}>
            {action !== "View" ? (props.showSave &&
                <Button variant="success" className="btn-add-color semiBoldToggleButton rightAlignedButton" onClick={(e) => props.handleSelect(e, "save")}>
                    Save
                </Button>)
                :
                (<Button variant="success" className="btn-add-color semiBoldToggleButton rightAlignedButton" onClick={(e) => props.handleBack()}>
                    Done
                </Button>)
            }
        </Col>
        {/* </Row> */}
    </>
    )
}

export default TabButtons;
