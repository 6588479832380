import axios from "axios";
import { ServiceMasterAllData } from "./common-master-service";
import { getProjectActiveServiceEqui } from "./project-service";
import { getSelectedEquipDetails } from "./equipmentactivity-master-service";
import { getSORDashboardData } from "./siteobservationreport-service";
import { getISTEquipDashboardData } from "./integrated-system-testing-service";
import { getProjTranChklst } from "./project-transaction-service";
import { sortByPropertyInAscending } from "../utils/Utility";
import { getAllDocumentsDataForProject } from "./project-document-service";
import { getDesignReviewTrackerData } from "./designreviewtracker-service";
import { getDocReviewEquipmentTagData, getDocumentReviewTracker } from "./documentreviewtracker-service";
import { getChecklistParameterDetails } from "./checklist-master-service";
const TRANS_URL = process.env.REACT_APP_TRANSACTION_API_URL;


//==================== Get Milestone Master ====================
export async function getMilestoneMaster() {
    const response = await axios
        .get(TRANS_URL + "/dashboard/getmilestonemaster")
        .then(function (resp) {
            return resp;
        })
        .catch(function (error) {
            console.log("error => ", error);
            return { data: [] };
        });
    return response.data;
}

//==================== Dashboard Calculation ====================
export async function calculateDashboard(projObj) {
    console.log("projObj", projObj);

    const [serviceData, projEquipData, milestoneData, projectDocumentData] = await Promise.all([
        ServiceMasterAllData(),
        getProjectActiveServiceEqui(projObj.ProjectCode),
        getMilestoneMaster(),
        getAllDocumentsDataForProject(projObj.ProjectCode)
    ]);

    // Design Review Data for T&C projects
    let designRev = [];
    if (projObj.ProjectType === "T&C") {
        designRev = await getDesignReviewTrackerData(projObj.ProjectCode);
    }

    milestoneData.sort(sortByPropertyInAscending("SeqNo"));

    let arr = [];
    let equipWiseData = [];
    let allData = [];
    let finalData = [];
    let fireData = {};
    let distServices = {};
    let totalServicePerc = 0;
    let totalApplicableServicePerc = 0;
    let totalMilestonePerc = 0;

    for (let dt of serviceData) {
        totalServicePerc = totalServicePerc + dt.ServiceWeightage;
    }

    for (let dt of milestoneData) {
        totalMilestonePerc = totalMilestonePerc + + dt.MilestoneWeightage;
    }

    if (projEquipData.length > 0) {
        for (let element of projEquipData) {
            if (arr.indexOf(element.EquipmentCode) === -1) {
                arr.push(element.EquipmentCode)
                fireData[element.EquipmentCode] = { closedCount: 0, openCount: 0 };

                let tmpSrv = serviceData?.find(item => item.ServiceCode === element.ServiceCode);
                if (tmpSrv) {
                    if (!distServices[element.ServiceCode]) {
                        //calculate service wise Design review tracker count
                        let openDesign = [];
                        let closeDesign = [];
                        if (projObj.ProjectType === "T&C") {
                            openDesign = designRev.filter(x => x.ServiceCode === element.ServiceCode && x.FreezeInd !== "Yes");
                            closeDesign = designRev.filter(x => x.ServiceCode === element.ServiceCode && x.FreezeInd === "Yes");
                        }

                        distServices[element.ServiceCode] = { rowspanCnt: 0, srvData: tmpSrv, srvEquipQty: 0, ServiceCompletion: 0, DesignRevOpen: openDesign.length, DesignRevClosed: closeDesign.length };
                        totalApplicableServicePerc = totalApplicableServicePerc + tmpSrv.ServiceWeightage;
                    }
                }
            }
            if (element.FireIntegrationFlag === "Yes" || element.FireIntegrationFlag === "NA") {
                fireData[element.EquipmentCode].closedCount = fireData[element.EquipmentCode].closedCount + 1
            }
            else {
                fireData[element.EquipmentCode].openCount = fireData[element.EquipmentCode].openCount + 1
            }
        }
    }

    const [equipData, sorData, istEquipData, satData] = await Promise.all([getSelectedEquipDetails(arr),
    getSORDashboardData(projObj.ProjectCode),
    getISTEquipDashboardData(projObj.ProjectCode),
    getProjTranChklst("SAT", projObj.ProjectCode)]);

    if (equipData.length > 0) {

        for (let equiDt of equipData) {
            //calculate rowspan for service
            distServices[equiDt.ServiceCode].rowspanCnt = distServices[equiDt.ServiceCode].rowspanCnt + 1;

            // Document Review Data for HOTO projects
            let docRevMain = [];
            let docRevTag = [];
            if (projObj.ProjectType === "HOTO") {
                let mainObj = {
                    ProjectCode: projObj.ProjectCode,
                    EquipmentCode: equiDt.EquipmentCode
                };
                let tagObj = {
                    primekey: "DocumentReviewTracker#ProjectCode#" + projObj.ProjectCode + "#EquipmentCode#" + equiDt.EquipmentCode,
                    sortkey: "DocumentName#"
                };
                [docRevMain, docRevTag] = await Promise.all([getDocumentReviewTracker(mainObj),
                getDocReviewEquipmentTagData(tagObj)]);
            }

            //dashboard calculation for each equip
            let tmpEquip = projEquipData.filter(item => item.EquipmentCode === equiDt.EquipmentCode);
            let applicableCategory = equiDt.SATEquipApplicableChecklist.split(",");
            if (tmpEquip.length > 0) {
                let tmpDocRevEquipOpen = 0;
                let tmpDocRevEquipClose = 0;
                //calculating total equipment tag count against service
                distServices[equiDt.ServiceCode].srvEquipQty = distServices[equiDt.ServiceCode].srvEquipQty + tmpEquip.length;

                for (let dt of tmpEquip) {
                    let preChecklist = satData.filter(item => item.ChecklistCategory === "Pre-Functional" && equiDt.EquipmentCode === item.EquipmentCode && dt.EquipmentTag === item.EquipmentTag);
                    let funcChecklist = satData.filter(item => item.ChecklistCategory === "Functional" && equiDt.EquipmentCode === item.EquipmentCode && dt.EquipmentTag === item.EquipmentTag);
                    let tmpDocRevTag = docRevTag.filter(x => x.EquipmentTag === dt.EquipmentTag);
                    let tmpDocRevClosed = (docRevMain.length > 0 && (tmpDocRevTag.length > 0 && tmpDocRevTag[0].ApprovalStatus === "Completed")) ? 1 : 0;
                    let tmpDocRevOpen = docRevMain.length > 0 ? (docRevMain.length - tmpDocRevClosed) : 0;

                    let obj = {
                        ServiceCode: dt.ServiceCode,
                        ServiceName: distServices[dt.ServiceCode].srvData.ServiceName,
                        EquipmentCode: dt.EquipmentCode,
                        EquipmentName: equiDt.EquipmentName,
                        EquipmentTag: dt.EquipmentTag,
                        ServiceWeightage: distServices[dt.ServiceCode].srvData.ServiceWeightage,
                        TenderInclusion: distServices[dt.ServiceCode].srvData.TenderInclusion,
                        FireIntegrationFlag: dt.FireIntegrationFlag,
                        FATEquipApplicableChecklist: equiDt.FATEquipApplicableChecklist,
                        SATEquipApplicableChecklist: equiDt.SATEquipApplicableChecklist,
                        MethodStatement: equiDt.MethodStatement,
                        DetailedReport: equiDt.DetailedReport,
                        FireIntegrationService: equiDt.FireIntegrationService,
                        IntegrationSystemTest: equiDt.IntegrationSystemTest,
                        SORClosed: sorData.equipTagWiseSOR[equiDt.EquipmentCode + "#" + dt.EquipmentTag] ? sorData.equipTagWiseSOR[equiDt.EquipmentCode + "#" + dt.EquipmentTag].closedCount : 0,
                        SOROpen: sorData.equipTagWiseSOR[equiDt.EquipmentCode + "#" + dt.EquipmentTag] ? sorData.equipTagWiseSOR[equiDt.EquipmentCode + "#" + dt.EquipmentTag].openCount : 0,
                        ISTEquipClosed: istEquipData.equipTagWiseIST[equiDt.EquipmentCode + "#" + dt.EquipmentTag] ? istEquipData.equipTagWiseIST[equiDt.EquipmentCode + "#" + dt.EquipmentTag].closedCount : 0,
                        ISTEquipOpen: istEquipData.equipTagWiseIST[equiDt.EquipmentCode + "#" + dt.EquipmentTag] ? istEquipData.equipTagWiseIST[equiDt.EquipmentCode + "#" + dt.EquipmentTag].openCount : 0,
                        PreFuncApp: applicableCategory.includes("Pre-Functional") ? true : false,
                        FuncApp: applicableCategory.includes("Functional") ? true : false,
                        PreFuncChecklist: applicableCategory.includes("Pre-Functional") ? (preChecklist.length > 0 ? preChecklist[0]?.IsFreeze : false) : true,
                        FuncChecklist: applicableCategory.includes("Functional") ? (funcChecklist.length > 0 ? funcChecklist[0]?.IsFreeze : false) : true,
                        // FireClosed: fireData[equiDt.EquipmentCode] ? fireData[equiDt.EquipmentCode].closedCount : 0,
                        // FireOpen: fireData[equiDt.EquipmentCode] ? fireData[equiDt.EquipmentCode].openCount : 0,
                        DocumentRevOpen: tmpDocRevOpen,
                        DocumentRevClosed: tmpDocRevClosed,
                        DesignRevOpen: distServices[dt.ServiceCode].DesignRevOpen,
                        DesignRevClosed: distServices[dt.ServiceCode].DesignRevClosed
                    }

                    if (applicableCategory.includes("Pre-Functional") && preChecklist.length > 0) {
                        obj.Area = preChecklist[0]?.Area;
                    }
                    else if (applicableCategory.includes("Functional") && funcChecklist.length > 0) {
                        obj.Area = funcChecklist[0]?.Area;
                    }
                    else {
                        obj.Area = "";
                    }
                    tmpDocRevEquipOpen = tmpDocRevEquipOpen + tmpDocRevOpen;
                    tmpDocRevEquipClose = tmpDocRevEquipClose + tmpDocRevClosed;

                    allData.push(obj);
                }

                let isExist = equipWiseData.filter(item => item.EquipmentCode === equiDt.EquipmentCode);
                if (isExist.length === 0) {
                    let obj = {
                        ServiceCode: equiDt.ServiceCode,
                        ServiceName: distServices[equiDt.ServiceCode].srvData.ServiceName,
                        // FixedService: distServices[equiDt.ServiceCode].srvData.ServiceWeightage,
                        // ApplicableService: "Yes", //allData array has service data only if equipment is maintained in Project Master Tab 4
                        // CalculateService: distServices[equiDt.ServiceCode].srvData.ServiceWeightage,
                        // ServicePer: totalApplicableServicePerc === 0 ? 0 : parseFloat(distServices[equiDt.ServiceCode].srvData.ServiceWeightage * totalServicePerc / totalApplicableServicePerc).toFixed(2),
                        EquipmentCode: equiDt.EquipmentCode,
                        EquipmentName: equiDt.EquipmentName,
                        EquipmentQty: tmpEquip.length,
                        TenderInclusion: distServices[equiDt.ServiceCode].srvData.TenderInclusion,
                        FATEquipApplicableChecklist: equiDt.FATEquipApplicableChecklist,
                        SATEquipApplicableChecklist: equiDt.SATEquipApplicableChecklist,
                        MethodStatement: equiDt.MethodStatement,
                        DetailedReport: equiDt.DetailedReport,
                        FireIntegrationService: equiDt.FireIntegrationService,
                        IntegrationSystemTest: equiDt.IntegrationSystemTest,
                        SORClosed: sorData.equipWiseSOR[equiDt.EquipmentCode] ? sorData.equipWiseSOR[equiDt.EquipmentCode].closedCount : 0,
                        SOROpen: sorData.equipWiseSOR[equiDt.EquipmentCode] ? sorData.equipWiseSOR[equiDt.EquipmentCode].openCount : 0,
                        ISTEquipClosed: istEquipData.equipWiseIST[equiDt.EquipmentCode] ? istEquipData.equipWiseIST[equiDt.EquipmentCode].closedCount : 0,
                        ISTEquipOpen: istEquipData.equipWiseIST[equiDt.EquipmentCode] ? istEquipData.equipWiseIST[equiDt.EquipmentCode].openCount : 0,
                        PreFuncApp: applicableCategory.includes("Pre-Functional") ? true : false,
                        FuncApp: applicableCategory.includes("Functional") ? true : false,
                        FireClosed: fireData[equiDt.EquipmentCode] ? fireData[equiDt.EquipmentCode].closedCount : 0,
                        FireOpen: fireData[equiDt.EquipmentCode] ? fireData[equiDt.EquipmentCode].openCount : 0,
                        DocumentRevOpen: tmpDocRevEquipOpen,
                        DocumentRevClosed: tmpDocRevEquipClose,
                        DesignRevOpen: distServices[equiDt.ServiceCode].DesignRevOpen,
                        DesignRevClosed: distServices[equiDt.ServiceCode].DesignRevClosed
                    }
                    equipWiseData.push(obj);
                }
            }
        }
    }
    // console.log("total", totalServicePerc, totalApplicableServicePerc);
    // console.log("allData", allData);
    // console.log("equipWiseData", equipWiseData);
    equipWiseData.sort(sortByPropertyInAscending("ServiceCode"));

    // let tempSerComplArr = [];
    for (let dt of equipWiseData) {
        let tmpEquipData = [];
        let totalApplicableMilestone = 0;
        let totalMilestoneWeightage = 0;
        let totalMilestoneWeightageCompletion = 0;

        for (let ms of milestoneData) {
            let currentWeightage = 0;

            let obj = {
                ServiceCode: dt.ServiceCode,
                ServiceName: distServices[dt.ServiceCode].srvData.ServiceName,
                FixedService: distServices[dt.ServiceCode].srvData.ServiceWeightage,
                ApplicableService: "Yes", //allData array has service data only if equipment is maintained in Project Master Tab 4
                CalculateService: distServices[dt.ServiceCode].srvData.ServiceWeightage,
                ServicePer: totalApplicableServicePerc === 0 ? 0 : parseFloat((distServices[dt.ServiceCode].srvData.ServiceWeightage * totalServicePerc / totalApplicableServicePerc).toFixed(2)),
                EquipmentCode: dt.EquipmentCode,
                EquipmentName: dt.EquipmentName,
                EquipmentQty: dt.EquipmentQty,
                Milestone: ms.Milestone,
                FixedMilestone: ms.MilestoneWeightage,
                ApplicableMilestone: ms.MandatoryMilestone ? "Yes" : "No",
                MilestoneCount: 0,
                MilestoneCountCompleted: 0,
                ServiceCompletion: 0
            }

            currentWeightage = ms.MilestoneWeightage;

            if (ms.Milestone === "Document Verification") {
                let docVerification = {
                    "T&C": [{ DocumentType: "Commissioning", flag: "ProjectLevel" },
                    { DocumentType: "Summary", flag: "ProjectLevel" },
                    { DocumentType: "Tender", flag: "ServiceLevel", applicableFlag: "TenderInclusion" },
                    { DocumentType: "Method", flag: "EquipmentLevel", applicableFlag: "MethodStatement", additionalFlag: false },
                    { DocumentType: "Detailed SAT", flag: "EquipmentLevel", applicableFlag: "DetailedReport", additionalFlag: true, additionalFlagField: "SATEquipApplicableChecklist" },
                    { DocumentType: "Detailed FAT", flag: "EquipmentLevel", applicableFlag: "DetailedReport", additionalFlag: true, additionalFlagField: "FATEquipApplicableChecklist" },
                    { DocumentType: "IST", flag: "IST", SubDocumentType: "ISTMethodStatementCritical", CriticalDoc: true },
                    { DocumentType: "IST", flag: "IST", SubDocumentType: "ISTMethodStatementNonCritical", CriticalDoc: false },
                    { DocumentType: "IST", flag: "IST", SubDocumentType: "ISTReportCritical", CriticalDoc: true },
                    { DocumentType: "IST", flag: "IST", SubDocumentType: "ISTReportNonCritical", CriticalDoc: false }
                    ],
                    "HOTO": [{ DocumentType: "Commissioning", flag: "ProjectLevel" },
                    { DocumentType: "HOTO", flag: "ProjectLevel" },
                    { DocumentType: "IST", flag: "IST", SubDocumentType: "ISTMethodStatementCritical", CriticalDoc: true },
                    { DocumentType: "IST", flag: "IST", SubDocumentType: "ISTMethodStatementNonCritical", CriticalDoc: false },
                    { DocumentType: "IST", flag: "IST", SubDocumentType: "ISTReportCritical", CriticalDoc: true },
                    { DocumentType: "IST", flag: "IST", SubDocumentType: "ISTReportNonCritical", CriticalDoc: false }]
                }

                let tmpMilestoneCnt = 0;
                let tmpMilestoneCntCompleted = 0;

                for (let docDt of docVerification[projObj.ProjectType]) {
                    tmpMilestoneCnt++;
                    if (docDt.flag === "ProjectLevel") {
                        let docData = projectDocumentData.find((x) => x.DocumentType === docDt.DocumentType);
                        if (docData && docData.FreezeIndication === "Freeze") {
                            tmpMilestoneCntCompleted++;
                            for (let da of allData) {
                                da[docDt.DocumentType] = 1;
                            }
                        }
                    }
                    if (docDt.flag === "ServiceLevel") {
                        if (docDt.applicableFlag && dt[docDt.applicableFlag]) {
                            let docData = projectDocumentData.find((x) => x.DocumentType === docDt.DocumentType && x.ServiceCode === dt.ServiceCode);
                            if (docData && docData.FreezeIndication === "Freeze") {
                                tmpMilestoneCntCompleted++;

                                for (let da of allData.filter(x => x.ServiceCode === dt.ServiceCode)) {
                                    da[docDt.DocumentType] = 1;
                                }
                            }
                        }
                        else {
                            //If not applicable, consider as done.
                            tmpMilestoneCntCompleted++;
                            allData.filter(x => x.ServiceCode === dt.ServiceCode).map(res => ({ ...res, [docDt.DocumentType]: 1 }));
                        }
                    }
                    if (docDt.flag === "EquipmentLevel") {
                        if (docDt.applicableFlag && dt[docDt.applicableFlag] &&
                            (!docDt.additionalFlag || (docDt.additionalFlag && dt[docDt.additionalFlagField]))) {
                            let docData = projectDocumentData.find((x) => x.DocumentType === docDt.DocumentType && x.ServiceCode === dt.ServiceCode && x.EquipmentCode === dt.EquipmentCode);
                            if (docData && docData.FreezeIndication === "Freeze") {
                                tmpMilestoneCntCompleted++;
                                for (let da of allData.filter(x => x.ServiceCode === dt.ServiceCode && x.EquipmentCode === dt.EquipmentCode)) {
                                    da[docDt.DocumentType] = 1;
                                }
                            }
                        }
                        else {
                            //If not applicable, consider as done.
                            tmpMilestoneCntCompleted++;
                            for (let da of allData.filter(x => x.ServiceCode === dt.ServiceCode && x.EquipmentCode === dt.EquipmentCode)) {
                                da[docDt.DocumentType] = 1;
                            }
                        }
                    }

                    if (docDt.flag === "IST") {
                        if ((docDt.CriticalDoc && projObj.ISTApplicable === "Yes") || !docDt.CriticalDoc) {
                            let docData = projectDocumentData.find((x) => x.DocumentType === docDt.DocumentType && x.SubDocumentType === docDt.SubDocumentType);
                            if (docData && docData.FreezeIndication === "Freeze") {
                                tmpMilestoneCntCompleted++;
                                for (let da of allData.filter(x => x.ServiceCode === dt.ServiceCode && x.EquipmentCode === dt.EquipmentCode)) {
                                    da[docDt.DocumentType] = (da[docDt.DocumentType] ? da[docDt.DocumentType] : 0) + 1;
                                }
                            }
                        }
                        else {
                            //If not applicable, consider as done.
                            tmpMilestoneCntCompleted++;
                            for (let da of allData.filter(x => x.ServiceCode === dt.ServiceCode && x.EquipmentCode === dt.EquipmentCode)) {
                                da[docDt.DocumentType] = (da[docDt.DocumentType] ? da[docDt.DocumentType] : 0) + 1;
                            }
                        }
                    }
                }

                //SOR
                tmpMilestoneCnt = tmpMilestoneCnt + dt.SORClosed + dt.SOROpen;
                tmpMilestoneCntCompleted = tmpMilestoneCntCompleted + dt.SORClosed;

                if (projObj.ProjectType === "T&C") {
                    //Design Review Tracker
                    tmpMilestoneCnt = tmpMilestoneCnt + dt.DesignRevClosed + dt.DesignRevOpen;
                    tmpMilestoneCntCompleted = tmpMilestoneCntCompleted + dt.DesignRevClosed;

                    //Site Acceptance Test Pre-Functional
                    tmpMilestoneCnt = tmpMilestoneCnt + dt.EquipmentQty;
                    if (dt.PreFuncApp) {
                        let tmpChk = allData.filter(x => x.EquipmentCode === dt.EquipmentCode && x.PreFuncChecklist)
                        tmpMilestoneCntCompleted = tmpMilestoneCntCompleted + tmpChk.length;
                    }
                    else {
                        //If not applicable, consider as done.
                        tmpMilestoneCntCompleted = tmpMilestoneCntCompleted + dt.EquipmentQty;
                    }


                    //Site Acceptance Test Functional
                    tmpMilestoneCnt = tmpMilestoneCnt + dt.EquipmentQty;
                    if (dt.FuncApp) {
                        let tmpChk = allData.filter(x => x.EquipmentCode === dt.EquipmentCode && x.FuncChecklist)
                        tmpMilestoneCntCompleted = tmpMilestoneCntCompleted + tmpChk.length;
                    }
                    else {
                        //If not applicable, consider as done.
                        tmpMilestoneCntCompleted = tmpMilestoneCntCompleted + dt.EquipmentQty;
                    }
                }

                if (projObj.ProjectType === "HOTO") {
                    //Document Review Tracker
                    tmpMilestoneCnt = tmpMilestoneCnt + dt.DocumentRevClosed + dt.DocumentRevOpen;
                    tmpMilestoneCntCompleted = tmpMilestoneCntCompleted + dt.DocumentRevClosed;

                }
                obj.MilestoneCount = tmpMilestoneCnt;
                obj.MilestoneCountCompleted = tmpMilestoneCntCompleted;
            }

            switch (ms.Milestone) {
                case "Site Observation Report (SOR) Closing":
                    obj.MilestoneCount = dt.SORClosed + dt.SOROpen;
                    obj.MilestoneCountCompleted = dt.SORClosed;
                    break;
                case "Pre-functional Verification":
                    if (projObj.ProjectType === "T&C" && dt.PreFuncApp) {
                        obj.ApplicableMilestone = "Yes";
                        obj.MilestoneCount = dt.EquipmentQty;
                        let tmpChk = allData.filter(x => x.EquipmentCode === dt.EquipmentCode && x.PreFuncChecklist);
                        obj.MilestoneCountCompleted = tmpChk.length;
                    }
                    else {
                        obj.ApplicableMilestone = "No";
                        currentWeightage = 0;
                        obj.MilestoneCount = 0;
                        obj.MilestoneCountCompleted = 0;
                    }
                    break;
                case "Functional Verification":
                    if (projObj.ProjectType === "T&C" && dt.FuncApp) {
                        obj.ApplicableMilestone = "Yes";
                        obj.MilestoneCount = dt.EquipmentQty;
                        let tmpChk = allData.filter(x => x.EquipmentCode === dt.EquipmentCode && x.FuncChecklist);
                        obj.MilestoneCountCompleted = tmpChk.length;
                    }
                    else {
                        obj.ApplicableMilestone = "No";
                        currentWeightage = 0;
                        obj.MilestoneCount = 0;
                        obj.MilestoneCountCompleted = 0;
                    }
                    break;
                case "Fire Integration Verification":
                    if (dt.FireIntegrationService) {
                        obj.ApplicableMilestone = "Yes";
                        obj.MilestoneCount = dt.EquipmentQty;
                        obj.MilestoneCountCompleted = dt.FireClosed;
                    }
                    else {
                        obj.ApplicableMilestone = "No";
                        currentWeightage = 0;
                        obj.MilestoneCount = 0;
                        obj.MilestoneCountCompleted = 0;
                    }
                    break;
                case "IST - Integrated System Test":
                    if (dt.IntegrationSystemTest) {
                        obj.ApplicableMilestone = "Yes";
                        obj.MilestoneCount = dt.ISTEquipClosed + dt.ISTEquipOpen;
                        obj.MilestoneCountCompleted = dt.ISTEquipClosed;
                    }
                    else {
                        obj.ApplicableMilestone = "No";
                        currentWeightage = 0;
                        obj.MilestoneCount = 0;
                        obj.MilestoneCountCompleted = 0;
                    }
                    break;
            }

            obj.CalculatedMilestone = currentWeightage;
            totalApplicableMilestone = totalApplicableMilestone + currentWeightage;

            obj.EquipmentWeightage = distServices[dt.ServiceCode].srvEquipQty === 0 ? 0 : parseFloat((dt.EquipmentQty * Number(obj.ServicePer) / distServices[dt.ServiceCode].srvEquipQty).toFixed(2));

            // tempSerComplArr.push({ ServiceCode: dt.ServiceCode, MilestoneWeightageCompletion: obj.MilestoneWeightageCompletion });
            tmpEquipData.push(obj);
        }

        for (let tmpDt of tmpEquipData) {
            tmpDt.MilestonePerc = totalApplicableMilestone === 0 ? 0 : parseFloat((tmpDt.CalculatedMilestone * totalMilestonePerc / totalApplicableMilestone).toFixed(2));
            tmpDt.MilestoneWeightageDist = parseFloat((tmpDt.EquipmentWeightage * tmpDt.MilestonePerc / 100).toFixed(2));
            tmpDt.EquipmentMilestoneWeightage = tmpDt.ServicePer === 0 ? 0 : parseFloat((tmpDt.MilestoneWeightageDist * 100 / Number(tmpDt.ServicePer)).toFixed(2));
            tmpDt.MilestoneWeightage = Number(tmpDt.EquipmentMilestoneWeightage);
            tmpDt.MilestoneWeightageCompletion = tmpDt.MilestoneCount === 0 ? 0 : parseFloat((tmpDt.MilestoneWeightage * (tmpDt.MilestoneCountCompleted / tmpDt.MilestoneCount)).toFixed(2));
            tmpDt.MilestoneCompletion = tmpDt.MilestoneWeightage === 0 ? 0 : parseFloat(((tmpDt.MilestoneWeightageCompletion / tmpDt.MilestoneWeightage) * 100).toFixed(2));
            totalMilestoneWeightageCompletion = totalMilestoneWeightageCompletion + tmpDt.MilestoneWeightageCompletion;
            totalMilestoneWeightage = totalMilestoneWeightage + tmpDt.MilestoneWeightage;

            distServices[dt.ServiceCode].ServiceCompletion = distServices[dt.ServiceCode].ServiceCompletion + tmpDt.MilestoneWeightageCompletion;
        }

        for (let tmpDt of tmpEquipData) {
            tmpDt.EquipmentCompletion = totalMilestoneWeightage === 0 ? 0 : parseFloat(((totalMilestoneWeightageCompletion / totalMilestoneWeightage) * 100).toFixed(2));
        }

        finalData = [...finalData, ...tmpEquipData];
    }

    let dashTab1GridData = [];

    let servicecounter = 1;
    let equipTemp = [];
    let tempSevName = [];
    let spanCnt = 0;
    let equipSpanCnt = 0;
    let tab1SpanCnt = 0;

    let milestoneMappingforTab1 = {
        "Document Verification": "DocumentsSubmission",
        "Site Observation Report (SOR) Closing": "SORClosure",
        "Pre-functional Verification": "PreFunctional",
        "Functional Verification": "Functional",
        "Fire Integration Verification": "FireIntegration",
        "IST - Integrated System Test": "IST"
    }
    let tab1AvgValue = {
        ServiceName: "Activity Completion %",
        EquipmentName: "",
        OverallCompletionActivityWise: 0,
        totalServiceRowSpan: 1,
        ServiceRowSpan: 1
    };
    let tmpTab1Graph3Data = {};
    let tab1Graph3Data = {};

    for (let key of Object.keys(milestoneMappingforTab1)) {
        tab1AvgValue[milestoneMappingforTab1[key]] = 0;
        tmpTab1Graph3Data[milestoneMappingforTab1[key]] = { MilestoneWeightage: 0, MilestoneWeightageCompletion: 0 };
        tab1Graph3Data[milestoneMappingforTab1[key]] = { MilestoneWeightage: 0, MilestoneCompletion: 0, MilestoneProgress: 0 };
    }
    console.log("tmpTab1Graph3Data ini", tmpTab1Graph3Data)

    for (let ele of finalData) {
        ele.ServiceCompletion = parseFloat(distServices[ele.ServiceCode].ServiceCompletion.toFixed(2));
        ele.ServiceWeightageCompletion = parseFloat((ele.ServiceCompletion * Number(ele.ServicePer) / 100).toFixed(2));
    }
    for (let ele of finalData) {
        if (tempSevName.indexOf(ele.ServiceCode) === -1) {
            tempSevName.push(ele.ServiceCode)
            ele["ServcieSeqNo"] = servicecounter
            servicecounter++;
            spanCnt = 0;
            tab1SpanCnt = 0;
        }

        if (equipTemp.indexOf(ele.ServiceCode + "#" + ele.EquipmentCode) === -1) {
            equipTemp.push(ele.ServiceCode + "#" + ele.EquipmentCode);
            dashTab1GridData.push({
                ServiceCode: ele.ServiceCode,
                ServiceName: ele.ServiceName,
                EquipmentCode: ele.EquipmentCode,
                EquipmentName: ele.EquipmentName,
                ServicePer: ele.ServicePer,
                ServiceCompletion: ele.ServiceCompletion,
                ServiceWeightageCompletion: ele.ServiceWeightageCompletion,
                OverallCompletionActivityWise: ele.EquipmentCompletion,
                totalServiceRowSpan: distServices[ele.ServiceCode].rowspanCnt,
                ServiceRowSpan: distServices[ele.ServiceCode].rowspanCnt - tab1SpanCnt
            });
            equipSpanCnt = 0;
            tab1SpanCnt++;
            tab1AvgValue.OverallCompletionActivityWise = tab1AvgValue.OverallCompletionActivityWise + ele.EquipmentCompletion;
        }

        ele.totalEquipRowSpan = milestoneData.length;
        ele.EquipRowSpan = milestoneData.length - equipSpanCnt;
        equipSpanCnt++;

        ele.totalServiceRowSpan = distServices[ele.ServiceCode].rowspanCnt * milestoneData.length;
        ele.ServiceRowSpan = (distServices[ele.ServiceCode].rowspanCnt * milestoneData.length) - spanCnt;
        spanCnt++;

        dashTab1GridData[dashTab1GridData.length - 1][milestoneMappingforTab1[ele.Milestone]] = ele.MilestoneCompletion;
        tab1AvgValue[milestoneMappingforTab1[ele.Milestone]] = tab1AvgValue[milestoneMappingforTab1[ele.Milestone]] + ele.MilestoneCompletion;

        tmpTab1Graph3Data[milestoneMappingforTab1[ele.Milestone]].MilestoneWeightage = tmpTab1Graph3Data[milestoneMappingforTab1[ele.Milestone]].MilestoneWeightage + ele.MilestoneWeightage;
        tmpTab1Graph3Data[milestoneMappingforTab1[ele.Milestone]].MilestoneWeightageCompletion = tmpTab1Graph3Data[milestoneMappingforTab1[ele.Milestone]].MilestoneWeightageCompletion + ele.MilestoneWeightageCompletion;

    }
    console.log("tmpTab1Graph3Data", tmpTab1Graph3Data)
    if (dashTab1GridData.length > 0) {
        // tab1AvgValue.DocumentsSubmission = parseFloat((tab1AvgValue.DocumentsSubmission / dashTab1GridData.length).toFixed(2));
        // tab1AvgValue.SORClosure = parseFloat((tab1AvgValue.SORClosure / dashTab1GridData.length).toFixed(2));
        // tab1AvgValue.PreFunctional = parseFloat((tab1AvgValue.PreFunctional / dashTab1GridData.length).toFixed(2));
        // tab1AvgValue.Functional = parseFloat((tab1AvgValue.Functional / dashTab1GridData.length).toFixed(2));
        // tab1AvgValue.FireIntegration = parseFloat((tab1AvgValue.FireIntegration / dashTab1GridData.length).toFixed(2));
        // tab1AvgValue.IST = parseFloat((tab1AvgValue.IST / dashTab1GridData.length).toFixed(2));
        tab1AvgValue.OverallCompletionActivityWise = parseFloat((tab1AvgValue.OverallCompletionActivityWise / dashTab1GridData.length).toFixed(2));

        for (let key of Object.keys(milestoneMappingforTab1)) {
            tab1Graph3Data[milestoneMappingforTab1[key]].MilestoneWeightage = tmpTab1Graph3Data[milestoneMappingforTab1[key]].MilestoneWeightage === 0 ? 0 : parseFloat((tmpTab1Graph3Data[milestoneMappingforTab1[key]].MilestoneWeightageCompletion * 100 / tmpTab1Graph3Data[milestoneMappingforTab1[key]].MilestoneWeightage).toFixed(2));
            tab1Graph3Data[milestoneMappingforTab1[key]].MilestoneCompletion = parseFloat((tab1AvgValue[milestoneMappingforTab1[key]] / dashTab1GridData.length).toFixed(2));
            tab1Graph3Data[milestoneMappingforTab1[key]].MilestoneProgress = parseFloat((tmpTab1Graph3Data[milestoneMappingforTab1[key]].MilestoneWeightageCompletion / dashTab1GridData.length).toFixed(2));

            tab1AvgValue[milestoneMappingforTab1[key]] = parseFloat((tab1AvgValue[milestoneMappingforTab1[key]] / dashTab1GridData.length).toFixed(2));
        }

        dashTab1GridData.push(tab1AvgValue);
    }

    let tab2Data = await prepareTab2Data(projObj, distServices, allData, sorData);

    console.log("allData", allData);
    console.log("finalData", finalData);
    console.log("dashTab1GridData", dashTab1GridData);
    console.log("tab1Graph3Data", tab1Graph3Data);
    console.log("tab2Data", tab2Data);
    let dt = { "finalData": finalData, "dist": distServices, "dashTab1GridData": dashTab1GridData, "tab1Graph3Data": tab1Graph3Data, "tab2Data": tab2Data };
    return dt;
}

async function prepareTab2Data(projObj, serviceData, tagData, sorData) {
    let tab2Data = [];
    let tmpSrv = [];
    let tmpEquip = [];
    let tmpSor = 0;
    let spanCntSrv = 0;
    let spanCntEquip = 0;
    let prevObj = {};

    // for (let dt of sorData) {
    //     let tmpDt = dt.split("#");
    //     tmpSor.push({ EquipmentCode: tmpDt[0], EquipTag: tmpDt[1], OpenSorCnt: sorData.equipTagWiseSOR[tmpDt[0] + "#" + tmpDt[1]].openCount });
    // }

    for (let srv of Object.keys(serviceData)) {
        let tmpTagData = tagData.filter(x => x.ServiceCode === srv);
        tmpTagData.sort(sortByPropertyInAscending("EquipmentCode"));

        for (let dt of tmpTagData) {

            let obj = {
                ServiceCode: dt.ServiceCode,
                ServiceName: dt.ServiceName,
                EquipmentCode: dt.EquipmentCode,
                EquipmentName: dt.EquipmentName,
                EquipmentTag: dt.EquipmentTag,
                Area: dt.Area,
                Commissioning: dt.Commissioning && dt.Commissioning === 1 ? "Yes" : "No",
                SOR: dt.SOROpen === 0 ? "Yes" : "No",
                FireIntegration: dt.FireIntegrationService ? (dt.FireIntegrationFlag === "Yes" || dt.FireIntegrationFlag === "NA" ? "Yes" : "No") : "NA",
                IST: dt.IntegrationSystemTest ? (dt.ISTEquipOpen === 0 ? "Yes" : "No") : "NA"
            }

            if (projObj.ProjectType === "T&C") {
                obj.PreFunctional = dt.PreFuncApp ? (dt.PreFuncChecklist ? "Yes" : "No") : "NA";
                obj.Functional = dt.FuncApp ? (dt.FuncChecklist ? "Yes" : "No") : "NA";
                obj.Tender = dt.TenderInclusion ? (dt.Tender && dt.Tender === 1 ? "Yes" : "No") : "NA";
                obj.Method = dt.MethodStatement ? (dt.Method && dt.Method === 1 ? "Yes" : "No") : "NA";
                if (dt.DetailedReport) {
                    if (dt["Detailed SAT"] && dt["Detailed SAT"] === 1 && dt["Detailed FAT"] && dt["Detailed FAT"] === 1) {
                        obj.Detailed = "Yes";
                    }
                    else {
                        obj.Detailed = "No";
                    }
                }
                else {
                    obj.Detailed = "NA";
                }
                if (dt.Summary && dt.Summary === 1) {
                    obj.SummaryHOTOReport = "Yes";
                }
                else {
                    obj.SummaryHOTOReport = "No";
                }
                if (dt.DesignRevOpen === 0) {
                    obj.DesignDocumentReview = "Yes";
                }
                else {
                    obj.DesignDocumentReview = "No";
                }
            }
            if (projObj.ProjectType === "HOTO") {
                obj.PreFunctional = "NA";
                obj.Functional = "NA";
                obj.Tender = "NA";
                obj.Method = "NA";
                obj.Detailed = "NA";
                if (dt.HOTO && dt.HOTO === 1) {
                    obj.SummaryHOTOReport = "Yes";
                }
                else {
                    obj.SummaryHOTOReport = "No";
                }
                if (dt.DocumentRevOpen === 0) {
                    obj.DesignDocumentReview = "Yes";
                }
                else {
                    obj.DesignDocumentReview = "No";
                }
            }

            if (tmpEquip.indexOf(dt.ServiceCode + "#" + dt.EquipmentCode) === -1) {
                tmpEquip.push(dt.ServiceCode + "#" + dt.EquipmentCode);
                spanCntEquip = 0;
                if (Object.keys(prevObj).length > 0) {

                    let equipSor = sorData.equipWiseSOR[prevObj.EquipmentCode] ? sorData.equipWiseSOR[prevObj.EquipmentCode].openCount : 0;
                    let prevTagCnt = tagData.filter(x => x.ServiceCode === prevObj.ServiceCode && x.EquipmentCode === prevObj.EquipmentCode);
                    //No Tag line for prev Equipment
                    let tagObj = {
                        ServiceCode: prevObj.ServiceCode,
                        ServiceName: prevObj.ServiceName,
                        EquipmentCode: prevObj.EquipmentCode,
                        EquipmentName: prevObj.EquipmentName,
                        EquipmentTag: "No Tag",
                        Area: "",
                        Commissioning: "NA",
                        Tender: "NA",
                        Method: "NA",
                        Detailed: "NA",
                        SummaryHOTOReport: "NA",
                        PreFunctional: "NA",
                        Functional: "NA",
                        DesignDocumentReview: "NA",
                        SOR: (equipSor - tmpSor) <= 0 ? "Yes" : "No",
                        FireIntegration: "NA",
                        IST: "NA",
                        totalServiceRowSpan: serviceData[prevObj.ServiceCode].rowspanCnt + serviceData[prevObj.ServiceCode].srvEquipQty,
                        ServiceRowSpan: serviceData[prevObj.ServiceCode].rowspanCnt + serviceData[prevObj.ServiceCode].srvEquipQty - spanCntSrv,
                        totalEquipRowSpan: prevTagCnt.length + 1,
                        EquipRowSpan: 1
                    }
                    console.log("No Tag", tagObj)

                    tab2Data.push(tagObj);
                    tmpSor = 0;
                    spanCntSrv++;
                }
            }


            if (tmpSrv.indexOf(dt.ServiceCode) === -1) {
                tmpSrv.push(dt.ServiceCode);
                spanCntSrv = 0;
            }
            obj.totalServiceRowSpan = serviceData[dt.ServiceCode].rowspanCnt + serviceData[dt.ServiceCode].srvEquipQty; //All tags + No Tag line for each equipment
            obj.ServiceRowSpan = serviceData[dt.ServiceCode].rowspanCnt + serviceData[dt.ServiceCode].srvEquipQty - spanCntSrv;
            spanCntSrv++;

            let tagCnt = tagData.filter(x => x.ServiceCode === dt.ServiceCode && x.EquipmentCode === dt.EquipmentCode);
            obj.totalEquipRowSpan = tagCnt.length + 1; //All tags + No Tag line for equipment
            obj.EquipRowSpan = tagCnt.length + 1 - spanCntEquip;
            spanCntEquip++;

            tab2Data.push(obj);

            //To calculate No Tag Line;
            tmpSor = tmpSor + dt.SOROpen;
            prevObj = {
                ServiceCode: dt.ServiceCode,
                ServiceName: dt.ServiceName,
                EquipmentCode: dt.EquipmentCode,
                EquipmentName: dt.EquipmentName
            };
        }
    }
    //No tag line for last equip
    if (Object.keys(prevObj).length > 0) {
        //No Tag line for prev Equipment
        let equipSor = sorData.equipWiseSOR[prevObj.EquipmentCode] ? sorData.equipWiseSOR[prevObj.EquipmentCode].openCount : 0;
        let prevTagCnt = tagData.filter(x => x.ServiceCode === prevObj.ServiceCode && x.EquipmentCode === prevObj.EquipmentCode);

        let tagObj = {
            ServiceCode: prevObj.ServiceCode,
            ServiceName: prevObj.ServiceName,
            EquipmentCode: prevObj.EquipmentCode,
            EquipmentName: prevObj.EquipmentName,
            EquipmentTag: "No Tag",
            Area: "",
            Commissioning: "NA",
            Tender: "NA",
            Method: "NA",
            Detailed: "NA",
            SummaryHOTOReport: "NA",
            PreFunctional: "NA",
            Functional: "NA",
            DesignDocumentReview: "NA",
            SOR: (equipSor - tmpSor) <= 0 ? "Yes" : "No",
            FireIntegration: "NA",
            IST: "NA",
            totalServiceRowSpan: serviceData[prevObj.ServiceCode].rowspanCnt + serviceData[prevObj.ServiceCode].srvEquipQty,
            ServiceRowSpan: 1,
            totalEquipRowSpan: prevTagCnt.length + 1,
            EquipRowSpan: 1
        }
        console.log("No Tag", tagObj)

        tab2Data.push(tagObj);
    }
    return tab2Data;
}

export async function projectClosureDashboardCalc(projObj) {
    let allTabData = await calculateDashboard(projObj);

    //let dt = { "finalData": finalData, "dist": distServices, "dashTab1GridData": dashTab1GridData, "tab1Graph3Data": tab1Graph3Data, "tab2Data": tab2Data };

    let tmpObj = {
        "finalData": { "key": "Weightage", "dataType": "Array" },
        "dashTab1GridData": { "key": "OverallStatus", "dataType": "Array" },
        "tab1Graph3Data": { "key": "PhaseWiseCompletion", "dataType": "Json" },
        "tab2Data": { "key": "ProjectProgress", "dataType": "Array" }
    }

    let rowNo = 1;
    let counter = 0;
    let array = [];
    for (let dashboardKey of Object.keys(tmpObj)) {
        rowNo = 1;
        counter = 0;
        array = [];
        console.log(dashboardKey, tmpObj[dashboardKey].dataType, tmpObj[dashboardKey].key)
        if (tmpObj[dashboardKey].dataType === "Array") {
            console.log("Arr")
            for (let dt of allTabData[dashboardKey]) {
                dt.PK = tmpObj[dashboardKey].key + "#ProjectCode#" + projObj.ProjectCode;
                dt.SK = "RowNo#" + rowNo;
                dt.RowNo = rowNo;
                let tmpRes = AddDashboardDetails(dt);
                array.push(tmpRes);
                counter++;

                if (counter === 5) {
                    await Promise.all(array);
                    counter = 0;
                    array = [];
                }
                rowNo++;
            }
            if (array.length > 0) {
                await Promise.all(array);
            }
        }

        if (tmpObj[dashboardKey].dataType === "Json") {
            console.log("json")
            let dt = allTabData[dashboardKey];
            dt.PK = tmpObj[dashboardKey].key + "#ProjectCode#" + projObj.ProjectCode;
            dt.SK = "RowNo#" + rowNo;
            dt.RowNo = rowNo;
            await AddDashboardDetails(dt);
        }
    }
}

export async function fetchClosedProjectDashboard(projObj) {
    let tmpObj = {
        "finalData": { "key": "Weightage", "dataType": "Array" },
        "dashTab1GridData": { "key": "OverallStatus", "dataType": "Array" },
        "tab1Graph3Data": { "key": "PhaseWiseCompletion", "dataType": "Json" },
        "tab2Data": { "key": "ProjectProgress", "dataType": "Array" }
    }

    let results = [];
    let allDashboardData = {}
    for (let dashboardKey of Object.keys(tmpObj)) {
        let obj = {
            PK: tmpObj[dashboardKey].key + "#ProjectCode#" + projObj.ProjectCode,
            SK: "RowNo#"
        }
        let tmpRes = await getChecklistParameterDetails(obj);
        results.push({ "key": dashboardKey, "tabName": tmpObj[dashboardKey], "query": tmpRes });
    }
    if (results.length > 0) {
        for (let item of results) {
            let tmpRes1 = await Promise.resolve(item.query)
            if (item.tabName.dataType === "Array") {
                allDashboardData[item.key] = tmpRes1;
            }
            if (item.tabName.dataType === "Json") {
                if (tmpRes1.length > 0) {
                    allDashboardData[item.key] = tmpRes1[0];
                }
                else {
                    allDashboardData[item.key] = {};
                }
            }
        }
    }
    console.log("allDashboardData", allDashboardData)
    return allDashboardData;
}

export async function fetchProjectDashboard(projObj) {
    let dt = {};
    if (projObj.DeleteIndication === "Closed") {
        dt = await fetchClosedProjectDashboard(projObj);
    }
    else {
        dt = await calculateDashboard(projObj);
    }
    return dt;
}


export const AddDashboardDetails = async (Project) => {
    let url = `${TRANS_URL}/projectmaster/adddashboarddetails`;
    const response = await axios
        .post(url, Project)
        .then(function (resp) {
            return resp;
        })
        .catch(function (error) {
            console.log("error => ", error);
            return { data: { message: "Unable to find request", status: false } };
        });
    return response.data;
};
