import React, { Component } from 'react';
import { Form } from "react-bootstrap";
import PropTypes from 'prop-types';
import "./AutoComplete.css";

export class Autocomplete extends Component {
  static propTypes = {
    options: PropTypes.instanceOf(Array).isRequired
  };
  state = {
    activeOption: 0,
    filteredOptions: [],
    showOptions: false,
    userInput: ''
  };

  onBlurEvent = (e) => {
    if (this.props.onBlurFunc) {
      this.props.onBlurFunc(this.props.name, e.currentTarget.value);
    }
    this.setState({
      showOptions: false
    });
  }
  onChange = (e) => {
    const { options } = this.props;
    const userInput = e.currentTarget.value;

    let filteredOptions = [];
    if (this.props.type === "Number") {
      filteredOptions = options.filter(
        (optionName) =>
          optionName.toString().indexOf(userInput.toString()) > -1
      );
    } else {
      filteredOptions = options.filter(
        (optionName) =>
          optionName.toLowerCase().indexOf(userInput.toLowerCase()) > -1
      );
    }

    this.setState({
      activeOption: 0,
      filteredOptions,
      showOptions: true,
      userInput: e.currentTarget.value
    });
    this.props.onChangeEv(this.props.name, e.currentTarget.value)
  };

  onClick = (e) => {
    this.setState({
      activeOption: 0,
      filteredOptions: [],
      showOptions: false,
      userInput: e.currentTarget.innerText
    });

    this.props.onChangeEv(this.props.name, e.currentTarget.innerText)
  };
  onKeyDown = (e) => {
    const { activeOption, filteredOptions } = this.state;

    if (e.keyCode === 13) {
      this.setState({
        activeOption: 0,
        showOptions: false,
        userInput: filteredOptions[activeOption]
      });

      this.props.onChangeEv(this.props.name, filteredOptions[activeOption])
    } else if (e.keyCode === 38) {
      if (activeOption === 0) {
        return;
      }
      this.setState({ activeOption: activeOption - 1 });
    } else if (e.keyCode === 40) {
      if (activeOption === filteredOptions.length - 1) {
        console.log(activeOption);
        return;
      }
      this.setState({ activeOption: activeOption + 1 });
    }
  };

  render() {
    const {
      onChange,
      onClick,
      onKeyDown,
      onBlurEvent,

      state: { activeOption, filteredOptions, showOptions, userInput }
    } = this;
    let optionList;
    if (showOptions && userInput) {
      if (filteredOptions.length) {
        optionList = (
          <ul className="options">
            {filteredOptions.map((optionName, index) => {
              let className;
              if (index === activeOption) {
                className = 'option-active';
              }
              return (
                <li className={className} key={optionName} onClick={onClick}>
                  {optionName}
                </li>
              );
            })}
          </ul>
        );
      }
    }
    return (
      <React.Fragment>
        {this.props.type === "textarea" ?
          <Form.Control
            as="textarea"
            id={this.props.id}
            type={this.props.type}
            name={this.props.name}
            rows={this.props.rows}
            maxLength={this.props.maxLength}
            placeholder=" "
            required
            value={this.props.value}
            readOnly={this.props.readonly}
            onChange={(e) => { onChange(e) }}
            onBlur={(e) => { onBlurEvent(e) }}
            onKeyDown={onKeyDown} />
          :
          <Form.Control
            id={this.props.id}
            type={this.props.type}
            name={this.props.name}
            maxLength={this.props.maxLength}
            placeholder=" "
            required
            value={this.props.value}
            readOnly={this.props.readonly}
            onChange={(e) => { onChange(e) }}
            onBlur={(e) => { onBlurEvent(e) }}
            onKeyDown={onKeyDown} />
        }
        {/* <input
            type="text"
            className="search-box"
            onChange={onChange}
            onKeyDown={onKeyDown}
            value={userInput}
          /> */}
        <Form.Label className="frm-label" for={this.props.id} title={this.props.fieldName}>{this.props.fieldName} {this.props.optionalField && <span className="italicFont"> (optional)</span>}</Form.Label>
        {optionList}
      </React.Fragment>
    );
  }
}

export default Autocomplete;
